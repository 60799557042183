import React from "react";
import localData from "../../../localData";
import { Button, ContactCard } from "../../../components";

export default function GetInTouch() {
    const { email, location } = localData.svgs;
    const { favicon} = localData.images;

    return (
        <div className="get-in-touch">
            <h2 className="display-2 get-in-touch-title">Support Guide and detailed Documentation</h2>
            <div className="card-group">
                <ContactCard
                    title="DOCUMENTATION"
                    imageIcon={favicon}
                    link="https://support.blockchain-ads.com"
                    linkName="https://support.blockchain-ads.com"
                />
            </div>
            <br />
            <br />

            <h2 className="display-2 get-in-touch-title">Get in Touch</h2>
            <div className="cards-group">
                <ContactCard
                    title="CONTACT US ON TELEGRAM"
                    icon={location}
                    link="https://t.me/BlockchainAds_Support"
                    linkName="https://t.me/BlockchainAds_Support"
                />
                <ContactCard
                    title="SEND AS AN EMAIL"
                    icon={email}
                    link="mailto:contact@blockchain-ads.com"
                    linkName="contact@blockchain-ads.com"
                />
            </div>
        </div>
    );
}
