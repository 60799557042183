import React from "react";
import localData from "../../../localData";

export default function GeneralQuestions() {
    const { circleQuestion } = localData.svgs;

    return (
        <div className="general-questions">
            <h2 className="display-2 general-questions-title">General Questions</h2>

            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">What is Blockchain-Ads?</h5>
                    <p className="question-text">Web3-native Advertising platform that is build for performance.</p>
                </div>
            </div>

            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">
                        How is it different from other Web3 Advertising solutions?
                    </h5>
                    <p className="question-text">
                        Blockchain-Ads leverages an innovative proprietary technology known as web3cookie. By
                        uniquely linking a wallet address to a device, whether it's a phone, laptop, or
                        tablet, we enable advertisers to target their audience with pinpoint precision. This
                        approach allows brands to reach individuals based on their specific on-chain behavior,
                        setting Blockchain-Ads apart from any other advertising solution.
                    </p>
                </div>
            </div>
            
            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">How can I use the platform?</h5>
                    <p className="question-text">
                        Click on the "Start Now" button and schedule an onboarding call. Go live in 24-48h.
                        The media team will guide you step by step and provide fully managed service.
                    </p>
                </div>
            </div>

            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">Where are the ads displayed?</h5>
                    <p className="question-text">
                        Displayed on a network of 9000+ apps and websites, some of them are bscscan.com,
                        etherscan.io, coingecko.com, dextools.com, bitcoin.com, blockchain.com...
                    </p>
                </div>
            </div>

            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">What Ad formats does Blockchain-Ads support?</h5>
                    <p className="question-text">Display ads - Image ads, HTML ads and direct ads.</p>
                </div>
            </div>

            <div className="row question">
                <div className="question-icon">{circleQuestion}</div>
                <div className="wrapper">
                    <h5 className="display-6 question-title">
                        Why is Blockchain-Ads performance-based, on what basis?
                    </h5>
                    <p className="question-text">
                        Blockchain-Ads isn't just another platform; it's a paradigm shift in the web3
                        advertising space. Our performance-based approach stems from the tangible results we
                        deliver. Outperforming any other paid acquisition tool by an astonishing degree, we
                        make a steadfast promise to our BCA advertisers: Expect to see a 60% reduction in your
                        cost per acquisition and a doubling of conversions, including user acquisitions,
                        sales, and revenue, all within the first 30-45 days. It's not just advertising; it's a
                        strategic partnership driving your success.
                    </p>
                </div>
            </div>
           
        </div>
    );
}
