import React from "react";
import { Carousel } from "../../components";
import localData from "../../localData";

function CarouselCard({ title, description, name, position }) {
    return (
        <div className={`card carousel-card`}>
            <h4 className="card-title">{title}</h4>
            <div className="card-description">{description}</div>
            <div className="card-name">{name}</div>
            <div className="card-position text-success">{position}</div>
        </div>
    );
}

export default function Testimonials() {
    const { frame } = localData.svgs;
    const items = [
        {
            description:
                "We are running more than 45 campaigns for 10 different projects. The BCA team is outperforming anything else we have seen. Reminds me on Facebook Ads in 2014. WILD. We're able to scale our campaigns massively. Increase number of token holders, sales, increase volume, High value traders…..",
            name: "Adam Jordan",
            position: "CEO - Social Kick Marketing Agency",
        },
        {
            description:
                "The BCA team are moving on the fly. Definitely the next generation of Advertising Technology. Highly effective web3 campaigns",
            name: "Bryan Colligan",
            position: "CEO - Alpha Growth",
        },
        {
            description:
                "Easily the best Web3 advertising software on the market, right now. Love the automated detailed report on pre-sales campaign transactions",
            name: "Zack",
            position: "Marketing Director at eTukTuk",
        },
        {
            description:
                "We use Blockchain-Ads for our Token Launch - It is the only Marketing solution that can actually measure perfoamnce and Return on the Advertising spend. Highly Recommend them",
            name: "Jules",
            position: "Marketing Lead at The Standard",
        },
    ];

    return (
        <section className="testimonials" id="carousel">
            <div className="wrapper">
                <div className="testimonials-icon">{frame}</div>
                <h2 className="testimonials-title">
                    <span className="text-success">1k+</span> Successful Campaigns
                </h2>

                <Carousel items={items} Card={CarouselCard} options={{ spaceBetween: 50, breakpoints: {} }} />
            </div>
        </section>
    );
}
