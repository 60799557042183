import React from "react";
import { useGlobalContext } from "../../context";
import { motion } from "framer-motion";
import { Button } from "../../components";
import { useParams, useNavigate } from "react-router-dom";
import localData from "../../localData";

export default function Error() {
    const { fade } = useGlobalContext().animations;

    const navigate = useNavigate()
    const {notFound} = localData.images

    return (
        <motion.main className="main error-page" {...fade}>
               <section className="error">
                <div className="container">
                    <Button
                        variant="contained"
                        name="return"
                        color="dark"
                        className="rounded-pill"
                        onClick={() => navigate(-1)}
                    />
                    <div className="wrapper">
                        <div className="error-img">
                            <img src={notFound}  />
                        </div>
                    </div>
                    <h2 className="error-title">Not Found</h2>
                </div>
            </section>
        </motion.main>
    );
}
