import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../context";

const endDate = new Date(); // today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
    startDate,
    endDate,
    key: "selection",
};

const ITEMSPERPAGE = [
    { title: 5, isSelected: false, id: uuidv4() },
    { title: 10, isSelected: true, id: uuidv4() },
    { title: 15, isSelected: false, id: uuidv4() },
];

export default function useReportsState() {
    const [reports, setReports] = useState([]);
    const [reportsPage, setReportsPage] = useState(1);
    const [reportsPageSize, setReportsPageSize] = useState(10);
    const [reportsTotalPages, setReportsTotalPages] = useState(10);

    const [reportsItemsPerPage, setReportsItemsPerPage] = useState(ITEMSPERPAGE);

    const resetReportsPagination = () => {
        setReportsPage(1);
        setReportsPageSize(10);
        setReportsTotalPages(10);
        setReportsItemsPerPage(ITEMSPERPAGE);
        setReportSelectionRange(SELECTIONRANGE);
    };

    useEffect(() => {
        const reportsStates = JSON.parse(localStorage.getItem("reportsStates"));
        if (reportsStates?.pageSize) {
            let tempItemsPerPage = [...reportsItemsPerPage];
            tempItemsPerPage = tempItemsPerPage.map((item) => ({
                title: item.title,
                isSelected: item.title === reportsStates.pageSize,
                id: item.id,
            }));
            setReportsItemsPerPage(tempItemsPerPage);
            setReportsPageSize(reportsStates.pageSize);
        }
    }, []);

    const [reportSelectionRange, setReportSelectionRange] = useState(SELECTIONRANGE);

    useEffect(() => {
        const selectionRange = JSON.parse(localStorage.getItem("reportsSelectionRange"));
        if (selectionRange) {
            const { startDate, endDate } = selectionRange;
            setReportSelectionRange({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                key: "selection",
            });
        }
    }, []);

    return {
        reportsPage,
        setReportsPage,
        reportsPageSize,
        setReportsPageSize,
        reportsTotalPages,
        setReportsTotalPages,
        reportsItemsPerPage,
        setReportsItemsPerPage,
        reports,
        setReports,
        reportSelectionRange,
        setReportSelectionRange,
        resetReportsPagination,
    };
}
