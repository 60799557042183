import React, { useState, useEffect } from "react";
import { Modal, Button } from "../../../components";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import localData from "../../../localData";

const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal,
    arrowRight,
    penV2,
    textPreloader,
    BloomingImage,
    info,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function TrackingHelp() {
    const [showTrackingHelpModal, setShowTrackingHelpModal] = useState("none");
    return (
        <div className="tracking-help">
            <div
                className="help"
                data-tooltip-id="tooltip-tracking"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    <div className="instructions">
                        <div className="instructions-header">
                            Step-by-step instructions for Google Tag Manager
                        </div>
                        <div className="instructions-body">
                            <ul>
                                <li data-number="1">
                                    Create a new tag and select 'Custom HTML' as the tag type.
                                </li>
                                <li data-number="2">
                                    Paste the code snippet in the HTML field, set the trigger to 'All Pages'
                                    and save the tag.
                                </li>
                                <li data-number="3">Submit the changes and publish the container.</li>
                            </ul>
                        </div>
                    </div>
                )}
            >
                Show step-by-step instructions <div className="help-icon">{info}</div>
                <Tooltip id="tooltip-tracking" className="custom-tooltip tracking-instructions-tooltip" />
            </div>

            <strong>Need more help with integrating the script?</strong>
            <p className="text-style-1">View dedicated integration guides in our docs:</p>
            <Modal
                Child={HelpContent}
                className="tracking-help-modal modal-dialog-centered"
                display={showTrackingHelpModal}
                setDisplay={setShowTrackingHelpModal}
                Toggler={() => (
                    <Button
                        className="tracking-help-btn"
                        name="Integration docs"
                        // startIcon={info}
                        size="sm"
                        color="pale-dark"
                        variant="outlined"
                        onClick={() => setShowTrackingHelpModal("block")}
                    />
                )}
            ></Modal>
        </div>
    );
}

const HelpContent = ({ setIsOpen, setActiveTab }) => {
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">Documentation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
            <ul>
        <li data-number='1.'>Copy the Custom Script:</li>
        <li data-number='2.'>Visit the Blockchain-Ads website at app.blockchain-ads.com/data-sources/website.</li>
        <li data-number='3.'>
            In the table displaying your websites, locate the row corresponding to your website.
        </li>
        <li data-number='4.'>
            Click on the code icon next to your website row to copy the custom script specific to your website.
        </li>
        <li data-number='5.'>Add the Blockchain-Ads script to your Google Tag Manager project:</li>
        <li data-number='6.'>Visit tagmanager.google.com.</li>
        <li data-number='7.'>Select the project you want to add the Blockchain-Ads script to.</li>
        <li data-number='8.'>Click on Tags in the left sidebar.</li>
        <li data-number='9.'>Click on New.</li>
        <li data-number='10.'>Click on Tag Configuration.</li>
        <li data-number='11.'>Click on Custom HTML.</li>
        <li data-number='12.'>Paste the Blockchain-Ads script you copied in step 1 into the HTML field.</li>
        <li data-number='13.'>Click on Triggering.</li>
        <li data-number='14.'>Click on All Pages.</li>
    </ul>
    <br />
    <p>
        After pasting the script and selecting the All Pages trigger, your screen should look like this. You can now click on the Save button in the top-right corner. Please note that the "tagID" in the script is customized for your website and will be different from the one shown in the screenshot.
    </p>
    <br />
    <br />
    <ul>
        <li data-number='15.'>Click on Save.</li>
        <li data-number='16.'>Click on Submit.</li>
        <li data-number='17.'>Click on Publish.</li>
    </ul>
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="close-btn"
                        name="Close"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                </div>
            </div>
        </>
    );
};
