import React, { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import localData from "../../localData";
import { useGlobalContext } from "../../context";
import { useStateContext } from "../../stateContext";
import { Button, UploadCard, Modal } from "../../components";
import useUtil from "../../hooks/useUtil";

export default function UploadImage({
    // newCampaign,
    // setNewCampaign,
    selectedImages,
    setSelectedImages,
    disabled,
}) {
    const { placeholder } = localData.images;

    const { errorAlert, isDarkModeEnabled, campaignsState } = useGlobalContext();
    const { setDisplayCreativesModal, creativesState, setCreativesState } = campaignsState;

    const inputRef = useRef();

    const convertBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleGifImage = async (file) => {
        // console.log(file, size)
        const base64Image = await convertBase64(file);
        const image = new Image();
        image.src = base64Image;
        image.onload = (e) => {
            const width = e.currentTarget.naturalWidth;
            const height = e.currentTarget.naturalHeight;
            const size = file.size / 1024;
            let errorMessage = "";
            if (width !== 900 || height !== 600) {
                errorMessage = "Error: Incorrect image's size. Please upload 900x600px";
            }
            if (size > 720) {
                if (errorMessage !== "") errorMessage += "\n";
                errorMessage += "Error: Incorrect image's size. Please upload maximum 720kb";
            }
            if (errorMessage) {
                console.log(errorMessage);
                errorAlert(errorMessage);
                return;
            }
            // console.log(width, height, size);
            let item = {
                file,
                preview: URL.createObjectURL(file),
                image: base64Image,
                id: uuidv4(),
                status: 1,
                title: file.name,
                size: file.size,
                type: file.type, // Store the image type
            };

            setSelectedImages((prevImages) => [...prevImages, item]);
        };
    };

    // const handleCheckDimensions = (files) => {
    //     let returnResult = undefined;

    //     returnResult = Array.from(files).map((file) => {
    //         const img = new Image();
    //         img.src = URL.createObjectURL(file);

    //         return new Promise((resolve, reject) => {
    //             img.onload = function () {
    //                 // const { width, height } = img;
    //                 // if (
    //                 //     (width === 492 && height === 328 && file.size <= 750 * 1024) ||
    //                 //     (width === 900 && height === 600 && file.size <= 750 * 1024)
    //                 // ) {
    //                 //     resolve();
    //                 // } else {
    //                 //     const errors = [];

    //                 //     if (width !== 492 || height !== 328) {
    //                 //         errors.push("only 492x328 or 900x600 dimensions allowed");
    //                 //     } else if (width !== 600 || height !== 900) {
    //                 //         errors.push("only 492x328 or 900x600 dimensions allowed");
    //                 //     }

    //                 //     if (file.size > 750 * 1024) {
    //                 //         errors.push("Image size exceeds 750KB");
    //                 //     }

    //                 //     reject(new Error(errors.join("\n")));
    //                 // }
    //                 // const { width, height } = img;
    //                 resolve()
    //                 // if (file.size <= 720 * 1024) {
    //                 //     resolve();
    //                 // } else {
    //                 //     const errors = [];

    //                 //     if (file.size > 720 * 1024) {
    //                 //         errors.push("Image size exceeds 720KB");
    //                 //     }

    //                 //     reject(new Error(errors.join("\n")));
    //                 // }
    //             };
    //         });
    //     });

    //     return Promise.all(returnResult);
    // };
    const { getFileFromCanvas, resizeImage } = useUtil();
    const handleFileChange = async (e) => {
        if (!e.target.files[0]) return;
        if (e.target.files[0].type === "image/gif") {
            handleGifImage(e.target.files[0]);
            return;
        }
        const resizedDataURL = await resizeImage(e.target.files[0], 1080, 720);
        let image64 = await convertBase64(resizedDataURL);
        setCreativesState((prev) => ({
            ...prev,
            uploadedImage: image64,
        }));
        setDisplayCreativesModal("block");
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
    };

    const handleNewAdSetClick = () => {
        // inputRef.current.click();
    };

    return (
        <>
            <div
                // onDragOver={handleDragOver}
                // onDragEnter={handleDragEnter}
                // onDrop={handleFileDrop}
                className={`upload-image drag-drop-container ${disabled ? "on-hold" : ""}`}
            >
                <input
                    ref={inputRef}
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    className="file-input"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    // multiple
                />
                <div
                    className="wrapper"
                    onClick={() => inputRef.current.click()}
                    data-guide="upload-ads-tutorial-1"
                >
                    <div className="image-wrapper">
                        {/* Placeholder image */}
                        <img className={`file-image`} src={placeholder} />
                    </div>
                    <strong> 
                        Drag your files here, or <span className="text-primary">browse</span>
                    </strong>
                    <p className="info">
                        {" "}
                        {/* We support JPG, PNG, GIF up to 700kb. 3:2 ratio, 7:5 or 5:7 ratio. Recommended sizes
                        are 492 x 328 and 900 x 600. */}
                        We support JPG, PNG, GIF up to 700kb. 7:5 ratio. <br /> Recommended size is 900 x 600.
                    </p>
                </div>

                {/* Placeholder image remains above this line */}

                {/* <div className="border border-dashed" onDrop={handleFileDrop} onDragOver={handleDragOver}>
                    <Button
                    name="+ New Ad Set"
                    color="primary"
                    size="sm"
                    variant="contained"
                    onClick={handleNewAdSetClick}
                    />
                </div> */}
            </div>
            {/* {!selectedImages.length ? (
                ""
            ) : (
                <div className="upload-cards">
                    {selectedImages?.map((image, index) => (
                        <UploadCard
                            key={index}
                            {...{
                                image,
                                index,
                                // selectedImages,
                                setSelectedImages,
                                // newCampaign,
                                // setNewCampaign,
                            }}
                        />
                    ))}
                </div>
            )} */}
        </>
    );
}
