import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "./context";

export const StateContext = createContext();

export default function StateProvider({ children }) {
    return <StateContext.Provider value={{}}>{children}</StateContext.Provider>;
}

export const useStateContext = () => useContext(StateContext);
