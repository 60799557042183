import React, { useEffect, useState } from "react";
import {
    Field,
    Switch,
    Button,
    MultiSelect,
    Autocomplete,
    MultiSelectSearch,
    CountriesAndBid,
    Modal,
} from "../../../components";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter";
import ReactDOMServer from "react-dom/server";
import Joyride, { STATUS } from "react-joyride";

const { budget, date, arrowLeft, arrowRight, close, settings, info, trashV2 } = localData.svgs;

let timeout = "";

export default function Targeting({ setActiveTab, activeTab }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const {
        requestedData,
        campaignState,
        setCampaignState,
        campaignWizardStatus,
        isDarkModeEnabled,
        campaignsState,
    } = useGlobalContext();

    const { addCommas, formatLargeNumber } = useFormatter();

    const { campaignTabs, setCampaignTabs } = campaignsState;

    const [categoryOptions, setCategoryOptions] = useState([
        { title: "Decentralized Finance", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Blockchain Gaming", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Marketplace", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Infrastructure", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "NFT", isSelected: false, size: "720000", id: uuidv4() },
        { title: "DEX", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "CEX", isSelected: false, size: "8000000", id: uuidv4() },
        // LATEST OPTIONS
        { title: "Crypto Trading", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "Web3 Casino", isSelected: false, size: "1000000", id: uuidv4() },
        { title: "Other", isSelected: false, size: "", id: uuidv4() },
    ]);

    const [web3AudienceOptions, setWeb3AudienceOptions] = useState([
        { title: "Custom", isSelected: false, size: "", id: uuidv4() },
        { title: "BTC Holders", isSelected: false, size: "6400000", id: uuidv4() },
        { title: "ETH Holders", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "MATIC Holders", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "NEAR Holders", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "SOL Holders", isSelected: false, size: "1800000", id: uuidv4() },
        { title: "BNB Holders", isSelected: false, size: "1440000", id: uuidv4() },
        { title: "Stable Coin Holders", isSelected: false, size: "6000000", id: uuidv4() },
        { title: "DeFi", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "NFT Collectors", isSelected: false, size: "1440000", id: uuidv4() },
        { title: "Web3 Natives", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Blockchain Technology", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "Crypto Whales", isSelected: false, size: "240000", id: uuidv4() },
        { title: "IDO/ICO Participants", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Tokenized Assets Holders", isSelected: false, size: "3600000", id: uuidv4() },
        // LATEST OPTIONS
        { title: "Crypto Traders", isSelected: false, size: "24000000", id: uuidv4() },
        { title: "Casino Players", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Casinos Power Users", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Sports Betting", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "PlayToEarn", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "HOLDers", isSelected: false, size: "12000000", id: uuidv4() },
        { title: "Crypto News", isSelected: false, size: "7200000", id: uuidv4() },
        { title: "DeFi Trading", isSelected: false, size: "3600000", id: uuidv4() },
        { title: "Finance Users", isSelected: false, size: "7200000", id: uuidv4() },
        { title: "DeFi News", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "NFT Traders", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "NFT Whales", isSelected: false, size: "120000", id: uuidv4() },
        { title: "NFT Marketplaces", isSelected: false, size: "3600000", id: uuidv4() },
        { title: "Blockchain Gaming", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "Web3 Gamers", isSelected: false, size: "4320000", id: uuidv4() },
    ]);

    const [chainTargetingOptions, setChainTargetingOptions] = useState([
        { title: "Custom", value: "custom", isSelected: false, size: "", id: uuidv4() },
        { title: "Ethereum", value: "ETH", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "Binance Smart Chain", value: "BSC", isSelected: false, size: "1440000", id: uuidv4() },
        { title: "Polygon", value: "MATIC", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Solana", value: "SOL", isSelected: false, size: "1800000", id: uuidv4() },
        { title: "NEAR", value: "NEAR", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Arbitrum", value: "ARB", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Optimism", value: "OPT", isSelected: false, size: "720000", id: uuidv4() },
        { title: "Ripple", value: "XRP", isSelected: false, size: "1920000", id: uuidv4() },
        { title: "Cardano", value: "ADA", isSelected: false, size: "1680000", id: uuidv4() },
        { title: "Tron", value: "TRX", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Polkadot", value: "DOT", isSelected: false, size: "480000", id: uuidv4() },
        { title: "Avalanche", value: "AVAX", isSelected: false, size: "720000", id: uuidv4() },
        { title: "Stellar", value: "XLM", isSelected: false, size: "1680000", id: uuidv4() },
        { title: "Chainlink", value: "LINK", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Monero", value: "XMR", isSelected: false, size: "192000", id: uuidv4() },
        { title: "Cosmos", value: "ATOM", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Hedera", value: "HBAR", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Mantle", value: "MNT", isSelected: false, size: "60000", id: uuidv4() },
        { title: "Aptos", value: "APT", isSelected: false, size: "14400", id: uuidv4() },
        { title: "Chronos", value: "CHR", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Algorand", value: "ALGO", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Tezos", value: "XTZ", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Eos", value: "EOS", isSelected: false, size: "360000", id: uuidv4() },
        { title: "Partisia", value: "PRSS", isSelected: false, size: "480000", id: uuidv4() },
        { title: "Zksync", value: "ZKSYNC", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Bitcoin", value: "BTC", isSelected: false, size: "6400000", id: uuidv4() },
        { title: "Base", value: "BASE", isSelected: false, size: "480000", id: uuidv4() },
    ]);

    const [walletTargetingOptions, setWalletTargetingOptions] = useState([
        { title: "MetaMask", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "Trust", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "MEV", isSelected: false, size: "24000", id: uuidv4() },
        { title: "Coinomi", isSelected: false, size: "600000", id: uuidv4() },
        { title: "Coinbase", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "OKX", isSelected: false, size: "3840000", id: uuidv4() },
        { title: "Exodus", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Rainbow", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Argent", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Safe", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Zerion", isSelected: false, size: "84000", id: uuidv4() },
    ]);

    const [deviceTypeOptions, setDeviceTypeOptions] = useState([
        { title: "Mobile", isSelected: false, percentage: "58.21", id: uuidv4() },
        { title: "Desktop", isSelected: false, percentage: "41.79", id: uuidv4() },
    ]);

    const [operativeSystemOptions, setOperativeSystemOptions] = useState([
        { title: "Android", isSelected: false, percentage: "41.00", id: uuidv4() },
        { title: "Windows", isSelected: false, percentage: "40.00", id: uuidv4() },
        { title: "IOS", isSelected: false, percentage: "17.00", id: uuidv4() },
        { title: "Linux", isSelected: false, percentage: "2.00", id: uuidv4() },
        { title: "macOS", isSelected: false, percentage: "10.00", id: uuidv4() },
    ]);

    const [browserOptions, setBrowserOptions] = useState([
        { title: "Google Chrome", isSelected: false, percentage: "64.75", id: uuidv4() },
        { title: "Safari", isSelected: false, percentage: "19.66", id: uuidv4() },
        { title: "Microsoft Edge", isSelected: false, percentage: "4.32", id: uuidv4() },
        { title: "Firefox", isSelected: false, percentage: "2.98", id: uuidv4() },
        { title: "Opera", isSelected: false, percentage: "2.40", id: uuidv4() },
        { title: "Other", isSelected: false, percentage: "5.89", id: uuidv4() },
    ]);

    // const [countriesOptions, setCountriesOptions] = useState([
    //     {
    //         id: 403915,
    //         value: "ad",
    //         title: "Andorra",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403916,
    //         value: "ae",
    //         title: "United Arab Emirates",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403918,
    //         value: "af",
    //         title: "Afghanistan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403920,
    //         value: "ag",
    //         title: "Antigua And Barbuda",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403921,
    //         value: "ai",
    //         title: "Anguilla",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403923,
    //         value: "al",
    //         title: "Albania",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403924,
    //         value: "am",
    //         title: "Armenia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403926,
    //         value: "an",
    //         title: "Netherlands Antilles",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403928,
    //         value: "ao",
    //         title: "Angola",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403931,
    //         value: "aq",
    //         title: "Antarctica",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403932,
    //         value: "ar",
    //         title: "Argentina",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403934,
    //         value: "as",
    //         title: "American Samoa",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403936,
    //         value: "at",
    //         title: "Austria",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403937,
    //         value: "au",
    //         title: "Australia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403939,
    //         value: "aw",
    //         title: "Aruba",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403940,
    //         value: "ax",
    //         title: "Aland Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403942,
    //         value: "az",
    //         title: "Azerbaijan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403944,
    //         value: "ba",
    //         title: "Bosnia And Herzegowina",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403946,
    //         value: "bb",
    //         title: "Barbados",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403947,
    //         value: "bd",
    //         title: "Bangladesh",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403948,
    //         value: "be",
    //         title: "Belgium",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403950,
    //         value: "bf",
    //         title: "Burkina Faso",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403951,
    //         value: "bg",
    //         title: "Bulgaria",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403953,
    //         value: "bh",
    //         title: "Bahrain",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403955,
    //         value: "bi",
    //         title: "Burundi",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403957,
    //         value: "bj",
    //         title: "Benin",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403958,
    //         value: "bl",
    //         title: "Saint Barthelemy",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403959,
    //         value: "bm",
    //         title: "Bermuda",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403961,
    //         value: "bn",
    //         title: "Brunei Darussalam",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403963,
    //         value: "bo",
    //         title: "Bolivia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403965,
    //         value: "bq",
    //         title: "Bonaire/sint Eustatius/saba",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403966,
    //         value: "br",
    //         title: "Brazil",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403969,
    //         value: "bs",
    //         title: "Bahamas",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403971,
    //         value: "bt",
    //         title: "Bhutan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403972,
    //         value: "bv",
    //         title: "Bouvet Island",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403973,
    //         value: "bw",
    //         title: "Botswana",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403974,
    //         value: "by",
    //         title: "Belarus",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403975,
    //         value: "bz",
    //         title: "Belize",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403976,
    //         value: "ca",
    //         title: "Canada",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403979,
    //         value: "cc",
    //         title: "Cocos (keeling) Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403980,
    //         value: "cd",
    //         title: "Democratic Republic Of The Congo",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403982,
    //         value: "cf",
    //         title: "Central African Republic",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403983,
    //         value: "cg",
    //         title: "Congo",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403984,
    //         value: "ch",
    //         title: "Switzerland",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403987,
    //         value: "ci",
    //         title: "Cote D Ivoire",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403988,
    //         value: "ck",
    //         title: "Cook Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403989,
    //         value: "cl",
    //         title: "Chile",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403990,
    //         value: "cm",
    //         title: "Cameroon",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403991,
    //         value: "cn",
    //         title: "China",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403992,
    //         value: "co",
    //         title: "Colombia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403994,
    //         value: "cr",
    //         title: "Costa Rica",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403997,
    //         value: "cu",
    //         title: "Cuba",
    //         isSelected: false,
    //     },
    //     {
    //         id: 403999,
    //         value: "cv",
    //         title: "Cape Verde",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404001,
    //         value: "cw",
    //         title: "Curacao",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404002,
    //         value: "cx",
    //         title: "Christmas Island",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404003,
    //         value: "cy",
    //         title: "Cyprus",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404005,
    //         value: "cz",
    //         title: "Czech Republic",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404007,
    //         value: "de",
    //         title: "Germany",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404011,
    //         value: "dj",
    //         title: "Djibouti",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404012,
    //         value: "dk",
    //         title: "Denmark",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404013,
    //         value: "dm",
    //         title: "Dominica",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404014,
    //         value: "do",
    //         title: "Dominican Republic",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404016,
    //         value: "dz",
    //         title: "Algeria",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404018,
    //         value: "ec",
    //         title: "Ecuador",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404019,
    //         value: "ee",
    //         title: "Estonia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404021,
    //         value: "eg",
    //         title: "Egypt",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404022,
    //         value: "eh",
    //         title: "Western Sahara",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404026,
    //         value: "er",
    //         title: "Eritrea",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404027,
    //         value: "es",
    //         title: "Spain",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404029,
    //         value: "et",
    //         title: "Ethiopia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404035,
    //         value: "fi",
    //         title: "Finland",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404038,
    //         value: "fj",
    //         title: "Fiji",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404040,
    //         value: "fk",
    //         title: "Falkland Islands (malvinas)",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404041,
    //         value: "fm",
    //         title: "Federated States Of Micronesia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404042,
    //         value: "fo",
    //         title: "Faroe Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404044,
    //         value: "fr",
    //         title: "France",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404047,
    //         value: "ga",
    //         title: "Gabon",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404049,
    //         value: "gb",
    //         title: "United Kingdom",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404050,
    //         value: "gd",
    //         title: "Grenada",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404052,
    //         value: "ge",
    //         title: "Georgia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404053,
    //         value: "gf",
    //         title: "French Guiana",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404054,
    //         value: "gg",
    //         title: "Guernsey",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404055,
    //         value: "gh",
    //         title: "Ghana",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404056,
    //         value: "gi",
    //         title: "Gibraltar",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404057,
    //         value: "gl",
    //         title: "Greenland",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404059,
    //         value: "gm",
    //         title: "Gambia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404060,
    //         value: "gn",
    //         title: "Guinea",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404062,
    //         value: "gp",
    //         title: "Guadeloupe",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404063,
    //         value: "gq",
    //         title: "Equatorial Guinea",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404064,
    //         value: "gr",
    //         title: "Greece",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404065,
    //         value: "gs",
    //         title: "South Georgia / South Sandwich Isl",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404066,
    //         value: "gt",
    //         title: "Guatemala",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404067,
    //         value: "gu",
    //         title: "Guam",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404070,
    //         value: "gw",
    //         title: "Guinea-bissau",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404071,
    //         value: "gy",
    //         title: "Guyana",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404075,
    //         value: "hk",
    //         title: "Hong Kong",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404076,
    //         value: "hm",
    //         title: "Heard And Mc Donald Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404077,
    //         value: "hn",
    //         title: "Honduras",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404079,
    //         value: "hr",
    //         title: "Croatia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404081,
    //         value: "ht",
    //         title: "Haiti",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404083,
    //         value: "hu",
    //         title: "Hungary",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404088,
    //         value: "id",
    //         title: "Indonesia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404090,
    //         value: "ie",
    //         title: "Ireland",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404096,
    //         value: "il",
    //         title: "Israel",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404097,
    //         value: "im",
    //         title: "Isle Of Man",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404098,
    //         value: "in",
    //         title: "India",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404099,
    //         value: "io",
    //         title: "British Indian Ocean Territory",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404104,
    //         value: "iq",
    //         title: "Iraq",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404105,
    //         value: "ir",
    //         title: "Iran (islamic Republic Of)",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404106,
    //         value: "is",
    //         title: "Iceland",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404108,
    //         value: "it",
    //         title: "Italy",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404112,
    //         value: "je",
    //         title: "Jersey",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404113,
    //         value: "jm",
    //         title: "Jamaica",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404114,
    //         value: "jo",
    //         title: "Jordan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404115,
    //         value: "jp",
    //         title: "Japan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404118,
    //         value: "ke",
    //         title: "Kenya",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404119,
    //         value: "kg",
    //         title: "Kyrgyzstan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404121,
    //         value: "kh",
    //         title: "Cambodia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404122,
    //         value: "ki",
    //         title: "Kiribati",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404128,
    //         value: "km",
    //         title: "Comoros",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404130,
    //         value: "kn",
    //         title: "Saint Kitts And Nevis",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404133,
    //         value: "kp",
    //         title: "North Korea",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404134,
    //         value: "kr",
    //         title: "South Korea",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404139,
    //         value: "kw",
    //         title: "Kuwait",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404141,
    //         value: "ky",
    //         title: "Cayman Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404143,
    //         value: "kz",
    //         title: "Kazakhstan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404144,
    //         value: "la",
    //         title: "Lao Peoples Democratic Republic",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404146,
    //         value: "lb",
    //         title: "Lebanon",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404148,
    //         value: "lc",
    //         title: "Saint Lucia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404150,
    //         value: "li",
    //         title: "Liechtenstein",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404152,
    //         value: "lk",
    //         title: "Sri Lanka",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404155,
    //         value: "lr",
    //         title: "Liberia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404156,
    //         value: "ls",
    //         title: "Lesotho",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404157,
    //         value: "lt",
    //         title: "Lithuania",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404159,
    //         value: "lu",
    //         title: "Luxembourg",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404161,
    //         value: "lv",
    //         title: "Latvia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404163,
    //         value: "ly",
    //         title: "Libyan Arab Jamahiriya",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404164,
    //         value: "ma",
    //         title: "Morocco",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404165,
    //         value: "mc",
    //         title: "Monaco",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404166,
    //         value: "md",
    //         title: "Moldova",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404167,
    //         value: "me",
    //         title: "Montenegro",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404168,
    //         value: "mf",
    //         title: "Saint Martin",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404169,
    //         value: "mg",
    //         title: "Madagascar",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404171,
    //         value: "mh",
    //         title: "Marshall Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404174,
    //         value: "mk",
    //         title: "Macedonia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404176,
    //         value: "ml",
    //         title: "Mali",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404178,
    //         value: "mm",
    //         title: "Myanmar",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404179,
    //         value: "mn",
    //         title: "Mongolia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404181,
    //         value: "mo",
    //         title: "Macau",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404183,
    //         value: "mp",
    //         title: "Northern Mariana Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404184,
    //         value: "mq",
    //         title: "Martinique",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404185,
    //         value: "mr",
    //         title: "Mauritania",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404187,
    //         value: "ms",
    //         title: "Montserrat",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404189,
    //         value: "mt",
    //         title: "Malta",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404191,
    //         value: "mu",
    //         title: "Mauritius",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404192,
    //         value: "mv",
    //         title: "Maldives",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404193,
    //         value: "mw",
    //         title: "Malawi",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404194,
    //         value: "mx",
    //         title: "Mexico",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404195,
    //         value: "my",
    //         title: "Malaysia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404197,
    //         value: "mz",
    //         title: "Mozambique",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404198,
    //         value: "na",
    //         title: "Namibia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404202,
    //         value: "nc",
    //         title: "New Caledonia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404204,
    //         value: "ne",
    //         title: "Niger",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404206,
    //         value: "nf",
    //         title: "Norfolk Island",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404207,
    //         value: "ng",
    //         title: "Nigeria",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404209,
    //         value: "ni",
    //         title: "Nicaragua",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404210,
    //         value: "nl",
    //         title: "Netherlands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404213,
    //         value: "no",
    //         title: "Norway",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404215,
    //         value: "np",
    //         title: "Nepal",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404216,
    //         value: "nr",
    //         title: "Nauru",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404218,
    //         value: "nu",
    //         title: "Niue",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404221,
    //         value: "nz",
    //         title: "New Zealand",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404226,
    //         value: "om",
    //         title: "Oman",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404233,
    //         value: "pa",
    //         title: "Panama",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404235,
    //         value: "pe",
    //         title: "Peru",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404236,
    //         value: "pf",
    //         title: "French Polynesia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404237,
    //         value: "pg",
    //         title: "Papua New Guinea",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404238,
    //         value: "ph",
    //         title: "Philippines",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404240,
    //         value: "pk",
    //         title: "Pakistan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404241,
    //         value: "pl",
    //         title: "Poland",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404244,
    //         value: "pm",
    //         title: "St. Pierre And Miquelon",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404245,
    //         value: "pn",
    //         title: "Pitcairn",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404246,
    //         value: "pr",
    //         title: "Puerto Rico",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404247,
    //         value: "ps",
    //         title: "Palestinian Territories",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404249,
    //         value: "pt",
    //         title: "Portugal",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404252,
    //         value: "pw",
    //         title: "Palau",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404253,
    //         value: "py",
    //         title: "Paraguay",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404254,
    //         value: "qa",
    //         title: "Qatar",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404256,
    //         value: "re",
    //         title: "Reunion",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404259,
    //         value: "ro",
    //         title: "Romania",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404261,
    //         value: "rs",
    //         title: "Serbia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404262,
    //         value: "ru",
    //         title: "Russian Federation",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404264,
    //         value: "rw",
    //         title: "Rwanda",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404266,
    //         value: "sa",
    //         title: "Saudi Arabia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404270,
    //         value: "sb",
    //         title: "Solomon Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404271,
    //         value: "sc",
    //         title: "Seychelles",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404273,
    //         value: "sd",
    //         title: "Sudan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404275,
    //         value: "se",
    //         title: "Sweden",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404277,
    //         value: "sg",
    //         title: "Singapore",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404279,
    //         value: "sh",
    //         title: "St. Helena",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404280,
    //         value: "si",
    //         title: "Slovenia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404282,
    //         value: "sj",
    //         title: "Svalbard And Jan Mayen Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404283,
    //         value: "sk",
    //         title: "Slovakia (slovak Republic)",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404285,
    //         value: "sl",
    //         title: "Sierra Leone",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404287,
    //         value: "sm",
    //         title: "San Marino",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404289,
    //         value: "sn",
    //         title: "Senegal",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404291,
    //         value: "so",
    //         title: "Somalia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404294,
    //         value: "sr",
    //         title: "Suriname",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404296,
    //         value: "ss",
    //         title: "South Sudan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404298,
    //         value: "st",
    //         title: "Sao Tome And Principe",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404301,
    //         value: "sv",
    //         title: "El Salvador",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404304,
    //         value: "sx",
    //         title: "Sint Maarten",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404305,
    //         value: "sy",
    //         title: "Syrian Arab Republic",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404306,
    //         value: "sz",
    //         title: "Swaziland",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404310,
    //         value: "tc",
    //         title: "Turks And Caicos Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404311,
    //         value: "td",
    //         title: "Chad",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404313,
    //         value: "tf",
    //         title: "French Southern Territories",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404314,
    //         value: "tg",
    //         title: "Togo",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404316,
    //         value: "th",
    //         title: "Thailand",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404319,
    //         value: "tj",
    //         title: "Tajikistan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404320,
    //         value: "tk",
    //         title: "Tokelau",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404322,
    //         value: "tl",
    //         title: "Timor-leste",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404324,
    //         value: "tm",
    //         title: "Turkmenistan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404325,
    //         value: "tn",
    //         title: "Tunisia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404327,
    //         value: "to",
    //         title: "Tonga",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404329,
    //         value: "tr",
    //         title: "Turkey",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404332,
    //         value: "tt",
    //         title: "Trinidad And Tobago",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404334,
    //         value: "tv",
    //         title: "Tuvalu",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404335,
    //         value: "tw",
    //         title: "Taiwan Province Of China",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404338,
    //         value: "tz",
    //         title: "Tanzania",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404339,
    //         value: "ua",
    //         title: "Ukraine",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404341,
    //         value: "ug",
    //         title: "Uganda",
    //         isSelected: false,
    //     },
    //     {
    //         id: 404344,
    //         value: "um",
    //         title: "Us Minor Outlying Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405138,
    //         value: "us",
    //         title: "United States",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405236,
    //         value: "uy",
    //         title: "Uruguay",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405237,
    //         value: "uz",
    //         title: "Uzbekistan",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405249,
    //         value: "va",
    //         title: "Holy See (vatican City State)",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405397,
    //         value: "vc",
    //         title: "Saint Vincent And The Grenadines",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405414,
    //         value: "ve",
    //         title: "Venezuela",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405603,
    //         value: "vg",
    //         title: "British Virgin Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 405608,
    //         value: "vi",
    //         title: "Us Virgin Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 406023,
    //         value: "vn",
    //         title: "Viet Nam",
    //         isSelected: false,
    //     },
    //     {
    //         id: 406254,
    //         value: "vu",
    //         title: "Vanuatu",
    //         isSelected: false,
    //     },
    //     {
    //         id: 406744,
    //         value: "wf",
    //         title: "Wallis And Futuna Islands",
    //         isSelected: false,
    //     },
    //     {
    //         id: 407209,
    //         value: "ws",
    //         title: "Samoa",
    //         isSelected: false,
    //     },
    //     {
    //         id: 407472,
    //         value: "ye",
    //         title: "Yemen",
    //         isSelected: false,
    //     },
    //     {
    //         id: 407551,
    //         value: "yt",
    //         title: "Mayotte",
    //         isSelected: false,
    //     },
    //     {
    //         id: 407575,
    //         value: "za",
    //         title: "South Africa",
    //         isSelected: false,
    //     },
    //     {
    //         id: 407843,
    //         value: "zm",
    //         title: "Zambia",
    //         isSelected: false,
    //     },
    //     {
    //         id: 407904,
    //         value: "zw",
    //         title: "Zimbabwe",
    //     },
    // ]);
    const [countriesOptions, setCountriesOptions] = useState([
        {
            id: 403915,
            value: "ad",
            title: "Andorra",
            size: 950,
            isSelected: false,
        },
        {
            id: 403916,
            value: "ae",
            title: "United Arab Emirates",
            size: 120203,
            isSelected: false,
        },
        {
            id: 403918,
            value: "af",
            title: "Afghanistan",
            size: 525560,
            isSelected: false,
        },
        {
            id: 403920,
            value: "ag",
            title: "Antigua And Barbuda",
            size: 1212,
            isSelected: false,
        },
        {
            id: 403921,
            value: "ai",
            title: "Anguilla",
            size: 0,
            isSelected: false,
        },
        {
            id: 403923,
            value: "al",
            title: "Albania",
            size: 33142,
            isSelected: false,
        },
        {
            id: 403924,
            value: "am",
            title: "Armenia",
            size: 33337,
            isSelected: false,
        },
        {
            id: 403926,
            value: "an",
            title: "Netherlands Antilles",
            size: 0,
            isSelected: false,
        },
        {
            id: 403928,
            value: "ao",
            title: "Angola",
            size: 427068,
            isSelected: false,
        },
        {
            id: 403931,
            value: "aq",
            title: "Antarctica",
            size: 0,
            isSelected: false,
        },
        {
            id: 403932,
            value: "ar",
            title: "Argentina",
            size: 554903,
            isSelected: false,
        },
        {
            id: 403934,
            value: "as",
            title: "American Samoa",
            size: 0,
            isSelected: false,
        },
        {
            id: 403936,
            value: "at",
            title: "Austria",
            size: 0,
            isSelected: false,
        },
        {
            id: 403937,
            value: "au",
            title: "Australia",
            size: 306492,
            isSelected: false,
        },
        {
            id: 403939,
            value: "aw",
            title: "Aruba",
            size: 0,
            isSelected: false,
        },
        {
            id: 403940,
            value: "ax",
            title: "Aland Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 403942,
            value: "az",
            title: "Azerbaijan",
            size: 121768,
            isSelected: false,
        },
        {
            id: 403944,
            value: "ba",
            title: "Bosnia And Herzegowina",
            size: 0,
            isSelected: false,
        },
        {
            id: 403946,
            value: "bb",
            title: "Barbados",
            size: 3168,
            isSelected: false,
        },
        {
            id: 403947,
            value: "bd",
            title: "Bangladesh",
            size: 2098079,
            isSelected: false,
        },
        {
            id: 403948,
            value: "be",
            title: "Belgium",
            size: 141096,
            isSelected: false,
        },
        {
            id: 403950,
            value: "bf",
            title: "Burkina Faso",
            size: 279799,
            isSelected: false,
        },
        {
            id: 403951,
            value: "bg",
            title: "Bulgaria",
            size: 78244,
            isSelected: false,
        },
        {
            id: 403953,
            value: "bh",
            title: "Bahrain",
            size: 21656,
            isSelected: false,
        },
        {
            id: 403955,
            value: "bi",
            title: "Burundi",
            size: 154068,
            isSelected: false,
        },
        {
            id: 403957,
            value: "bj",
            title: "Benin",
            size: 163692,
            isSelected: false,
        },
        {
            id: 403958,
            value: "bl",
            title: "Saint Barthelemy",
            size: 0,
            isSelected: false,
        },
        {
            id: 403959,
            value: "bm",
            title: "Bermuda",
            size: 0,
            isSelected: false,
        },
        {
            id: 403961,
            value: "bn",
            title: "Brunei Darussalam",
            size: 5298,
            isSelected: false,
        },
        {
            id: 403963,
            value: "bo",
            title: "Bolivia",
            size: 144655,
            isSelected: false,
        },
        {
            id: 403965,
            value: "bq",
            title: "Bonaire/sint Eustatius/saba",
            size: 0,
            isSelected: false,
        },
        {
            id: 403966,
            value: "br",
            title: "Brazil",
            size: 2597069,
            isSelected: false,
        },
        {
            id: 403969,
            value: "bs",
            title: "Bahamas",
            size: 0,
            isSelected: false,
        },
        {
            id: 403971,
            value: "bt",
            title: "Bhutan",
            size: 9330,
            isSelected: false,
        },
        {
            id: 403972,
            value: "bv",
            title: "Bouvet Island",
            size: 0,
            isSelected: false,
        },
        {
            id: 403973,
            value: "bw",
            title: "Botswana",
            size: 30204,
            isSelected: false,
        },
        {
            id: 403974,
            value: "by",
            title: "Belarus",
            size: 109512,
            isSelected: false,
        },
        {
            id: 403975,
            value: "bz",
            title: "Belize",
            size: 5298,
            isSelected: false,
        },
        {
            id: 403976,
            value: "ca",
            title: "Canada",
            size: 481160,
            isSelected: false,
        },
        {
            id: 403979,
            value: "cc",
            title: "Cocos (keeling) Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 403980,
            value: "cd",
            title: "Democratic Republic Of The Congo",
            size: 0,
            isSelected: false,
        },
        {
            id: 403982,
            value: "cf",
            title: "Central African Republic",
            size: 64548,
            isSelected: false,
        },
        {
            id: 403983,
            value: "cg",
            title: "Congo",
            size: 1227145,
            isSelected: false,
        },
        {
            id: 403984,
            value: "ch",
            title: "Switzerland",
            size: 0,
            isSelected: false,
        },
        {
            id: 403987,
            value: "ci",
            title: "Cote D Ivoire",
            size: 340675,
            isSelected: false,
        },
        {
            id: 403988,
            value: "ck",
            title: "Cook Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 403989,
            value: "cl",
            title: "Chile",
            size: 233907,
            isSelected: false,
        },
        {
            id: 403990,
            value: "cm",
            title: "Cameroon",
            size: 342290,
            isSelected: false,
        },
        {
            id: 403991,
            value: "cn",
            title: "China",
            size: 0,
            isSelected: false,
        },
        {
            id: 403992,
            value: "co",
            title: "Colombia",
            size: 650973,
            isSelected: false,
        },
        {
            id: 403994,
            value: "cr",
            title: "Costa Rica",
            size: 62491,
            isSelected: false,
        },
        {
            id: 403997,
            value: "cu",
            title: "Cuba",
            size: 133359,
            isSelected: false,
        },
        {
            id: 403999,
            value: "cv",
            title: "Cape Verde",
            size: 0,
            isSelected: false,
        },
        {
            id: 404001,
            value: "cw",
            title: "Curacao",
            size: 0,
            isSelected: false,
        },
        {
            id: 404002,
            value: "cx",
            title: "Christmas Island",
            size: 0,
            isSelected: false,
        },
        {
            id: 404003,
            value: "cy",
            title: "Cyprus",
            size: 15378,
            isSelected: false,
        },
        {
            id: 404005,
            value: "cz",
            title: "Czech Republic",
            size: 129118,
            isSelected: false,
        },
        {
            id: 404007,
            value: "de",
            title: "Germany",
            size: 1013901,
            isSelected: false,
        },
        {
            id: 404011,
            value: "dj",
            title: "Djibouti",
            size: 12912,
            isSelected: false,
        },
        {
            id: 404012,
            value: "dk",
            title: "Denmark",
            size: 70587,
            isSelected: false,
        },
        {
            id: 404013,
            value: "dm",
            title: "Dominica",
            size: 870,
            isSelected: false,
        },
        {
            id: 404014,
            value: "do",
            title: "Dominican Republic",
            size: 130896,
            isSelected: false,
        },
        {
            id: 404016,
            value: "dz",
            title: "Algeria",
            size: 553871,
            isSelected: false,
        },
        {
            id: 404018,
            value: "ec",
            title: "Ecuador",
            size: 220752,
            isSelected: false,
        },
        {
            id: 404019,
            value: "ee",
            title: "Estonia",
            size: 15898,
            isSelected: false,
        },
        {
            id: 404021,
            value: "eg",
            title: "Egypt",
            size: 1352599,
            isSelected: false,
        },
        {
            id: 404022,
            value: "eh",
            title: "Western Sahara",
            size: 0,
            isSelected: false,
        },
        {
            id: 404026,
            value: "er",
            title: "Eritrea",
            size: 44688,
            isSelected: false,
        },
        {
            id: 404027,
            value: "es",
            title: "Spain",
            size: 570492,
            isSelected: false,
        },
        {
            id: 404029,
            value: "et",
            title: "Ethiopia",
            size: 1518325,
            isSelected: false,
        },
        {
            id: 404035,
            value: "fi",
            title: "Finland",
            size: 66860,
            isSelected: false,
        },
        {
            id: 404038,
            value: "fj",
            title: "Fiji",
            size: 11095,
            isSelected: false,
        },
        {
            id: 404040,
            value: "fk",
            title: "Falkland Islands (malvinas)",
            size: 0,
            isSelected: false,
        },
        {
            id: 404041,
            value: "fm",
            title: "Federated States Of Micronesia",
            size: 1368,
            isSelected: false,
        },
        {
            id: 404042,
            value: "fo",
            title: "Faroe Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 404044,
            value: "fr",
            title: "France",
            size: 789560,
            isSelected: false,
        },
        {
            id: 404047,
            value: "ga",
            title: "Gabon",
            size: 28716,
            isSelected: false,
        },
        {
            id: 404049,
            value: "gb",
            title: "United Kingdom",
            size: 847910,
            isSelected: false,
        },
        {
            id: 404050,
            value: "gd",
            title: "Grenada",
            size: 1488,
            isSelected: false,
        },
        {
            id: 404052,
            value: "ge",
            title: "Georgia",
            size: 0,
            isSelected: false,
        },
        {
            id: 404053,
            value: "gf",
            title: "French Guiana",
            size: 0,
            isSelected: false,
        },
        {
            id: 404054,
            value: "gg",
            title: "Guernsey",
            size: 0,
            isSelected: false,
        },
        {
            id: 404055,
            value: "gh",
            title: "Ghana",
            size: 409462,
            isSelected: false,
        },
        {
            id: 404056,
            value: "gi",
            title: "Gibraltar",
            size: 0,
            isSelected: false,
        },
        {
            id: 404057,
            value: "gl",
            title: "Greenland",
            size: 0,
            isSelected: false,
        },
        {
            id: 404059,
            value: "gm",
            title: "Gambia",
            size: 31644,
            isSelected: false,
        },
        {
            id: 404060,
            value: "gn",
            title: "Guinea",
            size: 179172,
            isSelected: false,
        },
        {
            id: 404062,
            value: "gp",
            title: "Guadeloupe",
            size: 0,
            isSelected: false,
        },
        {
            id: 404063,
            value: "gq",
            title: "Equatorial Guinea",
            size: 18698,
            isSelected: false,
        },
        {
            id: 404064,
            value: "gr",
            title: "Greece",
            size: 125790,
            isSelected: false,
        },
        {
            id: 404065,
            value: "gs",
            title: "South Georgia / South Sandwich Isl",
            size: 0,
            isSelected: false,
        },
        {
            id: 404066,
            value: "gt",
            title: "Guatemala",
            size: 217351,
            isSelected: false,
        },
        {
            id: 404067,
            value: "gu",
            title: "Guam",
            size: 0,
            isSelected: false,
        },
        {
            id: 404070,
            value: "gw",
            title: "Guinea-bissau",
            size: 24312,
            isSelected: false,
        },
        {
            id: 404071,
            value: "gy",
            title: "Guyana",
            size: 9476,
            isSelected: false,
        },
        {
            id: 404075,
            value: "hk",
            title: "Hong Kong",
            size: 88958,
            isSelected: false,
        },
        {
            id: 404076,
            value: "hm",
            title: "Heard And Mc Donald Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 404077,
            value: "hn",
            title: "Honduras",
            size: 128436,
            isSelected: false,
        },
        {
            id: 404079,
            value: "hr",
            title: "Croatia",
            size: 46092,
            isSelected: false,
        },
        {
            id: 404081,
            value: "ht",
            title: "Haiti",
            size: 135336,
            isSelected: false,
        },
        {
            id: 404083,
            value: "hu",
            title: "Hungary",
            size: 115275,
            isSelected: false,
        },
        {
            id: 404088,
            value: "id",
            title: "Indonesia",
            size: 3330409,
            isSelected: false,
        },
        {
            id: 404090,
            value: "ie",
            title: "Ireland",
            size: 62516,
            isSelected: false,
        },
        {
            id: 404096,
            value: "il",
            title: "Israel",
            size: 117564,
            isSelected: false,
        },
        {
            id: 404097,
            value: "im",
            title: "Isle Of Man",
            size: 0,
            isSelected: false,
        },
        {
            id: 404098,
            value: "in",
            title: "India",
            size: 17006078,
            isSelected: false,
        },
        {
            id: 404099,
            value: "io",
            title: "British Indian Ocean Territory",
            size: 0,
            isSelected: false,
        },
        {
            id: 404104,
            value: "iq",
            title: "Iraq",
            size: 550190,
            isSelected: false,
        },
        {
            id: 404105,
            value: "ir",
            title: "Iran (islamic Republic Of)",
            size: 1070054,
            isSelected: false,
        },
        {
            id: 404106,
            value: "is",
            title: "Iceland",
            size: 4504,
            isSelected: false,
        },
        {
            id: 404108,
            value: "it",
            title: "Italy",
            size: 707647,
            isSelected: false,
        },
        {
            id: 404112,
            value: "je",
            title: "Jersey",
            size: 0,
            isSelected: false,
        },
        {
            id: 404113,
            value: "jm",
            title: "Jamaica",
            size: 32112,
            isSelected: false,
        },
        {
            id: 404114,
            value: "jo",
            title: "Jordan",
            size: 133956,
            isSelected: false,
        },
        {
            id: 404115,
            value: "jp",
            title: "Japan",
            size: 1476348,
            isSelected: false,
        },
        {
            id: 404118,
            value: "ke",
            title: "Kenya",
            size: 673184,
            isSelected: false,
        },
        {
            id: 404119,
            value: "kg",
            title: "Kyrgyzstan",
            size: 80735,
            isSelected: false,
        },
        {
            id: 404121,
            value: "kh",
            title: "Cambodia",
            size: 200666,
            isSelected: false,
        },
        {
            id: 404122,
            value: "ki",
            title: "Kiribati",
            size: 1440,
            isSelected: false,
        },
        {
            id: 404128,
            value: "km",
            title: "Comoros",
            size: 10889,
            isSelected: false,
        },
        {
            id: 404130,
            value: "kn",
            title: "Saint Kitts And Nevis",
            size: 571,
            isSelected: false,
        },
        {
            id: 404133,
            value: "kp",
            title: "North Korea",
            size: 308631,
            isSelected: false,
        },
        {
            id: 404134,
            value: "kr",
            title: "South Korea",
            size: 623696,
            isSelected: false,
        },
        {
            id: 404139,
            value: "kw",
            title: "Kuwait",
            size: 57864,
            isSelected: false,
        },
        {
            id: 404141,
            value: "ky",
            title: "Cayman Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 404143,
            value: "kz",
            title: "Kazakhstan",
            size: 235541,
            isSelected: false,
        },
        {
            id: 404144,
            value: "la",
            title: "Lao Peoples Democratic Republic",
            size: 92995,
            isSelected: false,
        },
        {
            id: 404146,
            value: "lb",
            title: "Lebanon",
            size: 82260,
            isSelected: false,
        },
        {
            id: 404148,
            value: "lc",
            title: "Saint Lucia",
            size: 1992,
            isSelected: false,
        },
        {
            id: 404150,
            value: "li",
            title: "Liechtenstein",
            size: 0,
            isSelected: false,
        },
        {
            id: 404152,
            value: "lk",
            title: "Sri Lanka",
            size: 266280,
            isSelected: false,
        },
        {
            id: 404155,
            value: "lr",
            title: "Liberia",
            size: 67536,
            isSelected: false,
        },
        {
            id: 404156,
            value: "ls",
            title: "Lesotho",
            size: 26352,
            isSelected: false,
        },
        {
            id: 404157,
            value: "lt",
            title: "Lithuania",
            size: 31925,
            isSelected: false,
        },
        {
            id: 404159,
            value: "lu",
            title: "Luxembourg",
            size: 7930,
            isSelected: false,
        },
        {
            id: 404161,
            value: "lv",
            title: "Latvia",
            size: 21549,
            isSelected: false,
        },
        {
            id: 404163,
            value: "ly",
            title: "Libyan Arab Jamahiriya",
            size: 0,
            isSelected: false,
        },
        {
            id: 404164,
            value: "ma",
            title: "Morocco",
            size: 446643,
            isSelected: false,
        },
        {
            id: 404165,
            value: "mc",
            title: "Monaco",
            size: 438,
            isSelected: false,
        },
        {
            id: 404166,
            value: "md",
            title: "Moldova",
            size: 30563,
            isSelected: false,
        },
        {
            id: 404167,
            value: "me",
            title: "Montenegro",
            size: 7260,
            isSelected: false,
        },
        {
            id: 404168,
            value: "mf",
            title: "Saint Martin",
            size: 0,
            isSelected: false,
        },
        {
            id: 404169,
            value: "mg",
            title: "Madagascar",
            size: 380421,
            isSelected: false,
        },
        {
            id: 404171,
            value: "mh",
            title: "Marshall Islands",
            size: 720,
            isSelected: false,
        },
        {
            id: 404174,
            value: "mk",
            title: "Macedonia",
            size: 22041,
            isSelected: false,
        },
        {
            id: 404176,
            value: "ml",
            title: "Mali",
            size: 278840,
            isSelected: false,
        },
        {
            id: 404178,
            value: "mm",
            title: "Myanmar",
            size: 662726,
            isSelected: false,
        },
        {
            id: 404179,
            value: "mn",
            title: "Mongolia",
            size: 41249,
            isSelected: false,
        },
        {
            id: 404181,
            value: "mo",
            title: "Macau",
            size: 0,
            isSelected: false,
        },
        {
            id: 404183,
            value: "mp",
            title: "Northern Mariana Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 404184,
            value: "mq",
            title: "Martinique",
            size: 0,
            isSelected: false,
        },
        {
            id: 404185,
            value: "mr",
            title: "Mauritania",
            size: 61188,
            isSelected: false,
        },
        {
            id: 404187,
            value: "ms",
            title: "Montserrat",
            size: 0,
            isSelected: false,
        },
        {
            id: 404189,
            value: "mt",
            title: "Malta",
            size: 6456,
            isSelected: false,
        },
        {
            id: 404191,
            value: "mu",
            title: "Mauritius",
            size: 15192,
            isSelected: false,
        },
        {
            id: 404192,
            value: "mv",
            title: "Maldives",
            size: 6372,
            isSelected: false,
        },
        {
            id: 404193,
            value: "mw",
            title: "Malawi",
            size: 275052,
            isSelected: false,
        },
        {
            id: 404194,
            value: "mx",
            title: "Mexico",
            size: 1588494,
            isSelected: false,
        },
        {
            id: 404195,
            value: "my",
            title: "Malaysia",
            size: 402886,
            isSelected: false,
        },
        {
            id: 404197,
            value: "mz",
            title: "Mozambique",
            size: 401035,
            isSelected: false,
        },
        {
            id: 404198,
            value: "na",
            title: "Namibia",
            size: 31741,
            isSelected: false,
        },
        {
            id: 404202,
            value: "nc",
            title: "New Caledonia",
            size: 0,
            isSelected: false,
        },
        {
            id: 404204,
            value: "ne",
            title: "Niger",
            size: 337396,
            isSelected: false,
        },
        {
            id: 404206,
            value: "nf",
            title: "Norfolk Island",
            size: 0,
            isSelected: false,
        },
        {
            id: 404207,
            value: "ng",
            title: "Nigeria",
            size: 2690929,
            isSelected: false,
        },
        {
            id: 404209,
            value: "ni",
            title: "Nicaragua",
            size: 83580,
            isSelected: false,
        },
        {
            id: 404210,
            value: "nl",
            title: "Netherlands",
            size: 0,
            isSelected: false,
        },
        {
            id: 404213,
            value: "no",
            title: "Norway",
            size: 66033,
            isSelected: false,
        },
        {
            id: 404215,
            value: "np",
            title: "Nepal",
            size: 383856,
            isSelected: false,
        },
        {
            id: 404216,
            value: "nr",
            title: "Nauru",
            size: 0,
            isSelected: false,
        },
        {
            id: 404218,
            value: "nu",
            title: "Niue",
            size: 0,
            isSelected: false,
        },
        {
            id: 404221,
            value: "nz",
            title: "New Zealand",
            size: 61772,
            isSelected: false,
        },
        {
            id: 404226,
            value: "om",
            title: "Oman",
            size: 55224,
            isSelected: false,
        },
        {
            id: 404233,
            value: "pa",
            title: "Panama",
            size: 55068,
            isSelected: false,
        },
        {
            id: 404235,
            value: "pe",
            title: "Peru",
            size: 413934,
            isSelected: false,
        },
        {
            id: 404236,
            value: "pf",
            title: "French Polynesia",
            size: 0,
            isSelected: false,
        },
        {
            id: 404237,
            value: "pg",
            title: "Papua New Guinea",
            size: 123984,
            isSelected: false,
        },
        {
            id: 404238,
            value: "ph",
            title: "Philippines",
            size: 1401167,
            isSelected: false,
        },
        {
            id: 404240,
            value: "pk",
            title: "Pakistan",
            size: 2885828,
            isSelected: false,
        },
        {
            id: 404241,
            value: "pl",
            title: "Poland",
            size: 453449,
            isSelected: false,
        },
        {
            id: 404244,
            value: "pm",
            title: "St. Pierre And Miquelon",
            size: 0,
            isSelected: false,
        },
        {
            id: 404245,
            value: "pn",
            title: "Pitcairn",
            size: 0,
            isSelected: false,
        },
        {
            id: 404246,
            value: "pr",
            title: "Puerto Rico",
            size: 0,
            isSelected: false,
        },
        {
            id: 404247,
            value: "ps",
            title: "Palestinian Territories",
            size: 66084,
            isSelected: false,
        },
        {
            id: 404249,
            value: "pt",
            title: "Portugal",
            size: 125608,
            isSelected: false,
        },
        {
            id: 404252,
            value: "pw",
            title: "Palau",
            size: 0,
            isSelected: false,
        },
        {
            id: 404253,
            value: "py",
            title: "Paraguay",
            size: 90736,
            isSelected: false,
        },
        {
            id: 404254,
            value: "qa",
            title: "Qatar",
            size: 34795,
            isSelected: false,
        },
        {
            id: 404256,
            value: "re",
            title: "Reunion",
            size: 0,
            isSelected: false,
        },
        {
            id: 404259,
            value: "ro",
            title: "Romania",
            size: 0,
            isSelected: false,
        },
        {
            id: 404261,
            value: "rs",
            title: "Serbia",
            size: 79764,
            isSelected: false,
        },
        {
            id: 404262,
            value: "ru",
            title: "Russian Federation",
            size: 1733332,
            isSelected: false,
        },
        {
            id: 404264,
            value: "rw",
            title: "Rwanda",
            size: 164592,
            isSelected: false,
        },
        {
            id: 404266,
            value: "sa",
            title: "Saudi Arabia",
            size: 447516,
            isSelected: false,
        },
        {
            id: 404270,
            value: "sb",
            title: "Solomon Islands",
            size: 8885,
            isSelected: false,
        },
        {
            id: 404271,
            value: "sc",
            title: "Seychelles",
            size: 1284,
            isSelected: false,
        },
        {
            id: 404273,
            value: "sd",
            title: "Sudan",
            size: 589251,
            isSelected: false,
        },
        {
            id: 404275,
            value: "se",
            title: "Sweden",
            size: 125804,
            isSelected: false,
        },
        {
            id: 404277,
            value: "sg",
            title: "Singapore",
            size: 68443,
            isSelected: false,
        },
        {
            id: 404279,
            value: "sh",
            title: "St. Helena",
            size: 0,
            isSelected: false,
        },
        {
            id: 404280,
            value: "si",
            title: "Slovenia",
            size: 25284,
            isSelected: false,
        },
        {
            id: 404282,
            value: "sj",
            title: "Svalbard And Jan Mayen Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 404283,
            value: "sk",
            title: "Slovakia (slovak Republic)",
            size: 64656,
            isSelected: false,
        },
        {
            id: 404285,
            value: "sl",
            title: "Sierra Leone",
            size: 0,
            isSelected: false,
        },
        {
            id: 404287,
            value: "sm",
            title: "San Marino",
            size: 0,
            isSelected: false,
        },
        {
            id: 404289,
            value: "sn",
            title: "Senegal",
            size: 226899,
            isSelected: false,
        },
        {
            id: 404291,
            value: "so",
            title: "Somalia",
            size: 204792,
            isSelected: false,
        },
        {
            id: 404294,
            value: "sr",
            title: "Suriname",
            size: 7356,
            isSelected: false,
        },
        {
            id: 404296,
            value: "ss",
            title: "South Sudan",
            size: 164182,
            isSelected: false,
        },
        {
            id: 404298,
            value: "st",
            title: "Sao Tome And Principe",
            size: 2772,
            isSelected: false,
        },
        {
            id: 404301,
            value: "sv",
            title: "El Salvador",
            size: 78248,
            isSelected: false,
        },
        {
            id: 404304,
            value: "sx",
            title: "Sint Maarten",
            size: 0,
            isSelected: false,
        },
        {
            id: 404305,
            value: "sy",
            title: "Syrian Arab Republic",
            size: 233428,
            isSelected: false,
        },
        {
            id: 404306,
            value: "sz",
            title: "Swaziland",
            size: 0,
            isSelected: false,
        },
        {
            id: 404310,
            value: "tc",
            title: "Turks And Caicos Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 404311,
            value: "td",
            title: "Chad",
            size: 219343,
            isSelected: false,
        },
        {
            id: 404313,
            value: "tf",
            title: "French Southern Territories",
            size: 0,
            isSelected: false,
        },
        {
            id: 404314,
            value: "tg",
            title: "Togo",
            size: 0,
            isSelected: false,
        },
        {
            id: 404316,
            value: "th",
            title: "Thailand",
            size: 860364,
            isSelected: false,
        },
        {
            id: 404319,
            value: "tj",
            title: "Tajikistan",
            size: 125064,
            isSelected: false,
        },
        {
            id: 404320,
            value: "tk",
            title: "Tokelau",
            size: 0,
            isSelected: false,
        },
        {
            id: 404322,
            value: "tl",
            title: "Timor-leste",
            size: 15816,
            isSelected: false,
        },
        {
            id: 404324,
            value: "tm",
            title: "Turkmenistan",
            size: 77057,
            isSelected: false,
        },
        {
            id: 404325,
            value: "tn",
            title: "Tunisia",
            size: 145956,
            isSelected: false,
        },
        {
            id: 404327,
            value: "to",
            title: "Tonga",
            size: 1272,
            isSelected: false,
        },
        {
            id: 404329,
            value: "tr",
            title: "Turkey",
            size: 1061080,
            isSelected: false,
        },
        {
            id: 404332,
            value: "tt",
            title: "Trinidad And Tobago",
            size: 16757,
            isSelected: false,
        },
        {
            id: 404334,
            value: "tv",
            title: "Tuvalu",
            size: 0,
            isSelected: false,
        },
        {
            id: 404335,
            value: "tw",
            title: "Taiwan Province Of China",
            size: 280391,
            isSelected: false,
        },
        {
            id: 404338,
            value: "tz",
            title: "Tanzania",
            size: 809256,
            isSelected: false,
        },
        {
            id: 404339,
            value: "ua",
            title: "Ukraine",
            size: 447477,
            isSelected: false,
        },
        {
            id: 404341,
            value: "ug",
            title: "Uganda",
            size: 570779,
            isSelected: false,
        },
        {
            id: 404344,
            value: "um",
            title: "Us Minor Outlying Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 405138,
            value: "us",
            title: "United States",
            size: 4079959,
            isSelected: false,
        },
        {
            id: 405236,
            value: "uy",
            title: "Uruguay",
            size: 42491,
            isSelected: false,
        },
        {
            id: 405237,
            value: "uz",
            title: "Uzbekistan",
            size: 421955,
            isSelected: false,
        },
        {
            id: 405249,
            value: "va",
            title: "Holy See (vatican City State)",
            size: 0,
            isSelected: false,
        },
        {
            id: 405397,
            value: "vc",
            title: "Saint Vincent And The Grenadines",
            size: 1200,
            isSelected: false,
        },
        {
            id: 405414,
            value: "ve",
            title: "Venezuela",
            size: 404855,
            isSelected: false,
        },
        {
            id: 405603,
            value: "vg",
            title: "British Virgin Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 405608,
            value: "vi",
            title: "Us Virgin Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 406023,
            value: "vn",
            title: "Viet Nam",
            size: 1186307,
            isSelected: false,
        },
        {
            id: 406254,
            value: "vu",
            title: "Vanuatu",
            size: 3708,
            isSelected: false,
        },
        {
            id: 406744,
            value: "wf",
            title: "Wallis And Futuna Islands",
            size: 0,
            isSelected: false,
        },
        {
            id: 407209,
            value: "ws",
            title: "Samoa",
            size: 2724,
            isSelected: false,
        },
        {
            id: 407472,
            value: "ye",
            title: "Yemen",
            size: 406524,
            isSelected: false,
        },
        {
            id: 407551,
            value: "yt",
            title: "Mayotte",
            size: 0,
            isSelected: false,
        },
        {
            id: 407575,
            value: "za",
            title: "South Africa",
            size: 723116,
            isSelected: false,
        },
        {
            id: 407843,
            value: "zm",
            title: "Zambia",
            size: 235329,
            isSelected: false,
        },
        {
            id: 407904,
            value: "zw",
            title: "Zimbabwe",
            size: 198360,
            isSelected: false,
        },
    ]);

    const setDefaultOptions = ({ property, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = campaignState.campaignInfo[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    const setAdditionalFormOptions = ({ property, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = campaignState.campaignInfo[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    const setDefaultMultiSelectItems = () => {
        setDefaultOptions({ property: "category", state: categoryOptions, setState: setCategoryOptions });
        setDefaultOptions({
            property: "web3Audience",
            state: web3AudienceOptions,
            setState: setWeb3AudienceOptions,
        });
        setDefaultOptions({
            property: "chainTargeting",
            state: chainTargetingOptions,
            setState: setChainTargetingOptions,
        });

        setDefaultOptions({
            property: "walletTargeting",
            state: walletTargetingOptions,
            setState: setWalletTargetingOptions,
        });
        setDefaultOptions({
            property: "deviceType",
            state: deviceTypeOptions,
            setState: setDeviceTypeOptions,
        });
        setDefaultOptions({
            property: "operativeSystem",
            state: operativeSystemOptions,
            setState: setOperativeSystemOptions,
        });
        setDefaultOptions({
            property: "browser",
            state: browserOptions,
            setState: setBrowserOptions,
        });
        setDefaultOptions({
            property: "countriesTargeting",
            state: countriesOptions,
            setState: setCountriesOptions,
        });

        // for countries and bid
        setState((state) => ({
            ...state,
            countriesTargeting: campaignState.campaignInfo.countriesTargeting,
        }));
    };

    useEffect(() => {
        if (campaignWizardStatus === "edit") {
            setDefaultMultiSelectItems();
        }
    }, []);
    
    useEffect(()=>{
        if(campaignWizardStatus === "create") {
            const {websiteURL} = requestedData.userProfileData.currentOrganization.additionalData
            console.log(websiteURL)
            // setAdditionalFormOptions
            if(websiteURL) {
                
            }
        }

    },[requestedData.userProfileData])

    const [allEstimatedSizes, setAllEstimatedSizes] = useState({
        categoryEstimatedSize: 0,
        web3AudienceEstimatedSize: 0,
        walletTargetingEstimatedSize: 0,
        chainTargetingEstimatedSize: 0,
        countriesEstimatedSize: 0,
    });

    const [estimatedSize, setEstimatedSize] = useState({ smallestEstimateSize: 0, largestEstimateSize: 0 });
    const [isEstimatedSizeLoading, setIsEstimatedSizeLoading] = useState(false);
    const [isUnableToEstimate, setIsUnableToEstimate] = useState(false);

    const [estimatedPercentage, setEstimatedPercentage] = useState({
        smallestEstimatePercentage: 0,
        largestEstimatePercentage: 0,
    });
    const [allEstimatedPercentages, setAllEstimatedPercentages] = useState({
        deviceTypeEstimatedPercentage: 0,
        operativeSystemPercentage: 0,
        browserPercentage: 0,
    });

    const resetParameters = () => {
        setDefaultMultiSelectItems();
    };

    const concat = (options) => {
        let concatedValue = 0;
        options.forEach((item) => {
            if (!item.isSelected) return;
            concatedValue += parseFloat(item.size) || parseFloat(item.percentage) || 0;
        });

        return concatedValue;
    };

    const getFilteredSmallestEstimatePercentage = ({ smallestEstimatePercentage }) => {
        const deviceType = deviceTypeOptions.filter((item) => item.isSelected).map((item) => item.title);
        const os = operativeSystemOptions.filter((item) => item.isSelected).map((item) => item.title);
        const browser = browserOptions.filter((item) => item.isSelected).map((item) => item.title);

        setIsUnableToEstimate((prev) => false);

        if (
            (os.includes("Android") || os.includes("Windows")) &&
            (browser.includes("Safari") || browser.includes("Opera"))
        ) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }
        if (os.includes("IOS") && (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }
        if (
            (os.includes("Windows") || os.includes("Linux") || os.includes("macOS")) &&
            deviceType.includes("Mobile")
        ) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }
        if (os.includes("Android") && deviceType.includes("Desktop")) {
            setIsUnableToEstimate((prev) => true);
            return 0;
        }

        // if (
        //     os.length === 1 &&
        //     (os.includes("Android") || os.includes("Windows")) &&
        //     browser.length === 1 &&
        //     (browser.includes("Safari") || browser.includes("Opera"))
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }
        // if (
        //     os.length === 1 &&
        //     os.includes("IOS") &&
        //     browser.length === 1 &&
        //     (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }
        // if (
        //     os.length === 1 &&
        //     (os.includes("Windows") || os.includes("Linux") || os.includes("macOS")) &&
        //     deviceType.length === 1 &&
        //     deviceType.includes("Mobile")
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }
        // if (
        //     os.length === 1 &&
        //     os.includes("Android") &&
        //     deviceType.length === 1 &&
        //     deviceType.includes("Desktop")
        // ) {
        //     setIsUnableToEstimate((prev)=>true)
        //     return 0;
        // }

        return smallestEstimatePercentage;
    };

    const [result, setResult] = useState(0);

    const handleEstimatedSize = () => {
        // let categoryEstimatedSize = 0;
        // let web3AudienceEstimatedSize = 0;
        // let walletTargetingEstimatedSize = 0;
        // let chainTargetingEstimatedSize = 0;
        // let countriesEstimatedSize = 0;

        // categoryOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     categoryEstimatedSize += parseInt(item.size) || 0;
        // });
        // web3AudienceOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     web3AudienceEstimatedSize += parseInt(item.size) || 0;
        // });
        // walletTargetingOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     walletTargetingEstimatedSize += parseInt(item.size) || 0;
        // });
        // chainTargetingOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     chainTargetingEstimatedSize += parseInt(item.size) || 0;
        // });
        // countriesOptions.forEach((item) => {
        //     if (!item.isSelected) return;
        //     countriesEstimatedSize += parseInt(item.size) || 0;
        // });

        //SIZES

        const categoryEstimatedSize = concat(categoryOptions);
        const web3AudienceEstimatedSize = concat(web3AudienceOptions);
        const walletTargetingEstimatedSize = concat(walletTargetingOptions);
        const chainTargetingEstimatedSize = concat(chainTargetingOptions);
        const countriesEstimatedSize = concat(countriesOptions);

        const allSizes = {
            categoryEstimatedSize,
            web3AudienceEstimatedSize,
            walletTargetingEstimatedSize,
            chainTargetingEstimatedSize,
            countriesEstimatedSize,
        };

        const nonZeroSizes = Object.values(allSizes).filter((size) => size !== 0);
        let smallestEstimateSize = nonZeroSizes.length > 0 ? Math.min(...nonZeroSizes) : 0;
        let largestEstimateSize = nonZeroSizes.length > 0 ? Math.max(...nonZeroSizes) : 0;

        // if (largestEstimateSize < 32300000) largestEstimateSize = 32300000;
        // if (smallestEstimateSize < 29200000 || smallestEstimateSize == largestEstimateSize)
        //     smallestEstimateSize = 29200000;

        const sizes = { smallestEstimateSize, largestEstimateSize };

        setEstimatedSize(sizes);
        setAllEstimatedSizes(allSizes);

        // PERCENTAGES

        const deviceTypeEstimatedPercentage = concat(deviceTypeOptions);
        const operativeSystemPercentage = concat(operativeSystemOptions);
        const browserPercentage = concat(browserOptions);

        const allPercentages = {
            deviceTypeEstimatedPercentage,
            operativeSystemPercentage,
            browserPercentage,
        };

        const nonZeroPercentages = Object.values(allPercentages).filter((percentage) => percentage !== 0);
        let smallestEstimatePercentage = nonZeroPercentages.length > 0 ? Math.min(...nonZeroPercentages) : 0;
        let largestEstimatePercentage = nonZeroPercentages.length > 0 ? Math.max(...nonZeroPercentages) : 0;

        const percentages = { smallestEstimatePercentage, largestEstimatePercentage };

        setEstimatedPercentage(percentages);
        setAllEstimatedPercentages(allPercentages);

        smallestEstimatePercentage = getFilteredSmallestEstimatePercentage({ smallestEstimatePercentage });

        let result = smallestEstimateSize;

        if (smallestEstimatePercentage) {
            result = (smallestEstimateSize * smallestEstimatePercentage) / 100;
        }

        setResult(result);
    };

    useEffect(() => {
        if (!isEstimatedSizeLoading) return;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setIsEstimatedSizeLoading(false);
        }, 3000);
    }, [estimatedSize]);

    useEffect(() => {
        setIsEstimatedSizeLoading(true);

        handleEstimatedSize();
    }, [
        categoryOptions,
        web3AudienceOptions,
        chainTargetingOptions,
        walletTargetingOptions,
        countriesOptions,

        deviceTypeOptions,
        operativeSystemOptions,
        browserOptions,
    ]);

    const handleMultiSelectChange = (items, name) => {
        let selectedItems = items?.filter((item) => item.isSelected);
        selectedItems = selectedItems?.map((item) => item.value || item.title);

        setState((state) => ({
            ...state,
            [name]: selectedItems,
        }));
    };

    const [state, setState] = useState({
        category: [],
        web3Audience: [],
        chainTargeting: [],

        walletTargeting: [],
        deviceType: [],
        operativeSystem: [],
        browser: [],
        countriesTargeting: [],
    });

    const [errors, setErrors] = useState({
        categoryError: false,
        web3AudienceError: false,
        chainTargetingError: false,
        walletTargetingError: false,
        deviceTypeError: false,
        operativeSystemError: false,
        browserError: false,
        countriesTargetingError: false,
    });

    useEffect(() => {
        setErrors({
            ...errors,
            categoryError: !state.category.length,
            web3AudienceError: !state.web3Audience.length,
            chainTargetingError: !state.chainTargeting.length,
            walletTargetingError: !state.walletTargeting.length,
            deviceTypeError: !state.deviceType.length,
            operativeSystemError: !state.operativeSystem.length,
            browserError: !state.browser.length,
            countriesTargetingError: !state.countriesTargeting.length,
        });
    }, [state]);

    useEffect(() => {
        const haveError = true;
        // !errors.categoryError &&
        // !errors.web3AudienceError &&
        // !errors.chainTargetingError &&
        // !errors.walletTargetingError &&
        // !errors.deviceTypeError &&
        // !errors.operativeSystemError &&
        // !errors.browserError &&
        // !errors.countriesTargetingError

        setIsButtonDisabled(!haveError);
    }, [errors]);

    const [displayGeoModal, setDisplayGeoModal] = useState("none");

    return (
        <div className="targeting">
            <TargetingJoyride activeTab={activeTab} />
            <div className="wrapper tab-content-center">
                <p className="targeting-description">
                    {" "}
                    For assistance on targeting, contact the Blockchain-Ads team via Telegram at{" "}
                    <a
                        href="https://t.me/BlockchainAds_Support"
                        underline="hover"
                        className="link link-primary"
                        target="_blank"
                    >
                        https://t.me/BlockchainAds_Support
                    </a>{" "}
                    or via email at{" "}
                    <a
                        href="mailto:contact@blockchain-ads.com"
                        underline="hover"
                        className="link link-primary"
                        target="_blank"
                    >
                        contact@blockchain-ads.com
                    </a>
                </p>
                <div
                    className={`row row-top  `}
                    // className={`row row-top ${campaignWizardStatus === "edit" ? "not-editable" : ""} `}
                    // data-tooltip-id="tooltip-targeting"
                    // data-tooltip-content="Not Editable"
                >
                    <div className="fields-group fields-group-top" data-guide="targeting-tutorial-1">
                        <MultiSelect
                            {...{
                                items: categoryOptions,
                                setItems: setCategoryOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Category",
                                labelInfo: "(Optional) Select your project’s vertical.",
                                name: "category",
                                callback: handleMultiSelectChange,
                            }}
                        />
                        <MultiSelect
                            {...{
                                items: walletTargetingOptions,
                                setItems: setWalletTargetingOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Wallet Targeting",
                                labelInfo: "(Optional) Target specific wallet users.",
                                name: "walletTargeting",
                                callback: handleMultiSelectChange,
                                // direction: window.innerWidth > 576 ? "up" : "down",
                            }}
                        />
                        <MultiSelect
                            {...{
                                items: web3AudienceOptions,
                                setItems: setWeb3AudienceOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                name: "web3Audience",
                                label: "Web3 Audiences",
                                labelInfo: "Pick your campaign targeting among the preset Audiences.",
                                callback: handleMultiSelectChange,
                            }}
                        />
                        <MultiSelect
                            {...{
                                items: chainTargetingOptions,
                                setItems: setChainTargetingOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                name: "chainTargeting",
                                label: "Chain Targeting",
                                labelInfo: "Pick your campaign targeting.",
                                callback: handleMultiSelectChange,
                            }}
                        />
                    </div>
                    <div className="fields-group fields-group-bottom" data-guide="targeting-tutorial-2">
                        <MultiSelect
                            {...{
                                items: operativeSystemOptions,
                                setItems: setOperativeSystemOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Operative System",
                                labelInfo: "(Optional) Choose specific operating systems to target.",
                                name: "operativeSystem",
                                callback: handleMultiSelectChange,
                            }}
                        />

                        <MultiSelect
                            {...{
                                items: browserOptions,
                                setItems: setBrowserOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Browser",
                                labelInfo: "Choose specific browsers to target.",
                                name: "browser",
                                callback: handleMultiSelectChange,
                            }}
                        />
                        <Modal
                            // overlayClickDisabled={true}
                            restProps={{
                                state,
                                setState,
                                items: countriesOptions,
                                setItems: setCountriesOptions,
                                estimatedSize,
                                isEstimatedSizeLoading,
                                result,
                                isUnableToEstimate,
                            }}
                            Toggler={() => (
                                // <Button
                                //     className="geo-modal-toggler"
                                //     name="Geo"
                                //     endIcon={settings}
                                //     onClick={() => setDisplayGeoModal("block")}
                                //     color="secondary"
                                //     variant="outlined"
                                // />
                                <MultiSelect
                                    {...{
                                        items: countriesOptions,
                                        setItems: setCountriesOptions,
                                        variant: "outlined",
                                        color: "secondary",
                                        label: "Geo",
                                        name: "countriesTargeting",
                                        labelInfo: "(Optional) Choose specific countries to target.",
                                        toggleIcon: settings,
                                        // callback: handleMultiSelectChange,
                                        useAsButton: true,
                                        limitTags: 2,
                                        buttonCallback: () => setDisplayGeoModal("block"),
                                    }}
                                />
                            )}
                            // modalCloseCallback={modalCloseCallback}
                            display={displayGeoModal}
                            setDisplay={setDisplayGeoModal}
                            className="modal-dialog-centered geo-modal"
                            Child={Geo}
                        />
                        <MultiSelect
                            {...{
                                withCheckbox: false,
                                items: deviceTypeOptions,
                                setItems: setDeviceTypeOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Device Type",
                                labelInfo: "(Optional) Choose Mobile or Desktop.",
                                name: "deviceType",
                                callback: handleMultiSelectChange,
                            }}
                        />
                    </div>
                </div>
                {/* <table>
                    <tbody>
                        <tr>
                            <td>browser:</td>
                            <td>{allEstimatedPercentages.browserPercentage}</td>
                        </tr>
                        <tr>
                            <td>operativeSystem:</td>
                            <td>{allEstimatedPercentages.operativeSystemPercentage}</td>
                        </tr>
                        <tr>
                            <td>deviceType:</td>
                            <td>{allEstimatedPercentages.deviceTypeEstimatedPercentage}</td>
                        </tr>

                        <tr>
                            <div className="estimated-size">
                                <div className="estimated-size-header">
                                    <h6 className="estimated-size-title">estimated percentages</h6>
                                </div>
                                <hr />
                                <div className="estimated-size-body">
                                    {false ? (
                                        "loading..."
                                    ) : (
                                        <>
                                            <div style={{ display: "flex", gap: "30px" }}>
                                                smallest percentage:
                                                <strong className="estimated-size-value">
                                                    {estimatedPercentage.smallestEstimatePercentage}
                                                </strong>
                                            </div>
                                            <div style={{ display: "flex", gap: "30px" }}>
                                                result ={" "}
                                                {formatLargeNumber(estimatedSize.smallestEstimateSize)} *{" "}
                                                {estimatedPercentage.smallestEstimatePercentage}{" "} / 100 :
                                                <strong className="estimated-size-value">
                                                    {
                                                    addCommas(result) }
                                                </strong>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </tr>
                        <tr style={{ height: "30px" }}></tr>
                        <tr>
                            <td>category:</td>
                            <td>{addCommas(allEstimatedSizes.categoryEstimatedSize)}</td>
                        </tr>
                        <tr>
                            <td>web3Audience:</td>
                            <td>{addCommas(allEstimatedSizes.web3AudienceEstimatedSize)}</td>
                        </tr>
                        <tr>
                            <td>walletTargeting:</td>
                            <td>{addCommas(allEstimatedSizes.walletTargetingEstimatedSize)}</td>
                        </tr>
                        <tr>
                            <td>chainTargeting:</td>
                            <td>{addCommas(allEstimatedSizes.chainTargetingEstimatedSize)}</td>
                        </tr>
                        <tr>
                            <td>countries:</td>
                            <td>{addCommas(allEstimatedSizes.countriesEstimatedSize)}</td>
                        </tr>
                    </tbody>
                </table> */}
                {!result ? (
                    ""
                ) : (
                    <div className="row row-middle">
                        <div className="estimated-size">
                            <div className="estimated-size-header">
                                <h6 className="estimated-size-title">Audience estimate</h6>
                                <div
                                    data-tooltip-id="estimated-size-tooltip"
                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                        "Estimated audience size"
                                    )}
                                    className="info-popup info-popup-dark"
                                >
                                    {info}
                                </div>
                                <Tooltip id="estimated-size-tooltip" className="custom-tooltip" />
                            </div>
                            <hr />
                            <div className="estimated-size-body">
                                {isEstimatedSizeLoading ? (
                                    "loading..."
                                ) : isUnableToEstimate ? (
                                    <p className="estimated-size-unable">Unable to estimate</p>
                                ) : (
                                    <>
                                        <strong className="estimated-size-value">
                                            {formatLargeNumber(result)}
                                        </strong>
                                        <p className="estimated-size-description description">
                                            Based on your targeting selections, this is the estimated size of
                                            your audience over 30 days
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <br />

                <div className="row row-bottom btn-group">
                    <Button
                        size="sm"
                        disabled={false}
                        name="Reset Parameters"
                        color={"dark"}
                        startIcon={trashV2}
                        onClick={resetParameters}
                        style={{ marginRight: "auto" }}
                    />
                    <Button
                        size="sm"
                        startIcon={arrowLeft}
                        name="Back"
                        variant="text"
                        color="dark"
                        // onClick={() => setActiveTab((prevNumber) => prevNumber - 1)}
                        onClick={() => setActiveTab(0)}
                    />
                    <Button
                        size="sm"
                        name="Save & Continue"
                        dataGuide="targeting-tutorial-3"
                        variant="contained"
                        color={isDarkModeEnabled ? "light" : "dark"}
                        disabled={isButtonDisabled}
                        onClick={() => {
                            setCampaignState((prev) => {
                                return {
                                    ...prev,
                                    campaignInfo: {
                                        ...prev.campaignInfo,
                                        ...state,
                                        // chainTargeting: state.chainTargeting.length
                                        //     ? state.chainTargeting
                                        //     : ["BSC"],
                                    },
                                };
                            });
                            setActiveTab((prevNumber) => prevNumber + 1);

                            setCampaignTabs({
                                ...campaignTabs,
                                clickable: [...campaignTabs.clickable, 2],
                                marked: [...campaignTabs.marked, 2],
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const Geo = ({
    setIsOpen,
    state,
    setState,
    items,
    setItems,
    estimatedSize,
    isEstimatedSizeLoading,
    result,
    isUnableToEstimate,
}) => {
    const closeModal = () => {
        setIsOpen(false);
    };
    const { close } = localData.svgs;
    const { map } = localData.images;

    const { formatLargeNumber } = useFormatter();

    return (
        <>
            <div className="modal-header">
                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                <CountriesAndBid state={state} setState={setState} items={items} setItems={setItems} />
                <br />
                <img src={map} className="geo-modal-cover" alt="" />
                <br />
            </div>
            <div className="modal-footer">
                {!result ? (
                    ""
                ) : (
                    <div className="estimated-size">
                        <div className="estimated-size-header">
                            <h6 className="estimated-size-title">Audience estimate</h6>
                            <div
                                data-tooltip-id="estimated-size-tooltip"
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    "Estimated audience size"
                                )}
                                className="info-popup info-popup-dark"
                            >
                                {info}
                            </div>
                            <Tooltip id="estimated-size-tooltip" className="custom-tooltip" />
                        </div>
                        <hr />
                        <div className="estimated-size-body">
                            {isEstimatedSizeLoading ? (
                                "loading..."
                            ) : isUnableToEstimate ? (
                                <p className="estimated-size-unable">Unable to estimate</p>
                            ) : (
                                <>
                                    <strong className="estimated-size-value">
                                        {formatLargeNumber(result)}
                                    </strong>
                                    <p className="estimated-size-description description">
                                        Based on your targeting selections, this is the estimated size of your
                                        audience over 30 days
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <Button
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
                onClick={closeModal}
                name="Done"
                // size="sm"
            />
        </>
    );
};

const TargetingJoyride = ({ activeTab }) => {
    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: [
            {
                content: <h2 className="joyride-subtitle"> Adjust audience settings here.</h2>,
                locale: { skip: <strong>Skip</strong> },
                placement: "center",
                target: "body",
                title: "Targeting",
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Set web3 targeting parameters, such as token holders or blockchain users.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=targeting-tutorial-1]",
                title: "Web3 Targeting Parameters",
                // scrollToOffset: 260,
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Set web2 targeting parameters, such as countries or specific browsers.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=targeting-tutorial-2]",
                title: "Web2 Targeting Parameters",
                // scrollToOffset: 260,
            },
            {
                content: <h2 className="joyride-subtitle">Click here to proceed to the next steps.</h2>,
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=targeting-tutorial-3]",
                title: "Proceed to Next Steps",
                // scrollToOffset: 260,
            },
        ],
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "targetingTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (guideTutorials && guideTutorials.includes("targetingTutorial")) {
            if (activeTab == 1) {
                setTimeout(() => {
                    setSteps((prev) => ({ ...prev, run: true }));
                }, 500);
            }
        }
    }, [activeTab]);
    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={100}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};
