import React, { useState, useEffect } from "react";
import { Tabs, Field, Select, Button, CopyTextButton, Modal } from "../../../../../components";
import { useGlobalContext } from "../../../../../context";
import localData from "../../../../../localData";
import { v4 as uuidv4 } from "uuid";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import TrackingHelp from "../../../tracking-help/TrackingHelp";
import Joyride, { STATUS } from "react-joyride";

const { arrowLeft, arrowRight, TrackingPage, BloomingImage, email } = localData.svgs;
const { pixel, preloader, preloaderBig } = localData.images;

export default function CreateConversionTab({ setActiveTab, activeTab, callbackFromParent }) {
    const [showCreateConversionModal, setShowCreateConversionModal] = useState("none");

    const { trackingState, requestedData } = useGlobalContext();

    const { isEditingMode } = trackingState;

    return (
        <div className="create-conversion-tab">
            <CreateConversionJoyride activeTab={activeTab} />
            <h4 className="create-conversion-tab-title">Create conversion</h4>

            <p className="install-pixel-tab-description text-style-1">
                The conversion pixel is designed to be triggered by specific actions, such as button clicks or
                other events you define. It is not dependent on page load events, so the dynamic routing of
                Next.js should not impact its ability to track conversions.
            </p>

            <Tabs
                tabsProps={{}}
                tabs={createConversioFormTabs}
                title="Create conversion"
                // indicatorIcon={question}
                className="create-conversion-tabs"
            />
            <br />
            <div className="create-conversion-tab-footer">
                <div className="btn-group">
                    <Button
                        color="pale-dark"
                        // variant="contained"
                        startIcon={arrowLeft}
                        name="back"
                        size="sm"
                        onClick={() => setActiveTab(0)}
                    />
                    <Button
                        color={`${isEditingMode ? "warning" : "pale-dark"}`}
                        variant="contained"
                        name={`${"Complete"}`}
                        size="sm"
                        onClick={callbackFromParent}
                        className="complete-btn"
                    />

                    {/* <Modal
                        Child={CreateConversionModal}
                        className="create-conversion-modal tracking-modal modal-dialog-centered"
                        display={showCreateConversionModal}
                        setDisplay={setShowCreateConversionModal}
                        restProps={{ setActiveTab }}
                        Toggler={() => (
                            <Button
                                className="next-step-btn"
                                name="Next"
                                endIcon={arrowRight}
                                size="sm"
                                variant="contained"
                                color="pale-dark"
                                onClick={() => setShowCreateConversionModal("block")}
                            />
                        )}
                    ></Modal> */}
                </div>
            </div>
        </div>
    );
}

const CreateConversionJoyride = ({ activeTab }) => {
    const defaultSteps = [
        {
            content: (
                <h2 className="joyride-subtitle">
                    Create a conversion script. Choose a recognizable name for the action (e.g., "Form Sign
                    Up").
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Creating Conversion Script",
        },

        {
            content: (
                <h2 className="joyride-subtitle">Click "Generate Script" to view the conversion code.</h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#create-conversion-tutorial-2",
            title: "Generating Conversion Script",
        },
        {
            content: (
                <h2 className="joyride-subtitle">
                    Ask the developer to place the conversion script on the appropriate conversion trigger
                    (e.g., form sign-ups). Contact support for help.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#create-conversion-tutorial-3",
            title: "Implementing Conversion Script",
        },
        {
            content: (
                <h2 className="joyride-subtitle">
                    View conversion data on the main dashboard under "Campaigns." If no conversions appear
                    after a few days, double-check the tracking implementation or contact support.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Viewing Conversion Data",
        },
    ];

    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "createConversionTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("createConversionTutorial")) return;
        if (activeTab == 1) {
            setTimeout(() => {
                setSteps((prev) => ({ ...prev, run: true }));
            }, 500);
        }
    }, [activeTab]);

    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};

// const CreateConversionModal = ({ setIsOpen, setActiveTab }) => {
//     const { conversionOptions } = useGlobalContext();
//     const { close } = localData.svgs;
//     const closeModal = () => {
//         setIsOpen(false);
//     };
//     return (
//         <>
//             <div className="modal-header">
//                 <h2 className="modal-title">Confirmation</h2>

//                 <Button
//                     className="btn-close"
//                     variant="circle"
//                     color="dark"
//                     size="sm"
//                     icon={close}
//                     onClick={closeModal}
//                 />
//             </div>
//             <div className="modal-body">
//                 {/* need to confirm
//                 <br />
//                 <br /> */}
//                 <TrackingHelp />
//             </div>
//             <div className="modal-footer">
//                 <div className="btn-group">
//                     <Button
//                         className="cancel-btn"
//                         name="Cancel"
//                         size="sm"
//                         variant="contained"
//                         color="light"
//                         onClick={closeModal}
//                     />
//                     <Button
//                         className="next-step-btn"
//                         name="Continue"
//                         size="sm"
//                         variant="contained"
//                         color="pale-dark"
//                         onClick={(e) => {
//                             closeModal();
//                             setTimeout(() => {
//                                 setActiveTab(2);
//                             }, 500);
//                         }}
//                     />
//                 </div>
//             </div>
//         </>
//     );
// };

const TrackAnEventContent = ({ setActiveTab, activeTab }) => {
    const {
        CreatePixelTagRequest,
        CreateConversionPixelRequest,
        EditConversionPixelRequest,
        conversionOptions,
        conversionOptions: { trackAnEventScriptTag, pixelScriptTag },
        setConversionOptions,
        requestedData,
        requestedData: {
            userProfileData: { currentOrganization },
        },
        trackingState,
        setEditingTagId,
        _conversionTagEmail,
    } = useGlobalContext();

    const { isEditingMode, editingTagId, state, setState } = trackingState;

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({
            ...state,
            [name]: value,
        });
    };

    const [items, setItems] = useState([
        { title: "Is", isSelected: false, id: uuidv4() },
        { title: "Contains", isSelected: false, id: uuidv4() },
        { title: "Does not contain", isSelected: false, id: uuidv4() },
    ]);

    const [isEditLoading, setIsEditLoading] = useState(false);

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (isEditingMode) {
            EditConversionPixelRequest({
                tagId: editingTagId,
                tagName: state.tagName,
                setIsLoading: setIsEditLoading,
            });
        } else {
            CreateConversionPixelRequest({ tagName: state.tagName });
        }
    };

    const [isSendInstructionsLoading, setIsSendInstructionsLoading] = useState(false);

    const [developerEmail, setDeveloperEmail] = useState("");

    return (
        <div className="step-1">
            {/* <br /> */}
            {/* <h2 className="display-2">Track an event</h2> */}

            <br />
            <div className="form-legend ">
                <form action="" onSubmit={handleOnSubmit} className="conversion-form">
                    <Field
                        required={true}
                        type="text"
                        label="Tag Name"
                        name="tagName"
                        value={state.tagName}
                        color="secondary"
                        placeholder=""
                        // required={true}
                        size="sm"
                        callback={handleOnChange}
                    />
                    <Button
                        id="create-conversion-tutorial-2"
                        name="Generate script"
                        variant="contained"
                        color={!isEditingMode ? "pale-dark" : "warning"}
                        style={{ width: "100%" }}
                        icon={
                            conversionOptions.trackAnEventScriptTag.isLoading || isEditLoading ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={conversionOptions.trackAnEventScriptTag.isLoading || isEditLoading}
                    />
                </form>

                <BloomingImage className="cover" />
            </div>
            <br />
            <br />
            <br />
            {trackAnEventScriptTag.value && (
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            _conversionTagEmail({
                                setIsLoading: setIsSendInstructionsLoading,
                                conversionName: state.tagName,
                                blockChainPixelID: pixelScriptTag.blockChainPixelID,
                                conversionTagID: trackAnEventScriptTag.tagID,
                                conversionAPIKey: trackAnEventScriptTag.apiKey,
                                developerEmail: developerEmail,
                                userEmail: requestedData.userProfileData.userEmail
                                // callback: ()=>{

                                // }
                            });
                        }}
                        className="instructions-form"
                    >
                        <h4 className="instructions-form-title">{email} Send Instructions to Developer</h4>
                        <p className="instructions-form-description description">
                            Email your personalized instructions to your developer so they can complete the
                            implementation for you.
                        </p>
                        <div className="wrapper">
                            <Field
                                required={true}
                                type="email"
                                label="Developer email"
                                name="tagName"
                                value={developerEmail}
                                color="secondary"
                                placeholder=""
                                // required={true}
                                size="sm"
                                callback={(e) => setDeveloperEmail(e.target.value)}
                            />
                            <Button
                                style={{ width: "100%" }}
                                id="instructions-btn"
                                name="Send Instructions"
                                variant="contained"
                                color="dark"
                                icon={
                                    isSendInstructionsLoading ? (
                                        <span className="endIcon">
                                            <img src={preloaderBig} />
                                        </span>
                                    ) : null
                                }
                                disabled={isSendInstructionsLoading || !state.tagName}
                            />
                        </div>
                    </form>
                    <br />
                    <br />
                    <br />
                </>
            )}
            <p className="text-style-1">
                Ask your website developer to install this conversion tag to fire whenever a user performs a
                conversion. You can use a Tag Management solution - like Google Tag Manager - to simplify this
                process.
            </p>

            <div className="code" id="create-conversion-tutorial-3">
                <div className="row">
                    <div className="label">
                        <img src={pixel} alt="" />
                        pixel
                    </div>
                    <CopyTextButton
                        text={trackAnEventScriptTag.value}
                        disabled={!trackAnEventScriptTag.value}
                        size="medium"
                    />
                </div>

                <div className="script-wrapper" style={{ fontSize: "12px" }}>
                    {trackAnEventScriptTag.isLoading ? (
                        "Loading..."
                    ) : trackAnEventScriptTag.value ? (
                        <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                            {trackAnEventScriptTag.value}
                        </SyntaxHighlighter>
                    ) : (
                        "Filled up the form above to generate the event script"
                    )}
                </div>
            </div>
        </div>
    );
};

// const TrackAPageContent = ({ setActiveTab }) => {
//     const {
//         CreatePixelTagRequest,
//         CreateConversionPixelRequest,
//         conversionOptions,
//         conversionOptions: { trackAnEventScriptTag },
//         setConversionOptions,
//         requestedData: {
//             userProfileData: { currentOrganization },
//         },
//     } = useGlobalContext();

//     const [state, setState] = useState({ tagName: "", url: "", contains: "" });

//     const handleOnChange = (e) => {
//         const { name, value } = e.target || e;
//         setState({
//             ...state,
//             [name]: value,
//         });
//     };

//     const [items, setItems] = useState([
//         { title: "Is", isSelected: false, id: uuidv4() },
//         { title: "Contains", isSelected: false, id: uuidv4() },
//         { title: "Does not contain", isSelected: false, id: uuidv4() },
//     ]);

//     const handleOnSubmit = (e) => {
//         e.preventDefault();
//         CreateConversionPixelRequest(state.tagName || "test tagName", "conversion");
//     };

//     return (
//         <div className="step-2">
//             <br />
//             <h2 className="display-2">Track a page</h2>

//             <br />
//             <div className="form-legend">
//                 <form onSubmit={(e) => e.preventDefault()}>
//                     <Field
//                         type="text"
//                         label="Tag Name"
//                         name="tagName"
//                         value={state.tagName}
//                         color="secondary"
//                         placeholder="Converson name"
//                         // required={true}
//                         size="sm"
//                         callback={handleOnChange}
//                     />
//                     <div className="wrapper">
//                         <Select
//                             {...{
//                                 label: "Select",
//                                 items,
//                                 setItems,
//                                 placeholder: "Select",
//                                 variant: "outlined",
//                                 color: "secondary",
//                                 isLoading: false,
//                                 className: "conversion-select",
//                             }}
//                         />
//                         <Field
//                             type="text"
//                             label="URL"
//                             name="conversion-url"
//                             value={state.url}
//                             color="secondary"
//                             placeholder="e.g. https://johndoe.com"
//                             // required={true}
//                             size="sm"
//                             callback={handleOnChange}
//                         />
//                     </div>

//                     <Button
//                         name="Confirm"
//                         variant="contained"
//                         color="pale-dark"
//                         style={{ width: "100%" }}
//                         onClick={handleOnSubmit}
//                     />
//                 </form>

//                 <TrackingPage className="cover" />
//             </div>

//             <p className="text-style-1">
//                 “Ask your website developer to install this conversion tag to fire whenever a user performs a
//                 conversion. You can use a Tag Management solution - like Google Tag Manager - to simplify this
//                 process.
//             </p>

//             <div className="code">
//                 <div className="row">
//                     <div className="label">
//                         <img src={pixel} alt="" />
//                         pixel
//                     </div>
//                     <CopyTextButton
//                         text={trackAnEventScriptTag.value}
//                         disabled={!trackAnEventScriptTag.value}
//                     />
//                 </div>

//                 <div className="script-wrapper" style={{ fontSize: "12px" }}>
//                     {trackAnEventScriptTag.isLoading ? (
//                         "Loading..."
//                     ) : trackAnEventScriptTag.value && trackAnEventScriptTag.type == "conversion" ? (
//                         <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
//                             {trackAnEventScriptTag.value}
//                         </SyntaxHighlighter>
//                     ) : (
//                         "Filled up the form above to generate the conversion script"
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// const Or = () => {
//     return <div>fkdlj</div>;
// };

const createConversioFormTabs = [
    {
        title: "Track an event",
        // startImageIcon: pixel,
        content: <TrackAnEventContent />,
        id: "track-an-event-2983jfosfod",
    },
    // {
    //     title: "or",
    //     content: <Or />,
    //     id: "08feijwoi",
    //     tabDisabled: true,
    // },
    // {
    //     title: "Track a page",
    //     // startImageIcon: pixel,
    //     content: <TrackAPageContent />,
    //     id: "track-a-page-0283fjoij3w",
    // },
];
