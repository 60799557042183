import React, { useState, useEffect, useRef } from "react";
import { Field, Switch, Button, MultiSelect, Select, ConfirmationPopup, Modal } from "../../../components";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import useFormatter from "../../../hooks/useFormatter";
import { Tooltip } from "react-tooltip";
import Joyride, { STATUS, ACTIONS } from "react-joyride";
import ReactDOMServer from "react-dom/server";

export default function CampaignInformation({ setActiveTab, activeTab }) {
    const {
        formatDate,
        getDelayedDate,
        campaignState,
        setCampaignState,
        campaignWizardStatus,
        isDarkModeEnabled,
        campaignsState,
        requestedData,
    } = useGlobalContext();
    const { formatNumber, formatCurrency, addCommas } = useFormatter();
    const { campaignTabs, setCampaignTabs } = campaignsState;

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    // const setDefaultOptions = ({ property, state, setState }) => {
    //     let tempState = [...state];
    //     tempState = tempState.map((item) => {
    //         const _property = campaignState.campaignInfo[property]
    //         if(!_property) return
    //         const isSelected = _property.includes(item.title);
    //         return { ...item, isSelected };
    //     });
    //     setState(tempState);
    // };

    // useEffect(() => {
    //     // let tempCampaignTypeOptions = [...campaignTypeOptions];
    //     // tempCampaignTypeOptions = tempCampaignTypeOptions.map((item) => {
    //     //     const isSelected = campaignState.campaignInfo.campaignType.includes(item.title);
    //     //     return { ...item, isSelected };
    //     // });
    //     // setCampaignTypeOptions(tempCampaignTypeOptions);
    //     setDefaultOptions({
    //         property: "chainTargeting",
    //         state: chainTargetingOptions,
    //         setState: setChainTargetingOptions,
    //     });
    // }, []);

    const [state, setState] = useState({
        campaignName: campaignState.campaignInfo.campaignName,
        targetURL: campaignState.campaignInfo.targetURL,
        smartContractAddress: campaignState.campaignInfo.smartContractAddress,
        budget: campaignState.campaignInfo.budget,
        maxCPC: campaignState.campaignInfo.maxCPC,
        startDate: campaignState.campaignInfo.startDate,
        endDate: campaignState.campaignInfo.endDate,
        campaignType: [],
    });

    const [startDate, setStartDate] = useState(
        campaignState.campaignInfo.startDate?.split("/").reverse().join("-") ||
            new Date().toISOString().slice(0, 10)
    );
    const [endDate, setEndDate] = useState(campaignState.campaignInfo.endDate?.split("/").reverse().join("-") || "");

    const resetParameters = () => {
        setState({
            campaignName: campaignState.campaignInfo.campaignName,
            targetURL: campaignState.campaignInfo.targetURL,
            smartContractAddress: campaignState.campaignInfo.smartContractAddress,
            budget: campaignState.campaignInfo.budget,
            maxCPC: campaignState.campaignInfo.maxCPC,
            startDate: campaignState.campaignInfo.startDate,
            endDate: campaignState.campaignInfo.endDate,
            campaignType: [],
        });
        // resetMultiSelectItems(campaignTypeOptions, setCampaignTypeOptions)
        setDefaultOptions({
            property: "campaignType",
            state: campaignTypeOptions,
            setState: setCampaignTypeOptions,
        });
        setStartDate(
            campaignState.campaignInfo.startDate?.split("/").reverse().join("-") ||
                new Date().toISOString().slice(0, 10)
        );
        setEndDate(campaignState.campaignInfo.endDate?.split("/").reverse().join("-") || "")
    };

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const { budget, info, trashV2 } = localData.svgs;

    function checkIsValidUrl(url) {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({
            ...state,
            [name]:
                name === "startDate" || name === "endDate"
                    ? formatDate(value)
                    : name === "budget" || name === "maxCPC"
                    ? addCommas(value)
                    : // : name === "smartContractAddress"
                      // ? checkIsBlockchainAddress(value)
                      value,
        });
    };

    // const handleMultiSelectChange = (items, name) => {
    //     let selectedItem = items?.filter((item) => item.isSelected);
    //     selectedItem = selectedItem?.map((item) => item.title);

    //     setState((state) => ({ ...state, [name]: selectedItem }));
    // };
    const handleSelectChange = (items, name) => {
        let selectedItem = items.find((item) => item.isSelected);

        setState((state) => ({ ...state, [name]: selectedItem.title }));
    };

    const [errors, setErrors] = useState({
        campaignNameError: false,
        targetURLError: false,
        smartContractAddressError: false,
        budgetError: false,
        startDateError: false,
        endDateError: false,
    });

    useEffect(() => {
        // console.log(Date.now(), " - date now");
        // console.log(new Date(startDateRef.current.value).getTime(), " start date");

        setErrors({
            ...errors,
            campaignNameError: state.campaignName === "",
            targetURLError: state.targetURL === "" || !checkIsValidUrl(state.targetURL),
            smartContractAddressError:
                state.smartContractAddress !== "" && !checkIsBlockchainAddress(state.smartContractAddress),
            budgetError: state.budget < 360,
            // startDateError: state.startDate === "",
            // campaignTypeError: !state.campaignType.length,
            startDateError: campaignWizardStatus !== "edit" && getStartDateError() ? true : false,
            endDateError: getEndDateError() ? true : false,
        });
    }, [state]);

    // useEffect(() => {
    //     console.log(errors, " errors");
    // }, [errors]);

    useEffect(() => {
        const haveError =
            !errors.campaignNameError &&
            !errors.targetURLError &&
            !errors.smartContractAddressError &&
            !errors.budgetError &&
            !errors.startDateError &&
            !errors.endDateError;
        setIsButtonDisabled(!haveError);
    }, [errors]);

    const getStartDateError = () => {
        let errorMessage = "";
        const startDate = new Date(startDateRef.current.value).getTime();
        const endDate = new Date(endDateRef.current.value).getTime();
        const day = 24 * 60 * 60 * 1000;

        if (startDate < Date.now() - day) errorMessage = "Start date can't be in the past.";
        if (!startDate) errorMessage = "Start date can't be empty";

        return errorMessage;
    };

    const getEndDateError = () => {
        let errorMessage = "";
        const startDate = new Date(startDateRef.current.value).getTime();
        const endDate = new Date(endDateRef.current.value).getTime();
        const day = 24 * 60 * 60 * 1000;

        if (endDate < startDate) errorMessage = "End date must be after start date.";
        if (endDate < Date.now() - day && campaignWizardStatus !== "edit")
            errorMessage = "End date can't be in the past.";
        // if(startDate && !endDate) errorMessage = "End date can't be empty"
        return errorMessage;
    };

    function checkIsBlockchainAddress(text) {
        var pattern = /^0x[a-fA-F0-9]+$/;
        return pattern.test(text);
    }

    const [campaignTypeOptions, setCampaignTypeOptions] = useState([
        { title: "Awareness (max reach)", isSelected: false, id: uuidv4() },
        { title: "Engagement (website visit/interaction)", isSelected: false, id: uuidv4() },
        { title: "Conversion (website download/transactions/sales)", isSelected: false, id: uuidv4() },
    ]);

    const handleMultiSelectChange = (items, name) => {
        let selectedItems = items?.filter((item) => item.isSelected);
        selectedItems = selectedItems?.map((item) => item.value || item.title);

        setState((state) => ({
            ...state,
            [name]: selectedItems,
        }));
    };

    const setDefaultOptions = ({ property, state, setState }) => {
        let tempState = [...state];
        tempState = tempState.map((item) => {
            const isSelected = campaignState.campaignInfo[property].includes(item.value || item.title);
            return { ...item, isSelected };
        });
        setState(tempState);
    };

    useEffect(() => {
        setDefaultOptions({
            property: "campaignType",
            state: campaignTypeOptions,
            setState: setCampaignTypeOptions,
        });
    }, []);

    const [showConfirmationModal, setShowConfirmationModal] = useState("none");

    const handleSubmit = () => {
        setCampaignState((prev) => {
            return {
                ...prev,
                campaignInfo: {
                    ...prev.campaignInfo,
                    ...state,
                    budget: state.budget.toString().replace(/,/g, ""),
                    maxCPC: state.maxCPC.toString().replace(/,/g, ""),
                    startDate: state.startDate || formatDate(),
                    endDate: state.endDate,
                    // endDate:
                    //     state.endDate ||
                    //     formatDate(getDelayedDate(startDateRef?.current?.value, 30)),
                    // smartContractAddress: state.smartContractAddress,
                    // smartContractAddress:
                    //     state.smartContractAddress ||
                    //     "0x0000000000000000000000000000000000001000",
                },
            };
        });
        setActiveTab(1);
        setCampaignTabs({
            ...campaignTabs,
            clickable: [...campaignTabs.clickable, 1],
            marked: [...campaignTabs.marked, 1],
        });
    };

    return (
        <div className="campaign-information">
            <CampaignInformationJoyride activeTab={activeTab} />

            <Modal
                Child={ConfirmationPopup}
                className="campaign-information-modal modal-dialog-centered"
                display={showConfirmationModal}
                setDisplay={setShowConfirmationModal}
                // restProps={{ setActiveTab }}
                Toggler={() => {}}
                restProps={{
                    content: (
                        <div>
                            <strong>NOTE:</strong> Your DAILY budget was set to ${state.budget}.
                        </div>
                    ),
                    callback: () => {
                        handleSubmit();
                    },
                }}
            ></Modal>
            <div className="wrapper tab-content-center">
                <div className="row row-top">
                    <div className="col col-left">
                        <Field
                            dataGuide="campaign-information-tutorial-2"
                            type="text"
                            label="Campaign Name *"
                            labelInfo="Choose a name for your campaign. Make sure to pick a unique name to better recognize your campaign later."
                            name="campaignName"
                            value={state.campaignName}
                            color="secondary"
                            placeholder="e.g. johnDoeCamp"
                            required={true}
                            size="sm"
                            callback={handleOnChange}
                        />
                        {/* <div
                            className={`${campaignWizardStatus === "edit" ? "not-editable" : ""}`}
                            data-tooltip-id="tooltip-targetURL"
                            data-tooltip-content="Not Editable"
                        > */}
                        <Field
                            dataGuide="campaign-information-tutorial-4"
                            type="text"
                            label="Target URL*"
                            labelInfo="Select a Landing Page for your ads. We recommend using UTM Parameters to track the performance of your campaigns."
                            name="targetURL"
                            value={state.targetURL}
                            color="secondary"
                            placeholder="e.g. https://johndoe.com"
                            required={true}
                            size="sm"
                            callback={handleOnChange}
                            // isValid={state.targetURL === "" || checkIsValidUrl(state.targetURL)}
                            // errorMessage={`Invalid URL. Please enter a valid URL. e.g.
                            // <br /> https://johndoe.com`}
                            errorMessage={
                                state.targetURL !== "" && !checkIsValidUrl(state.targetURL)
                                    ? `Invalid URL. Please enter a valid URL. e.g.
                                <br /> https://johndoe.com`
                                    : ""
                            }
                        />
                        {/* {campaignWizardStatus === "edit" && (
                                <Tooltip id="tooltip-targetURL" className="custom-tooltip" />
                            )}
                        </div> */}
                        {/* <div
                            className={`${campaignWizardStatus === "edit" ? "not-editable" : ""}`}
                            data-tooltip-id="tooltip-smart-contract"
                            data-tooltip-content="Not Editable"
                        > */}
                        <Field
                            dataGuide="campaign-information-tutorial-5"
                            type="text"
                            label="Smart Contract"
                            labelInfo="(Optional) Select the Smart Contract of your project, so аre able to track transactions."
                            color="secondary"
                            placeholder="e.g. 0x00000000000000"
                            required={true}
                            size="sm"
                            name="smartContractAddress"
                            value={state.smartContractAddress}
                            callback={handleOnChange}
                            errorMessage={
                                state.smartContractAddress !== "" &&
                                !checkIsBlockchainAddress(state.smartContractAddress)
                                    ? `Invalid smart contract address.
                                <br /> e.g. 0x00000000000000`
                                    : ""
                            }
                        />
                        {/* {campaignWizardStatus === "edit" && (
                                <Tooltip id="tooltip-smart-contract" className="custom-tooltip" />
                            )}
                        </div> */}

                        {/* <div
                            className={`${campaignWizardStatus === "edit" ? "not-editable" : ""}`}
                            data-tooltip-id="tooltip-campaign-type"
                            data-tooltip-content="Not Editable"
                        >
                            <Select
                                {...{
                                    name: "chainTargeting",
                                    items: chainTargetingOptions,
                                    setItems: setChainTargetingOptions,
                                    placeholder: "Select",
                                    variant: "outlined",
                                    color: "secondary",
                                    isLoading: false,
                                    label: "Chain Targeting",
                                    placeholder: " Select Tags",
                                    // labelInfo: "(Optional) Select one or more chains you want to target.",
                                    labelInfo: "(Optional) Select the chain you want to target.",
                                    secondCallback: handleSelectChange,
                                }}
                            />
                            {campaignWizardStatus === "edit" && (
                                <Tooltip id="tooltip-campaign-type" className="custom-tooltip" />
                            )}
                        </div> */}
                        <Field
                            dataGuide="campaign-information-tutorial-6"
                            type="text"
                            label="Daily Budget (USD)*"
                            labelInfo="Choose how much you want to spend each day. Minimum is $360"
                            name="budget"
                            value={state.budget}
                            color="secondary"
                            placeholder="Budget"
                            required={true}
                            size="sm"
                            startIcon={budget}
                            callback={handleOnChange}
                            errorMessage={errors.budgetError && "Monthly budget must be at least $360."}
                            additionalMessage={
                                campaignWizardStatus === "edit" && (
                                    <>Updates to this limit will take effect the next day.</>
                                )
                            }
                            // warningInfo={
                            //     campaignWizardStatus === "edit" &&
                            //    <div>Updates to this limit will take<br/>  effect the next day.</div>
                            // }
                        />
                    </div>

                    <div className="col col-right">
                        {/* <Switch color="primary" label="Monthly" checked={true} /> */}

                        <div
                            className={`${campaignWizardStatus === "edit" ? "not-editable" : ""}`}
                            data-tooltip-id="tooltip-max-cpc"
                            data-tooltip-content="Not Editable"
                        >
                            <Field
                                dataGuide="campaign-information-tutorial-3"
                                type="text"
                                label="Max CPC (USD)*"
                                labelInfo="Choose the maximum amount of USD you are willing to pay for each click."
                                name="maxCPC"
                                value={state.maxCPC}
                                color="secondary"
                                placeholder="Max CPC"
                                required={true}
                                size="sm"
                                startIcon={budget}
                                callback={handleOnChange}
                            />
                            {campaignWizardStatus === "edit" && (
                                <Tooltip id="tooltip-max-cpc" className="custom-tooltip" />
                            )}
                        </div>

                        <div
                            className={`${campaignWizardStatus === "edit" ? "not-editable" : ""}`}
                            data-tooltip-id="tooltip-start-date"
                            data-tooltip-content="Not Editable"
                        >
                            <Field
                                dataGuide="campaign-information-tutorial-7"
                                className="date"
                                type="date"
                                label="Start Date"
                                labelInfo=" Pick the starting date for your campaign. If you leave it empty, the campaign will start immediately after moderation."
                                name="startDate"
                                // value={state.startDate}
                                value={startDate}
                                // placeholder="dd/mm/yyyy"
                                color="secondary"
                                required={true}
                                size="sm"
                                // endIcon={date}
                                callback={(e) => {
                                    handleOnChange(e);
                                    setStartDate(e.target.value);
                                }}
                                errorMessage={
                                    campaignWizardStatus !== "edit" &&
                                    startDateRef.current &&
                                    getStartDateError()
                                }
                                _ref={startDateRef}
                            />
                            {campaignWizardStatus === "edit" && (
                                <Tooltip id="tooltip-start-date" className="custom-tooltip" />
                            )}
                        </div>

                        <div
                            className={`${campaignWizardStatus === "edit" ? "not-editable" : ""}`}
                            data-tooltip-id="tooltip-end-date"
                            data-tooltip-content="Not Editable"
                        >
                            <Field
                                className="date"
                                type="date"
                                label="End Date"
                                labelInfo="Pick the end date for your campaign. If you leave it empty, the campaign will run until you stop it or until your account balance is exhausted."
                                name="endDate"
                                // value={state.endDate}
                                value={
                                    endDate
                                }
                                color="secondary"
                                placeholder="Placeholder text"
                                required={true}
                                size="sm"
                                // endIcon={date}
                                callback={(e) => {
                                    handleOnChange(e);
                                    setEndDate(e.target.value)
                                }}
                                // errorMessage={errors.endDateError ? "end date can't be in the past." : ""}
                                errorMessage={endDateRef.current && getEndDateError()}
                                _ref={endDateRef}
                            />
                            {campaignWizardStatus === "edit" && (
                                <Tooltip id="tooltip-end-date" className="custom-tooltip" />
                            )}
                        </div>
                        {/* <div>
                            <Field
                                className="date"
                                type="date"
                                label="End Date"
                                labelInfo="Pick the end date for your campaign. If you leave it empty, the campaign will run until you stop it or until your account balance is exhausted."
                                name="endDate"
                                // value={state.endDate}
                                value={
                                    campaignState.campaignInfo.endDate?.split("/").reverse().join("-") || ""
                                }
                                color="secondary"
                                placeholder="Placeholder text"
                                required={true}
                                size="sm"
                                // endIcon={date}
                                callback={handleOnChange}
                                // errorMessage={errors.endDateError ? "end date can't be in the past." : ""}
                                errorMessage={endDateRef.current && getEndDateError()}
                                _ref={endDateRef}
                            />
                        </div> */}
                        <MultiSelect
                            {...{
                                withCheckbox: false,
                                selectedItemsLimit: 2,
                                name: "campaignType",
                                items: campaignTypeOptions,
                                setItems: setCampaignTypeOptions,
                                variant: "outlined",
                                limitTags: 2,
                                color: "secondary",
                                label: "Campaign Type",
                                labelInfo:
                                    "Select your campaign objective. Awareness campaigns require less budget to perform well. Conversion campaigns require more budget, but will give you more Return on Investment.",
                                placeholder: "Select Tags",
                                callback: handleMultiSelectChange,
                            }}
                        />
                    </div>
                </div>

                <div className="row row-bottom btn-group">
                    <Button
                        size="sm"
                        disabled={false}
                        name="Reset Parameters"
                        color={"dark"}
                        startIcon={trashV2}
                        onClick={resetParameters}
                    />
                    <Button
                        dataGuide="campaign-information-tutorial-8"
                        size="sm"
                        disabled={isButtonDisabled}
                        name="Save & Continue"
                        variant="contained"
                        color={isDarkModeEnabled ? "light" : "dark"}
                        onClick={() => {
                            if (state.budget.replace(/,/g, "") >= 10000) {
                                setShowConfirmationModal("block");
                            } else {
                                handleSubmit();
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const CampaignInformationJoyride = ({ activeTab }) => {
    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: [
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Welcome to the campaign creation tutorial. Follow this guide to launch your first
                        campaign.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "center",
                target: "body",
                title: "Welcome to Campaign Creation",
            },
            {
                content: <h2 className="joyride-subtitle">Choose a recognizable campaign name.</h2>,
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=campaign-information-tutorial-2]",
                title: "Campaign Naming",
                // scrollToOffset: 260,
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Set a maximum CPC (Cost per Click). Higher CPC improves traffic quality.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=campaign-information-tutorial-3]",
                title: "Setting CPC",
                // scrollToOffset: 260,
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Add the website or landing page. Use UTM parameters to track results.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=campaign-information-tutorial-4]",
                title: "Adding Website/Landing Page",
                // scrollToOffset: 260,
            },
            {
                content: (
                    <h2 className="joyride-subtitle">
                        Copy-paste a Binance or Ethereum smart contract to track web3 conversions from our
                        ads.
                    </h2>
                ),
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=campaign-information-tutorial-5]",
                title: "Smart Contract Integration",
                // scrollToOffset: 260,
            },

            {
                content: <h2 className="joyride-subtitle">Click here to proceed to the next steps.</h2>,
                locale: { skip: <strong>Skip</strong> },
                placement: "auto",
                target: "[data-guide=campaign-information-tutorial-8]",
                title: "Proceed to Next Steps",
                // scrollToOffset: 260,
            },
        ],
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;
        // console.log("Joyride event:", { status, type, index });

        // if (type === ACTIONS.STEP_AFTER) {
        //     console.log("Skipping the first step");
        // }

        // if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        //     console.log("skipping by clicking button");
        // }
        // if (type === ACTIONS.CLOSE) {
        //     console.log("User skipped the tutorial by clicking the black overlay");
        // }
        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "campaignInformationTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (guideTutorials && guideTutorials.includes("campaignInformationTutorial")) {
            if (activeTab == 0) {
                setTimeout(() => {
                    setSteps((prev) => ({ ...prev, run: true }));
                }, 500);
            }
        }

        // RESET SCROLL
        const campaignCreateDrawer = document.querySelector(".campaign-create-drawer");
        if (!campaignCreateDrawer) return;
        campaignCreateDrawer.scrollTo({
            top: 0,
            // behavior: 'smooth',
        });
    }, [activeTab]);
    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={100}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },

                buttonNext: {},

                buttonBack: {},
                buttonClose: {},
            }}
        />
    );
};
