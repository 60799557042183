import React from "react";
import axios from "axios";

export default function useConvertKit() {
    const sendConvertKit = async ({ name,email}) => {
    

        try {
            const response = await axios.post("https://api.convertkit.com/v3/forms/6765229/subscribe", {
                api_key: process.env.REACT_APP_CONVERTKIT_API_KEY || "jywBzHZhKib1743qgqM6bQ",
                email: email,
                first_name: name,
         
                // fields: {
                //     last_name: form.lastName,
                //     custom_field: form.customField,
                // },
            });
            if (response.data.subscription) {
                // setMessage("Subscription successful!");
                console.log("success");
            } else {
                console.log("fail");
                // setMessage("Subscription failed. Please try again.");
            }
        } catch (error) {
            // setMessage("An error occurred. Please try again.");
            console.log(error,"fetch error");
        }
    };
    return {sendConvertKit};
}
