import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../context";
import BlockchainsTable from "../../pages/campaigns/blockchains/blockchains-table/BlockchainsTable";

const ITEMSPERPAGE = [
    { title: 5, isSelected: false, id: uuidv4() },
    { title: 10, isSelected: true, id: uuidv4() },
    { title: 15, isSelected: false, id: uuidv4() },
];

export default function useBlockchainsState({
    requestedData,
    setRequestedData,
    setIsLoading,
    activeBlockchain,
    getLoadBlockchainData,
}) {
    const [blockchains, setBlockchains] = useState([]);
    const [blockchainsPage, setBlockchainsPage] = useState(1);
    const [blockchainsPageSize, setBlockchainsPageSize] = useState(10);
    const [blockchainsTotalPages, setBlockchainsTotalPages] = useState(10);

    const [blockchainsItemsPerPage, setBlockchainsItemsPerPage] = useState(ITEMSPERPAGE);

    const resetBlockchainsPagination = () => {
        setBlockchainsPage(1);
        setBlockchainsPageSize(10);
        setBlockchainsTotalPages(10);
        setBlockchainsItemsPerPage(ITEMSPERPAGE);
    };

    useEffect(() => {
        const blockchainsStates = JSON.parse(localStorage.getItem("blockchainsStates"));
        if (blockchainsStates?.pageSize) {
            let tempItemsPerPage = [...blockchainsItemsPerPage];
            tempItemsPerPage = tempItemsPerPage.map((item) => ({
                title: item.title,
                isSelected: item.title === blockchainsStates.pageSize,
                id: item.id,
            }));
            setBlockchainsItemsPerPage(tempItemsPerPage);
            setBlockchainsPageSize(blockchainsStates.pageSize);
        }
    }, []);

    const resetBlockchainData = () => {
        setRequestedData((prev) => ({
            ...prev,
            blockchainData: [],
        }));
    };

    const DEFAULTTABS = [
        {
            startIcon: null,
            title: "",
            isTitleHidden: true,
            content: <BlockchainsTable data={[]} />,
        },
    ];

    const [blockchainTabs, setBlockchainTabs] = useState(DEFAULTTABS);
    const [activeNetwork, setActiveNetwork] = useState("");

    useEffect(() => {
        setBlockchainsPage(1);
        if (!activeBlockchain) {
            resetBlockchainData()
            setBlockchainTabs(DEFAULTTABS);
            return;
        }
        const smartContractAddress = activeBlockchain.extraData.smartContractAddress;
        if (!smartContractAddress || smartContractAddress === "N/A") {
            resetBlockchainData();
            setBlockchainTabs(DEFAULTTABS);
            return;
        }

        // let networks = activeBlockchain.extraData.chainTargeting || ["BSC"];
        // if(!Array.isArray(networks)) networks = ["BSC"]  //check for outdated old campaigns
        // if(Array.isArray(networks) && !networks.length) networks = ["BSC"]  //check for outdated old campaigns
        
        
        const address = activeBlockchain.extraData.smartContractAddress;
        // getLoadBlockchainData(address, activeNetwork || networks[0]);
        getLoadBlockchainData(address, activeNetwork || 'BSC');
    }, [activeBlockchain, activeNetwork]);

    useEffect(() => {
        const firstTab = document.querySelectorAll(".blockchain-tabs .nav-link")[0];
        if (firstTab) firstTab.click();
    }, [activeBlockchain]);

    useEffect(() => {
        if (!activeBlockchain) {
            setBlockchainTabs(DEFAULTTABS);
            return
        }

        // let networks = activeBlockchain.extraData.chainTargeting || ["BSC"];

        // if(!Array.isArray(networks)) networks = ["BSC"]  //check for outdated old campaigns
        // if(Array.isArray(networks) && !networks.length) networks = ["BSC"] 
        const tempBlockchainTabs = ["BSC", "ETH"].map((item) => {
            return {
                startIcon: null,
                title: item,
                isTitleHidden: !item,
                content: <BlockchainsTable data={requestedData.blockchainData} />,
            };
        });
        setBlockchainTabs(tempBlockchainTabs);
    }, [requestedData.blockchainData]);

    return {
        blockchainsPage,
        setBlockchainsPage,
        blockchainsPageSize,
        setBlockchainsPageSize,
        blockchainsTotalPages,
        setBlockchainsTotalPages,
        blockchainsItemsPerPage,
        setBlockchainsItemsPerPage,
        blockchains,
        setBlockchains,
        resetBlockchainsPagination,
        blockchainTabs,
        setActiveNetwork,
    };
}
