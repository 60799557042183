import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import {
    Navbar,
    Button,
    Select,
    Geochart,
    TrafficSourcesTable,
    SocialMetrics,
    Field,
    Testimonials,
} from "../../../components";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";
import  useFetch  from "../../../hooks/useFetch";

import { auth, googleProvider } from "../../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
let timeout = ''

export default function SignIn() {
    const { fade } = useGlobalContext().animations;
    const {  errorAlert, handleSignOut} =
        useGlobalContext();
    const { disconnect } = localData.svgs;
    const { preloaderBig } = localData.images;
    const { logoDark, google, facebook } = localData.images;
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const { resetAccountPassword } = useFetch();

    // console.log(auth?.currentUser?.email, 'sign in page');

    const [raw, setRaw] = useState({
        email: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setRaw({ ...raw, [name]: value });
    };

    const handleResetAccountPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const data = await resetAccountPassword(() => {}, { email: raw.email });
            handleSignOut("Password reset instructions sent. Please check your email.")
            // clearTimeout(timeout);
            // timeout = setTimeout(() => {
                navigate("/");
            // }, 1000);
            console.log(data, "=resetAccountPassword= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=resetAccountPassword= request error");
        }
        setIsLoading(false);
    };

    return (
        <motion.main {...fade} className="main forgot-password-page">
            <div className="container">
                <div className="wrapper">
                    <form className="form" onSubmit={handleResetAccountPassword}>
                        {/* <img className="logo" src={logoDark} /> */}

                        <h2 className="form-title">Did you forget your password?</h2>
                        <div className="form-description">
                            Enter your email address you're using for your account below and we will send you
                            a password rese link
                        </div>

                        <Field
                            type="email"
                            label="Email"
                            color="secondary"
                            placeholder="Enter your email address"
                            required={true}
                            name="email"
                            callback={handleOnChange}
                        />

                        <Button
                            className="submit-btn"
                            name="Request reset link"
                            color="primary"
                            variant="contained"
                            size="lg"
                            icon={
                                isLoading ? (
                                    <span className="endIcon">
                                        <img src={preloaderBig} />
                                    </span>
                                ) : null
                            }
                            disabled={isLoading}
                        />
                    </form>

                    <p className="login-signup-now">
                        <Link to="/sign-in" underline="hover" className="link link-primary">
                            {" "}
                            Back to Sign in
                        </Link>
                    </p>
                </div>
                <p className="copyright">
                    ©Blockchain-Ads. All rights reserved.
                    <Link to="/privacy-policy" underline="hover" className="link link-primary">
                        {" "}
                        Privacy Policy
                    </Link>
                </p>
            </div>
        </motion.main>
    );
}
