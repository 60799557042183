import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import "swiper/css/bundle";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import Provider from "./context";
import StateProvider from "./stateContext";
import CampaignsProvider from "./campaignsContext";
import { ToastContainer, toast } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Router>
        <Provider>
            <StateProvider>
                <CampaignsProvider>
                    <ToastContainer autoClose={3000} />
              
                    <App />
                </CampaignsProvider>
            </StateProvider>
        </Provider>
    </Router>
    //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
