import React, { useState, useEffect, useRef } from "react";
import localData from "../../localData";
import { useGlobalContext } from "../../context";
import { v4 as uuidv4 } from "uuid";
import { Button } from "..";
import useCrop from "../../hooks/useCrop";
import useUtil from "../../hooks/useUtil";

import ReactCrop, {
    centerCrop,
    convertToPercentCrop,
    convertToPixelCrop,
    makeAspectCrop,
} from "react-image-crop";

const ASPECT_RATIO = 3 / 2;
const MIN_DIMENSTION = 50;
export default function ImageCropper({ image, callback }) {
    const [crop, setCrop] = useState();
    const imageRef = useRef(null);
    const canvasRef = useRef(null);

    const { placeholder } = localData.images;
    const { close } = localData.svgs;
    const { setSelectedImages, campaignsState } = useGlobalContext();

    const handleImageCrop = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSTION / width) * 100;
        // const cropHeightInPercent = (MIN_DIMENSTION / height) * 100;
        const crop = makeAspectCrop(
            {
                unit: "%", // Can be 'px' or '%'
                width: width,
            },
            ASPECT_RATIO,
            width,
            height
        );

        const centeredCrop = centerCrop(crop, width, height);

        setCrop(centeredCrop);
    };

    const { setCanvasPreview } = useCrop();
    const { getFileFromCanvas, resizeFile } = useUtil();

    function fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    }

    const updateImages = (dataURL, file) => {
        resizeFile(file, 900, 600)
            .then((resizedFile) => {
                fileToBase64(resizedFile)
                    .then((resizedDataURL) => {
                        let item = {
                            file: resizedFile,
                            preview: URL.createObjectURL(resizedFile),
                            image: resizedDataURL,
                            id: uuidv4(),
                            status: 1,
                            title: resizedFile.name,
                            size: resizedFile.size,
                            type: getImageTypeFromBase64(resizedDataURL), // Store the image type
                        };

                        // setSelectedImages((prevImages) => [...prevImages, item]);
                    })
                    .catch((error) => {
                        // Handle error
                        console.error("Error converting file to Base64:", error);
                    });
            })
            .catch((error) => {
                console.error("Error resizing image:", error);
            });
    };

    const getImageTypeFromBase64 = (base64String) => {
        const base64HeaderRegex = /^data:(image\/\w+);base64,/;
        const match = base64String.match(base64HeaderRegex);
        if (match && match[1]) {
            return match[1]; // Extracted image type (e.g., "image/jpeg", "image/png")
        } else {
            return null; // Image type not found
        }
    };

    const initCrop = async () => {
        try {
            setCanvasPreview(
                imageRef.current,
                canvasRef.current,
                convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
            );
            // console.log(imageRef.current.type);
            const file = await getFileFromCanvas(
                canvasRef.current,
                getImageTypeFromBase64(imageRef.current.src)
            );
            const dataURL = canvasRef.current.toDataURL();
            updateImages(dataURL, file);
            callback(dataURL);
        } catch (error) {
            console.error("Error generating file from canvas:", error);
        }
    };

    useEffect(()=>{
        if(!crop) return 
        initCrop()
    },[crop])

    return (
        <div className="image-cropper">
            <div className="image-cropper-header"></div>

            <div className="image-cropper-body">
                <ReactCrop
                    crop={crop}
                    onChange={(pixelCrop, percentCrop) => {
                        setCrop(percentCrop);
                    
                    }}
                    // onComplete={canvasRef.current && initCrop}
                    // circularCrop
                    keepSelection
                    aspect={ASPECT_RATIO}
                    minWidth={MIN_DIMENSTION}
                    style={{ display: true ? "block" : "none" }}
                >
                    <img
                        ref={imageRef}
                        className={`file-image`}
                        src={image || placeholder}
                        alt="Selected Image"
                        // style={{ maxHeight: "70vh" }}
                        onLoad={(e) => handleImageCrop(e)}
                    />
                </ReactCrop>
            </div>

            <div className="image-cropper-footer">
                {crop && (
                    <canvas
                        ref={canvasRef}
                        style={{
                            display: "none",
                            border: "1px solid",
                            width: "150px",
                            height: "150px",
                            objectFit: "contain",
                        }}
                    />
                )}
                {/* <Button
           
                    variant="contained"
                    color="primary"
                    name="Crop Image"
                    onClick={async () => {
                        setCanvasPreview(
                            imageRef.current,
                            canvasRef.current,
                            convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
                        );
                        try {
                            console.log(imageRef.current.type);
                            const file = await getFileFromCanvas(
                                canvasRef.current,
                                getImageTypeFromBase64(imageRef.current.src)
                            );
                            const dataURL = canvasRef.current.toDataURL();
                            updateImages(dataURL, file);
                            callback(dataURL)
                        } catch (error) {
                            console.error("Error generating file from canvas:", error);
                        }
                        
                    }}
                /> */}
            </div>
        </div>
    );
}
