import React from "react";
import localData from "../../../localData";

export default function InfoPopup({
    content,
    position,
    toggler,
    className = "",
}) {
    const { info, caretLeft } = localData.svgs;
    return (
        <span className={`popup info-popup ${className}`}>
            {toggler || info}
            <div className={`popup-content ${position}`}>
                {caretLeft}
                {content || "content here"}
            </div>
        </span>
    );
}
