import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Select, Pagination, TableSkeleton } from "../../../components";
import ReactPaginate from "react-paginate";
import localData from "../../../localData";

const { arrowRight, arrowLeft } = localData.svgs;
const { textPreloader } = localData.images;

export default function InstightsTable({ items = [], itemsPerPage = 10,isLoading }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };
    return (
        <div className="insights-table-wrapper">
            {isLoading ? (
                <TableSkeleton message="Loading..." icon={textPreloader} />
            ) : (
                <div className="scroll">
                    <table className="table insights-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Impressions</th>
                                <th>Clicks</th>
                                <th>Conversions</th>
                                <th>Conversions Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!items.length ? (
                                <tr style={{ pointerEvents: "none" }}>
                                    <td colSpan="10">
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                currentItems.map((item, index) => {
                                    return <InsightsRow key={index} {...{ ...item, item, index }} />;
                                })
                            )}
                        </tbody>
                    </table>
                    {!items.length ? (
                        ""
                    ) : (
                        <>
                            <br />
                            <ReactPaginate
                                breakLabel="..."
                                // nextLabel="next >"
                                nextLabel={arrowRight}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                // previousLabel="< prev"
                                previousLabel={arrowLeft}
                                renderOnZeroPageCount={null}
                            />
                            <br />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

const InsightsRow = ({ item, index }) => {
    return (
        <tr>
            <td>{item.Date}</td>
            <td>{item.Impressions}</td>
            <td>{item.Clicks}</td>
            <td>{item.Conversions}</td>
            <td>{item["Conversion Value"]}</td>
        </tr>
    );
};
