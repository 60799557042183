import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import localData from "../../localData";
import { Button } from "../../components";
import useFormatter from "../useFormatter";

const {
    avatar,
    user,
    coinTelegraph,
    bitcoin,
    beInCrypto,
    techTimes,
    uToday,
    hackernoon,
    dappRadar,
    bloomberg,
    theDailyHodl,
    startupFortune,
    techBoolean,
    publish0x,
    benzinga,
    yahoo,
} = localData.images;

// const fetchedData = [
//     {
//         id: uuidv4(),
//         packageType: "Featured",
//         packageName: "Featured",
//         media: "Diamond",
//         format: "Feature",
//         monthlyTraffic: 705100,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         price: "20000",
//         isFeatured: true,
//         extraSource: ["+75 Crypto Websites"],
//         discount: "1.75B+",
//         products: [
//             { media: "Cointelegraph", logo: coinTelegraph },
//             { media: "Bitcoin.com", logo: bitcoin },
//             { media: "BeInCrypto", logo: beInCrypto },
//         ],
//         included: [
//             "PR Guaranteed on Cointelegraph, Bitcoin.com and BeInCrypto",
//             "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
//             "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
//             "75 Guaranteed Press Releases on leading news crypto websites (cryptonews.com, cryptopotato.com, cryptoslate.com, etc)",
//             "Twitter Post done by Bitcoin.com (2.5M Followers Verified account)",
//             "Facebook Post done by Bitcoin.com (300K Followers)",
//             "Your Project Featured on the homepage of all news outlets",
//             "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
//             "Up to 2 images per article",
//             "Transparent and detailed reporting",
//             "PR writing upon request",
//         ],
//     },
//     {
//         id: uuidv4(),
//         packageType: "Featured",
//         packageName: "Featured",
//         media: "Platinum",
//         format: "Feature",
//         monthlyTraffic: 705100,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         price: "18000",
//         isFeatured: true,
//         extraSource: ["+75 Crypto Websites"],
//         discount: "690M+",
//         products: [
//             { media: "Cointelegraph", logo: coinTelegraph },
//             { media: "BeInCrypto", logo: beInCrypto },
//         ],
//         included: [
//             "PR Guaranteed on Cointelegraph",
//             "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
//             "PR Guaranteed on Mainstream Media + Yahoo + Benzinga + MarketWatch",
//             "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
//             "Your Project Featured on the homepage of all news outlets",
//             "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
//             "Up to 2 images per article",
//             "Transparent and detailed reporting",
//             "PR writing upon request",
//         ],
//     },
//     {
//         id: uuidv4(),
//         packageType: "Featured",
//         packageName: "Featured",
//         media: "Gold",
//         format: "Feature",
//         monthlyTraffic: 705100,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         price: "12000",
//         isFeatured: true,
//         extraSource: ["+25 Crypto Websites"],
//         discount: "310M+",
//         products: [{ media: "Cointelegraph", logo: coinTelegraph }],
//         included: [
//             "    PR Guaranteed on Cointelegraph                                                                                                                                                                                                                                                                                                                                                                  ",
//             "PR Published on up to 25 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
//             "Your Project Featured on the homepage of all news outlets",
//             "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
//             "Up to 2 images per article",
//             "Transparent and detailed reporting",
//         ],
//     },
//     {
//         id: uuidv4(),
//         packageType: "Featured",
//         packageName: "Featured",
//         media: "Gold",
//         format: "Feature",
//         monthlyTraffic: 705100,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         price: "10000",
//         isFeatured: true,
//         extraSource: ["+75 Crypto Websites", "+Mainstream Media PR (BusinessInsider, TradingView)"],
//         discount: "500M+ readers",
//         products: [],
//         included: [
//             "Press Release submission or written by us",
//             "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
//             "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to approval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
//             "PR Guaranteed on 50 Smaller Crypto Sites (cryptounfolded.com, cryptopress.news, cryptonews.direct, etc)",
//             "Article featured on crypto websites homepages",
//             "Social Media posts included for some websites as a bonus",
//             "SEO link-building targeted to growth of intent-driven traffic",
//             "Transparent and detailed reporting",
//         ],
//     },
//     {
//         id: uuidv4(),
//         packageType: "Featured",
//         packageName: "Featured",
//         media: "Silver",
//         format: "Feature",
//         monthlyTraffic: 705100,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         price: "25500",
//         isFeatured: true,
//         extraSource: ["+25 Crypto Websites"],
//         discount: "500M+ readers",
//         products: [],
//         included: [
//             "Press Release (PR) posted on Premium Japan Media              ",
//             "Japanese journalist will write/translate the article",
//             "PR Guaranteed on Coinpost (nr. 1 for Crypto in Japan)",
//             "PR Guaranteed on Cointelegraph Japan",
//             "Press Release (PR) posted on Korean Media",
//             "Korean journalist will write/translate the article",
//             "Published on 130+ Korean Sites",
//             "PR Guaranteed on Naver.com",
//             "PR Guaranteed on Cobak.co.kr",
//             "PR Guaranteed on Coinreaders.com",
//             "Press Release (PR) posted on Chinese Media",
//             "Chinese journalist will write/translate the article",
//             "Published on 130+ Chinese Sites",
//             "Baidu Guaranteed",
//             "SEO link-building targeted to growth of intent-driven traffic",
//             "Transparent and detailed reporting",
//         ],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage1",
//         packageName: "Tech Startups Package 1",
//         media: "TechTimes",
//         format: "Feature",
//         monthlyTraffic: 705100,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage1",
//         packageName: "Tech Startups Package 1",
//         media: "TechBullion",
//         format: "Feature or interview",
//         monthlyTraffic: 218880,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage1",
//         packageName: "Tech Startups Package 1",
//         media: "Startup Fortune",
//         format: "Feature or interview",
//         monthlyTraffic: 14100,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage1",
//         packageName: "Tech Startups Package 1",
//         media: "Yahoo News",
//         format: "1 Press release",
//         monthlyTraffic: 271100000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage1",
//         packageName: "Tech Startups Package 1",
//         media: "Yahoo Finance",
//         format: "Unknown",
//         monthlyTraffic: 245300000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage1",
//         packageName: "Tech Startups Package 1",
//         media: "Market Watch",
//         format: "1 Press release",
//         monthlyTraffic: 70600000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage1",
//         packageName: "Tech Startups Package 1",
//         media: "Benzinga",
//         format: "Unknown",
//         monthlyTraffic: 15300000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "TechTimes",
//         format: "Feature or interview",
//         monthlyTraffic: 705100,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "TechBullion",
//         format: "Feature or interview",
//         monthlyTraffic: 218880,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "TechGyd",
//         format: "Feature",
//         monthlyTraffic: 73700,
//         turnaroundTime: "1.5-2.5 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "Startup Fortune",
//         format: "Feature or interview",
//         monthlyTraffic: 14100,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "ITProPortal",
//         format: "Brand mention",
//         monthlyTraffic: 65700,
//         turnaroundTime: "2-3 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "Digital Journal",
//         format: "Brand mention",
//         monthlyTraffic: 903000,
//         turnaroundTime: "1.5-2.5 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "Market Watch",
//         format: "Press release",
//         monthlyTraffic: 70600000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "TechStartupsPackage2",
//         packageName: "Tech Startups Package 2",
//         media: "Yahoo News",
//         format: "Press release",
//         monthlyTraffic: 271100000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage1",
//         packageName: "Crypto Package 1",
//         media: "U.Today",
//         format: "Feature",
//         monthlyTraffic: 2900000,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage1",
//         packageName: "Crypto Package 1",
//         media: "Hackernoon",
//         format: "Interview",
//         monthlyTraffic: 1900000,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage1",
//         packageName: "Crypto Package 1",
//         media: "Publish0x",
//         format: "Feature or interview",
//         monthlyTraffic: 1300000,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage1",
//         packageName: "Crypto Package 1",
//         media: "The Crypto Updates",
//         format: "Feature",
//         monthlyTraffic: 14400,
//         turnaroundTime: "5-8 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage1",
//         packageName: "Crypto Package 1",
//         media: "Crypto Mode",
//         format: "Feature or interview",
//         monthlyTraffic: 159600,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "Hackernoon",
//         format: "Interview",
//         monthlyTraffic: 1900000,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "Publish0x",
//         format: "Feature or interview",
//         monthlyTraffic: 1300000,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "Coinpedia",
//         format: "Feature",
//         monthlyTraffic: 471800,
//         turnaroundTime: "3-10 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "ZyCrypto",
//         format: "Feature",
//         monthlyTraffic: 342100,
//         turnaroundTime: "5-8 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "The Crypto Updates",
//         format: "Feature",
//         monthlyTraffic: 14400,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "Crypto Mode",
//         format: "Feature or interview",
//         monthlyTraffic: 159600,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "BitcoinGarden",
//         format: "Feature",
//         monthlyTraffic: 12200,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "Crypto Daily",
//         format: "Feature or interview",
//         monthlyTraffic: 2220000,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "Crypto.news",
//         format: "Feature",
//         monthlyTraffic: 399400,
//         turnaroundTime: "2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage2",
//         packageName: "Crypto Package 2",
//         media: "BitcoinWarrior",
//         format: "Feature",
//         monthlyTraffic: "No data",
//         turnaroundTime: "1 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Cointelegraph",
//         format: "Press release (direct link)",
//         monthlyTraffic: 9900000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Hackernoon",
//         format: "Interview",
//         monthlyTraffic: 1900000,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Benzinga",
//         format: "Sponsored article",
//         monthlyTraffic: 15300000,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Crypto Daily",
//         format: "Feature or interview",
//         monthlyTraffic: 2220000,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Publish0x",
//         format: "Feature or interview",
//         monthlyTraffic: 1300000,
//         turnaroundTime: "1.5-2.5 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "The Crypto Updates",
//         format: "Feature",
//         monthlyTraffic: 14400,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "TechBullion",
//         format: "Feature or interview",
//         monthlyTraffic: 218880,
//         turnaroundTime: "5-8 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Coinspeaker",
//         format: "Feature or interview",
//         monthlyTraffic: 107100,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Coinpedia",
//         format: "Feature",
//         monthlyTraffic: 471800,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Crypto Mode",
//         format: "Feature or interview",
//         monthlyTraffic: 159600,
//         turnaroundTime: "3-5 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Yahoo Finance",
//         format: "Press release",
//         monthlyTraffic: 245300000,
//         turnaroundTime: "3-10 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "Yahoo News",
//         format: "Press release",
//         monthlyTraffic: 271100000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "BeInCrypto",
//         format: "Press release",
//         monthlyTraffic: 3610000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "NewsBTC",
//         format: "Press release",
//         monthlyTraffic: 792300,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoPackage3",
//         packageName: "Crypto Package 3",
//         media: "CryptoNews",
//         format: "Press release",
//         monthlyTraffic: 3000000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "DappRadar (Games section)",
//         format: "Sponsored article",
//         monthlyTraffic: 1100000,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "Gamer Empire",
//         format: "Sponsored article",
//         monthlyTraffic: 1100000,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "PlayToEarn.net",
//         format: "Press release",
//         monthlyTraffic: 204000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "1926.00",
//     },
//     // Gaming Package
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "Back2Gaming",
//         format: "Press release",
//         monthlyTraffic: 75100,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "Einpresswire (Games section)",
//         format: "Press release",
//         monthlyTraffic: 640000,
//         turnaroundTime: "1-2 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "MmoGames.com",
//         format: "Press release",
//         monthlyTraffic: 37500,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "Game Industry",
//         format: "Press release",
//         monthlyTraffic: 430000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "GamingPackage",
//         packageName: "Gaming Package",
//         media: "Games Press",
//         format: "Press release",
//         monthlyTraffic: 540000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "1500.00",
//     },
//     // Finance - Package 1
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage1",
//         packageName: "Finance Package 1",
//         media: "Bloomberg",
//         format: "Press release (direct link)",
//         monthlyTraffic: 71140000,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage1",
//         packageName: "Finance Package 1",
//         media: "Benzinga",
//         format: "Sponsored article",
//         monthlyTraffic: 15300000,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage1",
//         packageName: "Finance Package 1",
//         media: "Finance Magnates",
//         format: "Feature",
//         monthlyTraffic: 267300,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage1",
//         packageName: "Finance Package 1",
//         media: "Market Watch",
//         format: "Press release",
//         monthlyTraffic: 71400000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage1",
//         packageName: "Finance Package 1",
//         media: "Yahoo Finance",
//         format: "Press release",
//         monthlyTraffic: 244000000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "2500.00",
//     },
//     // Finance - Package 2
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "Finance Magnates",
//         format: "Feature",
//         monthlyTraffic: 267300,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "Bloomberg",
//         format: "Press release (direct link)",
//         monthlyTraffic: 71140000,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "Benzinga",
//         format: "Sponsored article",
//         monthlyTraffic: 15300000,
//         turnaroundTime: "1.5-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "FXStreet",
//         format: "Feature",
//         monthlyTraffic: 3600000,
//         turnaroundTime: "2-2.5 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "Zerohedge",
//         format: "Brand Mention",
//         monthlyTraffic: 35800000,
//         turnaroundTime: "1-2 weeks",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "AtoZ Markets",
//         format: "Feature",
//         monthlyTraffic: 71600,
//         turnaroundTime: "1-1.5 week",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "Market Watch",
//         format: "Press release",
//         monthlyTraffic: 70600000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "Yahoo Finance",
//         format: "Press release",
//         monthlyTraffic: 245300000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "FinancePackage2",
//         packageName: "Finance Package 2",
//         media: "Yahoo News",
//         format: "Press release",
//         monthlyTraffic: 271100000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "2600.00",
//     },
//     // Press-Release Package - 1
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage1",
//         packageName: "Press Release Package 1",
//         media: "Market Watch",
//         format: "1 press release",
//         monthlyTraffic: 70600000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage1",
//         packageName: "Press Release Package 1",
//         media: "Benzinga",
//         format: "Unknown",
//         monthlyTraffic: 15300000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage1",
//         packageName: "Press Release Package 1",
//         media: "Yahoo Finance",
//         format: "Unknown",
//         monthlyTraffic: 245300000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage1",
//         packageName: "Press Release Package 1",
//         media: "Yahoo News",
//         format: "Unknown",
//         monthlyTraffic: 271100000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage1",
//         packageName: "Press Release Package 1",
//         media: "200+ news sites",
//         format: "Unknown",
//         monthlyTraffic: "No data",
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "1500.00",
//     },
//     // Press-Release Package - 2
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage2",
//         packageName: "Press Release Package 2",
//         media: "Bloomberg",
//         format: "1 press release",
//         monthlyTraffic: 71140000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage2",
//         packageName: "Press Release Package 2",
//         media: "Associated Press",
//         format: "Unknown",
//         monthlyTraffic: 38400000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage2",
//         packageName: "Press Release Package 2",
//         media: "Market Watch",
//         format: "Unknown",
//         monthlyTraffic: 70600000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage2",
//         packageName: "Press Release Package 2",
//         media: "Benzinga",
//         format: "Unknown",
//         monthlyTraffic: 15300000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage2",
//         packageName: "Press Release Package 2",
//         media: "Yahoo Finance",
//         format: "Unknown",
//         monthlyTraffic: 245300000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage2",
//         packageName: "Press Release Package 2",
//         media: "Yahoo News",
//         format: "Unknown",
//         monthlyTraffic: 271100000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "PressReleasePackage2",
//         packageName: "Press Release Package 2",
//         media: "400+ news sites",
//         format: "Unknown",
//         monthlyTraffic: "No data",
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "1500.00",
//     },
//     // Crypto Releases - Pack 1
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack1",
//         packageName: "Crypto Releases Pack 1",
//         media: "The Daily Hodl",
//         format: "1 press release",
//         monthlyTraffic: 2200000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack1",
//         packageName: "Crypto Releases Pack 1",
//         media: "The Crypto Updates",
//         format: "Unknown",
//         monthlyTraffic: 14400,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack1",
//         packageName: "Crypto Releases Pack 1",
//         media: "TechBullion",
//         format: "Unknown",
//         monthlyTraffic: 218880,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack1",
//         packageName: "Crypto Releases Pack 1",
//         media: "Crypto Mode",
//         format: "Unknown",
//         monthlyTraffic: 159600,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack1",
//         packageName: "Crypto Releases Pack 1",
//         media: "25+ small crypto media",
//         format: "Unknown",
//         monthlyTraffic: "No data",
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "1500.00",
//     },
//     // Crypto Releases - Pack 2
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "The Daily Hodl",
//         format: "1 press release",
//         monthlyTraffic: 2200000,
//         turnaroundTime: "2-3 business days",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "Cryptopolitan",
//         format: "Unknown",
//         monthlyTraffic: 1600000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "Publish0x",
//         format: "Unknown",
//         monthlyTraffic: 1300000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "Cryptela",
//         format: "Unknown",
//         monthlyTraffic: 137700,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "The Crypto Updates",
//         format: "Unknown",
//         monthlyTraffic: 14400,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "BlockchainReporter",
//         format: "Unknown",
//         monthlyTraffic: 736600,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "TechBullion",
//         format: "Unknown",
//         monthlyTraffic: 218880,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "The Merkle",
//         format: "Unknown",
//         monthlyTraffic: 10900,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "Crypto Mode",
//         format: "Unknown",
//         monthlyTraffic: 159600,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "Coinspeaker",
//         format: "Unknown",
//         monthlyTraffic: 107100,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "TheNewsCrypto",
//         format: "Unknown",
//         monthlyTraffic: 68700,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "NFT News Today",
//         format: "Unknown",
//         monthlyTraffic: 52600,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "Blockonomi",
//         format: "Unknown",
//         monthlyTraffic: 87000,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "Tech Startups",
//         format: "Unknown",
//         monthlyTraffic: 39800,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "0",
//         products: [],
//         included: [],
//     },
//     {
//         id: uuidv4(),
//         packageType: "CryptoReleasesPack2",
//         packageName: "Crypto Releases Pack 2",
//         media: "ICOHolder",
//         format: "Unknown",
//         monthlyTraffic: 33100,
//         turnaroundTime: "",
//         isInShoppingCart: false,
//         isInWishlist: false,
//         cover: "",
//         isFeatrued: false,
//         discount: "",
//         extraSource: [],
//         price: "2900.00",
//     },
// ];

const fetchedData = [
    {
        id: "featured-1-103803391c1d",
        packageName: "Featured 1",
        packageShortName: "featured 1",
        packageType: "diamond",
        isInShoppingCart: false,
        isInWishlist: false,
        cover: "",
        totalPrice: "20000",
        isFeatured: true,
        extraSource: ["+75 Crypto Websites"],
        totalReach: "Publication potential reach: 1.75B+",
        products: [
            { media: "Cointelegraph", logo: coinTelegraph },
            { media: "Bitcoin.com", logo: bitcoin },
            { media: "BeInCrypto", logo: beInCrypto },
        ],
        included: [
            "PR Guaranteed on Cointelegraph, Bitcoin.com and BeInCrypto",
            "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
            "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
            "75 Guaranteed Press Releases on leading news crypto websites (cryptonews.com, cryptopotato.com, cryptoslate.com, etc)",
            "Twitter Post done by Bitcoin.com (2.5M Followers Verified account)",
            "Facebook Post done by Bitcoin.com (300K Followers)",
            "Your Project Featured on the homepage of all news outlets",
            "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
            "Up to 2 images per article",
            "Transparent and detailed reporting",
            "PR writing upon request",
        ],
    },
    {
        id: "featured-2-103803391c1d",
        packageName: "Featured 2",
        packageShortName: "featured 2",
        packageType: "Platinum",
        isInShoppingCart: false,
        isInWishlist: false,
        cover: "",
        totalPrice: "18000",
        isFeatured: true,
        extraSource: ["+75 Crypto Websites"],
        totalReach: "Publication potential reach: 690M+",
        products: [
            { media: "Cointelegraph", logo: coinTelegraph },
            { media: "BeInCrypto", logo: beInCrypto },
        ],
        included: [
            "PR Guaranteed on Cointelegraph",
            "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
            "PR Guaranteed on Mainstream Media + Yahoo + Benzinga + MarketWatch",
            "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
            "Your Project Featured on the homepage of all news outlets",
            "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
            "Up to 2 images per article",
            "Transparent and detailed reporting",
            "PR writing upon request",
        ],
    },
    {
        id: "featured-3-103803391c1d",
        packageName: "Featured 3",
        packageShortName: "featured 3",
        packageType: "Gold",
        isInShoppingCart: false,
        isInWishlist: false,
        cover: "",
        totalPrice: "12000",
        isFeatured: true,
        extraSource: ["+25 Crypto Websites"],
        totalReach: "Publication potential reach: 310M+",
        products: [{ media: "Cointelegraph", logo: coinTelegraph }],
        included: [
            "    PR Guaranteed on Cointelegraph                                                                                                                                                                                                                                                                                                                                                                  ",
            "PR Published on up to 25 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
            "Your Project Featured on the homepage of all news outlets",
            "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
            "Up to 2 images per article",
            "Transparent and detailed reporting",
        ],
    },
    {
        id: "featured-4-103803391c1d",
        packageName: "Featured 4",
        packageShortName: "featured 4",
        packageType: "Silver",
        isInShoppingCart: false,
        isInWishlist: false,
        cover: "",
        totalPrice: "10000",
        isFeatured: true,
        extraSource: ["+75 Crypto Websites", "+Mainstream Media PR (BusinessInsider, TradingView)"],
        totalReach: "Publication potential reach: 500M+ readers",
        products: [],
        included: [
            "Press Release submission or written by us",
            "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
            "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to approval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
            "PR Guaranteed on 50 Smaller Crypto Sites (cryptounfolded.com, cryptopress.news, cryptonews.direct, etc)",
            "Article featured on crypto websites homepages",
            "Social Media posts included for some websites as a bonus",
            "SEO link-building targeted to growth of intent-driven traffic",
            "Transparent and detailed reporting",
        ],
    },
    {
        id: "featured-5-103803391c1d",
        packageName: "Featured 5",
        packageShortName: "featured 5",
        packageType: "Asia",
        isInShoppingCart: false,
        isInWishlist: false,
        cover: "",
        totalPrice: "25500",
        isFeatured: true,
        extraSource: ["+25 Crypto Websites"],
        totalReach: "Publication potential reach: 500M+ readers",
        products: [],
        included: [
            "Press Release (PR) posted on Premium Japan Media              ",
            "Japanese journalist will write/translate the article",
            "PR Guaranteed on Coinpost (nr. 1 for Crypto in Japan)",
            "PR Guaranteed on Cointelegraph Japan",
            "Press Release (PR) posted on Korean Media",
            "Korean journalist will write/translate the article",
            "Published on 130+ Korean Sites",
            "PR Guaranteed on Naver.com",
            "PR Guaranteed on Cobak.co.kr",
            "PR Guaranteed on Coinreaders.com",
            "Press Release (PR) posted on Chinese Media",
            "Chinese journalist will write/translate the article",
            "Published on 130+ Chinese Sites",
            "Baidu Guaranteed",
            "SEO link-building targeted to growth of intent-driven traffic",
            "Transparent and detailed reporting",
        ],
    },
    {
        id: "tech-startup-package-1-103803391c1d",
        packageName: "Tech Startups - Package 1",
        packageShortName: "tech 1",
        packageType: "Tech package 1",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "4500",
        extraSource: [],
        discount: "",
        totalReach: "Publication potential reach: Over 602 millions",
        description: "Some publications editorial teams might take between 5 to 8 days at their discretion.",
        content:
            "Additionally, your Press Release will be featured in more than 200 publications within the syndicate.",
        content2: "Distribution will be within 24-72h",
        products: [
            {
                id: "yahoo-news-2b3c4d5e-6789",
                media: "Yahoo News",
                logo: yahoo,
                format: "1 Press Release on Yahoo News",
                monthlyTraffic: "",
                reach: "14100",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
            },
            {
                id: "yahoo-finance-3c4d5e6f-7890",
                media: "Yahoo Finance",
                format: "1 Press Release on Yahoo Finance",
                monthlyTraffic: "",
                reach: "271100000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "benzinga-5e6f7g8h-9012",
                media: "Benzinga",
                logo: benzinga,
                format: "1 Press Release on Benzinga",
                monthlyTraffic: "",
                reach: "70600000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "market-watch-4d5e6f7g-8901",
                media: "Market Watch",
                format: "1 Press Release on Market Watch",
                monthlyTraffic: "",
                reach: "245300000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "startup-fortune-1a2b3c4d-5678",
                media: "Startup Fortune",
                format: "Feature or Interview on Startup Fortune",
                monthlyTraffic: "",
                reach: "218880",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
                logo: startupFortune,
            },
        ],
        included: ["Guaranteed Feature on TechTimes"],
    },
    {
        id: "tech-startup-package-2-103803391c1d",
        packageName: "Tech Startups - Package 2",
        packageShortName: "tech 2",
        packageType: "Tech package 2",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "9500",
        extraSource: [],
        discount: "",
        totalReach: "Publication potential reach: Over 350 Milion",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "techbullion-1a2b3c4d-5678",
                media: "TechBullion",
                format: "Feature or Interview on TechBullion",
                monthlyTraffic: "",
                reach: "705100",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
                logo: techBoolean,
            },
            {
                id: "techgyd-2b3c4d5e-6789",
                media: "TechGyd",
                format: "Feature on TechGyd",
                monthlyTraffic: "",
                reach: "218880",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
            },
            {
                id: "startup-fortune-3c4d5e6f-7890",
                media: "Startup Fortune",
                format: "Feature or Interview on Startup Fortune",
                monthlyTraffic: "",
                reach: "73700",
                turnaroundTime: "",
                duration: "1.5-2.5 weeks",
                price: "",
            },
            {
                id: "itproportal-4d5e6f7g-8901",
                media: "ITProPortal",
                format: "Brand Mention on ITProPortal",
                monthlyTraffic: "",
                reach: "14100",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
            },
            {
                id: "digital-journal-5e6f7g8h-9012",
                media: "Digital Journal",
                format: "Brand Mention on Digital Journal",
                monthlyTraffic: "",
                reach: "65700",
                turnaroundTime: "",
                duration: "2-3 weeks",
                price: "",
            },
            {
                id: "market-watch-6f7g8h9i-0123",
                media: "Market Watch",
                format: "Press Release on Market Watch",
                monthlyTraffic: "",
                reach: "903000",
                turnaroundTime: "",
                duration: "1.5-2.5 weeks",
                price: "",
            },
            {
                id: "yahoo-news-7g8h9i0j-1234",
                media: "Yahoo News",
                logo: yahoo,
                format: "Press Release on Yahoo News",
                monthlyTraffic: "",
                reach: "70600000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
        ],
        included: ["Feature or Interview on TechTimes"],
    },
    {
        id: "crypto-package-1-103803391c1d",
        packageName: "Crypto - Package 1",
        packageShortName: "crypto 1",
        packageType: "Crypto package 1",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "5200",
        totalReach: "Publication potential reach: Over 6 Milion",
        extraSource: [],
        discount: "",
        description:
            "Your crypto project will benefit from significant exposure across leading crypto publications, enhancing visibility and engagement within the crypto community.",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",

        products: [
            {
                id: "hackernoon-8h9i0j1k-2345",
                media: "Hackernoon",
                format: "Interview on Hackernoon",
                monthlyTraffic: "",
                reach: "2900000",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
                logo: hackernoon,
            },
            {
                id: "publish0x-9i0j1k2l-3456",
                media: "Publish0x",
                format: "Feature or Interview on Publish0x",
                monthlyTraffic: "",
                reach: "1900000",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
            },
            {
                id: "the-crypto-updates-0j1k2l3m-4567",
                media: "The Crypto Updates",
                format: "Feature on The Crypto Updates",
                monthlyTraffic: "",
                reach: "1300000",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
            {
                id: "crypto-mode-1k2l3m4n-5678",
                media: "Crypto Mode",
                format: "Feature or Interview on Crypto Mode",
                monthlyTraffic: "",
                reach: "14400",
                turnaroundTime: "5-8 business days",
                duration: "",
                price: "",
            },
        ],
        included: ["Feature on U.Today"],
    },
    {
        id: "crypto-package-2-103803391c1d",
        packageName: "Crypto - Package 2",
        packageShortName: "crypto 2",
        packageType: "Crypto package 2",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "8500",
        totalReach: "Publication potential reach: Over 7 Milion",
        extraSource: [],
        discount: "",
        description:
            "Your crypto project will receive substantial exposure across a wide array of reputable crypto platforms, ensuring heightened visibility and engagement within the crypto community.",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "publish0x-1a2b3c4d-5678",
                media: "Publish0x",
                format: "Feature or Interview on Publish0x",
                monthlyTraffic: "",
                reach: "1900000",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
                logo: publish0x,
            },
            {
                id: "coinpedia-2b3c4d5e-6789",
                media: "Coinpedia",
                format: "Feature on Coinpedia",
                monthlyTraffic: "",
                reach: "1300000",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
            },
            {
                id: "zycrypto-3c4d5e6f-7890",
                media: "ZyCrypto",
                format: "Feature on ZyCrypto",
                monthlyTraffic: "",
                reach: "471800",
                turnaroundTime: "3-10 business days",
                duration: "",
                price: "",
            },
            {
                id: "the-crypto-updates-4d5e6f7g-8901",
                media: "The Crypto Updates",
                format: "Feature on The Crypto Updates",
                monthlyTraffic: "",
                reach: "342100",
                turnaroundTime: "5-8 business days",
                duration: "",
                price: "",
            },
            {
                id: "crypto-mode-5e6f7g8h-9012",
                media: "Crypto Mode",
                format: "Feature or Interview on Crypto Mode",
                monthlyTraffic: "",
                reach: "14400",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
            },
            {
                id: "bitcoingarden-6f7g8h9i-0123",
                media: "BitcoinGarden",
                format: "Feature on BitcoinGarden",
                monthlyTraffic: "",
                reach: "159600",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
            {
                id: "crypto-daily-7g8h9i0j-1234",
                media: "Crypto Daily",
                format: "Feature or Interview on Crypto Daily",
                monthlyTraffic: "",
                reach: "12200",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
            },
            {
                id: "crypto-news-8h9i0j1k-2345",
                media: "Crypto.news",
                format: "Feature on Crypto.news",
                monthlyTraffic: "",
                reach: "2220000",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
            {
                id: "bitcoinwarrior-9i0j1k2l-3456",
                media: "BitcoinWarrior",
                format: "Feature on BitcoinWarrior",
                monthlyTraffic: "",
                reach: "399400",
                turnaroundTime: "",
                duration: "2 weeks",
                price: "",
            },
        ],
        included: ["Interview on Hackernoon"],
    },
    {
        id: "crypto-package-3-103803391c1d",
        packageName: "Crypto - Package 3",
        packageShortName: "crypto 3",
        packageType: "Crypto package 3",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "15800",
        totalReach: "Publication potential reach: Over 557 Millions",
        extraSource: [],
        discount: "",
        description:
            "Your crypto project will gain extensive exposure across a diverse range of influential platforms, ensuring broad visibility and engagement within the crypto community.",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "hackernoon-1a2b3c4d-5678",
                media: "Hackernoon",
                format: "Interview on Hackernoon",
                monthlyTraffic: "",
                reach: "9900000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
                logo: hackernoon,
            },
            {
                id: "benzinga-2b3c4d5e-6789",
                media: "Benzinga",
                logo: benzinga,
                format: "Sponsored Article on Benzinga",
                monthlyTraffic: "",
                reach: "1900000",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
            },
            {
                id: "crypto-daily-3c4d5e6f-7890",
                media: "Crypto Daily",
                format: "Feature or Interview on Crypto Daily",
                monthlyTraffic: "",
                reach: "15300000",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
            },
            {
                id: "publish0x-4d5e6f7g-8901",
                media: "Publish0x",
                format: "Feature or Interview on Publish0x",
                monthlyTraffic: "",
                reach: "2220000",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
            },
            {
                id: "the-crypto-updates-5e6f7g8h-9012",
                media: "The Crypto Updates",
                format: "Feature on The Crypto Updates",
                monthlyTraffic: "",
                reach: "1300000",
                turnaroundTime: "",
                duration: "1.5-2.5 weeks",
                price: "",
            },
            {
                id: "techbullion-6f7g8h9i-0123",
                media: "TechBullion",
                format: "Feature or Interview on TechBullion",
                monthlyTraffic: "",
                reach: "14400",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
            },
            {
                id: "coinspeaker-7g8h9i0j-1234",
                media: "Coinspeaker",
                format: "Feature or Interview on Coinspeaker",
                monthlyTraffic: "",
                reach: "218880",
                turnaroundTime: "5-8 business days",
                duration: "",
                price: "",
            },
            {
                id: "coinpedia-8h9i0j1k-2345",
                media: "Coinpedia",
                format: "Feature on Coinpedia",
                monthlyTraffic: "",
                reach: "107100",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
            {
                id: "crypto-mode-9i0j1k2l-3456",
                media: "Crypto Mode",
                format: "Feature or Interview on Crypto Mode",
                monthlyTraffic: "",
                reach: "471800",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
            },
            {
                id: "yahoo-finance-0j1k2l3m-4567",
                media: "Yahoo Finance",
                format: "Press Release on Yahoo Finance",
                monthlyTraffic: "",
                reach: "159600",
                turnaroundTime: "3-5 business days",
                duration: "",
                price: "",
            },
            {
                id: "yahoo-news-1k2l3m4n-5678",
                media: "Yahoo News",
                logo: yahoo,
                format: "Press Release on Yahoo News",
                monthlyTraffic: "",
                reach: "245300000",
                turnaroundTime: "3-10 business days",
                duration: "",
                price: "",
            },
            {
                id: "beincrypto-2l3m4n5o-6789",
                media: "BeInCrypto",
                format: "Press Release on BeInCrypto",
                monthlyTraffic: "",
                reach: "271100000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "newsbtc-3m4n5o6p-7890",
                media: "NewsBTC",
                format: "Press Release on NewsBTC",
                monthlyTraffic: "",
                reach: "3610000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "cryptonews-4n5o6p7q-8901",
                media: "CryptoNews",
                format: "Press Release on CryptoNews",
                monthlyTraffic: "",
                reach: "792300",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
        ],
        included: ["Press Release (Direct Link) on Cointelegraph"],
    },
    {
        id: "gaming-package-1-103803391c1d",
        packageName: "Gaming Package",
        packageShortName: "gaming",
        packageType: "Gaming package",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "8900",
        totalReach: "Publication potential reach: Over 2,5 Milion",
        extraSource: [],
        discount: "",
        description:
            "Your gaming project will receive significant exposure across key gaming platforms, enhancing visibility and engagement within the gaming community.",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "gamer-empire-1a2b3c4d-5678",
                media: "Gamer Empire",
                format: "Sponsored Article on Gamer Empire",
                monthlyTraffic: "",
                reach: "1100000",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
            {
                id: "playtoearn-net-2b3c4d5e-6789",
                media: "PlayToEarn.net",
                format: "Press Release on PlayToEarn.net",
                monthlyTraffic: "",
                reach: "1100000",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
            {
                id: "back2gaming-3c4d5e6f-7890",
                media: "Back2Gaming",
                format: "Press Release on Back2Gaming",
                monthlyTraffic: "",
                reach: "204000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "nft-plazas-4d5e6f7g-8901",
                media: "NFT Plazas",
                format: "Press Release on NFT Plazas",
                monthlyTraffic: "",
                reach: "75100",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "invision-game-community-5e6f7g8h-9012",
                media: "Invision Game Community",
                format: "Feature on Invision Game Community",
                monthlyTraffic: "",
                reach: "74900",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "egamers-io-6f7g8h9i-0123",
                media: "eGamers.io",
                format: "Press Release on eGamers.io",
                monthlyTraffic: "",
                reach: "27700",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
        ],
        included: ["Sponsored Article on DappRadar (Games Section)"],
    },
    {
        id: "finance-package-1-103803391c1d",
        packageName: "Finance - Package 1",
        packageShortName: "finance 1",
        packageType: "Finance package 1",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "6200",
        totalReach: "Publication potential reach: Over 402 Milion",
        extraSource: [],
        discount: "",
        description:
            "Your finance project will receive substantial exposure across leading financial publications, ensuring broad visibility and engagement within the finance community.",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "benzinga-1a2b3c4d-5678",
                media: "Benzinga",
                logo: benzinga,
                format: "Sponsored Article on Benzinga",
                monthlyTraffic: "",
                reach: "71140000",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
                logo: benzinga,
            },
            {
                id: "finance-magnates-2b3c4d5e-6789",
                media: "Finance Magnates",
                format: "Feature on Finance Magnates",
                monthlyTraffic: "",
                reach: "15300000",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
            },
            {
                id: "market-watch-3c4d5e6f-7890",
                media: "Market Watch",
                format: "Press Release on Market Watch",
                monthlyTraffic: "",
                reach: "267300",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
            },
            {
                id: "yahoo-finance-4d5e6f7g-8901",
                media: "Yahoo Finance",
                format: "Press Release on Yahoo Finance",
                monthlyTraffic: "",
                reach: "71400000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
        ],
        included: ["Press Release (Direct Link) on Bloomberg"],
    },
    {
        id: "finance-package-2-103803391c1d",
        packageName: "Finance - Package 2",
        packageShortName: "finance 2",
        packageType: "Finance package 2",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "10500",
        totalReach: "Publication potential reach: Over 763 Milion",
        extraSource: [],
        discount: "",

        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "bloomberg-1a2b3c4d-5678",
                media: "Bloomberg",
                format: "Press Release (Direct Link) on Bloomberg",
                monthlyTraffic: "",
                reach: "267300",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
                logo: bloomberg,
            },
            {
                id: "benzinga-2b3c4d5e-6789",
                media: "Benzinga",
                logo: benzinga,
                format: "Sponsored Article on Benzinga",
                monthlyTraffic: "",
                reach: "71140000",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
            },
            {
                id: "fxstreet-3c4d5e6f-7890",
                media: "FXStreet",
                format: "Feature on FXStreet",
                monthlyTraffic: "",
                reach: "15300000",
                turnaroundTime: "",
                duration: "1.5-2 weeks",
                price: "",
            },
            {
                id: "zerohedge-4d5e6f7g-8901",
                media: "Zerohedge",
                format: "Brand Mention on Zerohedge",
                monthlyTraffic: "",
                reach: "3600000",
                turnaroundTime: "",
                duration: "2-2.5 weeks",
                price: "",
            },
            {
                id: "atoz-markets-5e6f7g8h-9012",
                media: "AtoZ Markets",
                format: "Feature on AtoZ Markets",
                monthlyTraffic: "",
                reach: "35800000",
                turnaroundTime: "",
                duration: "1-2 weeks",
                price: "",
            },
            {
                id: "market-watch-6f7g8h9i-0123",
                media: "Market Watch",
                format: "Press Release on Market Watch",
                monthlyTraffic: "",
                reach: "71600",
                turnaroundTime: "",
                duration: "1-1.5 weeks",
                price: "",
            },
            {
                id: "yahoo-finance-7g8h9i0j-1234",
                media: "Yahoo Finance",
                format: "Press Release on Yahoo Finance",
                monthlyTraffic: "",
                reach: "70600000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "yahoo-news-8h9i0j1k-2345",
                media: "Yahoo News",
                logo: yahoo,
                format: "Press Release on Yahoo News",
                monthlyTraffic: "",
                reach: "245300000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
        ],
        included: ["Feature on Finance Magnates"],
    },
    {
        id: "press-release-package-1-103803391c1d",
        packageName: "Press-Release Package - 1",
        packageShortName: "press-release 1",
        packageType: "Press-release package 1",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "3200",
        totalReach: "Publication potential reach: Over 602 Milion",
        extraSource: ["200+ news sites"],
        discount: "",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "benzinga-1a2b3c4d-5678",
                media: "Benzinga",
                logo: benzinga,
                format: "Sponsored Article on Benzinga",
                monthlyTraffic: "",
                reach: "70600000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
                logo: benzinga,
            },
            {
                id: "yahoo-finance-2b3c4d5e-6789",
                media: "Yahoo Finance",
                format: "Press Release on Yahoo Finance",
                monthlyTraffic: "",
                reach: "15300000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "yahoo-news-3c4d5e6f-7890",
                media: "Yahoo News",
                logo: yahoo,
                format: "Press Release on Yahoo News",
                monthlyTraffic: "",
                reach: "245300000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
        ],
        included: ["Press Release on Market Watch"],
    },
    {
        id: "press-release-package-2-103803391c1d",
        packageName: "Press-Release Package - 2",
        packageShortName: "press-release 2",
        packageType: "Press-release package 2",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "4100",
        totalReach: "Publication potential reach: Over 771 Milion",
        extraSource: ["400+ news sites"],
        discount: "",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "associated-press-1a2b3c4d-5678",
                media: "Associated Press",
                format: "Press Release on Associated Press",
                monthlyTraffic: "",
                reach: "71140000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "market-watch-2b3c4d5e-6789",
                media: "Market Watch",
                format: "Press Release on Market Watch",
                monthlyTraffic: "",
                reach: "38400000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "benzinga-3c4d5e6f-7890",
                media: "Benzinga",
                logo: benzinga,
                format: "Sponsored Article on Benzinga",
                monthlyTraffic: "",
                reach: "70600000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "yahoo-finance-4d5e6f7g-8901",
                media: "Yahoo Finance",
                format: "Press Release on Yahoo Finance",
                monthlyTraffic: "",
                reach: "15300000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "yahoo-news-5e6f7g8h-9012",
                media: "Yahoo News",
                logo: yahoo,
                format: "Press Release on Yahoo News",
                monthlyTraffic: "",
                reach: "245300000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
        ],
        included: ["Press Release on Bloomberg"],
    },
    {
        id: "crypto-release-package-1-103803391c1d",
        packageName: "Crypto Releases - Pack 1",
        packageShortName: "crypto release 1",
        packageType: "Crypto release package 1",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "4200",
        totalReach: "Publication potential reach: Over 2,5 Milion",
        extraSource: ["25+ small crypto media"],
        discount: "",
        details:
            "Your crypto releases will gain exposure across leading crypto publications and small crypto media outlets, ensuring comprehensive visibility and engagement within the crypto community.",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "the-crypto-updates-1a2b3c4d-5678",
                media: "The Crypto Updates",
                format: "Feature on The Crypto Updates",
                monthlyTraffic: "",
                reach: "2200000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "techbullion-2b3c4d5e-6789",
                media: "TechBullion",
                format: "Feature or Interview on TechBullion",
                monthlyTraffic: "",
                reach: "14400",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "crypto-mode-3c4d5e6f-7890",
                media: "Crypto Mode",
                format: "Feature or Interview on Crypto Mode",
                monthlyTraffic: "",
                reach: "218880",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
        ],
        included: ["Press Release on The Daily Hodl"],
    },
    {
        id: "crypto-release-package-2-103803391c1d",
        packageName: "Crypto Releases - Pack 2",
        packageShortName: "crypto release 2",
        packageType: "Crypto release package 2",
        isInShoppingCart: false,
        isInWishlist: false,
        isFeatured: false,
        totalPrice: "7900",
        totalReach: "Publication potential reach: Over 6 Milion",
        extraSource: ["55+ small crypto media"],
        discount: "",
        description: "Some Publication editorail team might take between 5-8 days at there discretion",
        content:
            "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
        content2: "Distrubution will be within 24-72h",
        products: [
            {
                id: "cryptopolitan-1a2b3c4d-5678",
                media: "Cryptopolitan",
                format: "Feature on Cryptopolitan",
                monthlyTraffic: "",
                reach: "2200000",
                turnaroundTime: "2-3 business days",
                duration: "",
                price: "",
            },
            {
                id: "publish0x-2b3c4d5e-6789",
                media: "Publish0x",
                format: "Feature or Interview on Publish0x",
                monthlyTraffic: "",
                reach: "1600000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "cryptela-3c4d5e6f-7890",
                media: "Cryptela",
                format: "Feature on Cryptela",
                monthlyTraffic: "",
                reach: "1300000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "the-crypto-updates-4d5e6f7g-8901",
                media: "The Crypto Updates",
                format: "Feature on The Crypto Updates",
                monthlyTraffic: "",
                reach: "137700",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "blockchainreporter-5e6f7g8h-9012",
                media: "BlockchainReporter",
                format: "Feature on BlockchainReporter",
                monthlyTraffic: "",
                reach: "14400",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "techbullion-6f7g8h9i-0123",
                media: "TechBullion",
                format: "Feature or Interview on TechBullion",
                monthlyTraffic: "",
                reach: "736600",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "the-merkle-7g8h9i0j-1234",
                media: "The Merkle",
                format: "Feature on The Merkle",
                monthlyTraffic: "",
                reach: "218880",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "crypto-mode-8h9i0j1k-2345",
                media: "Crypto Mode",
                format: "Feature or Interview on Crypto Mode",
                monthlyTraffic: "",
                reach: "10900",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "coinspeaker-9i0j1k2l-3456",
                media: "Coinspeaker",
                format: "Feature on Coinspeaker",
                monthlyTraffic: "",
                reach: "159600",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "thenewscrypto-0j1k2l3m-4567",
                media: "TheNewsCrypto",
                format: "Feature on TheNewsCrypto",
                monthlyTraffic: "",
                reach: "107100",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "nft-news-today-1k2l3m4n-5678",
                media: "NFT News Today",
                format: "Feature on NFT News Today",
                monthlyTraffic: "",
                reach: "68700",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "blockonomi-2l3m4n5o-6789",
                media: "Blockonomi",
                format: "Feature on Blockonomi",
                monthlyTraffic: "",
                reach: "52600",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
            {
                id: "tech-startups-3m4n5o6p-7890",
                media: "Tech Startups",
                format: "Feature on Tech Startups",
                monthlyTraffic: "",
                reach: "87000",
                turnaroundTime: "",
                duration: "",
                price: "",
            },
        ],
        included: ["Press Release on The Daily Hodl"],
    },
];
export default function useMarketplaceState() {
    const [activeMarketplaceDetails, setActiveMarketplaceDetails] = useState({});
    const [displayMarketplaceDetailsModal, setDisplayMarketplaceDetailsModal] = useState("none");

    const [publications, setPublications] = useState(fetchedData);

    const { addCommas } = useFormatter();

    useEffect(() => {
        if (!Object.keys(activeMarketplaceDetails).length) return;
        setDisplayMarketplaceDetailsModal("block");
    }, [activeMarketplaceDetails]);

    function MarketplaceCardDetails({ setIsOpen }) {
        const {
            packageType,
            totalPrice,
            format,
            discount,
            products,
            included,
            extraSource,
            description,
            content,
            content2,
            totalReach,
        } = activeMarketplaceDetails;

        const closeModal = () => {
            setIsOpen(false);
        };
        const { close } = localData.svgs;
        return (
            <>
                <div className="modal-header">
                    <div className="wrapper">
                        <h2 className="display-2 modal-title">{packageType}</h2>
                        {totalReach && <p className="total-reach text-style-1">{totalReach}</p>}
                    </div>
                    <p className="description">{format}</p>
                    <br />
                    <br />
                    <Button
                        className="btn-close"
                        variant="circle"
                        color="dark"
                        size="sm"
                        icon={close}
                        onClick={closeModal}
                    />

                    <div className="products">
                        {!products.length
                            ? ""
                            : products.map((item, index) => {
                                  if (!item.logo) return;
                                  return (
                                      <div className="product" key={index}>
                                          {/* <h6 className="product-title">{item.title}</h6> */}
                                          {item.logo && (
                                              <img className="product-logo" src={item.logo} alt="" />
                                          )}
                                      </div>
                                  );
                              })}
                        {!extraSource.length
                            ? ""
                            : extraSource.map((item, index) => {
                                  return (
                                      <div className="product" key={index}>
                                          <h6 className="product-title">{item}</h6>
                                      </div>
                                  );
                              })}
                    </div>
                    {/* {discount && (
                        <div className="discount-badge">Publication potential reach: {discount}</div>
                    )} */}
                    <br />
                    <br />
                </div>
                <div className="modal-body">
                    <div className="row row-1">
                        {!products || !products.length ? (
                            ""
                        ) : (
                            <div className="col-left">
                                <p className="">What's included</p>
                                <br />
                                <ul className="included">
                                    {products.map((item, index) => {
                                        return (
                                            <li className="included-item" key={index}>
                                                <p className="included-title text-style-1"> {item.media}</p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}

                        {(content || content2) && (
                            <div className="col-right">
                                {content && <p className="content-1 text-style-1">{content}</p>}
                                <br />

                                {content2 && <p className="content-2 text-style-1">{content2}</p>}
                            </div>
                        )}
                    </div>
                    <br />
                    <br />
                    <br />

                    <div className="price">${addCommas(totalPrice) || 0}</div>
                    <br />
                    {description && <p className="modal-description text-style-1">{description}</p>}
                </div>
                <div className="modal-footer">
                    <Button className="marketplace-details-close-btn" color="dark" onClick={closeModal}>
                        Close {close}
                    </Button>
                </div>
            </>
        );
    }

    return {
        activeMarketplaceDetails,
        setActiveMarketplaceDetails,
        displayMarketplaceDetailsModal,
        setDisplayMarketplaceDetailsModal,
        publications,
        setPublications,
        MarketplaceCardDetails,
    };
}
