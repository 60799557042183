// import React from "react";
// import { Field, Button } from "../../../components";
// import localData from "../../../localData";

// export default function Reports() {
//     const { sendDollar, handHoldingDollar, transferMoney } = localData.svgs;

//     return (
//         <div className="paypal">
//             <h2 className="display-2">Payout details: </h2>
//             <p className="text-style-1">
//                 Any commissions you earn will be denominated in US Dollars value and payout made in stable
//                 coin (USDT/USDC){" "}
//             </p>
//             <br />
//             <br />

//             {/* <div className="paypal-form">
//                 <Field
//                     type="text"
//                     label="Your wallet address:"
//                     color="secondary"
//                     required={true}
//                     size="sm"
//                     name={"wallet"}
//                     value={""}
//                     // callback={handleOnChange}
//                     className="horizontal-field"
//                 >
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         // onClick={()=>{}}
//                         size="xs"
//                         className={`paypal-btn`}
//                         name=" Connect Wallet"
//                     />
//                 </Field>
//                 <Field
//                     type="text"
//                     label="PayPal Email Address:"
//                     color="secondary"
//                     required={true}
//                     size="sm"
//                     name={"paypal"}
//                     value={""}
//                     // callback={handleOnChange}
//                     className="horizontal-field"
//                 >
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         // onClick={()=>{}}
//                         size="xs"
//                         className={`paypal-btn`}
//                         name="Update PayPal Email"
//                     />
//                 </Field>
//             </div> */}
//             <br />
//             <br />

//             <div className="paypal-banner">
//                 <h2 className="display-3">Your Payout Account</h2>
//                 <p className="text-style-1">
//                     Within this section, you can access a comprehensive array of tools and features to manage,
//                     customize, and monitor the various settings, preferences, and details tied to your Payout
//                     account, ensuring seamless control and optimization of your financial transactions and
//                     experiences.
//                 </p>
//             </div>
//             <br />
//             <div className="paypal-cards-group">
//                 <div className="card paypal-card">
//                     <div className="card-icon">{sendDollar}</div>
//                     <div className="card-title">Commission Tracking</div>
//                     <p className="card-description">
//                     Track your earnings effortlessly. Stay updated on referral performance and commissions.
//                     </p>
//                 </div>
//                 <div className="card paypal-card">
//                     <div className="card-icon">{handHoldingDollar}</div>
//                     <div className="card-title">Payout History</div>
//                     <p className="card-description">
//                     Review past earnings and transactions easily. Access your complete payout history.
//                     </p>
//                 </div>
//                 <div className="card paypal-card">
//                     <div className="card-icon">{transferMoney}</div>
//                     <div className="card-title">Referral Dashboard</div>
//                     <p className="card-description text-primary">
//                     Maximize your earnings with insights. Monitor referral activity and analyze performance.
//                     </p>
//                 </div>
//             </div>

//             <div className="cover"></div>
//         </div>
//     );
// }

import React, { useEffect, useState } from "react";
import { Field, Button } from "../../../components";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";

export default function Reports() {
    const { sendDollar, handHoldingDollar, transferMoney } = localData.svgs;
    const { preloaderBig } = localData.images;
    const {
        getUpdateUserInfo,
        getLoadUserProfile,
        requestedData,
        isLoading,
        requestedData: {
            userProfileData: { walletAddress, payPalEmailAddress },
        },
    } = useGlobalContext();

    const [state, setState] = useState({});

    useEffect(() => {
        setState({
            wallet: walletAddress || "",
            paypal: payPalEmailAddress || "",
        });
    }, [requestedData.userProfileData]);

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isWalletChanged = state.wallet !== walletAddress;
        const isPaypalEmailChanged = state.paypal !== payPalEmailAddress;
        const obj = {};
        if (isWalletChanged) obj.wallet_address = state.wallet;
        if (isPaypalEmailChanged) obj.paypal_email_address = state.paypal;
        if (Object.keys(obj).length > 0) {
            getUpdateUserInfo(obj, () => {
                getLoadUserProfile();
            });
            // console.log('obj not empty', obj)
        }
    };

    // const tmp = {};
    // console.log('state', state)

    return (
        <div className="paypal">
            <h2 className="display-2">Payout details: </h2>
            <p className="text-style-1">
                Any commissions you earn will be denominated in US Dollars value and payout made in stable
                coin (USDT/USDC){" "}
            </p>
            <br />
            <br />

            <div className="paypal-form">
                <Field
                    type="text"
                    label="Your wallet address:"
                    color="secondary"
                    required={true}
                    size="sm"
                    name={"wallet"}
                    value={state.wallet}
                    callback={handleOnChange}
                    className="horizontal-field"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        size="xs"
                        className={`wallet-btn`}
                        name="Update Wallet Details"
                        disabled={ state.wallet === walletAddress || isLoading.userInfoIsUpdating}
                        icon={
                            isLoading.userInfoIsUpdating ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                    />
                </Field>
                <Field
                    type="text"
                    label="PayPal Email Address:"
                    color="secondary"
                    required={true}
                    size="sm"
                    name={"paypal"}
                    value={state.paypal}
                    callback={handleOnChange}
                    className="horizontal-field"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        size="xs"
                        className={`paypal-btn`}
                        name="Update PayPal Email"
                        disabled={state.paypal === payPalEmailAddress || isLoading.userInfoIsUpdating}
                        icon={
                            isLoading.userInfoIsUpdating ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                    />
                </Field>
            </div>
            <br />
            <br />

            <div className="paypal-banner">
                <h2 className="display-3">Your Payout Account</h2>
                <p className="text-style-1">
                    Within this section, you can access a comprehensive array of tools and features to manage,
                    customize, and monitor the various settings, preferences, and details tied to your Payout
                    account, ensuring seamless control and optimization of your financial transactions and
                    experiences.
                </p>
            </div>
            <br />
            <div className="paypal-cards-group">
                <div className="card paypal-card">
                    <div className="card-icon">{sendDollar}</div>
                    <div className="card-title">Commission Tracking</div>
                    <p className="card-description">
                        Track your earnings effortlessly. Stay updated on referral performance and
                        commissions.
                    </p>
                </div>
                <div className="card paypal-card">
                    <div className="card-icon">{handHoldingDollar}</div>
                    <div className="card-title">Payout History</div>
                    <p className="card-description">
                        Review past earnings and transactions easily. Access your complete payout history.
                    </p>
                </div>
                <div className="card paypal-card">
                    <div className="card-icon">{transferMoney}</div>
                    <div className="card-title">Referral Dashboard</div>
                    <p className="card-description text-primary">
                        Maximize your earnings with insights. Monitor referral activity and analyze
                        performance.
                    </p>
                </div>
            </div>

            <div className="cover"></div>
        </div>
    );
}
