import React, { useState, useEffect } from "react";
import { motion, warning } from "framer-motion";
import { useGlobalContext } from "../../../context";
import {
    Navbar,
    Button,
    Select,
    MultiSelect,
    Geochart,
    TrafficSourcesTable,
    SocialMetrics,
    Field,
    Testimonials,
    Tabs,
    Radio,
    Switch,
    Checkbox,
    Popup,
    InfoPopup,
} from "../../../components";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useConvertKit from "../../../hooks/useConvertKit";
import { v4 as uuidv4 } from "uuid";

import { auth, db } from "../../../config/firebase";
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
} from "firebase/auth";
import { collection, getDoc, doc } from "firebase/firestore";

const { eye, eyeSlash, arrowLeft, logout } = localData.svgs;
const { logoDark, google, facebook, preloaderBig } = localData.images;

export default function Qualification() {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get("ref") || null;
    const [signupDisabled, setSignupDisable] = useState(false);
    const [errors, setErrors] = useState({});
    const { fade } = useGlobalContext().animations;
    const { _OrganizationAdditionalData } = useGlobalContext();

    const [isLoading, setIsLoading] = useState(false);

    const { register, checkEmailExists } = useFetch();
    const { sendConvertKit } = useConvertKit();
    const [qualificationState, setQualificationState] = useState({
        // step 1
        name: "",

        // step 2
        companyName: "",
        _category: "",
        marektingBudget: "",

        // step 3
        websiteURL: "",
        marektingGoal: [],
        AITargeting: false,
        _chainTargeting: [],
        _walletTargeting: [],
        _smartContractAddress: "",
        isAdditionalDataFormCompleted: false,
    });
    const [isAditionalFormLoading, setIsAditionalFormLoading] = useState(false);

    const callbackFromParent = () => {};

    const trackingTabs = [
        {
            title: "Step 1",
            content: <Step1 />,
            id: "qualification-step-1-eifoei2983yr23",
        },
        {
            title: "Step 2",
            content: <Step2 />,
            id: "qualification-step-2-eifoei2983yr23",
        },
        {
            title: "Step 3",
            content: <Step3 {...{ isAditionalFormLoading }} />,
            id: "qualification-step-3-eifoei2983yr23",
        },
    ];

    useEffect(() => {
        console.log(qualificationState, " here");
        if (!qualificationState.isAdditionalDataFormCompleted) return;
        _OrganizationAdditionalData({
            ...qualificationState,
            qualificationState,
            setQualificationState,
            setIsLoading: setIsAditionalFormLoading,
        });
    }, [qualificationState]);

    return (
        <motion.main {...fade} className="main qualification-page">
            <div className="container">
                <section className="qualification">
                    <div className="qualification-header">
                        <img className="logo" src={logoDark} />

                        <div className="qualification-description">Qualification steps</div>
                    </div>
                    <Tabs
                        tabsProps={{ callbackFromParent, setQualificationState }}
                        tabs={trackingTabs}
                        title="Setup Tracking"
                        // indicatorIcon={question}
                        className="tracking-tabs"
                    />
                    <p className="copyright">
                        ©Blockchain-Ads. All rights reserved.
                        <Link to="/privacy-policy" underline="hover" className="link link-primary">
                            {" "}
                            Privacy Policy
                        </Link>
                    </p>
                </section>
            </div>
        </motion.main>
    );
}

const Step1 = ({ setActiveTab, activeTab, setQualificationState }) => {
    const [state, setState] = useState({
        name: "",
    });

    const [errors, setErrors] = useState({
        nameError: false,
    });

    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    // const [professionOptions, setProfessionOptions] = useState([
    //     { title: "Brand (advertiser)", isSelected: false, id: uuidv4() },
    //     { title: "Marketing Agency (manage multiple brands)", isSelected: false, id: uuidv4() },
    //     {
    //         title: "Affinitie (run campaign for other brands independently)",
    //         isSelected: false,
    //         id: uuidv4(),
    //     },
    // ]);

    const { handleSignOut } = useGlobalContext();

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    useEffect(() => {
        setErrors((prev) => ({
            ...prev,
            nameError: state.name === "",
            // professionError: state.profession === "",
        }));
    }, [state]);

    useEffect(() => {
        let haveError = false;
        Object.keys(errors).forEach((item) => {
            if (errors[item]) {
                haveError = true;
                return;
            }
        });
        setIsBtnDisabled(haveError);
    }, [errors]);

    return (
        <div className="step-1">
            <div className="form">
                <h2 className="form-title">Account Info</h2>

                <Field
                    type="text"
                    label="Name*"
                    color="secondary"
                    // placeholder="Enter your name"
                    required={true}
                    size="sm"
                    name="name"
                    value={state.name}
                    callback={handleOnChange}
                />

                <br />
                <br />

                <div className="btn-group">
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Log out"
                        color="danger"
                        size="sm"
                        onClick={handleSignOut}
                        startIcon={logout}
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={false}
                    />
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Continue"
                        color="dark"
                        variant="contained"
                        size="sm"
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        onClick={() => {
                            setQualificationState((prev) => ({
                                ...prev,
                                ...state,
                            }));
                            setActiveTab(1);
                        }}
                        disabled={isBtnDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

const Step2 = ({ setActiveTab, activeTab, setQualificationState }) => {
    const [state, setState] = useState({
        companyName: "",
        _category: "",
        marektingBudget: "",
    });

    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    const [errors, setErrors] = useState({
        categoryError: false,
        marektingBudgetError: false,
    });

    const [verticalsOptions, setVerticalsOptions] = useState([
        { title: "Decentralized Finance",value: "decentralizedfinance", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Blockchain Gaming",value: "blockchainGaming",  isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Marketplace",value: "marketplace",  isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Infrastructure",value: "infrastructure",  isSelected: false, size: "4800000", id: uuidv4() },
        { title: "NFT",value: "NFT",  isSelected: false, size: "720000", id: uuidv4() },
        { title: "DEX",value: "DEX",  isSelected: false, size: "2400000", id: uuidv4() },
        { title: "CEX",value: "CEX",  isSelected: false, size: "8000000", id: uuidv4() },
        // LATEST OPTIONS
        { title: "Crypto Trading" ,value: "cryptoTrading", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "Web3 Casino",value: "web3Casinos",  isSelected: false, size: "1000000", id: uuidv4() },
        { title: "Other",value: "other",  isSelected: false, size: "", id: uuidv4() },
    ]);

    const [marektingBudgetOptions, setMarektingBudgetOptions] = useState([
        { title: "$10k-50k ", isSelected: false, id: uuidv4() },
        { title: "50-100", isSelected: false, id: uuidv4() },
        { title: "Above 100k ", isSelected: false, id: uuidv4() },
    ]);

    const { setRequestedData } = useGlobalContext();

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    // const handleVerticals = (title) => {
    //     let options = [...verticalsOptions];
    //     options = options.map((item) => {
    //         return {
    //             ...item,
    //             isSelected: item.title == title,
    //         };
    //     });
    //     setVerticalsOptions(options);

    //     setState({ ...state, verticals: title });
    // };

    useEffect(() => {
        const activeAudience = JSON.parse(localStorage.getItem("activeAudience"));
        if (!activeAudience) return;
        let tempVerticalOptions = [...verticalsOptions];
        tempVerticalOptions = verticalsOptions.map((item) => {
            return {
                ...item,
                isSelected: activeAudience == item.value ? true : false,
            };
        });
        setVerticalsOptions(tempVerticalOptions);
    }, []);
    useEffect(() => {
        setErrors((prev) => ({
            ...prev,
            categoryError: state.verticals === "",
            marektingBudgetError: state.marektingBudget === "",
        }));
    }, [state]);

    useEffect(() => {
        let haveError = false;
        Object.keys(errors).forEach((item) => {
            if (errors[item]) {
                haveError = true;
                return;
            }
        });
        setIsBtnDisabled(haveError);
    }, [errors]);

    return (
        <div className="step-2">
            <div className="form">
                <h2 className="form-title">Company Info</h2>

                <Field
                    size="sm"
                    type="text"
                    label="Company Name"
                    color="secondary"
                    // placeholder="Enter your company name"
                    required={true}
                    name="companyName"
                    value={state.companyName}
                    callback={handleOnChange}
                />

                <Select
                    {...{
                        items: verticalsOptions,
                        setItems: setVerticalsOptions,
                        // placeholder: "Monthly marketing budget",
                        variant: "outlined",
                        color: "secondary",
                        label: "Vertical you are advertising in*",
                        callback: (selectedItem) => {
                            setState((prev) => ({
                                ...prev,
                                _category: selectedItem.title,
                            }));
                        },
                    }}
                />

                <Select
                    {...{
                        items: marektingBudgetOptions,
                        setItems: setMarektingBudgetOptions,
                        // placeholder: "Monthly marketing budget",
                        variant: "outlined",
                        color: "secondary",
                        label: "Monthly marketing budget*",
                        callback: (selectedItem) => {
                            setState((prev) => ({
                                ...prev,
                                marektingBudget: selectedItem.title,
                            }));
                        },
                    }}
                />

                {/* <div className="radio-multi-select">
                    <label htmlFor="" className="select-label">
                        Vertical you are advertising in*
                    </label>
                    {verticalsOptions.map((item, index) => {
                        return (
                            <Radio
                                key={index}
                                label={item.title}
                                checked={item.isSelected}
                                color="dark"
                                onClick={() => handleVerticals(item.title)}
                            />
                        );
                    })}
                </div>
                <br /> */}

                <div className="btn-group">
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Back"
                        color="dark"
                        startIcon={arrowLeft}
                        size="sm"
                        onClick={() => setActiveTab(0)}
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={false}
                    />
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Continue"
                        color="dark"
                        variant="contained"
                        size="sm"
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        onClick={() => {
                            setQualificationState((prev) => ({
                                ...prev,
                                ...state,
                            }));
                            // setRequestedData((prev) => ({
                            //     ...prev,
                            //     // userProfileData: {...prev.userProfileData, isQualified: false},
                            //     isQualified: true,
                            // }));
                            // navigate("/");
                            setActiveTab(2);
                        }}
                        disabled={isBtnDisabled}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );
};

const Step3 = ({ setActiveTab, activeTab, setQualificationState, isAditionalFormLoading }) => {
    const [state, setState] = useState({
        websiteURL: "",
        marektingGoal: [],
        AITargeting: true,
        _chainTargeting: [],
        _walletTargeting: [],
        _smartContractAddress: "",
    });
    const [errors, setErrors] = useState({
        websiteURLError: false,
        marektingGoalError: false,
        // _smartContractAddressError: false,
    });

    const [marektingGoalOptions, setMarektingGoalOptions] = useState([
        { title: "sign ups", value: "sign ups", isSelected: false, id: uuidv4() },
        { title: "deposits", value: "deposits", isSelected: false, id: uuidv4() },
        { title: "trading volume", value: "trading volume", isSelected: false, id: uuidv4() },
        { title: "other", value: "other", isSelected: false, id: uuidv4() },
    ]);

    const [marketingGoalOther, setMarketingGoalOther] = useState("other");

    const [chainTargetingOptions, setChainTargetingOptions] = useState([
        { title: "Custom", value: "custom", isSelected: false, size: "", id: uuidv4() },
        { title: "Ethereum", value: "ETH", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "Binance Smart Chain", value: "BSC", isSelected: false, size: "1440000", id: uuidv4() },
        { title: "Polygon", value: "MATIC", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Solana", value: "SOL", isSelected: false, size: "1800000", id: uuidv4() },
        { title: "NEAR", value: "NEAR", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Arbitrum", value: "ARB", isSelected: false, size: "1200000", id: uuidv4() },
        { title: "Optimism", value: "OPT", isSelected: false, size: "720000", id: uuidv4() },
        { title: "Ripple", value: "XRP", isSelected: false, size: "1920000", id: uuidv4() },
        { title: "Cardano", value: "ADA", isSelected: false, size: "1680000", id: uuidv4() },
        { title: "Tron", value: "TRX", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Polkadot", value: "DOT", isSelected: false, size: "480000", id: uuidv4() },
        { title: "Avalanche", value: "AVAX", isSelected: false, size: "720000", id: uuidv4() },
        { title: "Stellar", value: "XLM", isSelected: false, size: "1680000", id: uuidv4() },
        { title: "Chainlink", value: "LINK", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Monero", value: "XMR", isSelected: false, size: "192000", id: uuidv4() },
        { title: "Cosmos", value: "ATOM", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Hedera", value: "HBAR", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Mantle", value: "MNT", isSelected: false, size: "60000", id: uuidv4() },
        { title: "Aptos", value: "APT", isSelected: false, size: "14400", id: uuidv4() },
        { title: "Chronos", value: "CHR", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Algorand", value: "ALGO", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "Tezos", value: "XTZ", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Eos", value: "EOS", isSelected: false, size: "360000", id: uuidv4() },
        { title: "Partisia", value: "PRSS", isSelected: false, size: "480000", id: uuidv4() },
        { title: "Zksync", value: "ZKSYNC", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Bitcoin", value: "BTC", isSelected: false, size: "6400000", id: uuidv4() },
        { title: "Base", value: "BASE", isSelected: false, size: "480000", id: uuidv4() },
    ]);

    const [walletTargetingOptions, setWalletTargetingOptions] = useState([
        { title: "MetaMask", isSelected: false, size: "4800000", id: uuidv4() },
        { title: "Trust", isSelected: false, size: "2400000", id: uuidv4() },
        { title: "MEV", isSelected: false, size: "24000", id: uuidv4() },
        { title: "Coinomi", isSelected: false, size: "600000", id: uuidv4() },
        { title: "Coinbase", isSelected: false, size: "8000000", id: uuidv4() },
        { title: "OKX", isSelected: false, size: "3840000", id: uuidv4() },
        { title: "Exodus", isSelected: false, size: "240000", id: uuidv4() },
        { title: "Rainbow", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Argent", isSelected: false, size: "120000", id: uuidv4() },
        { title: "Safe", isSelected: false, size: "12000", id: uuidv4() },
        { title: "Zerion", isSelected: false, size: "84000", id: uuidv4() },
    ]);

    const { setRequestedData } = useGlobalContext();

    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    function checkIsValidUrl(url) {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }

    useEffect(() => {
        setErrors((prev) => ({
            ...prev,
            websiteURLError: state.websiteURL === "" || !checkIsValidUrl(state.websiteURL),
            marektingGoalError: !state.marektingGoal.length,
            // _smartContractAddressErrror:
            //     state._smartContractAddress === "" || !checkIsBlockchainAddress(state._smartContractAddress),
        }));
    }, [state]);

    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    useEffect(() => {
        let haveError = false;
        Object.keys(errors).forEach((item) => {
            if (errors[item]) {
                haveError = true;
                return;
            }
        });
        setIsBtnDisabled(haveError);
    }, [errors]);

    const [marektingGoalOther, setMarektingGoalOther] = useState("");

    const handleMarketingGoal = (title = "") => {
        let options = [...marektingGoalOptions];
        options = options.map((item) => {
            if (item.title !== title) return { ...item };
            return {
                ...item,
                isSelected: !item.isSelected,
                value: item.title === "other" ? marektingGoalOther : item.value,
            };
        });
        setMarektingGoalOptions(options);
        setState({
            ...state,
            marektingGoal: options
                .filter((item) => item.isSelected)
                .map((item) => (item.title === "other" ? marektingGoalOther : item.value)),
        });
    };

    const handleMultiSelectChange = (items, name) => {
        let selectedItems = items?.filter((item) => item.isSelected);
        selectedItems = selectedItems?.map((item) => item.value || item.title);

        setState((state) => ({
            ...state,
            [name]: selectedItems,
        }));
    };

    function checkIsBlockchainAddress(text) {
        var pattern = /^0x[a-fA-F0-9]+$/;
        return pattern.test(text);
    }

    return (
        <div className="step-3">
            <div className="form">
                <h2 className="form-title">Campaign details</h2>
                <Field
                    size="sm"
                    type="text"
                    label="Website URL*"
                    color="secondary"
                    // placeholder="Enter your company name"
                    required={true}
                    name="websiteURL"
                    value={state.websiteURL}
                    callback={handleOnChange}
                    errorMessage={
                        state.websiteURL !== "" && !checkIsValidUrl(state.websiteURL)
                            ? `Invalid URL. Please enter a valid URL. e.g.
                        <br /> https://johndoe.com`
                            : ""
                    }
                />
                <Field
                    type="text"
                    label="Insert your token smart contract address"
                    color="secondary"
                    placeholder="e.g. 0x00000000000000"
                    // required={true}
                    size="sm"
                    name="_smartContractAddress"
                    value={state._smartContractAddress}
                    callback={handleOnChange}
                    errorMessage={
                        state._smartContractAddress !== "" &&
                        !checkIsBlockchainAddress(state._smartContractAddress)
                            ? `Invalid smart contract address.
                                <br /> e.g. 0x00000000000000`
                            : ""
                    }
                />

                <MultiSelect
                    {...{
                        items: chainTargetingOptions,
                        setItems: setChainTargetingOptions,
                        variant: "outlined",
                        limitTags: 3,
                        color: "secondary",
                        name: "_chainTargeting",
                        label: "Blockchains you support/are built on?",
                        callback: handleMultiSelectChange,
                    }}
                />
                <br />

                <MultiSelect
                    {...{
                        items: walletTargetingOptions,
                        setItems: setWalletTargetingOptions,
                        variant: "outlined",
                        limitTags: 3,
                        color: "secondary",
                        label: "Which wallets do you support?",
                        name: "_walletTargeting",
                        callback: handleMultiSelectChange,
                        // direction: window.innerWidth > 576 ? "up" : "down",
                    }}
                />
                <br />
                <br />

                <div className="checkbox-multi-select">
                    <label htmlFor="" className="select-label">
                        Marketing goal*
                    </label>
                    <br />
                    {marektingGoalOptions.map((item, index) => {
                        return (
                            <Checkbox
                                key={index}
                                callback={() => {
                                    handleMarketingGoal(item.title);
                                }}
                                checked={item.isSelected}
                                color="secondary"
                                size="sm"
                                label={item.title}
                                className={``}
                            >
                                {item.title == "other" && (
                                    <Field
                                        size="sm"
                                        type="text"
                                        color="secondary"
                                        // placeholder="Enter your company name"
                                        required={true}
                                        name="other"
                                        value={marektingGoalOther}
                                        callback={(e) => setMarektingGoalOther(e.target.value)}
                                        onBlur={handleMarketingGoal}
                                    />
                                )}
                            </Checkbox>
                        );
                    })}
                </div>
                <br />

                <div className="switch-select">
                    <div className="wrapper">
                        <label htmlFor="" className="select-label">
                            Assisted AI targeting
                        </label>

                        <InfoPopup content="Blockchain-Ads will automatically select the best performing audiences based on your vertical and marketing goals. You will be able to change this option later" />
                    </div>
                    <Switch
                        disabled={false}
                        color="primary"
                        checked={state.AITargeting}
                        callback={() => {
                            setState((prev) => ({ ...prev, AITargeting: !state.AITargeting }));
                        }}
                    />
                </div>
                <br />
                <br />
                <br />

                <div className="btn-group">
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Back"
                        color="dark"
                        startIcon={arrowLeft}
                        size="sm"
                        onClick={() => {
                            setActiveTab(1);
                            // setQualificationState((prev) => ({
                            //     ...prev,
                            //     isAdditionalDataFormCompleted: false
                            // }));
                        }}
                        icon={
                            false ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={false}
                    />
                    <Button
                        type="button"
                        className="submit-btn"
                        name="Complete"
                        color="dark"
                        variant="contained"
                        size="sm"
                        icon={
                            isAditionalFormLoading ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        onClick={() => {
                            setQualificationState((prev) => ({
                                ...prev,
                                ...state,
                                isAdditionalDataFormCompleted: true,
                            }));
                        }}
                        disabled={isAditionalFormLoading || isBtnDisabled}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );
};
