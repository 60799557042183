import React, { useState, useEffect, useRef } from "react";
import { Field, Button, ReferralBanner } from "../../../components";
import localData from "../../../localData";
import useUtil from "../../../hooks/useUtil";
import { Tooltip } from "react-tooltip";

const ReferralField = ({ label, name = "field", value }) => {
    // const [fieldValue, setFieldValue] = useState(value);
    const [isCopied, setIsCopied] = useState();

    const fieldRef = useRef(null);
    const { copyText } = useUtil();

    const { copy, check } = localData.svgs;

    const handleCopyText = async () => {
        if (isCopied) return;
        const success = await copyText(fieldRef.current);

        if (!success) return;
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    return (
        <Field
            type="text"
            label={label}
            color="secondary"
            required={true}
            size="sm"
            name={name}
            value={value}
            // callback={handleOnChange}
            readOnly={true}
            _ref={fieldRef}
            className="horizontal"
        >
            <Button
                tabIndex={-1}
                onClick={handleCopyText}
                className="field-endIcon"
                icon={isCopied ? check : copy}
                variant="circle"
                size="sm"
                color="secondary"
                type="button"
                tooltipContent={isCopied ? "Copied!" : "Copy Address"}
            />
        </Field>
    );
};

export default function ReferralProgram({ userProfileData }) {
    const {
        bannerTransparent,
        bannerDark,
        bannerTransparentV2,
        bannerDarkV2,
        bannerTransparentHorizontal,
        bannerDarkHorizontal,
    } = localData.images;

    return (
        <div className="referral-program">
            <h2 className="display-2">Your Referral Link</h2>
            <br />
            <ReferralField label="Your Referral Code:" name="referralId" value={userProfileData?.userId} />
            <ReferralField
                label="Referral Link:"
                name="referralLink"
                value={`https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}`}
            />
            <br />

            <p className="text-style-1">
                OR, link to any page on the blockchain-ads.com website with the query string: ?ref=
                {userProfileData?.userId}
            </p>
            <p className="text-style-1">
                For example, to link to the home page use: https://hub.blockchain-ads.com/sign-up?ref=
                {userProfileData?.userId}
            </p>
            <p className="block-share text-style-1">
                Earn 10% recurring commissions by referring clients to Blockchain-Ads!
            </p>
            <br />
            <br />

            <h2 className="display-2">How It Works:</h2>
            <p className="text-style-1">
                Simply share your referral link and every new sign-up you refer will be recorded, and when
                each sign-up makes a payment you will receive 10% of that payment straight into your payment
                account within 1 business day.
            </p>
            <p className="text-style-1">
                <strong className="text-dark">Note:</strong> You must add a payout account in order to receive
                commissions.”
            </p>

            <br />
            <br />

            <h2 className="display-2">Referral Media</h2>
            <p className="text-style-1">
                Please feel free to use any of the following advertisements on your website, you may host the
                image files yourself as you require. If you require any alternate sizes, please contact us.
            </p>
            <br />
            <br />

    
            <ReferralBanner
            type="row"
                name="300x65 - white"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="https://uploads-ssl.webflow.com/64da12dbd6d6a3ceb04e7710/6658617f636a7729dbf0dbf2_Blockchain-Ads%20logo%20white-%20for%20black%20background.svg" width="300" height="65"  alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

            <ReferralBanner
            type="row"
                name="300x65 - black"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="https://assets-global.website-files.com/64da12dbd6d6a3ceb04e7710/64e8f0da6f973c0c232e8bf1_logo-s2-white2x-p-500.png" width="300" height="65"  alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

            <ReferralBanner
            type="row"
                name="300x65 - green"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="https://uploads-ssl.webflow.com/64da12dbd6d6a3ceb04e7710/665861824891e42e458f6e8b_BlockchainAds-7.svg" width="300" height="65"  alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

         

            {/* <ReferralBanner
                name="300x250 - Blue Guru"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="${bannerDark}" width="300" height="250" alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

            <ReferralBanner
                name="300x250 - Grey Guru"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="${bannerTransparent}" width="300" height="250" alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

            <ReferralBanner
                name="300x250 - Blue Forget"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="${bannerDarkV2}" width="300" height="250" alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

            <ReferralBanner
                name="300x250 - Grey Forget"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="${ bannerTransparentV2}" width="300" height="250" alt="ads.txt Blockchaind-Ads" />`}
            />
            <br />
            <br />

            <ReferralBanner
                type="row"
                name="728x90 - Blue"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="${bannerDarkHorizontal}" width="728" height="90" alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

            <ReferralBanner
                type="row"
                name="728x90 - Grey"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads"><img src="${bannerTransparentHorizontal}" width="728" height="90" alt="ads.txt Blockchaind-Ads" /></a>`}
            />
            <br />
            <br />

            <ReferralBanner
                name="Text Link"
                value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${userProfileData?.userId}" title="ads.txt Blockchain-Ads">ads.txt Guru - Manage, Validate, Collaborate</a>`}
            />
            <br />
            <br /> */}
        </div>
    );
}
