import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import {
    Dropdown,
    Select,
    Button,
    Calendar,
    Loading,
    TableSkeleton,
    ChartSkeleton,
} from "../../../components";
import ReactDOMServer from "react-dom/server";
import { v4 as uuidv4 } from "uuid";
import { BarChart, LineChart } from "../../../components";
import { useGlobalContext } from "../../../context";

import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter";

export default function CampaignsGraph() {
    const {
        requestedData: {
            userProfileData,
            campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
        },
        isLoading: { campaignDataIsLoading, userProfileIsLoading },
        formatNumber,
        getDateName,
        activeCampaign,
        markedCampaigns,
        isDarkModeEnabled,
        campaignsState,
    } = useGlobalContext();
    const { campaignSelectionRange } = campaignsState;

    const { formatCurrency, removeAlias, formatDateToMMDD, addCommas } = useFormatter();

    const { user, textPreloader } = localData.images;
    const { eye, transactions, coins, lineChart, barChart } = localData.svgs;

    const [graphBtns, setGraphBtns] = useState({
        impressions: {
            isActive: true,
            propertyName: "impressions",
            displayName: "impressions",
            normalizeName: "normalizeImpressions",
            color: "purple",
        },
        amount_spent: {
            isActive: true,
            propertyName: "amount_spent",
            displayName: "ad spend",
            normalizeName: "normalizeAmount_spent",
            color: "rgba(35, 149, 110,1)",
        },

        clicks: {
            isActive: true,
            propertyName: "clicks",
            displayName: "clicks",
            normalizeName: "normalizeClicks",
            color: "rgba(21, 161, 197,1)",
        },
        CPM: {
            isActive: true,
            propertyName: "CPM",
            displayName: "CPM",
            normalizeName: "normalizeCPM",
            color: "rgba(6, 23, 38,1)",
        },
        CPC: {
            isActive: true,
            propertyName: "CPC",
            displayName: "CPC",
            normalizeName: "normalizeCPC",
            color: "rgba(249, 57, 57,1)",
        },
    });

    const handleActiveChartBtn = (e) => {
        const name = e.currentTarget.dataset.name;
        setGraphBtns({
            ...graphBtns,
            [name]: {
                // propertyName: graphBtns[name].propertyName,
                // displayName: graphBtns[name].displayName,
                ...graphBtns[name],
                isActive: !graphBtns[name].isActive,
            },
        });
    };

    // const [activeGraphCampaign, setActiveGraphCampaign] = useState(null);
    const defaultTotal = {
        impressions: 0,
        amount_spent: 0,
        clicks: 0,
        CPM: 0,
        CPC: 0,
    };
    const [total, setTotal] = useState(defaultTotal);
    const [filteredGraphData, setFilteredGraphData] = useState([]);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // const filterGraphData = () => {
    //     if (!currentOrganizationCampaigns.length) {
    //         setFilteredGraphData([]);
    //         return;
    //     }
    //     if (!activeCampaign) return;

    //     const { progressionData } = activeCampaign;
    //     if (!progressionData) return;
    //     const { startDate, endDate } = campaignSelectionRange;
    //     const array = [];

    //     for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    //         const dateString = formatDate(date);
    //         const dataForDate = progressionData[dateString];

    //         if (dataForDate) {
    //             const obj = {};
    //             obj.name = getDateName({ date: dateString });

    //             if (graphBtns.impressions.isActive) obj.impressions = dataForDate.impressions || 0;
    //             if (graphBtns.amount_spent.isActive) obj.amount_spent = dataForDate.amount_spent || 0;
    //             if (graphBtns.clicks.isActive) obj.clicks = dataForDate.clicks || 0;
    //             if (graphBtns.CPM.isActive) obj.CPM = dataForDate.cpm || 0;
    //             if (graphBtns.CPC.isActive) obj.CPC = dataForDate.cpc || 0;

    //             array.push(obj);
    //         } else {
    //             const obj = {};
    //             obj.name = formatDateToMMDD(date);
    //             obj.empty = 0;
    //             array.push(obj);
    //         }
    //     }
    //     setFilteredGraphData(array);
    // };

    const filterMultiGraphData = () => {
        if (!currentOrganizationCampaigns.length) {
            setFilteredGraphData([]);
            setTotal(defaultTotal);
            return;
        }
        if (!markedCampaigns.length) {
            setFilteredGraphData([]);
            setTotal(defaultTotal);
            return;
        }

        const { startDate, endDate } = campaignSelectionRange;

        const array = [];
        const object = {
            impressions: 0,
            amount_spent: 0,
            clicks: 0,
            CPM: 0,
            CPC: 0,
        };

        
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const dateString = formatDate(date);
            const aggregatedData = {
                name: getDateName({ date: dateString }),
                impressions: 0,
                amount_spent: 0,
                clicks: 0,
                CPM: 0,
                CPC: 0,
                AllCPM: 0,
                AllCPC: 0
            };
            let dataFound = false;
            markedCampaigns.forEach((campaign) => {
                const { progressionData } = campaign;
                if (progressionData && progressionData[dateString]) {
                    dataFound = true;
                    const dataForDate = progressionData[dateString];
                    if (graphBtns.impressions.isActive)
                        aggregatedData.impressions += dataForDate.impressions || 0;
                    if (graphBtns.amount_spent.isActive)
                        aggregatedData.amount_spent += dataForDate.amount_spent || 0;
                    if (graphBtns.clicks.isActive) aggregatedData.clicks += dataForDate.clicks || 0;
                    if (graphBtns.CPM.isActive) aggregatedData.CPM += dataForDate.cpm || 0;
                    if (graphBtns.CPC.isActive) aggregatedData.CPC += dataForDate.cpc || 0;
                   

                    object.impressions += dataForDate.impressions;
                    object.amount_spent += dataForDate.amount_spent;
                    object.clicks += dataForDate.clicks;
                    object.CPM += dataForDate.cpm;
                    object.CPC += dataForDate.cpc;
                }
            });

            if (dataFound) {
                if (graphBtns.CPM.isActive) {
                    aggregatedData.AllCPM =
                        (parseInt(aggregatedData.amount_spent) / aggregatedData.impressions) * 1000 || 0;
                }
                if (graphBtns.CPC.isActive) {
                    aggregatedData.AllCPC = parseInt(aggregatedData.amount_spent) / aggregatedData.clicks || 0;
                }
                const normalizer = () => {
                    aggregatedData.normalizeImpressions = Math.log10(aggregatedData.impressions + 1);
                    aggregatedData.normalizeAmount_spent = Math.log10(aggregatedData.amount_spent + 1);
                    aggregatedData.normalizeClicks = Math.log10(aggregatedData.clicks + 1);
                    aggregatedData.normalizeCPM = Math.log10(aggregatedData.CPM + 1);
                    aggregatedData.normalizeCPC = Math.log10(aggregatedData.CPC + 1);
                };
                normalizer();
                array.push(aggregatedData);
            } else {
                const emptyData = {
                    name: formatDateToMMDD(date),
                    empty: 0,
                };
                array.push(emptyData);
            }
        }
        setTotal(object);
        setFilteredGraphData(array);
    };

    // useEffect(() => {
    //     if (!currentOrganizationCampaigns.length) return;
    //     if (!activeCampaign) return;
    //     const tempActiveGraphCampaign = currentOrganizationCampaigns.find(
    //         (item) => item.id === activeCampaign.id
    //     );
    //     setActiveGraphCampaign(tempActiveGraphCampaign);
    // }, [activeCampaign, currentOrganizationCampaigns]);

    useEffect(() => {
        // if (!markedCampaigns.length) {
        //     filterGraphData();
        // } else {

        filterMultiGraphData();
        // }
    }, [markedCampaigns, activeCampaign, graphBtns, currentOrganizationCampaigns]);

    const [isLineChartActive, setIsLineChartActive] = useState(true);

    return (
        <div className="campaigns-graph shadow">
            <div className="campaigns-graph-inner">
                <div className="graph" id="tour-step-1">
                    <div className="graph-header">
                        <img src={userProfileData.profile_image} alt="" />
                        <p className="graph-text dark-mode-style">
                            <span>Welcome to Blockchain-Ads</span>
                            <strong className="dark-mode-style">
                                {userProfileIsLoading ? (
                                    <img src={textPreloader} className="text-preloader" />
                                ) : (
                                    userProfileData.userName
                                )}
                            </strong>
                        </p>
                    </div>

                    <div className="graph-body">
                        <h2
                            className="graph-title"
                            data-tooltip-id="tooltip-graph-title"
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                markedCampaigns.map((item) => <div>{item.name}</div>)
                            )}
                        >
                            {" "}
                            <span className="prefix">Campaign(s):</span>
                            {/* {campaignDataIsLoading ? "" : activeCampaign && removeAlias(activeCampaign.name)} */}
                            {campaignDataIsLoading ? (
                                <img src={textPreloader} className="text-preloader" />
                            ) : markedCampaigns.length == 1 ? (
                                markedCampaigns[0].name
                            ) : markedCampaigns.length > 1 ? (
                                "Multiple Selected"
                            ) : (
                                "None Selected"
                            )}
                            <Tooltip id="tooltip-graph-title" className="custom-tooltip" />
                        </h2>
                        <div className="division">
                            <div className="division-icon btn-icon">{eye}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Ad Spend</h6>
                                <div
                                    className={` division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    }`}
                                >
                                    {campaignDataIsLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        // formatCurrency(dashboardSummaryData?.totalAdSpend, 2)
                                        formatCurrency(total?.amount_spent, 2)
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="division">
                            <div className="division-icon btn-icon">{transactions}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Clicks</h6>
                                <div
                                    className={` division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    }`}
                                >
                                    {campaignDataIsLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        // formatNumber(dashboardSummaryData?.totalClicks?.toFixed(0) || "0")
                                        addCommas(total?.clicks?.toFixed(2)) || "0"
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="division">
                            <div className="division-icon btn-icon">{coins}</div>
                            <div className="wrapper">
                                <h6 className="division-title">Total Impressions</h6>
                                <div
                                    className={`division-amount ${
                                        isDarkModeEnabled ? "dark-mode-style" : ""
                                    } `}
                                >
                                    {campaignDataIsLoading ? (
                                        <img src={textPreloader} className="text-preloader" />
                                    ) : (
                                        addCommas(
                                            // dashboardSummaryData?.totalImpressions?.toFixed(0) || "0"
                                            total?.impressions.toFixed(2)
                                        ) || "0"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider"></div>

                <div className={`chart-wrapper `}>
                    {campaignDataIsLoading && (
                        <div className="loader">
                            <div className="message">Loading...</div>
                            <img src={textPreloader} />
                        </div>
                    )}
                    {/* <div className="row row-top">
                        <Dropdown
                            {...{
                                title: dateRange,
                                variant: "text",
                                color: "dark",
                                className: "calendar-dropdown",
                                isOpen: isCalendarOpen,
                                setIsOpen: setIsCalendarOpen,
                                size: "sm",
                            }}
                        >
                            <Calendar
                                setIsOpen={setIsCalendarOpen}
                                {...{
                                    dateRange,
                                    setDateRange,
                                    selectionRange,
                                    setSelectionRange,
                                    setDefaultSelectionRange,
                                    callback: callback,
                                }}
                            />
                        </Dropdown>
                    </div> */}
                    <div className="row row-bottom">
                        <div className="col col-left btn-group">
                            <Button
                                variant="circle"
                                icon={lineChart}
                                size="sm"
                                color={isLineChartActive ? "dark" : "light"}
                                onClick={() => setIsLineChartActive(true)}
                            />
                            <Button
                                variant="circle"
                                icon={barChart}
                                size="sm"
                                color={isLineChartActive ? "light" : "dark"}
                                onClick={() => setIsLineChartActive(false)}
                            />
                        </div>
                        <div className="col col-right btn-group">
                            {Object.values(graphBtns).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`chart-btn ${item.isActive ? "active" : ""}`}
                                        data-name={item.propertyName}
                                        onClick={handleActiveChartBtn}
                                    >
                                        <div
                                            className="list-style"
                                            style={{ backgroundColor: item.color }}
                                        ></div>
                                        {item.displayName}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* {isLineChartActive ? ( */}
                    <LineChart
                        isTooltipFormatted={markedCampaigns.length > 1 ? true : false}
                        data={filteredGraphData}
                        // defaultData={[
                        //     {
                        //         empty: 0,
                        //         name: "",
                        //     },
                        //     // {
                        //     //     empty: 0,
                        //     //     name: "Campaign progression is empty",
                        //     // },
                        // ]}
                        lines={Object.values(graphBtns)}
                        isLoading={campaignDataIsLoading}
                    />
                    {/* ) : (
                        <BarChart
                            data={filteredGraphData}
                            defaultData={[
                                {
                                    empty: 0,
                                    name: "Campaign progression is empty",
                                },
                            ]}
                            bars={Object.values(graphBtns)}
                            isLoading={campaignDataIsLoading}
                        />
                    )} */}
                </div>
            </div>
        </div>
    );
}
