import React from "react";
import {
    ComposedChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
    Area,
    Bar,
    Line,
} from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? 0 : 90;
let showYaxis = isSmallScreen ? true : false;

export default function Example({
    data = [],
    defaultData = [],
    isLoading = false,
    lines = [],
    className = "",
}) {
    const { formatNumber, formatCurrency } = useFormatter();

    const CustomTooltip = ({ payload, label }) => {
        if (!payload || payload.length === 0) return null;

        // Retrieve the original values from the payload
        const originalData = payload[0].payload;
        const yName = originalData.yName || "";
        const ySecondaryName = originalData.ySecondaryName || "";
        const formattedProgress = originalData.formattedProgress || "";
        return (
            <div className="chart-tooltip">
                <strong className="label">{`${label}`}</strong>
                {/* {(yName == "cost"  || ySecondaryName == "cost") && (
                    <p className="label">{`Cost: ${formatCurrency(originalData.progress,0)}`}</p>
                )}
                {(yName == "impressions"  || ySecondaryName == "impressions") && (
                    <p className="label">{`Impressions: ${formatNumber(originalData.progress)}`}</p>
                )}
                {(yName == "clicks" || ySecondaryName == "clicks") && (
                    <p className="label">{`Clicks: ${formatNumber(originalData.progress)}`}</p>
                )}
                { (yName == "conversions"  || ySecondaryName == "conversions")  && (
                    <p className="label">{`Conversions: ${formatNumber(originalData.progress)}`}</p>
                )}
                {(yName == "volumes"  || ySecondaryName == "volumes")  && (
                    <p className="label">{`Volumes: ${formatCurrency(originalData.progress,0)}`}</p>
                )} */}
                {(yName == "cost"  || ySecondaryName == "cost") && (
                    <p className="label">{`Cost: ${formattedProgress}`}</p>
                )}
                {(yName == "impressions"  || ySecondaryName == "impressions") && (
                    <p className="label">{`Impressions: ${formattedProgress}`}</p>
                )}
                {(yName == "clicks" || ySecondaryName == "clicks") && (
                    <p className="label">{`Clicks: ${formattedProgress}`}</p>
                )}
                { (yName == "conversions"  || ySecondaryName == "conversions")  && (
                    <p className="label">{`Conversions: ${formattedProgress}`}</p>
                )}
                {(yName == "volumes"  || ySecondaryName == "volumes")  && (
                    <p className="label">{`Volumes: ${formattedProgress}`}</p>
                )}
            </div>
        );
    };
    return (
        <div className={`position ${className} vertical-composed-chart`}>
            <div className="position-inner">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        layout="vertical"
                        width={500}
                        height={400}
                        data={data.length && !isLoading ? data : defaultData}
                        // margin={{
                        //     top: 30,
                        //     right: 0,
                        //     left: leftMargin,
                        //     bottom: 30,
                        // }}
                        margin = {{
                            left: !isSmallScreen ? -35 : 0,
                            right: !isSmallScreen ? -15 : 0
                        }}
                    >
                        <defs>
                            <linearGradient id="gradientColor" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stopColor="#15A0C5" stopOpacity={1} />
                                <stop offset="100%" stopColor="#00B368" stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                        <XAxis
                            type="number"
                            axisLine={false}
                            tickLine={false}
                            dy={15}
                            // tick={{ fontSize: fontSize}}
                            // dataKey="xName"
                            // style={{ marginTop: "-10px" }}
                            // scale="time"
                        />
                        <YAxis
                            dataKey="yName"
                            type="category"
                            axisLine={false}
                            tick={{ fontSize: fontSize }}
                            hide={showYaxis}
                            tickLine={false}
                            // dx={-5}
                            dy={-3}

                            // tickFormatter={formatYAxisLabel}
                            // tickFormatter={(value) => value}
                            width={210}
                            // textAnchor='middle'
                            id="left"
                        />
                        <YAxis
                        orientation="right"
                            dataKey="formattedProgress"
                            type="category"
                            axisLine={false}
                            tick={{ fontSize: fontSize }}
                            hide={showYaxis}
                            tickLine={false}
                            // dx={-5}
                            dy={-3}

                            // tickFormatter={formatYAxisLabel}
                            // tickFormatter={(value) => value}
                            width={100}
                            // textAnchor='middle'
                             yAxisId="right"
                        />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Tooltip
                            formatter={(value, name) => {
                                if (name === "impressions") return formatNumber(value);
                                if (name === "ad spend") return formatCurrency(value, 2);
                                if (name === "clicks") return formatNumber(value);
                                return formatNumber(value);
                            }}
                        /> */}
                        <Legend />
                        {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                        <Bar
                            dataKey="normalizedProgress"
                            barSize={20}
                            // fill="rgb(53, 12, 133)"
                            radius={[10, 10, 10, 10]}
                            fill="url(#gradientColor)"
                        />
                        {/* <Bar
                            dataKey="impressions"
                            barSize={20}
                            fill="rgb(53, 12, 133)"
                            radius={[10, 10, 10, 10]}
                        />
                        <Bar
                            dataKey="clicks"
                            barSize={20}
                            fill="rgb(53, 12, 133)"
                            radius={[10, 10, 10, 10]}
                        />
                        <Bar
                            dataKey="conversions"
                            barSize={20}
                            fill="rgb(53, 12, 133)"
                            radius={[10, 10, 10, 10]}
                        />
                        <Bar
                            dataKey="volumes"
                            barSize={20}
                            fill="rgb(53, 12, 133)"
                            radius={[10, 10, 10, 10]}
                        /> */}
                        {/* <Bar dataKey="volume" barSize={20} fill="#413ea0"  /> */}
                        {/* <Line dataKey="clicks" stroke="#ff7300" /> */}

                        {data.map((item, index) => (
                            <Bar
                                key={index}
                                dataKey={item.xName}
                                // stroke={item.color || "#000"}
                                // scale='time'
                            />
                        ))}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
