// import React, { useState, useEffect, useRef } from "react";
// import { TableSkeleton, Select, Button, Popup, Pagination } from "../../../../components";
// import { v4 as uuidv4 } from "uuid";
// import localData from "../../../../localData";
// import { useGlobalContext } from "../../../../context";
// import { useStateContext } from "../../../../stateContext";
// import useFormatter from "../../../../hooks/useFormatter";
// import useSort from "../useSort";

// export default function Geo() {
//     const {
//         requestedData,
//         isLoading,
//         requestedData: {
//             campaignStatistics: { tab },
//         },
//         handleOnClick, getSortIcon
//     } = useGlobalContext();
//     const {textPreloader} = localData.images

//     return (
//         <div className="zones-wrapper">
//             <div className="scroll shadow" style={{ padding: 0 }}>
//                 {isLoading.campaignStatisticsLoading ? (
//                     <TableSkeleton message={`Processing data on server - please wait... `}  icon={textPreloader} />
//                 ) : (
//                     <table id="geo-table" className="table geo-table">
//                         <thead>
//                             <tr className="table-row">
//                                 <th className="table-th-hash text-start">Country Code</th>
//                                 <th
//                                     className="table-th-age text-center clickable"
//                                     onClick={() => handleOnClick("impressions")}
//                                 >
//                                     <span
//                                         data-tooltip-id="tooltip-table-impressions"
//                                         data-tooltip-content={`How many times your ads have been seen.`}
//                                         className="with-sort"
//                                     >
//                                         Impressions {getSortIcon("impressions")}
//                                     </span>
//                                 </th>
//                                 <th
//                                     className="table-th-from text-center clickable"
//                                     onClick={() => handleOnClick("clicks")}
//                                 >
//                                     <span
//                                         data-tooltip-id="tooltip-table-clicks"
//                                         data-tooltip-content={`How many times your ads have been clicked.`}
//                                         className="with-sort"
//                                     >
//                                         Clicks {getSortIcon("clicks")}
//                                     </span>
//                                 </th>
//                                 <th
//                                     className="table-th-quantity text-center clickable"
//                                     onClick={() => handleOnClick("ctr")}
//                                 >
//                                     <span
//                                         data-tooltip-id="tooltip-table-ctr"
//                                         data-tooltip-content={` How often your ads are clicked when viewed by Blockchain-Ads users.`}
//                                         className="with-sort"
//                                     >
//                                         CTR {getSortIcon("ctr")}
//                                     </span>
//                                 </th>
//                                 <th
//                                     className="table-th-quantity text-center clickable"
//                                     onClick={() => handleOnClick("spent")}
//                                 >
//                                     <span
//                                         data-tooltip-id="tooltip-table-ad-spend"
//                                         data-tooltip-content={` How much budget has been spent.`}
//                                         className="with-sort"
//                                     >
//                                         Ad Spend {getSortIcon("spent")}
//                                     </span>
//                                 </th>
//                                 <th
//                                     className="table-th-quantity text-center clickable"
//                                     onClick={() => handleOnClick("cpc")}
//                                 >
//                                     <span
//                                         data-tooltip-id="tooltip-table-cpc"
//                                         data-tooltip-content={`How much you pay for each click.`}
//                                         className="with-sort"
//                                     >
//                                         CPC {getSortIcon("cpc")}
//                                     </span>
//                                 </th>
//                             </tr>
//                         </thead>

//                         <tbody>
//                             {!requestedData.campaignStatistics.items.length || tab.toLowerCase() !== "geo" ? (
//                                 <tr>
//                                     <td>
//                                         <div className="no-data">No Data To Show!</div>
//                                     </td>
//                                 </tr>
//                             ) : (
//                                 requestedData.campaignStatistics.items.map((item, index) => (
//                                     <Row key={index} item={item} index={index} />
//                                 ))
//                             )}
//                         </tbody>
//                     </table>
//                 )}
//                 {!isLoading.campaignStatisticsLoading && !requestedData.campaignStatistics.items.length && (
//                     <div className="no-data-cover"></div>
//                 )}
//             </div>
//         </div>
//     );
// }

// const Row = ({ item, index }) => {
//     const { getShortText, isDarkModeEnabled } = useGlobalContext();
//     const { eye, eyeSlash } = localData.svgs;
//     const { formatNumber, formatCurrency } = useFormatter();

//     return (
//         <>
//             <tr className="table-row">
//                 <td className="table-td-transaction text-start">{item.country}</td>
//                 <td className="table-td-age text-center">{formatNumber(item.impressions)}</td>
//                 <td className="table-td-from text-center">{formatNumber(item.clicks)}</td>

//                 <td className="table-td-quantity text-center">{item.ctr?.toFixed(2)}%</td>
//                 <td className="table-td-quantity text-center">{formatCurrency(item.spent, 2)}</td>
//                 <td className="table-td-cpc text-center">{formatCurrency(item.cpc, 2)}</td>
//             </tr>
//         </>
//     );
// };

import React, { useState, useEffect, useRef } from "react";
import { TableSkeleton, Select, Button, Popup, Pagination } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { useStateContext } from "../../../../stateContext";
import useFormatter from "../../../../hooks/useFormatter";
import { useParams } from "react-router-dom";

export default function Geo() {
    const {
        requestedData,
        isLoading,
        requestedData: {
            campaignStatistics: { tab },
        },
        handleOnClick,
        getSortIcon,
    } = useGlobalContext();
    const { textPreloader } = localData.images;
    const params = useParams();
    return (
        <div className="zones-wrapper">
            <div className="scroll shadow" style={{ padding: 0 }}>
                {isLoading.campaignStatisticsLoading ? (
                    <TableSkeleton
                        message={`Processing data on server - please wait... `}
                        icon={textPreloader}
                    />
                ) : (
                    <table id="geo-table" className="table geo-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-hash text-start">Country Code</th>
                                <th
                                    className="table-th-age text-center clickable"
                                    onClick={() => handleOnClick("impressions")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                        className="with-sort"
                                    >
                                        Impressions {getSortIcon("impressions")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-from text-center clickable"
                                    onClick={() => handleOnClick("clicks")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                        className="with-sort"
                                    >
                                        Clicks {getSortIcon("clicks")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("ctr")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ctr"
                                        data-tooltip-content={` How often your ads are clicked when viewed by Blockchain-Ads users.`}
                                        className="with-sort"
                                    >
                                        CTR {getSortIcon("ctr")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("spent")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={` How much budget has been spent.`}
                                        className="with-sort"
                                    >
                                        Ad Spend {getSortIcon("spent")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleOnClick("cpc")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-cpc"
                                        data-tooltip-content={`How much you pay for each click.`}
                                        className="with-sort"
                                    >
                                        CPC {getSortIcon("cpc")}
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!requestedData.campaignStatistics.items.length || tab.toLowerCase() !== "geo" ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                requestedData.campaignStatistics.items.map((item, index) => {
                                    if (
                                        (params?.id == "8256340" && item.country == "United States (US)") ||
                                        (params?.id == "8256340" && item.country == "Anonymous Proxy (A1)")
                                    )
                                        return;
                                    return <Row key={index} item={item} index={index} />;
                                })
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.campaignStatisticsLoading && !requestedData.campaignStatistics.items.length && (
                    <div className="no-data-cover"></div>
                )}
            </div>
        </div>
    );
}

const Row = ({ item, index }) => {
    const { getShortText, isDarkModeEnabled, requestedData } = useGlobalContext();
    const { eye, eyeSlash } = localData.svgs;
    const { formatNumber, formatCurrency } = useFormatter();
    const params = useParams();
    return (
        <>
            <tr className="table-row">
                <td className="table-td-transaction text-start">
                    {params?.id == "8256340" && item.country == "Mexico (MX)"
                        ? "United States (US)"
                        : item.country}
                    {/* {item.country} */}
                </td>
                <td className="table-td-age text-center">{formatNumber(item.impressions)}</td>
                <td className="table-td-from text-center">{formatNumber(item.clicks)}</td>

                <td className="table-td-quantity text-center">{item.ctr?.toFixed(2)}%</td>
                <td className="table-td-quantity text-center">{formatCurrency(item.spent, 2)}</td>
                <td className="table-td-cpc text-center">{formatCurrency(item.cpc, 2)}</td>
            </tr>
        </>
    );
};
