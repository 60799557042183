import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Select, Dropdown, LineChart, Loading, BigSkeleton, Carousel } from "../../../components";
import { useGlobalContext } from "../../../context";
import useFormatter from "../../../hooks/useFormatter";
import localData from "../../../localData";

const colors = [
    "purple",
    "rgba(21, 161, 197,1)",
    "rgba(6, 23, 38,1)",
    "rgba(249, 57, 57,1)",
    "rgba(245, 167, 49,1)",
    "rgba(46, 204, 113, 1)",
    "rgb(0, 128, 128)",
    "rgb(255, 0, 255)",
    "rgb(255, 165, 0)",
    "rgb(128, 0, 0)",
];

export default function AnalyticsDetails() {
    const {
        requestedData: {
            userProfileData,
            campaignData,
            campaignDataSummary,
            analyticsProjectData: { tableData, transformedData },
        },
        isLoading: { analyticsProjectIsLoading },
        analyticsState,
    } = useGlobalContext();
    const { activeAnalytic, selectionRange,activeAnalyticRows } = analyticsState;

    const { formatDateToMMDD, formatDateToYYYYMMDD, formatDate } = useFormatter();

    const [sourceItems, setSourceItems] = useState([
        { title: "item 1", isSelected: false, id: uuidv4() },
        { title: "item 2", isSelected: false, id: uuidv4() },
        { title: "lorem ipsum dolor samet", isSelected: false, id: uuidv4() },
        { title: "item 4", isSelected: false, id: uuidv4() },
        { title: "item 5", isSelected: false, id: uuidv4() },
        { title: "item 6", isSelected: false, id: uuidv4() },
        { title: "item 7", isSelected: false, id: uuidv4() },
        { title: "item 8", isSelected: false, id: uuidv4() },
        { title: "item 9", isSelected: false, id: uuidv4() },
        { title: "item 10", isSelected: false, id: uuidv4() },
    ]);

    const { user, textPreloader } = localData.images;
    const { eye, transactions, coins } = localData.svgs;

    const [graphBtns, setGraphBtns] = useState({
        conversions: {
            isActive: true,
            propertyName: "conversions",
            displayName: "Conversions",
            color: "purple",
        },
    });
    useEffect(() => {
        const data = activeAnalyticRows;
        let tempGraphBtns = {};
        data.forEach((item, index) => {
            tempGraphBtns = {
                ...tempGraphBtns,
                [item.firstUserSource]: {
                    propertyName: item.firstUserSource,
                    displayName: item.firstUserSource,
                    color: colors[index],
                    isActive: true,
                },
            };
        });
        setGraphBtns(tempGraphBtns);
    }, [activeAnalyticRows]);

    const [activeGraphCampaign, setActiveGraphCampaign] = useState(null);

    const [filteredGraphData, setFilteredGraphData] = useState([]);

    const handleActiveChartBtn = (e) => {
        const name = e.currentTarget.dataset.name;
        setGraphBtns({
            ...graphBtns,
            [name]: {
                ...graphBtns[name],
                isActive: !graphBtns[name].isActive,
            },
        });
    };

    // const filterChartData = () => {
    //     const data = activeAnalytic?.daily_data || []
    //     const filteredData = [];
    //     data.forEach((item) => {
    //         const obj = {};
    //         if (graphBtns.conversions.isActive) obj.conversions = item.conversions;
    //         if (graphBtns.engagedSessions.isActive) obj.engagedSessions = item.engagedSessions;
    //         if (graphBtns.engagementRate.isActive) obj.engagementRate = item.engagementRate;
    //         if (graphBtns.newUsers.isActive) obj.newUsers = item.newUsers;
    //         if (graphBtns.eventCount.isActive) obj.eventCount = item.eventCount;
    //         if (graphBtns.totalRevenue.isActive) obj.totalRevenue = item.totalRevenue;

    //         obj.name = formatDateToMMDD(item.date);
    //         filteredData.push(obj);
    //     });

    //     setFilteredGraphData(filteredData);
    // };

    // const filterChartData = () => {
    //     const data = activeAnalytic?.daily_data || [];
    //     const filteredData = [];
    //     const { startDate, endDate } = selectionRange;
    //     for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    //         const dateString = formatDateToYYYYMMDD(date);
    //         const dataForDate = data.find(item=> item.date == dateString)
    //         if (dataForDate) {
    //             const obj = {};
    //             obj.name = formatDateToMMDD(date);
    //             if (graphBtns.conversions.isActive) obj.conversions = dataForDate.conversions;
    //             if (graphBtns.engagedSessions.isActive) obj.engagedSessions = dataForDate.engagedSessions;
    //             if (graphBtns.engagementRate.isActive) obj.engagementRate = dataForDate.engagementRate;
    //             if (graphBtns.newUsers.isActive) obj.newUsers = dataForDate.newUsers;
    //             if (graphBtns.eventCount.isActive) obj.eventCount = dataForDate.eventCount;
    //             if (graphBtns.totalRevenue.isActive) obj.totalRevenue = dataForDate.totalRevenue;

    //             filteredData.push(obj);
    //         } else {
    //             const obj = {}
    //             obj.name = formatDateToMMDD(date);
    //             obj.empty = 0;
    //             filteredData.push(obj);
    //         }
    //     }
    //     console.log(filteredData)
    //     setFilteredGraphData(filteredData);
    // };

    const filterChartData = () => {
        // const data = transformedData.slice(0, 10);
        const data = activeAnalyticRows
        // console.log({data},  ' hereeeeeeeeeeeeeee')
        const { startDate, endDate } = selectionRange;
        const filteredData = {};

        data.forEach((obj) => {
            obj.daily_data.forEach((data) => {
                const { date, firstUserSource, newUsers } = data;
                if (!filteredData[date]) {
                    filteredData[date] = { name: formatDateToMMDD(date) };
                }
                const isSourceActive = Object.values(graphBtns).find(
                    (item) => item.propertyName == firstUserSource && item.isActive
                );
                if (isSourceActive) filteredData[date][firstUserSource] = newUsers;
            });
        });

        const result = Object.values(filteredData);

        // console.log(result, "result");
        setFilteredGraphData(result);
    };

    useEffect(() => {
        filterChartData();
    }, [graphBtns, transformedData, activeAnalyticRows]);

    return (
        <div className="analytics-details shadow">
            {analyticsProjectIsLoading ? (
                <BigSkeleton />
            ) : (
                <div className="analytics-details-inner">
                    <div className="wrapper">
                        {/* <Select
                            {...{
                                items: sourceItems,
                                setItems: setSourceItems,
                                placeholder: "Sources",
                                variant: "text",
                                color: "dark",
                                className: "source-select",
                            }}
                        /> */}
                        <div className="text-style-1" style={{ marginBottom: 0 }}>
                            First User Source
                        </div>
                        <div className="scroll-items">
                            <Carousel
                                items={Object.values(graphBtns)}
                                Card={(item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`chart-btn ${item.isActive ? "active" : ""}`}
                                            data-name={item.propertyName}
                                            onClick={handleActiveChartBtn}
                                        >
                                            <div
                                                className="list-style"
                                                style={{ backgroundColor: item.color }}
                                            ></div>
                                            {item.displayName}
                                        </div>
                                    );
                                }}
                                options={{
                                    slidesPerView: "auto",
                                    breakpoints: null,
                                    loop: false,
                                    autoplay: false,
                                    speed: 500,
                                    spaceBetween: 3,
                                }}
                            />
                        </div>
                    </div>
                    <LineChart
                        // data={[
                        //     { conversions: 5453, engaged_sessions: 4343, engagement_rate: 2532 },
                        //     { conversions: 223, engaged_sessions: 55, engagement_rate: 6264 },
                        //     { conversions: 6342, engaged_sessions: 6265, engagement_rate: 444 },
                        // ]}

                        data={filteredGraphData}
                        lines={Object.values(graphBtns)}
                        isLoading={analyticsProjectIsLoading}
                    />
                </div>
            )}
        </div>
    );
}
