import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../components";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import useFormatter from "../../../hooks/useFormatter";
import { v4 as uuidv4 } from "uuid";

let timeout = "";

export default function MarketplaceCard({
    id = "",
    packageType = "",
    format = "",
    totalPrice = "",
    discount = "",
    totalReach = "",
    description = "",
    cover = "",
    color = "",
    isInShoppingCart = false,
    isInWishlist = false,
    products = [],
    extraSource = [],
    icons = {
        favorite: true,
        shop: true,
        removeShoppingCart: false,
    },
    included = [],
    index=""
}) {
    const { shoppingCart, heart, trashV2, packagePlaceholder, plus, info } = localData.svgs;

    const { marketplaceState } = useGlobalContext();

    const { addCommas } = useFormatter();

    const navigate = useNavigate();

    const {
        publications,
        setPublications,
        activeMarketplaceDetails,
        setActiveMarketplaceDetails,
        setDisplayMarketplaceDetailsModal,
    } = marketplaceState;

    const handleAddToShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == id ? { ...item, isInShoppingCart: !isInShoppingCart } : { ...item }
        );
        setPublications(tempPublications);
    };

    const handelNavigateToShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == id ? { ...item, isInShoppingCart: true } : { ...item }
        );
        setPublications(tempPublications);

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            navigate("/additional-products/pr/shopping-cart");
        }, 500);
    };

    const handleAddToWishlist = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == id ? { ...item, isInWishlist: !isInWishlist } : { ...item }
        );
        setPublications(tempPublications);
    };

    const handleRemoveFromShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == id ? { ...item, isInShoppingCart: false } : { ...item }
        );
        setPublications(tempPublications);
    };

    const handleRemoveFromWishlist = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) =>
            item.id == id ? { ...item, isInWishlist: false } : { ...item }
        );
        setPublications(tempPublications);
    };
    return (
        <div className={`card marketplace-card  ${color ? "card-" + color : ""}`}>
            <div className="card-header">
                <h4 className="card-title display-3" title={packageType}>
                    {packageType}
                </h4>
                {/* <p className="card-description text-style-1" title={format}>
                    {format}
                </p> */}
            </div>
            <div className="card-body">
                {(discount || totalReach) && <div className="discount-badge">{discount || totalReach}</div>}
                <div className="card-content">
                    <div className="card-content-body">
                        {!products.length
                            ? ""
                            : products.map((item, index) => {
                                  if (index > 2 || !item.logo) return;
                                  return (
                                      <div className="product" key={index}>
                                          {item.logo && (
                                              <img className="product-logo" src={item.logo} alt="" />
                                          )}
                                      </div>
                                  );
                              })}
                        {!extraSource.length
                            ? ""
                            : extraSource.map((item, index) => {
                                  return (
                                      <div className="product" key={index}>
                                          <h6 className="product-title">{item}</h6>
                                      </div>
                                  );
                              })}
                    </div>
                    <div className="card-content-footer">
                        <div className="row price-wrapper">
                            <span className="card-price price">$ {addCommas(totalPrice) || 0} USD</span>
                            {/* <div className="btn-group">
                                {icons.favorite && (
                                    <Button
                                        className={`${isInWishlist ? "active" : ""}`}
                                        variant="circle"
                                        size=""
                                        onClick={handleAddToWishlist}
                                        color={`${"primary"}`}
                                    >
                                        {heart}
                                    </Button>
                                )}
                                {icons.shop && (
                                    <Button
                                        className={`${isInShoppingCart ? "active" : ""}`}
                                        variant="circle"
                                        size=""
                                        onClick={handleAddToShoppingCart}
                                        color={`${"success"}`}
                                    >
                                        {shoppingCart}
                                    </Button>
                                )}
                                {icons.removeShoppingCart && (
                                    <Button
                                        variant="circle"
                                        className="remove-card-btn"
                                        onClick={handleRemoveFromShoppingCart}
                                        color={`${"danger"}`}
                                    >
                                        {trashV2}
                                    </Button>
                                )}
                                {icons.removeWishlist && (
                                    <Button
                                        variant="circle"
                                        size="lg"
                                        className="remove-card-btn"
                                        onClick={handleRemoveFromWishlist}
                                        color={`${"danger"}`}
                                    >
                                        {trashV2}
                                    </Button>
                                )}
                            </div> */}
                        </div>
                        <Button
                            id={index == 0 ? `pr-tutorial-3` : `btn-${uuidv4()}`}
                            className="details-btn"
                            color="light"
                            size="sm"
                            // color="secondary"
                            onClick={() =>
                                setActiveMarketplaceDetails({
                                    id,
                                    packageType,
                                    format,
                                    totalPrice,
                                    discount,
                                    totalReach,
                                    description,
                                    cover,
                                    color,
                                    isInShoppingCart,
                                    isInWishlist,
                                    products,
                                    included,
                                    extraSource,
                                })
                            }
                        >
                            What’s included {info}
                        </Button>

                        <div className="btn-group">
                            {icons.favorite && (
                                <Button
                                    className={`circle-btn ${isInWishlist ? "active" : ""}`}
                                    variant="circle"
                                    size=""
                                    onClick={handleAddToWishlist}
                                    color={`${"primary"}`}
                                >
                                    {heart}
                                </Button>
                            )}
                            {icons.removeShoppingCart && (
                                <Button
                                    variant="circle"
                                    className="circle-btn remove-card-btn"
                                    onClick={handleRemoveFromShoppingCart}
                                    color={`${"danger"}`}
                                >
                                    {trashV2}
                                </Button>
                            )}
                            {icons.removeWishlist && (
                                <Button
                                    variant="circle"
                                    size="lg"
                                    className="circle-btn remove-card-btn"
                                    onClick={handleRemoveFromWishlist}
                                    color={`${"danger"}`}
                                >
                                    {trashV2}
                                </Button>
                            )}
                            {icons.shop && (
                                <Button
                                id={index == 0 ? `pr-tutorial-4` : `btn-${uuidv4()}`}
                                    className="order-btn"
                                    color="marketplace"
                                    variant="contained"
                                    onClick={handelNavigateToShoppingCart}
                                >
                                    <div>Add to cart</div>
                                    {/* <span className="price"> $ {addCommas(totalPrice) || 0}</span> */}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
