import React, { PureComponent } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? -20 : -10;

export default function Example({ data = [], defaultData = [], isLoading = false, bars = [] , className = ''}) {
    // static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";

    const { formatNumber, formatCurrency } = useFormatter();

    return (
        <div className={`position ${className}`}>
            <div className="position-inner">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data.length && !isLoading ? data : defaultData}
                        margin={{
                            top: 30,
                            right: 0,
                            left: leftMargin,
                            bottom: 30,
                        }}
                    >
                        <CartesianGrid strokeDasharray="" vertical={false} />
                        <XAxis
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            dy={15}
                            tick={{ fontSize: fontSize }}
                            // interval="auto"
                        />
                        <YAxis
                            axisLine={false}
                            tick={{ fontSize: fontSize }}
                            tickLine={false}
                            dx={-5}
                            // ticks={[0, 500, 1000, 2500, 5000, 7500, 10000]}
                        />
                        <Tooltip
                            formatter={(value, name, props) => {
                                if (name === "impressions") return formatNumber(value);
                                if (name === "ad spend") return formatCurrency(value / 10, 2);
                                if (name === "clicks") return formatNumber(value);
                                if (name === "CPC") return formatCurrency(value, 3);
                                if (name === "CPM") return formatCurrency(value, 3);
                            }}
                        />

                        {!bars.length
                            ? ""
                            : bars.map((bar, index) => {
                                  return (
                                      <Bar
                                          key={index}
                                          dataKey={bar.propertyName}
                                          fill={bar.color}
                                          name={bar.displayName}
                                          stackId={data.length > 10 ? "a" : undefined}
                                      />
                                  );
                              })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
