import React, { useEffect } from "react";
import { Drawer, DepositComponent } from "../index.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, NestedModal } from "../index.js";
import { useGlobalContext } from "../../context.js";
import localData from "../../localData.js";
import { auth } from "../../config/firebase.js";

// const menu = [
//     { title: "home", to: "/" },
//     { title: "about", to: "/about" },
//     { title: "products", to: "/products" },
// ];

export default function Navbar({ title = "", description = "", titlePopup = "", isPublic = false }) {
    // const location = useLocation();
    const { avatar, user } = localData.images;
    const {
        showSidebar,
        setShowSidebar,
        successAlert,

        showProfileDrawer,
        setShowProfileDrawer,
        showDepositModal,
        setShowDepositModal,
        requestedData,
        requestedData: { userProfileData },
        requestedData: {
            userProfileData: {
                currentOrganization: { balance },
            },
        },
        isLoading: { userProfileIsLoading },
        navbarRef,
        formatNumber,
        isDarkModeEnabled,
        setIsDarkModeEnabled,
        handleSignOut,
    } = useGlobalContext();
    // console.log(auth?.currentUser?.email, " navbar page");
    const { arrowDown, bars, barsClose, sun, moon, deposit } = localData.svgs;
    const { textPreloader } = localData.images;

    // DRAWER
    const DrawerComponent = ({ callbackFromParent }) => {
        const { logout } = localData.svgs;
        const { isLoading } = useGlobalContext();

        return (
            <>
                {/* <p>drawer with callback</p> */}
                {/* <Button variant="contained" name="close drawer" onClick={callbackFromParent} /> */}
                <div className="drawer-header">
                    <img src={userProfileData.profile_image} alt="" />
                    <p className="drawer-text">
                        Welcome to Blockchain-Ads
                        <br />{" "}
                        <strong>
                            {userProfileIsLoading ? (
                                <img src={textPreloader} className="text-preloader" />
                            ) : (
                                userProfileData.userName
                            )}
                        </strong>
                    </p>
                </div>

                <div className="drawer-body">
                    <Link to="/profile">
                        <Button name="My Profile" color="dark" onClick={callbackFromParent} />
                    </Link>
                    <br />

                    <Button
                        name="deposit funds"
                        color="dark"
                        onClick={() => {
                            // callbackFromParent()
                            setShowDepositModal("block");
                        }}
                    />
                    <br />
                    {/* <div className="on-hold" > */}
                    <Link to="/referral">
                        <Button name="Referral Program" color="dark" onClick={callbackFromParent} />
                    </Link>
                    {/* </div> */}
                    <br />
                    <br />

                    <Button
                        name="log out"
                        color="danger"
                        variant="outlined"
                        startIcon={logout}
                        onClick={() => {
                            callbackFromParent();
                            setTimeout(() => {
                                handleSignOut();
                            }, 1000);
                        }}
                    />
                </div>

                <div className="drawer-footer">
                    <div className="wrapper">
                        <Button
                            icon={sun}
                            // size="lg"
                            variant="text"
                            color="success"
                            className={`mode ${isDarkModeEnabled ? "" : "active"}`}
                            onClick={() => {
                                setIsDarkModeEnabled(false);
                                document.body.classList.remove("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", false);
                            }}
                        />
                        <Button
                            icon={moon}
                            // size="lg"
                            variant="contained"
                            color="dark"
                            className={`mode ${isDarkModeEnabled ? "active" : ""}`}
                            onClick={() => {
                                setIsDarkModeEnabled(true);
                                document.body.classList.add("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", true);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <nav className={`navbar navbar-expand-lg ${isPublic ? "navbar-public" : ""}`} ref={navbarRef}>
                <h1 className="navbar-title navbar-title-sm-media" title={titlePopup}>
                    {title}
                </h1>

                <div className="wrapper">
                    <Button
                        className="sidebar-toggler-show"
                        size="sm"
                        icon={showSidebar ? bars : barsClose}
                        variant="circle"
                        color="dark"
                        onClick={() => setShowSidebar(!showSidebar)}
                    />
                    <div className="titles">
                        <h1 className="navbar-title" title={titlePopup}>
                            {title}
                        </h1>
                        {description && <h3 className="description">{description}</h3>}
                    </div>
                </div>

                <div className="wrapper">
                    <div id="login-tutorial-2">
                        <Modal
                            Child={DepositComponent}
                            className="deposit-modal modal-dialog-centered"
                            // buttonTitle="Deposit"
                            // buttonStartIcon={deposit}
                            buttonClassName={`rounded-pill deposit-btn ${
                                window.innerWidth < 576 ? "btn-sm" : ""
                            }`}
                            // buttonColor="primary"
                            display={showDepositModal}
                            setDisplay={setShowDepositModal}
                            // togglerInfo={
                            //     <>
                            //         Top up your account balance to use <br /> Blockchain-Ads.
                            //     </>
                            // }
                            // togglerId="tooltip-deposit-btn"
                            Toggler={() => (
                                <Button
                                    tooltipContent={
                                        <div>
                                            Top up your account balance to use <br /> Blockchain-Ads.
                                        </div>
                                    }
                                    name="Deposit"
                                    variant="contained"
                                    color="primary"
                                    className={`rounded-pill deposit-btn ${
                                        window.innerWidth < 576 ? "btn-sm" : ""
                                    }`}
                                    id="login-tutorial-2"
                                    startIcon={deposit}
                                    onClick={() => setShowDepositModal("block")}
                                />
                            )}
                        ></Modal>
                    </div>
                    <div className="budget dark-mode-style">
                        <p className="budget-title">BALANCE</p>
                        <div className="budget-balance">
                            ${balance > 0 ? formatNumber(balance?.toFixed(0) || "0") : 0}
                        </div>
                    </div>
                    {!isPublic ? (
                        <Drawer
                            Component={DrawerComponent}
                            className="drawer-right"
                            display={showProfileDrawer}
                            setDisplay={setShowProfileDrawer}
                            disabled={userProfileIsLoading}
                            toggler={
                                <Button color="dark" variant="text">
                                    <div className="profile  dark-mode-style">
                                        <div className="profile-image">
                                            <img src={userProfileData.profile_image} alt="" />
                                        </div>
                                        <div className="profile-name">
                                            {userProfileIsLoading ? (
                                                <img src={textPreloader} className="text-preloader" />
                                            ) : (
                                                userProfileData.userName
                                            )}
                                        </div>
                                        <div className="profile-icon">{arrowDown}</div>
                                    </div>
                                </Button>
                            }
                        />
                    ) : (
                        <Link to="/sign-up">
                            <Button name="sign up" variant="outlined" size="sm" />
                        </Link>
                    )}
                </div>

                {/* <div className="navbar-collapse" id="navbarSupportedContent">
                     <ul className="navbar-nav me-auto">
                         {menu.map(({ title, to }, index) => (
                             <li className="nav-item" key={index}>
                                 <Link to={to} className={`nav-link ${to === location.pathname ? "active" : ""}`}>
                                     {title}
                                 </Link>
                             </li>
                         ))}
                        
                     </ul>
                 </div> */}
            </nav>
            <hr className="navbar-divider" />
        </>
    );
}
