import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import localData from "../../localData";
import { useGlobalContext } from "../../context";
import { useStateContext } from "../../stateContext";
import { Button, UploadCard, Modal } from "../../components";
import useUtil from "../../hooks/useUtil";
import ReactCrop, {
    centerCrop,
    convertToPercentCrop,
    convertToPixelCrop,
    makeAspectCrop,
} from "react-image-crop";

export default function UploadImageBasic({ defaultImage, disabled, callback }) {
    const { placeholder } = localData.images;

    const { errorAlert, isDarkModeEnabled, campaignsState } = useGlobalContext();
    const { setDisplayCreativesModal, creativesState, setCreativesState } = campaignsState;

    const inputRef = useRef();

    const [image, setImage] = useState(null);

    useEffect(()=>{
        setImage(defaultImage)
    },[defaultImage])

    const convertBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleGifImage = async (file) => {
        // console.log(file, size)
        const base64Image = await convertBase64(file);
        const image = new Image();
        image.src = base64Image;
        image.onload = (e) => {
            const width = e.currentTarget.naturalWidth;
            const height = e.currentTarget.naturalHeight;
            const size = file.size / 1024;
            let errorMessage = "";
            if (width !== 900 || height !== 600) {
                errorMessage = "Error: Incorrect image's size. Please upload 900x600px";
            }
            if (size > 720) {
                if (errorMessage !== "") errorMessage += "\n";
                errorMessage += "Error: Incorrect image's size. Please upload maximum 720kb";
            }
            if (errorMessage) {
                console.log(errorMessage);
                errorAlert(errorMessage);
                return;
            }
            // console.log(width, height, size);
            let item = {
                file,
                preview: URL.createObjectURL(file),
                image: base64Image,
                id: uuidv4(),
                status: 1,
                title: file.name,
                size: file.size,
                type: file.type, // Store the image type
            };

            // setSelectedImages((prevImages) => [...prevImages, item]);
        };
    };
    const { getFileFromCanvas, resizeImage } = useUtil();
    const handleFileChange = async (e) => {
        if (!e.target.files[0]) return;
        if (e.target.files[0].type === "image/gif") {
            handleGifImage(e.target.files[0]);
            return;
        }
        const resizedDataURL = await resizeImage(e.target.files[0], 1080, 720);
        let image64 = await convertBase64(resizedDataURL);
        setImage(image64);
        // setDisplayCreativesModal("block");
        callback(image64);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
    };

    const handleNewAdSetClick = () => {
        // inputRef.current.click();
    };

    return (
        <>
            <div
                // onDragOver={handleDragOver}
                // onDragEnter={handleDragEnter}
                // onDrop={handleFileDrop}
                className={`upload-image-basic drag-drop-container ${disabled ? "on-hold" : ""}`}
            >
                <input
                    ref={inputRef}
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    className="file-input"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    // multiple
                />
                <div
                    className="wrapper"
                    onClick={() => inputRef.current.click()}
                    data-guide="upload-ads-tutorial-1"
                >
                    {image ? (
                        <img src={image} />
                    ) : (
                        <>
                            <div className="image-wrapper">
                                <img className={`file-image`} src={placeholder} />
                            </div>
                            <strong>
                                Drag your files here, or <span className="text-primary">browse</span>
                            </strong>
                            <p className="info">
                                We support JPG, PNG formats.
                            </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
