import React, { useState, createContext, useContext, useEffect, useRef } from "react";
import localData from "./localData";
import { ToastContainer, toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import { auth, googleProvider, facebookProvider } from "./config/firebase.js";
import {
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
    sendEmailVerification,
} from "firebase/auth";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import useFetch from "./hooks/useFetch.js";
import useFormatter from "./hooks/useFormatter.jsx";
import useCampaignsState from "./hooks/state-hook/useCampaignsState.jsx";
import useBlockchainsState from "./hooks/state-hook/useBlockchainsState.jsx";
import useReportsState from "./hooks/state-hook/useReportsState.jsx";
import useAnalyticsState from "./hooks/state-hook/useAnalyticsState.jsx";
import useMarketplaceState from "./hooks/state-hook/useMarketplaceState.jsx";
import useTrackingState from "./pages/tracking/useTrackingState.jsx";
import useSort from "./hooks/useSort.jsx";
import useUtil from "./hooks/useUtil.jsx";
import useConvertKit from "./hooks/useConvertKit.jsx";
import { v4 as uuidv4 } from "uuid";

export const Context = createContext();

const { avatar, user, coinTelegraph, bitcoin, beInCrypto } = localData.images;

export default function Provider({ children }) {
    const {
        completeSignUp,
        resetAccountPassword,
        loadProfileData,
        loadUserProfile,
        loadBlockchainData,
        sumTransactionQuantities,
        updateTransaction,
        loadCampaignData,
        loadCampaignStatistics,
        // startOrStopCampaignZone,
        viewCampaignInfo,
        connectAnalyticsAccount,
        loadAnalyticsProfile,
        loadAnalyticsProject,
        disconnectAnalyticsAccount,
        switchOrganization,
        updateUserInfo,
        resetEmail,
        inviteUser,
        getAllCurrencies,
        playOrPauseCampaign,
        CreatePixelTag,
        CreateConversionPixel,
        getAdvertiserPixelStats,
        DeleteConversionPixel,
        EditConversionPixel,
        isEmailExists,
        updateAccountBCAName,
        getInsightsImages,
        conversionTagEmail,
        OrganizationAdditionalData,
        updateCampaignManualData,
        // guideTutorials,
        // updateGuideTutorials,
    } = useFetch();

    // const { checkForBalanceOnLast7days } = useUtil();

    const { sendConvertKit } = useConvertKit();

    const { formatDateToYYYYMMDD, calculateTimeLeft } = useFormatter();

    const location = useLocation();
    const pathname = location.pathname;

    const navigate = useNavigate();

    const navbarRef = useRef(null);
    const marketplaceTheadRef = useRef(null);
    const marketplaceScrollItems = useRef(null);

    const [state, setState] = useState({
        fetchedData: null,
        localData,
    });
    const [animations, setAnimations] = useState({
        fade: {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { duration: 0.5 },
        },
    });
    const [inviteData, setInviteData] = useState({
        invited: false,
        inviteOrgID: "",
    });
    const [refCode, setRefCode] = useState("");

    // const [allCurrencies, setAllCurrencies] = useState([]);

    const [showSidebar, setShowSidebar] = useState(false);
    const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false);
    const [isAdminTeamMember, setIsAdminTeamMember] = useState(false);

    // useEffect(() => {
    //     if (isAdminTeamMember)
    //         checkForBalanceOnLast7days(
    //             requestedData.userProfileData.allOrganization
    //         );
    // }, [isAdminTeamMember]);

    useEffect(() => {
        const isDarkModeEnabled = JSON.parse(localStorage.getItem("isDarkModeEnabled"));
        setIsDarkModeEnabled(isDarkModeEnabled);
        document.body.classList[isDarkModeEnabled ? "add" : "remove"]("dark-mode");
    }, []);

    const [placeholders, setPlaceholders] = useState({
        editProfile: {
            image: null,
            modalImage: null,
            croppedImage: null,
        },
    });

    const expandedAlert = {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        className: "expanded-toast",
        bodyClassName: "expanded-toast-body",
        // closeButton: false,
        draggablePercent: 10,
    };

    const errorAlertExpanded = (message = "This is a persistent toast!") => {
        toast.error(message, expandedAlert);
    };
    const warningAlertExpanded = (message = "This is a persistent toast!", options) => {
        toast.warning(message, { ...expandedAlert, ...options });
    };
    const successAlertExpanded = (message = "This is a persistent toast!") => {
        toast.success(message, expandedAlert);
    };

    const successAlert = (message = "success") => {
        toast.success(message);
    };
    const errorAlert = (message = "error") => {
        toast.error(message);
    };
    const warningAlert = (message = "warning") => {
        toast.warning(message);
    };

    const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);
    const handleGoogleSignIn = async () => {
        setIsGoogleSignInLoading(true);
        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account",
            });
            const response = await signInWithPopup(auth, provider);

            if (response.user.uid) {
                const temRaw = {
                    uid: response.user.uid,
                    email: response.user.email,
                };

                const data = await isEmailExists(() => {}, temRaw);
                const isExist = data.res_data.emailExists;

                // if (isExist) {
                //     successAlert("You have successfully signed in with Google account!");
                //     getLoadUserProfile();
                // }
                if (!isExist) {
                    localStorage.setItem(
                        "guideTutorials",
                        JSON.stringify([
                            "loginTutorial",
                            "campaignInformationTutorial",
                            "uploadAdsTutorial",
                            "targetingTutorial",
                            "summaryTutorial",
                            "trackingTutorial",
                            "installPixelTutorial",
                            "installPixelModalTutorial",
                            "createConversionTutorial",
                            "analyticsTutorial",
                            "prTutorial",
                            "prNoticeModalTutorial",
                        ])
                    );
                    // localStorage.setItem("isAdditionalFormCompleted", false);
                    sendConvertKit({
                        name: response.user.displayName || response.user.email.split("@")[0],
                        email: response.user.email,
                    });
                    let googleSignUpData = {
                        email: response.user.email,
                        uuid: response.user.uid,
                        photoURL: response.user.photoURL,
                        name: response.user.displayName,
                        invited: false,
                    };

                    const data = await completeSignUp(() => {}, googleSignUpData);
                    console.log(data, "=handleGoogleSignIn= request");
                    successAlert("You have successfully authenticated with Google account");
                    getLoadUserProfile();
                }
            }
        } catch (err) {
            if (err?.response?.data?.res_data?.emailExists) {
                successAlert("You have successfully signed in with Google account!");
                getLoadUserProfile();
            } else {
                // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
                warningAlert(
                    "Something went wrong. If you haven't signed up with Google, please sign up first before attempting to sign in."
                );
                console.error(err, "=handleGoogleSignIn= request error");
            }
        }
        setIsGoogleSignInLoading(false);
    };

    const [isGoogleSignUpLoading, setIsGoogleSignUpLoading] = useState(false);

    const handleGoogleSignUp = async () => {
        setIsGoogleSignUpLoading(true);
        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account",
            });

            const response = await signInWithPopup(auth, provider);
            // let response = await signInWithPopup(auth, googleProvider);

            if (response.user.uid) {
                const temRaw = {
                    uid: response.user.uid,
                    email: response.user.email,
                };

                const data = await isEmailExists(() => {}, temRaw);
                const isExist = data.res_data.emailExists;

                if (!isExist) {
                    localStorage.setItem(
                        "guideTutorials",
                        JSON.stringify([
                            "loginTutorial",
                            "campaignInformationTutorial",
                            "uploadAdsTutorial",
                            "targetingTutorial",
                            "summaryTutorial",
                            "trackingTutorial",
                            "installPixelTutorial",
                            "installPixelModalTutorial",
                            "createConversionTutorial",
                            "analyticsTutorial",
                            "prTutorial",
                            "prNoticeModalTutorial",
                        ])
                    );
                    // localStorage.setItem("isAdditionalFormCompleted", false);
                    sendConvertKit({
                        name: response.user.displayName || response.user.email.split("@")[0],
                        email: response.user.email,
                    });

                    let googleSignUpData = {
                        email: response.user.email,
                        uuid: response.user.uid,
                        photoURL: response.user.photoURL,
                        name: response.user.displayName,
                        invited: inviteData.invited,
                        inviteOrgID: inviteData.inviteOrgID,
                        referralCode: refCode,
                    };
                    if (!refCode) delete googleSignUpData.referralCode;

                    const data = await completeSignUp(() => {}, googleSignUpData);
                    console.log(data, "=handleGoogleSignUp= request");
                    successAlert("You have successfully authenticated with Google account");
                    getLoadUserProfile();
                }
            }
        } catch (err) {
            if (err?.response?.data?.res_data?.emailExists) {
                warningAlert(err?.response?.data?.res_msg || "Internal Server Error");
                successAlert("You have successfully signed in with Google account!");
                getLoadUserProfile();
            } else {
                errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
                console.error(err, "=handleGoogleSignUp= request error");
            }
        }
        setIsGoogleSignUpLoading(false);
    };

    const handleFacebookSignIn = async () => {
        try {
            let response = await signInWithPopup(auth, facebookProvider);

            sendConvertKit({
                name: response.user.displayName || response.user.email.split("@")[0],
                email: response.user.email,
            });

            if (pathname === "/sign-up") {
                // Extract necessary user information from the Facebook response
                const { email, uid, photoURL, displayName } = response.user;

                const facebookSignUpData = {
                    email,
                    uuid: uid,
                    photoURL,
                    name: displayName,
                    invited: inviteData.invited,
                    inviteOrgID: inviteData.inviteOrgID,
                    referralCode: refCode,
                };

                if (!refCode) delete facebookSignUpData.referralCode;

                const data = await completeSignUp(() => {}, facebookSignUpData);
            }
        } catch (error) {
            console.error("Facebook Sign-In Error:", error.message);
        }
    };

    const [showCampaingWizardDrawer, setShowCampaignWizardDrawer] = useState("none");
    const [showProfileDrawer, setShowProfileDrawer] = useState("none");
    const [showDepositModal, setShowDepositModal] = useState("none");

    // REQUESTED STATES
    const DEFAULTREQUESTEDDATA = {
        userProfileData: {
            profile_image: user,
            userName: "User",
            allOrganization: [],

            currentOrganization: {
                balance: 0,
                balancePredicted: 0,
                additionalData: {},
            },
        },
        guideTutorials: [
            "loginTutorial",
            "campaignInformationTutorial",
            "uploadAdsTutorial",
            "targetingTutorial",
            "summaryTutorial",
            "trackingTutorial",
            "installPixelTutorial",
            "installPixelModalTutorial",
            "createConversionTutorial",
            "analyticsTutorial",
            "prTutorial",
            "prNoticeModalTutorial",
        ],
        blockchainData: [],
        reportsData: [
            {
                date: "03/24/2024",
                accountName: "Account name 1",
                commissions: "Comissions 1",
                transactionID: "Transaction ID 1",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 2",
                commissions: "Comissions 2",
                transactionID: "Transaction ID 2",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 3",
                commissions: "Comissions 3",
                transactionID: "Transaction ID 3",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 4",
                commissions: "Comissions 4",
                transactionID: "Transaction ID 4",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 5",
                commissions: "Comissions 5",
                transactionID: "Transaction ID 5",
                isPaid: false,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 6",
                commissions: "Comissions 6",
                transactionID: "Transaction ID 6",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 7",
                commissions: "Comissions 7",
                transactionID: "Transaction ID 7",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 8",
                commissions: "Comissions 8",
                transactionID: "Transaction ID 8",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 9",
                commissions: "Comissions 9",
                transactionID: "Transaction ID 9",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 10",
                commissions: "Comissions 10",
                transactionID: "Transaction ID 10",
                isPaid: true,
            },
            {
                date: "03/24/2024",
                accountName: "Account name 11",
                commissions: "Comissions 11",
                transactionID: "Transaction ID 11",
                isPaid: true,
            },
        ],
        campaignData: {
            currentOrganizationCampaigns: [],
            dashboardSummaryData: {
                totalAdAmountSpent: 0,
                totalClicks: 0,
                totalImpressions: 0,
            },
            dataSize: 0,
        },
        campaignStatistics: {
            tab: "",
            items: [],
        },
        campaignDataSummary: {},

        analyticsProfileData: {
            isFetched: false,
            accountSummaries: [],
        },
        analyticsProjectData: {
            mapData: {
                rows: [],
            },
            tableData: {
                rows: [],
            },
            transformedData: [],
        },
        allCurrenciesData: [],
        isAnalyticsConnected: false,
    };

    const [authUser, setAuthUser] = useState(null);

    const [requestedData, setRequestedData] = useState(DEFAULTREQUESTEDDATA);

    // LOADINGS STATES
    const [isLoading, setIsLoading] = useState({
        userProfileIsLoading: false,
        blockchainDataIsLoading: false,
        reportsDataIsLoading: false,
        campaignDataIsLoading: false,
        analyticsConnectIsLoading: false,
        analyticsDisconnectIsLoading: false,
        analyticsProfileIsLoading: false,
        analyticsProjectIsLoading: false,
        userInfoIsUpdating: false,
        emailIsUpdating: false,
        passwordIsUpdating: false,
        inviteUserIsLoading: false,
        campaignStatisticsLoading: false,
        updateTransactionIsLoading: false,
        // startOrStopCampaignZoneIsLoading: false
    });

    const [conversionOptions, setConversionOptions] = useState({
        pixelScriptTag: { value: "", blockChainPixelID: "", isLoading: false },
        trackAnEventScriptTag: { value: "", tagID: "", apiKey: "", isLoading: false },
        advertiserPixelStats: { value: [], isLoading: false },
    });

    const updateAccountBCANameRequest = async ({
        bcaName = "",
        organizationID = "",
        setBcaNameIsLoading = () => {},
    }) => {
        setBcaNameIsLoading(true);
        const tempRaw = {
            organizationID,
            bcaName,
        };
        try {
            const data = await updateAccountBCAName(() => {}, tempRaw);

            console.log(data, "=updateAccountBCAName= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=updateAccountBCAName= request error");
        }
        setBcaNameIsLoading(false);
    };
    const _getInsightsImages = async ({ setIsLoading = false, callback = () => {} }) => {
        setIsLoading(true);
        const tempRaw = {
            // organizationID,
        };
        try {
            const data = await getInsightsImages(() => {}, tempRaw);
            callback(data);
            console.log(data, "=getInsightsImages= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=getInsightsImages= request error");
        }
        setIsLoading(false);
    };

    // const getGuideTutorials = async () => {
    //     const tempRaw = {
    //         uuid: auth.currentUser.uid,
    //     };
    //     try {
    //         // const data = await guideTutorials(() => {}, tempRaw);
    //         const data = {
    //             res_data: {
    //                 guideTutorials: [
    //                     "loginTutorial",
    //                     "campaignCreationTutorial",
    //                     "trackingTutorial",
    //                     "analyticsTutorial",
    //                 ],
    //             },
    //         };
    //         console.log(data, "=guideTutorials= request");

    //         setRequestedData((prev) => ({
    //             ...prev,
    //             guideTutorials: data.res_data.guideTutorials,
    //         }));
    //     } catch (err) {
    //         errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
    //         console.error(err, "=guideTutorials= request error");
    //     }
    // };

    // const getUpdateGuideTutorials = async ({
    //     guideTutorials = [
    //         "loginTutorial",
    //         "campaignCreationTutorial",
    //         "trackingTutorial",
    //         "analyticsTutorial",
    //     ],
    // }) => {
    //     const tempRaw = {
    //         uuid: auth.currentUser.uid,
    //         guideTutorials,
    //     };
    //     try {
    //         // const data = await updateGuideTutorials(() => {}, tempRaw);
    //         // console.log(data, "=updateGuideTutorials= request");

    //         getGuideTutorials();
    //     } catch (err) {
    //         errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
    //         console.error(err, "=updateGuideTutorials= request error");
    //     }
    // };

    // CREATE PIXEL TAG   CreatePixelTag
    const CreatePixelTagRequest = async () => {
        // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
        setConversionOptions((prev) => ({
            ...prev,
            pixelScriptTag: { ...prev.pixelScriptTag, isLoading: true },
        }));
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            // advertiserId: auth.currentUser.uid,
            advertiserId: organizationID,
        };
        try {
            const data = await CreatePixelTag(() => {}, tempRaw);
            console.log(data, "=CreatePixelTag= request");
            setConversionOptions((prev) => ({
                ...prev,
                pixelScriptTag: {
                    value: data.scriptTag,
                    blockChainPixelID: data.blockChainPixelID,
                    isLoading: false,
                },
            }));
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=CreatePixelTag= request error");
            setConversionOptions((prev) => ({
                ...prev,
                pixelScriptTag: { value: "", isLoading: false },
            }));
        }
        // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
    };
    const CreateConversionPixelRequest = async ({ tagName = "" }) => {
        setConversionOptions((prev) => ({
            ...prev,
            trackAnEventScriptTag: { value: "", tagID: "", apiKey: "", isLoading: true },
        }));
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            advertiserId: organizationID,
            tagName,
        };
        try {
            const data = await CreateConversionPixel(() => {}, tempRaw);
            console.log(data, "=CreateConversionPixel= request");
            setConversionOptions((prev) => ({
                ...prev,
                trackAnEventScriptTag: {
                    value: data.scriptTag,
                    tagID: data.tagID,
                    apiKey: data.apiKey,
                    isLoading: false,
                },
            }));
            getAdvertiserPixelStatsRequest();
            successAlert(
                `Stat '${tagName}' created and added to the table. Please ensure you followed the documentation before closing the tab.`
            );
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=CreateConversionPixel= request error");
            setConversionOptions((prev) => ({
                ...prev,
                trackAnEventScriptTag: { value: "", isLoading: false },
            }));
        }
    };
    const EditConversionPixelRequest = async ({ tagId = "", tagName = "", setIsLoading = () => {} }) => {
        setIsLoading(true);
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            advertiserId: organizationID,
            tagId,
            tagName,
        };
        try {
            const data = await EditConversionPixel(() => {}, tempRaw);
            console.log(data, "=EditConversionPixel= request");
            getAdvertiserPixelStatsRequest();
            successAlert(data.message);
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=EditConversionPixel= request error");
        }
        setIsLoading(false);
    };

    const DeleteConversionPixelRequest = async ({ tagId = "", tagName = "", setIsLoading = () => {} }) => {
        setIsLoading(true);

        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            advertiserId: organizationID,
            tagId,
        };
        try {
            const data = await DeleteConversionPixel(() => {}, tempRaw);
            console.log(data, "=DeleteConversionPixel= request");
            // successAlert(`Stat "${tagName}" has been deleted successfully.`)
            successAlert(data.message);

            getAdvertiserPixelStatsRequest();
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=DeleteConversionPixel= request error");
        }
        setIsLoading(false);
    };

    const getAdvertiserPixelStatsRequest = async (successCallback = () => {}) => {
        // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
        setConversionOptions((prev) => ({
            ...prev,
            advertiserPixelStats: { ...prev.advertiserPixelStats, isLoading: true },
        }));
        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            advertiserId: organizationID,
            startDate: selectionRangeFormatted.dateFrom,
            endDate: selectionRangeFormatted.dateTill,
        };
        try {
            const data = await getAdvertiserPixelStats(() => {}, tempRaw);
            console.log(data, "=getAdvertiserPixelStats= request");
            setConversionOptions((prev) => ({
                ...prev,
                advertiserPixelStats: { value: data, isLoading: false },
            }));
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=getAdvertiserPixelStats= request error");
            setConversionOptions((prev) => ({
                ...prev,
                advertiserPixelStats: { value: [], isLoading: false },
            }));
        }
        // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
    };

    const _conversionTagEmail = async ({
        setIsLoading = () => {},
        callback = () => {},
        conversionName = "",
        blockChainPixelID = "",
        conversionTagID = "",
        conversionAPIKey = "",
        developerEmail = "",
        userEmail = "",
    }) => {
        setIsLoading(true);
        const tempRaw = {
            // organizationID,
            conversionName,
            blockChainPixelID,
            conversionTagID,
            conversionAPIKey,
            developerEmail,
            userEmail,
        };
        try {
            const data = await conversionTagEmail(() => {}, tempRaw);
            callback(data);
            successAlert("Email sent successfully!");
            console.log(data, "=conversionTagEmail= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=conversionTagEmail= request error");
        }
        setIsLoading(false);
    };
    const _OrganizationAdditionalData = async (props) => {
        const {
            setIsLoading = () => {},
            callback = () => {},
            setQualificationState = () => {},
            name = "",

            companyName = "",
            _category = "",
            marektingBudget = "",

            websiteURL = "",
            marektingGoal = [],
            AITargeting = false,
            _chainTargeting = [],
            _walletTargeting = [],
            _smartContractAddress = "",
        } = props;
        setIsLoading(true);
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            organizationID,
            name,

            companyName,
            _category,
            marektingBudget,

            websiteURL,
            marektingGoal,
            AITargeting,
            _chainTargeting,
            _walletTargeting,
            _smartContractAddress,
        };
        try {
            const data = await OrganizationAdditionalData(() => {}, tempRaw);
            callback(data);
            successAlert("Organization additional data updated successfully.");
            localStorage.setItem("isAdditionalFormCompleted", true);
            getLoadUserProfile();
            setTimeout(() => {
                navigate("/campaigns");
            }, 1500);
            console.log(data, "=OrganizationAdditionalData= request");
        } catch (err) {
            setQualificationState((prev) => ({ ...prev, isAdditionalDataFormCompleted: false }));
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=OrganizationAdditionalData= request error");
        }
        setIsLoading(false);
    };
    const _updateCampaignManualData = async (props) => {
        const {
            setIsLoading = () => {},
            callback = () => {},
            campaignID = "",
            itemName = "",
            amount_spent,
            clicks,
            impressions,
            conversions,
        } = props;
        setIsLoading(true);
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            organizationID,
            campaignID,
            manualDataUpdates: [
                {
                    [itemName]: { spent: amount_spent, clicks, impressions, conversions },
                },
            ],
        };
        try {
            const data = await updateCampaignManualData(() => {}, tempRaw);
            callback(data);
            successAlert("Progression data updated successfully!");
            console.log(data, "=updateCampaignManualData= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=updateCampaignManualData= request error");
        }
        setIsLoading(false);
    };

    // LOAD USER PROFILE DATA
    const getLoadUserProfile = async () => {
        if (!auth.currentUser) return;
        // setIsLoading((prev) => ({ ...prev, userProfileIsLoading: true}));
        setIsLoading((prev) => ({ ...prev, userProfileIsLoading: true, campaignDataIsLoading: true }));
        const temRaw = {
            uuid: auth.currentUser.uid,
        };

        try {
            const data = await loadUserProfile(() => {}, temRaw);
            console.log(data, "=loadUserProfile= request");
            const token = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/token.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    client_id: "blockchain-ads",
                    client_secret: "blockchain-adsapi",
                    grant_type: "client_credentials",
                }),
            });

            const access_token = await token.json();

            const revivedata = await fetch(
                `${process.env.REACT_APP_API_ENDPOINT}/advertiser/advertiser-signup.php?access_token=` +
                    access_token.access_token,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        username: data.res_data.userName,
                        password: data.res_data.userName,
                        email: data.res_data.userEmail,
                        name: data.res_data.currentOrganization.name,
                        firebaseuserid: data.res_data.userId,
                        firebaseorganizationid: data.res_data.currentOrganization.id,
                    }),
                }
            );

            const revivedataJSON = await revivedata.json();
            // console.log(revivedataJSON);

            if (data.res_data.currentOrganization.id == "d2a4a5c0-05f3-4673-93bd-ec592e63346d") {
                data.res_data.currentOrganization.balance = -4.19;
            }
            setRequestedData((prev) => ({
                ...prev,
                userProfileData: data.res_data,
                reportsData: data.res_data.reportsData || [],
                // blockchainData: []
            }));
            const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));

            getLoadCampaignData(data.res_data.currentOrganization.id, 1, campaignsStates?.pageSize || 10);

            const isAdminTeamMember = data.res_data.allOrganization.find(
                (member) => member.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
            );

            setIsAdminTeamMember(isAdminTeamMember);
        } catch (err) {
            // if (err?.response?.data?.res_data?.redirect) {
            //     navigate("/sign-up");
            //     errorAlert(
            //         "Oops! It seems you haven't signed up with Google yet. Please create an account first before attempting to sign in."
            //     );
            //     // successAlert('Creating a google account...')
            //     // handleGoogleSignUp()
            // } else {
            //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            // }
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");

            console.error(err, "=loadUserProfile= request error");
            setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: false }));
            handleSignOut();
        }
        setIsLoading((prev) => ({ ...prev, userProfileIsLoading: false }));
    };
    // LOAD CAMPAIGN DATA

    const getLoadCampaignData = async (currentOrganizationID, page = 1, pageSize = 10) => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: true }));
        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };
        const storedPageSize = JSON.parse(localStorage.getItem("campaignsStates"))?.pageSize || pageSize;

        const tempRaw = {
            uuid: auth.currentUser.uid,
            currentOrganizationID,
            page,
            pageSize: storedPageSize,
            dateFrom: selectionRangeFormatted.dateFrom,
            dateTill: selectionRangeFormatted.dateTill,
        };
        try {
            let data = await loadCampaignData(() => {}, tempRaw);
            console.log(data, "=loadCampaignData= request");
            // data.res_data.currentOrganizationCampaigns =  data.res_data.currentOrganizationCampaigns.sort((a, b) => b.id - a.id);

            // TEMPORARY STATMENT start
            if (currentOrganizationID == "d2a4a5c0-05f3-4673-93bd-ec592e63346d") {
                const trustDiceUSCampaign = data.res_data.currentOrganizationCampaigns.find(
                    (item) => item.name == "TrustDice - US - UserAcqusition"
                );
                if (trustDiceUSCampaign) {
                    const spreadSheetProgressionData = {
                        "05/06/2024": {
                            amount_spent: 0,
                            clicks: 509,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 112080,
                            amount_spent: 503.91,
                        },
                        "06/06/2024": {
                            clicks: 507,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 166540,
                            amount_spent: 501.44,
                        },
                        "07/06/2024": {
                            amount_spent: 0,
                            clicks: 481,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 117788,
                            amount_spent: 475.2,
                        },
                        "08/06/2024": {
                            clicks: 390,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 111021,
                            amount_spent: 312.55,
                        },
                        "09/06/2024": {
                            clicks: 375,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 112316,
                            amount_spent: 320.63,
                        },
                        "10/06/2024": {
                            clicks: 363,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 120311,
                            amount_spent: 308.63,
                        },
                        "11/06/2024": {
                            clicks: 415,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 122345,
                            amount_spent: 300.26,
                        },
                        "12/06/2024": {
                            clicks: 436,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 131215,
                            amount_spent: 321.58,
                        },
                        "13/06/2024": {
                            clicks: 444,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 130111,
                            amount_spent: 325.3,
                        },
                        "14/06/2024": {
                            clicks: 450,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 131454,
                            amount_spent: 326.99,
                        },
                        "15/06/2024": {
                            clicks: 466,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 130215,
                            amount_spent: 330,
                        },
                        "16/06/2024": {
                            clicks: 455,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 125444,
                            amount_spent: 337.56,
                        },
                        "17/06/2024": {
                            clicks: 476,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 136852,
                            amount_spent: 342.66,
                        },
                        "18/06/2024": {
                            clicks: 483,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 140123,
                            amount_spent: 350,
                        },
                        "19/06/2024": {
                            clicks: 486,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 142111,
                            amount_spent: 348.22,
                        },
                        "20/06/2024": {
                            clicks: 480,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 138512,
                            amount_spent: 345.63,
                        },
                        "21/06/2024": {
                            clicks: 489,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 141211,
                            amount_spent: 348.55,
                        },
                        "22/06/2024": {
                            clicks: 490,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 145211,
                            amount_spent: 350.66,
                        },
                        "23/06/2024": {
                            clicks: 488,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 140494,
                            amount_spent: 349.74,
                        },
                        "24/06/2024": {
                            clicks: 491,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 140555,
                            amount_spent: 350.55,
                        },
                        "25/06/2024": {
                            clicks: 501,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 141981,
                            amount_spent: 355.11,
                        },
                        "26/06/2024": {
                            clicks: 505,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 140526,
                            amount_spent: 352.36,
                        },
                        "27/06/2024": {
                            clicks: 510,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 142331,
                            amount_spent: 355.46,
                        },
                        "28/06/2024": {
                            clicks: 520,
                            cpm: 0,
                            ctr: 0,
                            cpc: 0,
                            impressions: 145631,
                            amount_spent: 369.56,
                        },
                    };
                    let impressions = 0;
                    let clicks = 0;
                    let amount_spent = 0;
                    for (const key in spreadSheetProgressionData) {
                        if (trustDiceUSCampaign.progressionData.hasOwnProperty(key)) {
                            impressions += spreadSheetProgressionData[key].impressions;
                            clicks += spreadSheetProgressionData[key].clicks;
                            amount_spent += spreadSheetProgressionData[key].amount_spent;
                            trustDiceUSCampaign.progressionData[key] = spreadSheetProgressionData[key];
                        }
                    }
                    trustDiceUSCampaign.impressions = impressions;
                    trustDiceUSCampaign.clicks = clicks;
                    trustDiceUSCampaign.amount_spent = amount_spent;

                    // data.res_data.currentOrganizationCampaigns =
                    //     data.res_data.currentOrganizationCampaigns.map((item) =>
                    //         item.name == "TrustDice - US - UserAcqusition" ? trustDiceUSCampaign : item
                    //     );
                }
            }
            // TEMPORARY STATMENT end

            // TEMPORARY STATMENT start
            const accounts = [
                "608dfda2-59de-4495-9a25-1cc6b5eedb0c",
                "cfc829fb-d052-4724-998a-a58ddd1fa493",
                // "df5e76f7-60bc-4e2d-9821-4595f381498b",
                "9VDWIIRSZbWshlQ65iZxT6r0xHL2",
            ];
            if (accounts.includes(currentOrganizationID)) {
                data.res_data.currentOrganizationCampaigns = data.res_data.currentOrganizationCampaigns.map(
                    (item) => {
                        const progressionData = {};
                        for (const key in item.progressionData) {
                            progressionData[key] = {
                                ...item.progressionData[key],
                                impressions: item.progressionData[key].impressions * 1.5,
                                clicks: item.progressionData[key].clicks * 2,
                                // amount_spent: item.progressionData[key].clicks * 2
                            };
                        }

                        return {
                            ...item,
                            impressions: item.impressions * 1.5,
                            clicks: item.clicks * 2,
                            // amount_spent: item.amount_spent * 2,
                            progressionData,
                        };
                    }
                );

                data.res_data.dashboardSummaryData = {
                    ...data.res_data.dashboardSummaryData,
                    totalImpressions: data.res_data.dashboardSummaryData.totalImpressions * 1.5,
                    totalClicks: data.res_data.dashboardSummaryData.totalClicks * 2,
                    // totalAdSpend: data.res_data.dashboardSummaryData.totalAdSpend * 2,
                };
            }
            // TEMPORARY STATMENT end

            // // TEMPORARY STATMENT start
            // if (currentOrganizationID == "df5e76f7-60bc-4e2d-9821-4595f381498b") {
            //     const bombasticUSCampaign = data.res_data.currentOrganizationCampaigns.find(
            //         (item) => item.name == "Bombastic - SK - User Acquisition"
            //     );
            //     if (bombasticUSCampaign) {
            //         if (bombasticUSCampaign.progressionData["06/09/2024"]) {
            //             bombasticUSCampaign.progressionData["06/09/2024"] = {
            //                 ...bombasticUSCampaign.progressionData["06/09/2024"],
            //                 amount_spent: 256,
            //                 clicks: 230,

            //                 impressions: 40840,
            //             };
            //         }

            //         if (bombasticUSCampaign.progressionData["07/09/2024"]) {
            //             bombasticUSCampaign.progressionData["07/09/2024"] = {
            //                 ...bombasticUSCampaign.progressionData["07/09/2024"],
            //                 amount_spent: 300,
            //                 clicks: 286,
            //                 impressions: 50245,
            //                 cpc: 0.5,
            //             };
            //         }
            //         if (bombasticUSCampaign.progressionData["08/09/2024"]) {
            //             bombasticUSCampaign.progressionData["08/09/2024"] = {
            //                 ...bombasticUSCampaign.progressionData["08/09/2024"],
            //                 amount_spent: 380,
            //                 clicks: 345,
            //                 impressions: 62753,
            //             };
            //         }
            //         if (bombasticUSCampaign.progressionData["09/09/2024"]) {
            //             bombasticUSCampaign.progressionData["09/09/2024"] = {
            //                 ...bombasticUSCampaign.progressionData["09/09/2024"],
            //                 amount_spent: 395,
            //                 clicks: 412,
            //                 impressions: 70532,
            //             };
            //         }
            //         let impressions = 0;
            //         let clicks = 0;
            //         let amount_spent = 0;
            //         for (const key in bombasticUSCampaign.progressionData) {
            //             if (bombasticUSCampaign.progressionData.hasOwnProperty(key)) {
            //                 impressions += bombasticUSCampaign.progressionData[key].impressions;
            //                 clicks += bombasticUSCampaign.progressionData[key].clicks;
            //                 amount_spent += bombasticUSCampaign.progressionData[key].amount_spent;
            //                 bombasticUSCampaign.progressionData[key] =
            //                     bombasticUSCampaign.progressionData[key];
            //             }
            //         }
            //         bombasticUSCampaign.impressions = impressions;
            //         bombasticUSCampaign.clicks = clicks;
            //         bombasticUSCampaign.amount_spent = amount_spent;

            //         bombasticUSCampaign.CPC = 1.07;
            //     }
            // }
            // // TEMPORARY STATMENT end

            setRequestedData((prev) => ({
                ...prev,
                campaignData: { isRequested: true, ...data.res_data },
            }));
            // console.log(data, "=loadCampaignData= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=loadCampaignData= request error");
        }
        setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: false }));
    };

    const [timeRemaining, setTimeRemaining] = useState(0);

    const { handleOnClick, getSortIcon } = useSort({ requestedData, setRequestedData });

    const getLoadCampaignStatistics = async (campaignId, groupBy = "zone_id", activeTab = "") => {
        setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: true }));

        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
            dateFrom: formatDate(startDate),
            dateTill: formatDate(endDate),
        };
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const tempRaw = {
            campaignId,
            organizationID,
            groupBy,
            dateFrom: selectionRangeFormatted.dateFrom,
            dateTill: selectionRangeFormatted.dateTill,
        };
        try {
            const data = await loadCampaignStatistics(() => {}, tempRaw);
            console.log(data, "=loadCampaignStatistics= request");

            // if (organizationID == "d2a4a5c0-05f3-4673-93bd-ec592e63346d") {
            //     data.res_data.items = []
            // }

            setRequestedData((prev) => ({
                ...prev,
                campaignStatistics: { ...data.res_data, tab: activeTab },
            }));
            // switch (groupBy) {
            //     case "zone_id":
            //         handleOnClick("zone_id", "desc");
            //         break;
            //     case "banner_id" || "country_id":
            //         handleOnClick("impressions", "desc");
            //         break;
            // }
            handleOnClick("impressions", "desc");
        } catch (err) {
            // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=loadCampaignStatistics= request error");
            setRequestedData((prev) => ({
                ...prev,
                campaignStatistics: { tab: "", items: [] },
            }));
            navigate("/");
            warningAlert("Redirecting: Organization have been changed.");
        }
        setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: false }));
    };

    // LOAD BLOCKCHAIN DATA
    const getLoadBlockchainData = async (
        smartContractAddress,
        chainNetwork = "",
        page = 1,
        pageSize = 10000
    ) => {
        // "0x203C426C2cBDE770d8730FCBE64d16C8064F68FB"
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
        const tempRaw = {
            uuid: auth.currentUser.uid,
            chainNetwork,
            smartContractAddress,
            page,
            pageSize,
            order: "desc",
        };
        try {
            const data = await loadBlockchainData(() => {}, tempRaw);
            console.log(data, "=loadBlockchainData= request " + chainNetwork);

            setRequestedData((prev) => ({
                ...prev,
                blockchainData: data.res_data,
            }));
        } catch (err) {
            setRequestedData((prev) => ({
                ...prev,
                blockchainData: [],
            }));
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=loadBlockchainData= request error " + chainNetwork);
        }
        setIsLoading((prev) => ({
            ...prev,
            blockchainDataIsLoading: false,
        }));
    };

    // loadAnalyticsProfile
    const getLoadAnalyticsProfile = async (successCB = () => {}, errorCB = () => {}) => {
        if (!auth.currentUser) return;
        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID,
        };
        setIsLoading((prev) => ({ ...prev, analyticsProfileIsLoading: true }));
        try {
            const data = await loadAnalyticsProfile(() => {}, temRaw);
            console.log(data, "=loadAnalyticsProfile= request");
            setRequestedData((prev) => ({
                ...prev,
                analyticsProfileData: { ...data.res_data, isFetched: true },
                isAnalyticsConnected: true,
            }));
            successCB(data.res_data.accountSummaries);

            // getLoadAnalyticsProject(data.res_data.accountSummaries[0].account.replace(/\D/g, ''));
        } catch (err) {
            // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            setRequestedData((prev) => ({
                ...prev,
                analyticsProfileData: { isFetched: false, accountSummaries: [] },
                isAnalyticsConnected: false,
            }));
            errorCB();
            console.error(err, "=loadAnalyticsProfile= request error");
            getConnectAnalyticsAccount();
        }
        setIsLoading((prev) => ({ ...prev, analyticsProfileIsLoading: false }));
    };

    // connectAnalyticsAccount
    const getConnectAnalyticsAccount = async () => {
        if (!auth.currentUser) return;
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get("code");
        // console.log("Value of code parameter:", authCode);
        navigate("/analytics");
        if (!authCode) return;
        setIsLoading((prev) => ({ ...prev, analyticsConnectIsLoading: true }));

        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;

        const tempRaw = {
            uuid: auth.currentUser.uid,
            organizationID,
            authCode,
        };
        try {
            const data = await connectAnalyticsAccount(() => {}, tempRaw);
            console.log(data, "=connectAnalyticsAccount= request");
            setRequestedData((prev) => ({ ...prev, isAnalyticsConnected: true }));
            successAlert("Analytics connected, reloading the page...");
            // getLoadAnalyticsProfile();
            setTimeout(() => window.location.reload(), 2000);
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=connectAnalyticsAccount= request error");
        }
        setIsLoading((prev) => ({
            ...prev,
            analyticsConnectIsLoading: false,
        }));
    };

    // loadAnalyticsProject
    const getLoadAnalyticsProject = async (id) => {
        if (!auth.currentUser) return;

        const endDate = new Date(); // today
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6); // 7 days ago

        const selectionRange = JSON.parse(localStorage.getItem("analyticsSelectionRange")) || {
            startDate: formatDateToYYYYMMDD(startDate),
            endDate: formatDateToYYYYMMDD(endDate),
        };

        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;

        const temRaw = {
            uuid: auth.currentUser.uid,
            projectID: id,
            organizationID,
            ...selectionRange,
        };

        setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: true }));
        try {
            const data = await loadAnalyticsProject(() => {}, temRaw);
            console.log(data, "=loadAnalyticsProject= request");
            if (data.res_data.transformedData) {
                setRequestedData((prev) => ({
                    ...prev,
                    analyticsProjectData: data.res_data,
                }));
            } else {
                setRequestedData((prev) => ({
                    ...prev,
                    analyticsProjectData: {
                        mapData: {
                            rows: [],
                        },
                        tableData: {
                            rows: [],
                        },
                        transformedData: [],
                    },
                }));
            }
        } catch (err) {
            // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            warningAlert(err?.response?.data?.res_msg || "No Data To Show!");
            console.error(err, "=loadAnalyticsProject= request error");
            setRequestedData((prev) => ({
                ...prev,
                analyticsProjectData: {
                    mapData: {
                        rows: [],
                    },
                    tableData: {
                        rows: [],
                    },
                    transformedData: [],
                },
            }));
        }
        setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: false }));
    };

    // disconnectAnalyticsAccount
    const getDisconnectAnalyticsAccount = async () => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, analyticsDisconnectIsLoading: true }));

        // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
        const organizationID = requestedData.userProfileData.currentOrganization.id;

        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID,
        };
        try {
            const data = await disconnectAnalyticsAccount(() => {}, temRaw);
            console.log(data, "=disconnectAnalyticsAccount= request");
            // setRequestedData((prev) => ({ ...prev, analyticsProfileData: [] }));
            setRequestedData((prev) => ({
                ...prev,
                analyticsProfileData: { accountSummaries: [] },
                isAnalyticsConnected: false,
            }));
            successAlert("Analytics disconnected");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=disconnectAnalyticsAccount= request error");
        }
        setIsLoading((prev) => ({ ...prev, analyticsDisconnectIsLoading: false }));
    };

    // updateUserInfo
    const getUpdateUserInfo = async (info, successCallback = () => {}) => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
        const temRaw = {
            uuid: auth.currentUser.uid,
            info,
        };
        try {
            const data = await updateUserInfo(() => {}, temRaw);
            successAlert("personal information updated");
            successCallback();
            console.log(data, "=updateUserInfo= request");
            // if(auth?.currentUser) getLoadUserProfile();
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=updateUserInfo= request error");
        }
        setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
    };

    // resetEmail
    const getResetEmail = async (info) => {
        // if (!auth.currentUser) return;
        // setIsLoading((prev) => ({ ...prev, emailIsUpdating: true }));
        // const temRaw = {
        //     uuid: auth.currentUser.uid,
        //     info,
        // };
        // try {
        //     const data = await resetEmail(() => {}, temRaw);
        //     console.log(data, "=resetEmail= request");
        //     successAlert("email updated successfully");
        // } catch (err) {
        //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        //     console.error(err, "=resetEmail= request error");
        // }
        // setIsLoading((prev) => ({ ...prev, emailIsUpdating: false }));
    };

    // resetPassword
    const getResetPassword = async (info) => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, passwordIsUpdating: true }));
        const temRaw = {
            uuid: auth.currentUser.uid,
            info,
        };
        try {
            const data = await resetEmail(() => {}, temRaw);
            console.log(data, "=resetPassword= request");
            successAlert("email updated successfully");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=resetPassword= request error");
        }
        setIsLoading((prev) => ({ ...prev, passwordIsUpdating: false }));
    };

    // inviteUser
    const getInviteUser = async (invitedEmail, successCallback) => {
        if (!auth.currentUser) return;
        setIsLoading((prev) => ({ ...prev, inviteUserIsLoading: true }));
        const temRaw = {
            uuid: auth.currentUser.uid,
            organizationID: requestedData.userProfileData.currentOrganization.id,
            senderName: requestedData.userProfileData.userName,
            inviteeEmail: invitedEmail,
        };
        try {
            const data = await inviteUser(() => {}, temRaw);
            console.log(data, "=inviteUser= request");
            successAlert("user invitation sent successfully");
            successCallback();
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=inviteUser= request error");
        }
        setIsLoading((prev) => ({ ...prev, inviteUserIsLoading: false }));
    };

    const initRequests = () => {
        console.log(auth?.currentUser, " auth.currentUser");
        getLoadUserProfile();
    };

    const getShortText = (originalText) => {
        const charsToShow = 12; // You can adjust this value based on your preference
        const midPoint = originalText.length / 2;
        const startText = originalText.substring(0, midPoint - charsToShow);
        const endText = originalText.substring(midPoint + charsToShow);

        return startText + "..." + endText;
    };

    function formatDate(value = null) {
        const date = value !== null ? new Date(value) : new Date();
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const formatNumber = (value) => {
        const number = parseFloat(value.replace(/,/g, ""));

        if (!isNaN(number)) {
            return number.toLocaleString();
        }

        return value;
    };

    const getDelayedDate = (_date = "", delayTime) => {
        const date = new Date(_date);
        date.setDate(date.getDate() + delayTime);
        return date;
    };

    const getDateName = ({ date = "" }) => {
        var dateString = date;

        var parts = dateString.split("/");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        var dateObject = new Date(year, month - 1, day);
        var monthName = dateObject.toLocaleString("default", { month: "short" });
        return day + " " + monthName;
    };

    const calculateMinMax = (data) => {
        console.log("Minmax: ", data);
        let minMax = {
            impressions: { min: 0, max: -Infinity },
            amount_spent: { min: 0, max: -Infinity },
            clicks: { min: 0, max: -Infinity },
            cpm: { min: 0, max: -Infinity },
            cpc: { min: 0, max: -Infinity },
        };

        Object.values(data).forEach((item) => {
            Object.keys(minMax).forEach((key) => {
                if (item[key] !== undefined) {
                    if (item[key] < minMax[key].min) minMax[key].min = item[key];
                    if (item[key] > minMax[key].max) minMax[key].max = item[key];
                }
            });
        });

        return minMax;
    };

    const normalizeChartValue = (value, minMax = { min: 0, max: Infinity }) => {
        return ((value - minMax.min) / (minMax.max - minMax.min)) * 100;
    };

    const [selectedImages, setSelectedImages] = useState([]);
    const [creatives, setCreatives] = useState([]);
    const [singleCreativeInputs, setSingleCreativeInputs] = useState({});

    // useEffect(()=>{
    //     console.log(selectedImages, ' selectedImages')
    // },[selectedImages])

    const deleteCreative = (id) => {
        let tempCreatives = [...creatives];
        tempCreatives = tempCreatives.filter((item) => item.id !== id);
        setCreatives(tempCreatives);
    };

    const defaultCampaign = {
        uuid: auth?.currentUser?.uid,
        // organizationID: requestedData.userProfileData.currentOrganization.id,
        campaignInfo: {
            campaignName: "",
            targetURL: "",
            campaignType: [],
            budget: "360",
            maxCPC: "0.50", //formatCurrency(state.maxCPC,3,"decimal")
            // startDate: formatDate(),
            // endDate: formatDate(getDelayedDate(30)),
            startDate: "",
            endDate: "",
            timezone: 3,
            smartContractAddress: "",
            chainTargeting: [],
            category: [],
            web3Audience: [],
            walletTargeting: [],
            deviceType: [],
            operativeSystem: [],
            browser: [],
            countriesTargeting: [],
        },
        creatives: [],
    };

    const [campaignState, setCampaignState] = useState(defaultCampaign);

    // useEffect(() => {
    //     console.log(campaignState, " campaignState");
    // }, [campaignState]);

    const [campaignWizardStatus, setCampaignWizardStatus] = useState("create"); //edit

    // const [campaigns, setCampaigns] = useState(null);
    const [activeCampaign, setActiveCampaign] = useState(null);
    const [activeBlockchain, setActiveBlockchain] = useState(null);
    const [markedCampaigns, setMarkedCampaigns] = useState([]);
    const [activeInfoId, setActiveInfoId] = useState(null);

    // useEffect(()=>{
    //     console.log(activeBlockchain, ' activeBlockchain')
    // },[activeBlockchain])

    useEffect(() => {
        const campaigns = requestedData.campaignData.currentOrganizationCampaigns;
        setActiveCampaign(campaigns[0]);
        if (campaigns[0]) setMarkedCampaigns([campaigns[0]]);
        // setCampaigns(requestedData.campaignData.currentOrganizationCampaigns);

        setActiveBlockchain(campaigns.find((item) => item.extraData.smartContractAddress));
    }, [requestedData.campaignData.currentOrganizationCampaigns]);

    const campaignsState = useCampaignsState({ requestedData, getLoadCampaignData });
    const blockchainsState = useBlockchainsState({
        requestedData,
        setRequestedData,
        setIsLoading,
        activeBlockchain,
        getLoadBlockchainData,
    });
    const reportsState = useReportsState();
    const analyticsState = useAnalyticsState({ requestedData });
    const marketplaceState = useMarketplaceState();
    const trackingState = useTrackingState();

    const handleSignOut = async (message = "You have successfully signed out!") => {
        const { resetCampaignsState } = campaignsState;
        const { resetReportsPagination } = reportsState;
        try {
            await auth.signOut();
            localStorage.clear();
            setRequestedData(DEFAULTREQUESTEDDATA);
            resetCampaignsState();
            resetReportsPagination();
            // navigate("/sign-in");
            successAlert(message);
        } catch (error) {
            errorAlert("Error:", error.message);
            console.error("Error:", error.message);
        }
    };

    return (
        <Context.Provider
            value={{
                state,
                ...state,
                setState,
                campaignsState,
                blockchainsState,
                reportsState,
                setRequestedData,
                // isLoggedIn,
                // setIsLoggedIn,
                animations,
                showSidebar,
                setShowSidebar,
                // isProfileImageSelected,
                // setIsProfileImageSelected,
                inviteData,
                setInviteData,
                setRefCode,
                // profileImagePlaceholder,
                // setProfileImagePlaceholder,
                // profileImage,
                // setProfleImage,
                placeholders,
                setPlaceholders,
                successAlert,
                errorAlertExpanded,
                warningAlertExpanded,
                successAlertExpanded,
                errorAlert,
                warningAlert,
                handleGoogleSignIn,
                isGoogleSignUpLoading,
                isGoogleSignInLoading,
                handleGoogleSignUp,
                handleFacebookSignIn,
                // allCurrencies,
                // setAllCurrencies,
                showCampaingWizardDrawer,
                setShowCampaignWizardDrawer,
                showProfileDrawer,
                setShowProfileDrawer,
                showDepositModal,
                setShowDepositModal,
                campaignWizardStatus,
                setCampaignWizardStatus,
                isLoading,
                setIsLoading,
                requestedData,
                authUser,
                setAuthUser,
                getShortText,
                formatDate,
                getDelayedDate,
                formatNumber,
                getDateName,
                calculateMinMax,
                normalizeChartValue,
                navbarRef,
                marketplaceTheadRef,
                marketplaceScrollItems,
                // ANALYTICS
                analyticsState,

                // MAREKETPLACE
                marketplaceState,

                trackingState,

                // CREATIVES
                creatives,
                setCreatives,
                singleCreativeInputs,
                setSingleCreativeInputs,
                selectedImages,
                setSelectedImages,
                deleteCreative,
                //

                campaignState,
                setCampaignState,
                defaultCampaign,
                activeCampaign,
                setActiveCampaign,
                markedCampaigns,
                setMarkedCampaigns,
                activeBlockchain,
                setActiveBlockchain,

                activeInfoId,
                setActiveInfoId,
                isDarkModeEnabled,
                isAdminTeamMember,
                setIsAdminTeamMember,
                setIsDarkModeEnabled,

                // REQUESTS
                initRequests,
                getConnectAnalyticsAccount,
                getDisconnectAnalyticsAccount,
                getLoadAnalyticsProfile,
                getLoadUserProfile,
                getLoadAnalyticsProject,
                // getReturnAnalyticsProject,
                getUpdateUserInfo,
                getResetEmail,
                getResetPassword,
                getInviteUser,
                getLoadCampaignData,
                // getLoadCampaignStatistics,
                getLoadCampaignStatistics,
                // getStartOrStopCampaignZone,
                getLoadBlockchainData,

                // campaigns,
                // setCampaigns,
                handleSignOut,
                timeRemaining,
                handleOnClick,
                getSortIcon,
                CreatePixelTagRequest,
                CreateConversionPixelRequest,
                EditConversionPixelRequest,
                DeleteConversionPixelRequest,
                getAdvertiserPixelStatsRequest,
                conversionOptions,
                setConversionOptions,
                updateAccountBCANameRequest,
                _updateCampaignManualData,
                // guideTutorials,
                // getUpdateGuideTutorials,
                _getInsightsImages,
                _conversionTagEmail,
                _OrganizationAdditionalData,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export const useGlobalContext = () => useContext(Context);
