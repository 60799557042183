import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Carousel, MarketplaceCard, Breadcrumbs } from "../../../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../../context";
import localData from "../../../../localData";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShoppingPanel from "../shopping-panel/ShoppingPanel";
import OrderModal from "../orderModal/OrderModal";
import useFormatter from "../../../../hooks/useFormatter";

export default function ShoppingCart() {
    useEffect(() => {
        document.title = "Marketplace";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content = "Markeplace description";
    }, []);
    const { fade } = useGlobalContext().animations;
    const { marketplaceState, requestedData } = useGlobalContext();
    const { addCommas } = useFormatter();
    const {
        publications,
        setPublications,
        MarketplaceCardDetails,
        displayMarketplaceDetailsModal,
        setDisplayMarketplaceDetailsModal,
    } = marketplaceState;

    const { edit, question, password, support, userShield, heart, shoppingCart, trashV2 } = localData.svgs;
    const { productExample, emptyCart } = localData.images;
    const { EmptyCartIcon } = localData.svgs;

    const navigate = useNavigate();

    const [packages, setPackages] = useState([]);

    useEffect(() => {
        let tempPackages = [...publications];
        tempPackages = tempPackages.filter((item) => item.isInShoppingCart && { ...item });
        setPackages(tempPackages);
    }, [publications]);

    const hadleRemoveAllFromShoppingCart = () => {
        let tempPublications = [...publications];
        tempPublications = tempPublications.map((item) => ({ ...item, isInShoppingCart: false }));
        setPublications(tempPublications);
    };

    const [showPaymentModal, setShowPaymentModal] = useState("none");

    return (
        <motion.main {...fade} className="main shopping-cart-page">
            <Navbar title="Shopping Cart" />
            <ShoppingPanel />

            <Breadcrumbs items={[{ name: "PR", href: "/additional-products/pr" }, { name: "shopping-cart" }]} />
            <br />

            <h2 className="display-2">Shopping cart</h2>
            <div className="shopping-cart-header">
                <div className="shopping-cart-items-count">{packages.length} items</div>
                <div className="setup-btns">
                    {packages.length ? (
                        <Button
                            className="setup-btn"
                            startIcon={trashV2}
                            name="Delete all"
                            color="danger"
                            onClick={hadleRemoveAllFromShoppingCart}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <br />
            <hr className="hr-divider" />
            <br />
            <br />
            <div className="row">
                {!packages.length ? (
                    <div className="empty-cart-icon">
                        <EmptyCartIcon />
                    </div>
                ) : (
                    <>
                        <div className="col col-left">
                            {packages.map((item, index) => {
                                return (
                                    <MarketplaceCard
                                        key={index}
                                        {...item}
                                        color="success"
                                        icons={{ favorite: false, shop: false, removeShoppingCart: true }}
                                    />
                                );
                            })}
                            <Modal
                                Toggler={() => {}}
                                Child={MarketplaceCardDetails}
                                display={displayMarketplaceDetailsModal}
                                setDisplay={setDisplayMarketplaceDetailsModal}
                                className="modal-dialog-centered  marketplace-details-modal"
                            />
                        </div>
                        <div className="col col-right">
                            <div className="submit-order">
                                <div className="price">
                                    <span className="price-text">Total:</span>{" "}
                                    <strong className="price-amount">
                                        $
                                        {addCommas(
                                            packages
                                                .reduce((accumulator, item) => {
                                                    return accumulator + parseInt(item.totalPrice, 10);
                                                }, 0)
                                                .toString()
                                        )}
                                    </strong>
                                </div>
                                <br />
                                <hr />
                                <br />
                                <Modal
                                    Child={OrderModal}
                                    className="pr-order-modal modal-dialog-centered"
                                    display={showPaymentModal}
                                    setDisplay={setShowPaymentModal}
                                    Toggler={() => (
                                        <Button
                                            disabled={!requestedData.userProfileData.currentOrganization.id}
                                            className="submit-btn"
                                            name="Proceed order"
                                            size="lg"
                                            variant="contained"
                                            color="marketplace"
                                            onClick={() => setShowPaymentModal("block")}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </motion.main>
    );
}
