// import React from "react";

// export default function AdsCard({ title, images, sizes }) {
//     return (
//         <div className="card ads-card">
//             <h4 className="card-title">{title}</h4>
//             <div className="card-body">
//                 <div className="card-images">
//                     {!images.length
//                         ? "no images"
//                         : images.map((image, index) => {
//                               if (index > 3) return;
//                               return (
//                                   <div className="card-image" key={index}>
//                                       <img src={image} alt={title} />
//                                   </div>
//                               );
//                           })}
//                 </div>
//                 {images.length - 3 > 0 && <div className="card-text">+{images.length - 3} more</div>}
//             </div>
//             <div className="card-footer">
//                 <h4 className="card-footer-title">Sizes</h4>
//                 <div className="wrapper">
//                     {!sizes.length
//                         ? "no sizes"
//                         : sizes.map((size, index) => (
//                               <div className="card-size" key={index}>
//                                   {size}
//                               </div>
//                           ))}
//                 </div>
//             </div>
//         </div>
//     );
// }

import React from "react";
import localData from "../../../localData";
import { Button } from "../../../components";
import { useGlobalContext } from "../../../context";
// import { v4 as uuidv4 } from "uuid";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";

export default function AdsCard({ title, image, size, id, readOnly = false }) {
    const { close } = localData.svgs;
    const { deleteCreative } = useGlobalContext();

    // const [id, setId] = useState(`tooltip-ads-card-${uuidv4()}`);
    return (
        <div className="card ads-card">
            <h4 className="card-title">
                <span className="card-title-content">{title}</span>
                <Button
                    style={readOnly ? { opacity: 0, pointerEvents: "none" } : {}}
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    icon={close}
                    size="sm"
                    onClick={() => deleteCreative(id)}
                />
            </h4>
            <div className="card-body">
                <div className="card-images">
                    <div
                        className="card-image"
                        data-tooltip-float={true}
                        data-tooltip-id={`tooltip-ads-card`} 
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <div className="image-wrapper">
                                <img src={image} />
                            </div>
                        )}
                    >
                        <img src={image} alt={title} />
                    </div>
                </div>
            </div>
           
            <div className="card-footer">
                <h4 className="card-footer-title">Size</h4>
                <div className="wrapper">
                    <div className="card-size">{size}</div>
                </div>
            </div>
        </div>
    );
}
