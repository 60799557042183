import React, { useState, useEffect, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Field } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import { auth } from "../../../config/firebase"

export default function ResetEmail({}) {
    const { getUpdateUserInfo,handleSignOut, isLoading, errorAlert } = useGlobalContext();
    const { preloaderBig } = localData.images;

    const [state, setState] = useState({
        new_email: "",
        confirm_new_email: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const resetState = () => {
        setState({
            new_email: "",
            confirm_new_email: "",
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.new_email !== state.confirm_new_email) return errorAlert("Your emails don't match.");
        getUpdateUserInfo({ email: state.new_email }, () => {
            // resetState();
            handleSignOut('Email updated. Re-login for security.')
        });
    };

    return (
        <div className="reset-email">
            <form className="form" onSubmit={handleSubmit}>
                <div className="form-header">
                    <h2 className="form-title">Reset your email</h2>
                </div>

                <Field
                    name="new_email"
                    value={state.new_email}
                    type="email"
                    label="New Email"
                    color="secondary"
                    placeholder="eg: mail@blockchain-ads.com"
                    required={true}
                    size="sm"
                    callback={handleOnChange}
                />

                <Field
                    name="confirm_new_email"
                    value={state.confirm_new_email}
                    type="email"
                    label="Confirm New Email"
                    color="secondary"
                    placeholder="eg: mail@blockchain-ads.com"
                    required={true}
                    size="sm"
                    callback={handleOnChange}
                />

                <Button
                    className="submit-btn"
                    name="Update"
                    color="primary"
                    variant="contained"
                    size="sm"
                    disabled={
                        state.new_email == "" ||
                        state.confirm_new_email === "" ||
                        isLoading.userInfoIsUpdating
                    }
                    icon={
                        isLoading.userInfoIsUpdating ? (
                            <span className="endIcon">
                                <img src={preloaderBig} />
                            </span>
                        ) : null
                    }
                />
            </form>
        </div>
    );
}
