import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const endDate = new Date(); // today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
    startDate,
    endDate,
    key: "selection",
};

const ITEMSPERPAGE = [
    { title: 5, isSelected: false, id: uuidv4() },
    { title: 10, isSelected: true, id: uuidv4() },
    { title: 15, isSelected: false, id: uuidv4() },
];

export default function useAnalyticsState({requestedData}) {
    const [analyticsAccount, setAnalyticsAccount] = useState([]);
    const [analyticsProperties, setAnalyticsProperties] = useState([]);

    const [analyticsData, setAnalyticsData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(10);

    const [itemsPerPage, setItemsPerPage] = useState(ITEMSPERPAGE);

    const resetPagination = () => {
        setPage(1);
        setPageSize(10);
        setTotalPages(10);
        setItemsPerPage(ITEMSPERPAGE);
    };

    useEffect(() => {
        const analyticsStates = JSON.parse(localStorage.getItem("analyticsStates"));
        if (analyticsStates?.pageSize) {
            let tempItemsPerPage = [...itemsPerPage];
            tempItemsPerPage = tempItemsPerPage.map((item) => ({
                title: item.title,
                isSelected: item.title === analyticsStates.pageSize,
                id: item.id,
            }));
            setItemsPerPage(tempItemsPerPage);
            setPageSize(analyticsStates.pageSize);
        }
    }, []);

    // CALENDAR
    const [selectionRange, setSelectionRange] = useState(SELECTIONRANGE);

    useEffect(() => {
        const selectionRange = JSON.parse(localStorage.getItem("analyticsSelectionRange"));

        if (selectionRange) {
            const { startDate, endDate } = selectionRange;
            setSelectionRange({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                key: "selection",
            });
        }
    }, []);

    // const tempAggregatedData = [
    //     {
    //         id: '43245325324fsdfk',
    //         activeUsers: 259765,
    //         conversions: 0,
    //         engagedSessions: 595,
    //         engagementRate: 0.22671071331954018,
    //         eventCount: 955092,
    //         firstUserSource: "google",
    //         newUsers: 260537,
    //         sessions: 262449,
    //         totalRevenue: 0,
    //         userEngagementDuration: 22499,
    //         daily_data: [
    //             {
    //                 date: '2024-04-20',
    //                 activeUsers: 765,
    //                 conversions: 0,
    //                 engagedSessions: 95,
    //                 engagementRate: 0.21954018,
    //                 eventCount: 8092,
    //                 newUsers: 537,
    //                 sessions: 9,
    //                 totalRevenue: 0,
    //                 userEngagementDuration: 9,
    //             },
    //             {
    //                 date: '2024-04-21',
    //                 activeUsers: 65,
    //                 conversions: 0,
    //                 engagedSessions: 95,
    //                 engagementRate: 0.214018,
    //                 eventCount: 802,
    //                 newUsers: 57,
    //                 sessions: 9,
    //                 totalRevenue: 0,
    //                 userEngagementDuration: 2,
    //             },
    //             {
    //                 date: '2024-04-22',
    //                 activeUsers: 5,
    //                 conversions: 0,
    //                 engagedSessions: 5,
    //                 engagementRate: 0.4018,
    //                 eventCount: 92,
    //                 newUsers: 57,
    //                 sessions: 9,
    //                 totalRevenue: 0,
    //                 userEngagementDuration: 9,
    //             },
    //         ]
    //     },
    // ];

    const [activeAnalytic, setActiveAnalytic] = useState({});
    const [activeAnalyticRows,setActiveAnalyticRows] = useState([])

    useEffect(() => {
        setActiveAnalytic(requestedData.analyticsProjectData.transformedData[0]);
    }, [requestedData.analyticsProjectData.transformedData]);

    return {
        analyticsAccount,
        setAnalyticsAccount,
        analyticsProperties,
        setAnalyticsProperties,

        analyticsData,
        setAnalyticsData,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalPages,
        setTotalPages,
        itemsPerPage,
        setItemsPerPage,
        resetPagination,

        selectionRange,
        setSelectionRange,
        activeAnalytic,
        setActiveAnalytic,
        activeAnalyticRows,setActiveAnalyticRows
    };
}
