import React, { useState, useEffect, useRef } from "react";
import localData from "../../../localData";
import { Button, Field } from "../../";
import { useGlobalContext } from "../../../context";
import { marked } from 'marked';

const localRequest = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve("bot response");
        }, 2000);
    });
};

const getDate = () => {
    let date = new Date() ;

    let seconds = date.getSeconds();
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let hour = date.getHours().toString().padStart(2, '0');

    let year = date.getFullYear();
    let month = date.getMonth(); // beware: January = 0; February = 1, etc.
    let day = date.getDate();

    let dayOfWeek = date.getDay(); // Sunday = 0, Monday = 1, etc.
    let milliSeconds = date.getMilliseconds();

    return `${hour}:${minutes}`;
};

const BouncingDotsLoader = (props) => {
    return (
        <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

const Message = ({ message = "message", sender = "", date = "" }) => {
    const { errorAlert, requestedData, isDarkModeEnabled } = useGlobalContext();

    const [isUser, setIsUser] = useState(false);
    const { next } = localData.images;

    useEffect(() => {
        setIsUser(sender.toLowerCase() === requestedData.userProfileData.userName.toLowerCase());
    }, []);

    // const formatMessage = (message) => {
    //     let formattedMessage = message.replace(
    //         /(https?:\/\/[^\s]+)/g,
    //         '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>' // Detect and render hyperlinks
    //     );
    //     formattedMessage = formattedMessage.replace(/\n/g, "<br>"); // Replace newline characters with <br> tags

    //     formattedMessage = formattedMessage.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"); // Bold text: **text**
    //     formattedMessage = formattedMessage.replace(/\*(.*?)\*/g, "<em>$1</em>"); // Italic text: *text*
    //     formattedMessage = formattedMessage.replace(/__(.*?)__/g, "<u>$1</u>"); // Underline text: __text__
    //     formattedMessage = formattedMessage.replace(/~~(.*?)~~/g, "<s>$1</s>"); // Strikethrough text: ~~text~~
    //     formattedMessage = formattedMessage.replace(/`(.*?)`/g, "<code>$1</code>"); // Code block: `code`

    //     formattedMessage = formattedMessage.replace(
    //         /(https?:\/\/.*?\.(?:jpg|jpeg|gif|png))/g,
    //         '<img src="$1" alt="Image">' // Detect and render inline images
    //     );

    //     formattedMessage = formattedMessage.replace(/^\s*-\s(.*)$/gm, "<li>$1</li>"); // Detect and render bulleted lists
    //     formattedMessage = formattedMessage.replace(/^\s*\d+\.\s(.*)$/gm, "<li>$1</li>"); // Detect and render numbered lists

    //     formattedMessage = `<p>${formattedMessage}</p>`; // Wrap the whole message in <p> tags

    //     return <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />;
    // };

    const formatMessage = (message) => {
        const formattedMessage = marked.parse(message);
    
        return <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />;
    };
    

    return (
        <div
            className={`message ${isUser ? "user" : "chatbot"} ${isDarkModeEnabled ? "dark-mode-style" : ""}`}
        >
            <div className="col col-left">
                <img
                    className="message-icon"
                    src={isUser ? requestedData.userProfileData.profile_image : next}
                    alt=""
                />
            </div>
            <div className="col col-right">
                <div className="details">
                    <div className="details-identity">{sender}</div>
                    <div className="details-date">{date}</div>
                </div>
                {/* <div className="message-text">{message}</div> */}
                <div className="message-text">{isUser ? message : formatMessage(message)}</div>
            </div>
        </div>
    );
};

export default function BasicChat({ callback = localRequest }) {
    const { errorAlert, requestedData } = useGlobalContext();
    const [messages, setMessages] = useState([
        { message: "Hello there!", sender: "N.E.X.T", date: getDate() },
        { message: "What brings you here today?", sender: "N.E.X.T", date: getDate() },
    ]);

    const [value, setValue] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const chatLogRef = useRef(null);

    const { chatSend, close } = localData.svgs;
    // const { ellipsisPreloader } = localData.images;

    const createBotMessage = async (newMessages) => {
        const response = await callback(newMessages);
        return { message: response, sender: "N.E.X.T", date: getDate() };
    };

    const createUserMessage = () => {
        return { message: value, sender: requestedData.userProfileData.userName, date: getDate() };
    };

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleSend = async () => {
        const userMessage = createUserMessage();
        const newMessages = [...messages, userMessage];
        setMessages(newMessages);
        setValue("");
        setIsTyping(true);
        const botMessage = await createBotMessage(newMessages);
        setMessages([...newMessages, botMessage]);
        setIsTyping(false);
    };

    const onKeyDown = (e) => {
        if (e.key !== "Enter") return;
        // e.target.blur()
        handleSend();
    };

    useEffect(() => {
        chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }, [messages]);

    return (
        <div className="basic-chat">
            <div className="basic-chat-header">
                <div className="container">
                    <h2 className="basic-chat-title">I am N.E.X.T, your marketing AI agent!</h2>
                </div>
                {/* <Button className="close-btn" variant="circle" icon={close} /> */}
            </div>

            <div className="basic-chat-body">
                <div className="chat-log use-parent-height" ref={chatLogRef}>
                    <div className="container">
                        {!messages || !messages.length
                            ? "empty"
                            : messages.map((item, index) => <Message key={index} {...item} />)}
                        {/* {isTyping && <img className="load" src={ellipsisPreloader} alt="" />} */}
                        {isTyping && <BouncingDotsLoader />}
                    </div>
                </div>
            </div>

            <div className="basic-chat-footer">
                <div className="container">
                    <div className={`wrapper ${isTyping ? "on-hold" : ""}`}>
                        <Field
                            value={value}
                            variant="text"
                            placeholder="Write a message..."
                            color="secondary"
                            callback={handleChange}
                            onKeyDown={onKeyDown}
                        />
                        <Button
                            className="send-btn"
                            icon={chatSend}
                            variant="contained"
                            onClick={handleSend}
                            disabled={!value}
                            color="dark"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

