import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import {
    Navbar,
    Button,
    Select,
    Geochart,
    TrafficSourcesTable,
    SocialMetrics,
    Field,
    Testimonials,
} from "../../../components";
import useFetch from "../../../hooks/useFetch";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";
import { auth, googleProvider } from "../../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

let timeout = "";

export default function Invitation() {
    const { fade } = useGlobalContext().animations;
    const { isLoggedIn, setIsLoggedIn, errorAlert, successAlert, getLoadUserProfle, warningAlert } =
        useGlobalContext();

    const navigate = useNavigate();

    const { acceptInvite, rejectInvite } = useFetch();
    const { disconnect } = localData.svgs;
    const { preloaderBig } = localData.images;
    const { logoDark, google, facebook } = localData.images;
    const [acceptInvitationisLoading, setAcceptInvitationIsLoading] = useState(false);
    const [rejectInvitationisLoading, setRejectInvitationIsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // console.log(auth?.currentUser?.email, 'sign in page');

    function getParams() {
        const fullHref = window.location.href;
        let href = fullHref.split("?");

        if (href.length > 1) {
            href = href[1];
            const paramData = href.split("&");

            return {
                uuid: auth.currentUser.uid,
                organizationID: paramData[0].split("=")[1],
            };
        } else {
            return null;
        }
    }

    const redirect = ({ error = true, message = "" } = {}) => {
        error ? errorAlert(message || "Not Invited") : successAlert(message || "Invited");

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            window.location = '/'
        }, 3000);
    };

    const handleAccept = async (raw) => {
        if (!raw) return redirect();
        setAcceptInvitationIsLoading(true);
        setIsLoading(true);
        try {
            const data = await acceptInvite(() => {}, raw);
            redirect({ error: false, message: "Invitation accepted successfully!" });
            console.log(data, "=inviteUser= request");
            // getLoadUserProfle()
        } catch (err) {
            redirect({ error: true, message: err?.response?.data?.res_msg || "Internal Server Error" });
            console.error(err, "=inviteUser= request error");
        }
        setAcceptInvitationIsLoading(false);
        setIsLoading(false);
    };

    const handleReject = async (raw) => {
        if (!raw) return redirect();
        setRejectInvitationIsLoading(true);
        setIsLoading(true);
        try {
            const data = await rejectInvite(() => {}, raw);
            redirect({ error: false, message: "Invitation rejected successfully!" });
            console.log(data, "=rejectInvite= request");
        } catch (err) {
            redirect({ error: true, message: err?.response?.data?.res_msg || "Internal Server Error" });
            console.error(err, "=rejectInvite= request error");
        }
        setRejectInvitationIsLoading(false);
        setIsLoading(false);
    };

    useEffect(() => {
        if (!auth?.currentUser) {
            warningAlert("Please log in to your account first, then use the link from your email.");
            navigate("/sign-in");
        }
    }, []);

    if (!auth.currentUser) return "";
    return (
        <motion.main {...fade} className="main invitation-page">
            <div className="container">
                <form className="form" onSubmit={(e) => e.preventDefault()}>
                    <div className="wrapper">
                        <img className="logo" src={logoDark} />

                        <h2 className="form-title">Do you want to accept this invitation</h2>
                        <p className="form-description">
                            Accepting the invitation grant you access to the resource. Rejecting or ignoring
                            the invitation will not grant you access, and keeps you hidden from the inviter.
                        </p>

                        <div className="btn-group">
                            <Button
                                className="submit-btn"
                                name="Accept invitation"
                                color="primary"
                                variant="contained"
                                size="lg"
                                icon={
                                    acceptInvitationisLoading ? (
                                        <span className="endIcon">
                                            <img src={preloaderBig} />
                                        </span>
                                    ) : null
                                }
                                disabled={isLoading}
                                onClick={() => handleAccept(getParams())}
                            />
                            <Button
                                className="submit-btn"
                                name="Reject invitation"
                                color="danger"
                                variant="contained"
                                size="lg"
                                icon={
                                    rejectInvitationisLoading ? (
                                        <span className="endIcon">
                                            <img src={preloaderBig} />
                                        </span>
                                    ) : null
                                }
                                disabled={isLoading}
                                onClick={() => handleReject(getParams())}
                            />
                        </div>
                    </div>
                </form>

                <p className="copyright">
                    ©Blockchain-Ads. All rights reserved.
                    <Link to="/privacy-policy" underline="hover" className="link link-primary">
                        {" "}
                        Privacy Policy
                    </Link>
                </p>
            </div>
        </motion.main>
    );
}
