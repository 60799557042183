import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import { CSVLink } from "react-csv";
import { Button } from "../../../components";
import { useGlobalContext } from "../../../context";

export default function StatisticsCSV() {
    const {
        requestedData,
        isLoading,
        requestedData: {
            campaignStatistics: { items },
        },
    } = useGlobalContext();
    const { exportIcon } = localData.svgs;

    const [csvReport, setCsvReport] = useState({
        filename: "campaign_progression_data.csv",
        headers: [],
        data: [],
    });

    useEffect(() => {
        handleoDwnload();
    }, [items]);

    const formatDataToCSV = () => {
        const statisticsData = items;
        let csvData = [];

        items.forEach((item) => {
            // const { name, progressionData } = campaign;

            const { clicks, impressions, spent, ctr, cpc, cpm, zone_id, country, banner_id } = item;

            const csvRow = {
                // Name: "unknown",
                // Date: date,
                Clicks: clicks,
                Impressions: impressions,
                AmountSpent: spent,
                CTR: ctr,
                CPC: cpc,
                CPM: cpm,
            };

            if (zone_id) csvRow["Publisher ID"] = zone_id;
            if (country) csvRow["Country Code"] = country;
            if (banner_id) csvRow["Banner"] = banner_id;

            csvData.push(csvRow);
        });

        const headers = [
            // { label: "Name", key: "Name" },
            // { label: "Date", key: "Date" },
            { label: "Impressions", key: "Impressions" },
            { label: "Clicks", key: "Clicks" },
            { label: "CTR", key: "CTR" },
            { label: "Amount Spent", key: "AmountSpent" },
            { label: "CPC", key: "CPC" },
            // { label: "CPM", key: "CPM" },
        ];

        if (csvData.length && csvData[0]["Publisher ID"]) {
            headers.unshift({ label: "Publisher ID", key: "Publisher ID" });
        }
        if (csvData.length && csvData[0]["Country Code"]) {
            headers.unshift({ label: "Country Code", key: "Country Code" });
        }
        if (csvData.length && csvData[0]["Banner"]) {
            headers.unshift({ label: "Banner", key: "Banner" });
        }

        return { data: csvData, headers: headers };
    };

    const handleoDwnload = () => {
        const { data, headers } = formatDataToCSV();
        const csvReport = {
            filename: "campaign_progression_data.csv",
            headers: headers,
            data: data,
        };
        setCsvReport(csvReport);
    };

    return (
        <div className={`csv-btn-link-wrapper  ${!items.length || isLoading.campaignStatisticsLoading  ? "disabled" : ""}`}>
            <CSVLink {...csvReport} className={`csv-btn-link`}>
                <Button
                    size="sm"
                    className="csv-btn"
                    color="secondary"
                    name="Export"
                    startIcon={exportIcon}
                />
            </CSVLink>
        </div>
    );
}
