import React, { useState, useEffect } from "react";
import { Button } from "../..";
import localData from "../../../localData";

const { close } = localData.svgs;

export default function InstallPixelModal({ setIsOpen = false, content = "", callback = () => {} }) {
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            ></div>

            <div className="modal-header">
                <h2 className="modal-title">Confirmation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">{content}</div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="cancel-btn"
                        name="Cancel"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                    <Button
                        id="install-pixel-modal-tutorial-2"
                        className="next-step-btn"
                        name="Confirm"
                        size="sm"
                        variant="contained"
                        color="pale-dark"
                        onClick={(e) => {
                            closeModal();
                            setTimeout(() => {
                                callback();
                            }, 500);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
