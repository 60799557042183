import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal, SmallFooter } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
    useEffect(() => {
        document.title = "Privacy Policy | Blockchain-Ads' Commitment to Your Privacy.";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content =
            "Read Blockchain-Ads' privacy policy to understand how we protect your data and adhere to the highest standards of privacy in cryptocurrency advertising.";
    }, []);

    const { fade } = useGlobalContext().animations;

    const { edit, question, password, support, userShield, arrowLeftLong } = localData.svgs;
    const { avatar } = localData.images;
    const navigate = useNavigate();

    return (
        <motion.main {...fade} className="main privacy-policy-page">
            {/* <Navbar title="support" /> */}
            <div className="bar">
                <div className="container">
                    <Button color="dark" name="back" startIcon={arrowLeftLong} onClick={() => navigate(-1)} />
                </div>
            </div>

            <div className="showcase">
                <h1 className="display-1 privacy-policy-title">Privacy Policy</h1>
            </div>
            <br />
            <br />
            <br />
            <br />

            <div className="container iubenda_policy">
                <div id="wbars_all">
                    <div className="iub_container iub_base_container">
                        <div id="wbars">
                            <div className="iub_content legal_pp">
                                <div className="iub_header">
                                    <h1>
                                        Privacy Policy of <strong>hub.blockchain-ads.com</strong>
                                    </h1>

                                    <p>This Application collects some Personal Data from its Users.</p>
                                    <br />
                                    <p>
                                        Users may be subject to different protection standards and broader
                                        standards may therefore apply to some. Users can contact the Owner, to
                                        learn more about such standards.
                                    </p>

                                    <p>
                                        This document contains{" "}
                                        <a href="#gdpr_info" target="_self">
                                            a section dedicated to Users in the European Union and their
                                            privacy rights
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        This document contains{" "}
                                        <a href="#california_info" target="_self">
                                            a section dedicated to California consumers and their privacy
                                            rights
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        This document contains{" "}
                                        <a href="#virginia_info" target="_self">
                                            a section dedicated to Virginia consumers and their privacy rights
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        This document contains{" "}
                                        <a href="#colorado_info" target="_self">
                                            a section dedicated to Colorado consumers and their privacy
                                            rights.
                                        </a>
                                    </p>
                                    <p>
                                        This document contains{" "}
                                        <a href="#connecticut_info" target="_self">
                                            a section dedicated to Connecticut consumers and their privacy
                                            rights.
                                        </a>
                                    </p>
                                    <p>
                                        This document contains{" "}
                                        <a href="#utah_info" target="_self">
                                            a section dedicated to Utah consumers and their privacy rights.
                                        </a>
                                    </p>
                                    <p>
                                        This document can be printed for reference by using the print command
                                        in the settings of any browser.
                                    </p>
                                </div>

                                <div className="simple_pp">
                                    <div className="one_line_col">
                                        <h2 >
                                            Policy summary
                                        </h2>
                                    </div>

                                    <h2 id="purposes_data">
                                        Personal Data processed for the following purposes and using the
                                        following services:
                                    </h2>

                                    <ul className="for_boxes cf">
                                        <li className="one_line_col">
                                            <ul className="for_boxes">
                                                <li>
                                                    <div className="iconed policyicon_purpose_5">
                                                        <h3>Analytics</h3>

                                                        <ul className="unstyled">
                                                            <li>
                                                                <h3>Google Analytics 4</h3>
                                                                <p>
                                                                    Personal Data: number of Users; session
                                                                    statistics; Trackers; Usage Data
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="iconed policyicon_purpose_9">
                                                        <h3>Registration and authentication</h3>

                                                        <ul className="unstyled">
                                                            <li>
                                                                <h3>Google OAuth</h3>
                                                                <p>
                                                                    Personal Data: various types of Data as
                                                                    specified in the privacy policy of the
                                                                    service
                                                                </p>
                                                            </li>

                                                            <li>
                                                                <h3>Firebase Authentication</h3>
                                                                <p>
                                                                    Personal Data: email address; password;
                                                                    username
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <h2 id="contact_information">Contact information</h2>
                                    <ul className="for_boxes cf">
                                        <li className="one_line_col">
                                            <ul className="for_boxes">
                                                <li>
                                                    <div className="iconed icon_owner">
                                                        <h3>Owner and Data Controller</h3>
                                                        <p>
                                                            BlockchainAds LLC
                                                            <br />
                                                            2177 LLC 2022, Suite 305, Griffith Corporate
                                                            Centre, Beachmont, Kingstown, St. Vincent and the
                                                            Grenadines,
                                                        </p>
                                                        <p>
                                                            <strong>Owner contact email:</strong>{" "}
                                                            contact@blockchain-ads.com
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div className="one_line_col">
                                    <h2>Full policy</h2>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="owner_of_the_data">Owner and Data Controller</h2>

                                    <p>
                                        BlockchainAds LLC
                                        <br />
                                        2177 LLC 2022, Suite 305, Griffith Corporate Centre, Beachmont,
                                        Kingstown, St. Vincent and the Grenadines,
                                    </p>
                                    <p>
                                        <strong>Owner contact email:</strong> contact@blockchain-ads.com
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="types_of_data">Types of Data collected</h2>

                                    <p>
                                        Among the types of Personal Data that this Application collects, by
                                        itself or through third parties, there are: Trackers; Usage Data;
                                        number of Users; session statistics; email address; username;
                                        password.
                                    </p>

                                    <p>
                                        Complete details on each type of Personal Data collected are provided
                                        in the dedicated sections of this privacy policy or by specific
                                        explanation texts displayed prior to the Data collection.
                                        <br />
                                        Personal Data may be freely provided by the User, or, in case of Usage
                                        Data, collected automatically when using this Application.
                                        <br />
                                        Unless specified otherwise, all Data requested by this Application is
                                        mandatory and failure to provide this Data may make it impossible for
                                        this Application to provide its services. In cases where this
                                        Application specifically states that some Data is not mandatory, Users
                                        are free not to communicate this Data without consequences to the
                                        availability or the functioning of the Service.
                                        <br />
                                        Users who are uncertain about which Personal Data is mandatory are
                                        welcome to contact the Owner.
                                        <br />
                                        Any use of Cookies – or of other tracking tools — by this Application
                                        or by the owners of third-party services used by this Application
                                        serves the purpose of providing the Service required by the User, in
                                        addition to any other purposes described in the present document and
                                        in the Cookie Policy.
                                    </p>
                                    <p>
                                        Users are responsible for any third-party Personal Data obtained,
                                        published or shared through this Application.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="place_of_processing">Mode and place of processing the Data</h2>
                                    <h3 className="iub-subheading iub-subheading-legal-mode-processing">
                                        Methods of processing
                                    </h3>
                                    <p>
                                        The Owner takes appropriate security measures to prevent unauthorized
                                        access, disclosure, modification, or unauthorized destruction of the
                                        Data.
                                        <br />
                                        The Data processing is carried out using computers and/or IT enabled
                                        tools, following organizational procedures and modes strictly related
                                        to the purposes indicated. In addition to the Owner, in some cases,
                                        the Data may be accessible to certain types of persons in charge,
                                        involved with the operation of this Application (administration,
                                        sales, marketing, legal, system administration) or external parties
                                        (such as third-party technical service providers, mail carriers,
                                        hosting providers, IT companies, communications agencies) appointed,
                                        if necessary, as Data Processors by the Owner. The updated list of
                                        these parties may be requested from the Owner at any time.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-legal-place">Place</h3>
                                    <p>
                                        The Data is processed at the Owner's operating offices and in any
                                        other places where the parties involved in the processing are located.
                                        <br />
                                        <br />
                                        Depending on the User's location, data transfers may involve
                                        transferring the User's Data to a country other than their own. To
                                        find out more about the place of processing of such transferred Data,
                                        Users can check the section containing details about the processing of
                                        Personal Data.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-legal-time">Retention time</h3>
                                    <p>
                                        Unless specified otherwise in this document, Personal Data shall be
                                        processed and stored for as long as required by the purpose they have
                                        been collected for and may be retained for longer due to applicable
                                        legal obligation or based on the Users’ consent.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="use_collected_data">The purposes of processing</h2>
                                    <p>
                                        The Data concerning the User is collected to allow the Owner to
                                        provide its Service, comply with its legal obligations, respond to
                                        enforcement requests, protect its rights and interests (or those of
                                        its Users or third parties), detect any malicious or fraudulent
                                        activity, as well as the following: Registration and authentication
                                        and Analytics.
                                    </p>

                                    <p>
                                        For specific information about the Personal Data used for each
                                        purpose, the User may refer to the section “Detailed information on
                                        the processing of Personal Data”.
                                    </p>
                                </div>

                                <div data-locale="en"></div>

                                <div className="one_line_col">
                                    <h2 id="data_processing_detailed_info">
                                        Detailed information on the processing of Personal Data
                                    </h2>
                                    <p>
                                        Personal Data is collected for the following purposes and using the
                                        following services:
                                    </p>
                                    <ul className="for_boxes">
                                        <li>
                                            <div className="box_primary box_10 expand">
                                                <h3 className="expand-click w_icon_24 policyicon_purpose_11871426 iub-purpose iub-purpose-11871426">
                                                    Analytics
                                                </h3>
                                                <div className="expand-content">
                                                    <p>
                                                        The services contained in this section enable the
                                                        Owner to monitor and analyze web traffic and can be
                                                        used to keep track of User behavior.
                                                    </p>

                                                    <h4 className="iub-service iub-service-10249002">
                                                        Google Analytics 4 (Google LLC)
                                                    </h4>
                                                    <div className="wrap">
                                                        <p>
                                                            Google Analytics 4 is a web analysis service
                                                            provided by Google LLC (“Google”). Google utilizes
                                                            the Data collected to track and examine the use of
                                                            this Application, to prepare reports on its
                                                            activities and share them with other Google
                                                            services.
                                                            <br />
                                                            Google may use the Data collected to contextualize
                                                            and personalize the ads of its own advertising
                                                            network.
                                                            <br />
                                                            In Google Analytics 4, IP addresses are used at
                                                            collection time and then discarded before Data is
                                                            logged in any data center or server. Users can
                                                            learn more by consulting{" "}
                                                            <a href="https://support.google.com/analytics/answer/12017362?hl=en&amp;ref_topic=2919631">
                                                                Google’s official documentation
                                                            </a>
                                                            .
                                                        </p>
                                                    </div>

                                                    <p>
                                                        Personal Data processed: number of Users; session
                                                        statistics; Trackers; Usage Data.
                                                    </p>

                                                    <p>
                                                        Place of processing: United States &ndash;{" "}
                                                        <a
                                                            href="https://policies.google.com/privacy"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Privacy Policy
                                                        </a>{" "}
                                                        &ndash;{" "}
                                                        <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                                                            Opt Out
                                                        </a>
                                                        .{" "}
                                                    </p>

                                                    <p>
                                                        Category of personal information collected according
                                                        to the CCPA: internet or other electronic network
                                                        activity information.
                                                    </p>

                                                    <p>This processing constitutes:</p>

                                                    <ul>
                                                        <li>
                                                            a sale according to the CCPA, VCDPA, CPA, CTDPA
                                                            and UCPA
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="box_primary box_10 expand">
                                                <h3 className="expand-click w_icon_24 policyicon_purpose_11871424 iub-purpose iub-purpose-11871424">
                                                    Registration and authentication
                                                </h3>
                                                <div className="expand-content">
                                                    <p>
                                                        By registering or authenticating, Users allow this
                                                        Application to identify them and give them access to
                                                        dedicated services.
                                                        <br />
                                                        Depending on what is described below, third parties
                                                        may provide registration and authentication services.
                                                        In this case, this Application will be able to access
                                                        some Data, stored by these third-party services, for
                                                        registration or identification purposes. <br />
                                                        Some of the services listed below may also collect
                                                        Personal Data for targeting and profiling purposes; to
                                                        find out more, please refer to the description of each
                                                        service.
                                                    </p>

                                                    <h4 className="iub-service iub-service-10249000">
                                                        Google OAuth (Google LLC)
                                                    </h4>
                                                    <div className="wrap">
                                                        <p>
                                                            Google OAuth is a registration and authentication
                                                            service provided by Google LLC and is connected to
                                                            the Google network.
                                                        </p>
                                                    </div>

                                                    <p>
                                                        Personal Data processed: various types of Data as
                                                        specified in the privacy policy of the service.
                                                    </p>

                                                    <p>
                                                        Place of processing: United States &ndash;{" "}
                                                        <a
                                                            href="https://policies.google.com/privacy"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                        .{" "}
                                                    </p>

                                                    <p>
                                                        Category of personal information collected according
                                                        to the CCPA: identifiers.
                                                    </p>

                                                    <p>This processing constitutes:</p>

                                                    <ul>
                                                        <li>
                                                            a sale according to the CCPA, VCDPA, CPA, CTDPA
                                                            and UCPA
                                                        </li>
                                                    </ul>

                                                    <h4 className="iub-service iub-service-10249006">
                                                        Firebase Authentication (Google LLC)
                                                    </h4>
                                                    <div className="wrap">
                                                        <p>
                                                            Firebase Authentication is a registration and
                                                            authentication service provided by Google LLC. To
                                                            simplify the registration and authentication
                                                            process, Firebase Authentication can make use of
                                                            third-party identity providers and save the
                                                            information on its platform.
                                                        </p>
                                                    </div>

                                                    <p>
                                                        Personal Data processed: email address; password;
                                                        username.
                                                    </p>

                                                    <p>
                                                        Place of processing: United States &ndash;{" "}
                                                        <a
                                                            href="https://policies.google.com/privacy"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                        .{" "}
                                                    </p>

                                                    <p>
                                                        Category of personal information collected according
                                                        to the CCPA: identifiers.
                                                    </p>

                                                    <p>This processing constitutes:</p>

                                                    <ul>
                                                        <li>
                                                            a sale according to the CCPA, VCDPA, CPA, CTDPA
                                                            and UCPA
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div data-locale="en"></div>

                                <div className="one_line_col">
                                    <h2 id="cookie_policy">Cookie Policy</h2>
                                    <p>
                                        This Application uses Trackers. To learn more, Users may consult the{" "}
                                        <a
                                            href="//www.iubenda.com/privacy-policy/89746445/cookie-policy"
                                            title="Cookie Policy"
                                            className="show_comp_link iframe-preserve an-preserve"
                                        >
                                            Cookie Policy
                                        </a>
                                        .
                                    </p>
                                </div>

                                <h2 id="cookie_policy_further_information">
                                    Further Information for Users in the European Union{" "}
                                </h2>

                                <p id="gdpr_info">
                                    This section applies to all Users in the European Union, according to the
                                    General Data Protection Regulation (the “GDPR”), and, for such Users,
                                    supersedes any other possibly divergent or conflicting information
                                    contained in the privacy policy. Further details regarding the categories
                                    of Data processed, the purposes of processing, the categories of
                                    recipients of the Personal Data, if any, and further information about
                                    Personal Data can be found in{" "}
                                    <strong>
                                        the section titled “Detailed information on the processing of Personal
                                        Data” within this document
                                    </strong>
                                    .
                                </p>

                                <h3 className="iub-subheading iub-subheading-legal-basis-of-processing">
                                    Legal basis of processing
                                </h3>
                                <p>
                                    The Owner may process Personal Data relating to Users if one of the
                                    following applies:
                                </p>
                                <ul>
                                    <li>Users have given their consent for one or more specific purposes.</li>
                                    <li>
                                        provision of Data is necessary for the performance of an agreement
                                        with the User and/or for any pre-contractual obligations thereof;
                                    </li>
                                    <li>
                                        processing is necessary for compliance with a legal obligation to
                                        which the Owner is subject;
                                    </li>
                                    <li>
                                        processing is related to a task that is carried out in the public
                                        interest or in the exercise of official authority vested in the Owner;
                                    </li>
                                    <li>
                                        processing is necessary for the purposes of the legitimate interests
                                        pursued by the Owner or by a third party.
                                    </li>
                                </ul>
                                <p>
                                    In any case, the Owner will gladly help to clarify the specific legal
                                    basis that applies to the processing, and in particular whether the
                                    provision of Personal Data is a statutory or contractual requirement, or a
                                    requirement necessary to enter into a contract.{" "}
                                </p>

                                <h3 className="iub-subheading iub-subheading-legal-time">
                                    Further information about retention time
                                </h3>

                                <p>
                                    Unless specified otherwise in this document, Personal Data shall be
                                    processed and stored for as long as required by the purpose they have been
                                    collected for and may be retained for longer due to applicable legal
                                    obligation or based on the Users’ consent.
                                </p>
                                <p>Therefore:</p>
                                <ul>
                                    <li>
                                        Personal Data collected for purposes related to the performance of a
                                        contract between the Owner and the User shall be retained until such
                                        contract has been fully performed.
                                    </li>
                                    <li>
                                        Personal Data collected for the purposes of the Owner’s legitimate
                                        interests shall be retained as long as needed to fulfill such
                                        purposes. Users may find specific information regarding the legitimate
                                        interests pursued by the Owner within the relevant sections of this
                                        document or by contacting the Owner.
                                    </li>
                                </ul>
                                <p>
                                    The Owner may be allowed to retain Personal Data for a longer period
                                    whenever the User has given consent to such processing, as long as such
                                    consent is not withdrawn. Furthermore, the Owner may be obliged to retain
                                    Personal Data for a longer period whenever required to fulfil a legal
                                    obligation or upon order of an authority.
                                    <br />
                                    <br />
                                    Once the retention period expires, Personal Data shall be deleted.
                                    Therefore, the right of access, the right to erasure, the right to
                                    rectification and the right to data portability cannot be enforced after
                                    expiration of the retention period.
                                </p>

                                <h3 id="rights_subjects">
                                    The rights of Users based on the General Data Protection Regulation (GDPR)
                                </h3>
                                <p>
                                    Users may exercise certain rights regarding their Data processed by the
                                    Owner.
                                </p>

                                <p>
                                    In particular, Users have the right to do the following, to the extent
                                    permitted by law:
                                </p>

                                <ul>
                                    <li>
                                        <strong>Withdraw their consent at any time.</strong> Users have the
                                        right to withdraw consent where they have previously given their
                                        consent to the processing of their Personal Data.
                                    </li>
                                    <li>
                                        <strong>Object to processing of their Data.</strong> Users have the
                                        right to object to the processing of their Data if the processing is
                                        carried out on a legal basis other than consent.
                                    </li>
                                    <li>
                                        <strong>Access their Data.</strong> Users have the right to learn if
                                        Data is being processed by the Owner, obtain disclosure regarding
                                        certain aspects of the processing and obtain a copy of the Data
                                        undergoing processing.
                                    </li>
                                    <li>
                                        <strong>Verify and seek rectification.</strong> Users have the right
                                        to verify the accuracy of their Data and ask for it to be updated or
                                        corrected.
                                    </li>
                                    <li>
                                        <strong>Restrict the processing of their Data.</strong> Users have the
                                        right to restrict the processing of their Data. In this case, the
                                        Owner will not process their Data for any purpose other than storing
                                        it.
                                    </li>
                                    <li>
                                        <strong>
                                            Have their Personal Data deleted or otherwise removed.
                                        </strong>{" "}
                                        Users have the right to obtain the erasure of their Data from the
                                        Owner.
                                    </li>
                                    <li>
                                        <strong>
                                            Receive their Data and have it transferred to another controller.
                                        </strong>{" "}
                                        Users have the right to receive their Data in a structured, commonly
                                        used and machine readable format and, if technically feasible, to have
                                        it transmitted to another controller without any hindrance.
                                    </li>
                                    <li>
                                        <strong>Lodge a complaint.</strong> Users have the right to bring a
                                        claim before their competent data protection authority.
                                    </li>
                                </ul>

                                <p>
                                    Users are also entitled to learn about the legal basis for Data transfers
                                    abroad including to any international organization governed by public
                                    international law or set up by two or more countries, such as the UN, and
                                    about the security measures taken by the Owner to safeguard their Data.
                                </p>

                                <h4 className="iub-subheading iub-subheading-legal-right-to-object">
                                    Details about the right to object to processing
                                </h4>
                                <p>
                                    <strong>
                                        Where Personal Data is processed for a public interest, in the
                                        exercise of an official authority vested in the Owner or for the
                                        purposes of the legitimate interests pursued by the Owner, Users may
                                        object to such processing by providing a ground related to their
                                        particular situation to justify the objection.
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        Users must know that, however, should their Personal Data be processed
                                        for direct marketing purposes, they can object to that processing at
                                        any time, free of charge and without providing any justification.
                                        Where the User objects to processing for direct marketing purposes,
                                        the Personal Data will no longer be processed for such purposes. To
                                        learn whether the Owner is processing Personal Data for direct
                                        marketing purposes, Users may refer to the relevant sections of this
                                        document.{" "}
                                    </strong>
                                </p>

                                <h4 className="iub-subheading iub-subheading-legal-how-to-exercise">
                                    How to exercise these rights
                                </h4>
                                <p>
                                    Any requests to exercise User rights can be directed to the Owner through
                                    the contact details provided in this document. Such requests are free of
                                    charge and will be answered by the Owner as early as possible and always
                                    within one month, providing Users with the information required by law.
                                    Any rectification or erasure of Personal Data or restriction of processing
                                    will be communicated by the Owner to each recipient, if any, to whom the
                                    Personal Data has been disclosed unless this proves impossible or involves
                                    disproportionate effort. At the Users’ request, the Owner will inform them
                                    about those recipients.
                                </p>

                                <div className="one_line_col"></div>

                                <div className="one_line_col">
                                    <h2 id="california_info">Further information for California consumers</h2>
                                    <p>
                                        This section of the document integrates with and supplements the
                                        information contained in the rest of the privacy policy and is
                                        provided by the business running this Application and, if the case may
                                        be, its parent, subsidiaries and affiliates (for the purposes of this
                                        section referred to collectively as “we”, “us”, “our”).
                                    </p>
                                    <p>
                                        This section applies to all Users (Users are referred to below, simply
                                        as “you”, “your”, “yours”), who are consumers residing in the state of
                                        California, United States of America, according to the "
                                        <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5">
                                            California Consumer Privacy Act of 2018
                                        </a>
                                        " (the "CCPA"), as updated by the "California Privacy Rights Act" (the
                                        "CPRA") and subsequent regulations. For such consumers, this section
                                        supersedes any other possibly divergent or conflicting information
                                        contained in the privacy policy.
                                    </p>

                                    <p>
                                        This part of the document uses the terms “personal information” (and
                                        “sensitive personal information”) as defined in the California
                                        Consumer Privacy Act (CCPA).
                                    </p>

                                    <h3 id="notice_at_collection">Notice at collection</h3>

                                    <h4 className="iub-subheading iub-subheading-ccpa-information-collected">
                                        Categories of personal information collected, used, sold, or shared
                                    </h4>
                                    <p>
                                        In this section we summarize the categories of personal information
                                        that we've collected, used, sold, or shared and the purposes thereof.{" "}
                                        <strong>
                                            You can read about these activities in detail in the section
                                            titled “Detailed information on the processing of Personal Data”
                                            within this document.
                                        </strong>
                                    </p>

                                    <h5>
                                        Information we collect: the categories of personal information we
                                        collect
                                    </h5>
                                    <p>
                                        We have collected the following categories of personal information
                                        about you: identifiers and internet or other electronic network
                                        activity information.
                                    </p>

                                    <p>
                                        We have collected the following categories of sensitive personal
                                        information: username and password
                                    </p>

                                    <p>
                                        We will not collect additional categories of personal information
                                        without notifying you.
                                    </p>

                                    <h6>
                                        Your right to limit the use or disclosure of your sensitive personal
                                        information and how you can exercise it
                                    </h6>
                                    <p>
                                        You have the right to request that we limit the use or disclosure of
                                        your sensitive personal information to only that which is necessary to
                                        perform the services or provide the goods, as is reasonably expected
                                        by an average consumer.
                                    </p>
                                    <p>
                                        We can also use your sensitive personal information to perform
                                        specific purposes set forth by the law (such as, including but not
                                        limited to, helping to ensure security and integrity; undertaking
                                        activities to verify or maintain the quality or safety of our service)
                                        and as authorized by the relevant regulations.
                                    </p>
                                    <p>
                                        Outside of the aforementioned specific purposes, you have the right to
                                        freely request, at any time, that we do not use or disclose your
                                        sensitive personal information. This means that whenever you ask us to
                                        stop using your sensitive personal information, we will abide by your
                                        request and we will instruct our service providers and contractors to
                                        do the same.
                                    </p>
                                    <p>
                                        To fully exercise your right to limit the use or disclosure of your
                                        sensitive personal information you can contact us at any time, using
                                        the contact details provided in this document.
                                    </p>
                                    <p>
                                        For a simplified method you can also use the privacy choices link
                                        provided on this Application.
                                    </p>
                                    <p>
                                        We use any personal information collected from you in connection with
                                        the submission of your request solely for the purposes of complying
                                        with the request.
                                    </p>
                                    <p>
                                        Once you have exercised this right, we are required to wait at least
                                        12 months before asking whether you have changed your mind.
                                    </p>

                                    <h5>What are the purposes for which we use your personal information?</h5>
                                    <p>
                                        We may use your personal information to allow the operational
                                        functioning of this Application and features thereof (“business
                                        purposes”). In such cases, your personal information will be processed
                                        in a fashion necessary and proportionate to the business purpose for
                                        which it was collected, and strictly within the limits of compatible
                                        operational purposes.
                                    </p>
                                    <p>
                                        We may also use your personal information for other reasons such as
                                        for commercial purposes (as indicated within the section “Detailed
                                        information on the processing of Personal Data” within this document),
                                        as well as for complying with the law and defending our rights before
                                        the competent authorities where our rights and interests are
                                        threatened or we suffer an actual damage.
                                    </p>
                                    <p>
                                        We won’t process your information for unexpected purposes, or for
                                        purposes incompatible with the purposes originally disclosed, without
                                        your consent.
                                    </p>

                                    <h5>How long do we keep your personal information?</h5>
                                    <p>
                                        Unless stated otherwise inside the “Detailed information on the
                                        processing of Personal Data” section, we will not retain your personal
                                        information for longer than is reasonably necessary for the purpose(s)
                                        they have been collected for.
                                    </p>

                                    <h5>
                                        How we collect information: what are the sources of the personal
                                        information we collect?
                                    </h5>
                                    <p>
                                        We collect the above-mentioned categories of personal information,
                                        either directly or indirectly, from you when you use this Application.
                                    </p>
                                    <p>
                                        For example, you directly provide your personal information when you
                                        submit requests via any forms on this Application. You also provide
                                        personal information indirectly when you navigate this Application, as
                                        personal information about you is automatically observed and
                                        collected.
                                    </p>

                                    <p>
                                        Finally, we may collect your personal information from third parties
                                        that work with us in connection with the Service or with the
                                        functioning of this Application and features thereof.
                                    </p>

                                    <h5>
                                        How we use the information we collect: disclosing of your personal
                                        information with third parties for a business purpose
                                    </h5>

                                    <p>
                                        For our purposes, the word “third party” means a person who is not any
                                        of the following: a service provider or a contractor, as defined by
                                        the CCPA.
                                    </p>
                                    <p>
                                        We disclose your personal information with the third parties{" "}
                                        <b>
                                            listed in detail in the section titled “Detailed information on
                                            the processing of Personal Data” within this document.
                                        </b>{" "}
                                        These third parties are grouped and categorized in accordance with the
                                        different purposes of processing.
                                    </p>

                                    <h5>Sale or sharing of your personal information</h5>
                                    <p>
                                        For our purposes, the word “sale” means any “selling, renting,
                                        releasing, disclosing, disseminating, making available, transferring
                                        or otherwise communicating orally, in writing, or by electronic means,
                                        a consumer's personal information by the business to{" "}
                                        <strong>
                                            a third party, for monetary or other valuable consideration
                                        </strong>
                                        ”, as defined by the CCPA.
                                    </p>
                                    <p>
                                        This means that, for example, a sale can happen whenever an
                                        application runs ads, or makes statistical analyses on the traffic or
                                        views, or simply because it uses tools such as social network plugins
                                        and the like.
                                    </p>
                                    <p>
                                        For our purposes, the word “sharing” means any “sharing, renting,
                                        releasing, disclosing, disseminating, making available, transferring,
                                        or otherwise communicating orally, in writing, or by electronic or
                                        other means, a consumer's personal information by the business to a
                                        third party for cross-context behavioral advertising, whether or not
                                        for monetary or other valuable consideration, including transactions
                                        between a business and a third party for cross-context behavioral
                                        advertising for the benefit of a business in which no money is
                                        exchanged”, as defined by the CCPA.
                                        <br />
                                        Please note that the exchange of personal information with a service
                                        provider pursuant to a written contract that meets the requirements
                                        set by the CCPA, does not constitute a sale or sharing of your
                                        personal information.
                                    </p>

                                    <h6>
                                        Your right to opt out of the sale or sharing of your personal
                                        information and how you can exercise it
                                    </h6>
                                    <p>
                                        We sell or share your personal information with the third parties{" "}
                                        <b>
                                            listed in detail in the section titled “Detailed information on
                                            the processing of Personal Data” within this document.
                                        </b>{" "}
                                        These third parties are grouped and categorized in accordance with the
                                        different purposes of processing.
                                        <br />
                                        <br />
                                        You have the right to opt out of the sale or sharing of your personal
                                        information. This means that whenever you request us to stop selling
                                        or sharing your personal information, we will abide by your request.
                                        <br />
                                        Such requests can be made freely, at any time, without submitting any
                                        verifiable request.
                                        <br />
                                        To fully exercise your right to opt out, you can contact us at any
                                        time using the contact details provided in this document.
                                        <br />
                                        For a simplified opt-out method you can also use the privacy choices
                                        link provided on this Application.
                                    </p>

                                    <p>
                                        If you want to submit requests to opt out of the sale or sharing of
                                        personal information via a user-enabled global privacy control, like
                                        the Global Privacy Control (“
                                        <a href="https://globalprivacycontrol.org/">GPC</a>”), you are free to
                                        do so and we will abide by such request in a frictionless manner (as
                                        defined in the CPRA regulations). The GPC consists of a setting or
                                        extension in the browser or mobile device and acts as a mechanism that
                                        websites can use to indicate they support the GPC signal. If you want
                                        to use GPC, you can download and enable it via a{" "}
                                        <a href="https://globalprivacycontrol.org/#download">
                                            participating browser
                                        </a>{" "}
                                        or browser extension. More information about downloading GPC is
                                        available <a href="https://globalprivacycontrol.org/">here</a>.
                                    </p>

                                    <p>
                                        We use any personal information collected from you in connection with
                                        the submission of your opt-out request solely for the purposes of
                                        complying with the opt-out request.
                                    </p>
                                    <p>
                                        Once you have opted out, we are required to wait at least 12 months
                                        before asking whether you have changed your mind.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-ccpa-your-rigths">
                                        Your privacy rights under the California Consumer Privacy Act and how
                                        to exercise them
                                    </h3>

                                    <h4>
                                        The right to access personal information: the right to know and to
                                        portability
                                    </h4>
                                    <p>You have the right to request that we disclose to you:</p>

                                    <ul>
                                        <li>
                                            the categories of personal information that we collect about you;
                                        </li>
                                        <li>the sources from which the personal information is collected;</li>
                                        <li>the purposes for which we use your information;</li>
                                        <li>to whom we disclose such information;</li>
                                        <li>
                                            the specific pieces of personal information we have collected
                                            about you.
                                        </li>
                                    </ul>

                                    <p>
                                        You also have{" "}
                                        <b>
                                            the right to know what personal information is sold or shared and
                                            to whom.
                                        </b>{" "}
                                        In particular, you have the right to request two separate lists from
                                        us where we disclose:
                                    </p>
                                    <ul>
                                        <li>
                                            the categories of personal information that we sold or shared
                                            about you and the categories of third parties to whom the personal
                                            information was sold or shared;{" "}
                                        </li>
                                        <li>
                                            the categories of personal information that we disclosed about you
                                            for a business purpose and the categories of persons to whom it
                                            was disclosed for a business purpose.
                                        </li>
                                    </ul>

                                    <p>
                                        The disclosure described above will be limited to the personal
                                        information collected or used over the past 12 months.
                                    </p>
                                    <p>
                                        If we deliver our response electronically, the information enclosed
                                        will be "portable", i.e. delivered in an easily usable format to
                                        enable you to transmit the information to another entity without
                                        hindrance — provided that this is technically feasible.
                                    </p>

                                    <h4>The right to request the deletion of your personal information</h4>
                                    <p>
                                        You have the right to request that we delete any of your personal
                                        information, subject to exceptions set forth by the law (such as,
                                        including but not limited to, where the information is used to
                                        identify and repair errors on this Application, to detect security
                                        incidents and protect against fraudulent or illegal activities, to
                                        exercise certain rights etc.).
                                    </p>
                                    <p>
                                        If no legal exception applies, as a result of exercising your right,
                                        we will delete your personal information and notify any of our service
                                        providers and all third parties to whom we have sold or shared the
                                        personal information to do so — provided that this is technically
                                        feasible and doesn’t involve disproportionate effort.
                                    </p>

                                    <h4>The right to correct inaccurate personal information</h4>
                                    <p>
                                        You have the right to request that we correct any inaccurate personal
                                        information we maintain about you, taking into account the nature of
                                        the personal information and the purposes of the processing of the
                                        personal information.
                                    </p>

                                    <h4>
                                        The right to opt out of sale or sharing of personal information and to
                                        limit the use of your sensitive personal information
                                    </h4>
                                    <p>
                                        You have the right to opt out of the sale or sharing of your personal
                                        information. You also have the right to request that we limit our use
                                        or disclosure of your sensitive personal information.
                                    </p>

                                    <h4>
                                        The right of no retaliation following opt-out or exercise of other
                                        rights (the right to non-discrimination)
                                    </h4>
                                    <p>
                                        We will not discriminate against you for exercising your rights under
                                        the CCPA. This means that we will not discriminate against you,
                                        including, but not limited to, by denying goods or services, charging
                                        you a different price, or providing a different level or quality of
                                        goods or services just because you exercised your consumer privacy
                                        rights.
                                    </p>
                                    <p>
                                        However, if you refuse to provide your personal information to us or
                                        ask us to delete or stop selling your personal information, and that
                                        personal information or sale is necessary for us to provide you with
                                        goods or services, we may not be able to complete that transaction.
                                    </p>
                                    <p>
                                        To the extent permitted by the law, we may offer you promotions,
                                        discounts, and other deals in exchange for collecting, keeping, or
                                        selling your personal information, provided that the financial
                                        incentive offered is reasonably related to the value of your personal
                                        information.
                                    </p>

                                    <h4>How to exercise your rights</h4>
                                    <p>
                                        To exercise the rights described above, you need to submit your
                                        verifiable request to us by contacting us via the details provided in
                                        this document.
                                    </p>
                                    <p>
                                        For us to respond to your request, it’s necessary that we know who you
                                        are. Therefore, you can only exercise the above rights by making a
                                        verifiable request which must:
                                    </p>
                                    <ul>
                                        <li>
                                            provide sufficient information that allows us to reasonably verify
                                            you are the person about whom we collected personal information or
                                            an authorized representative;
                                        </li>
                                        <li>
                                            describe your request with sufficient detail that allows us to
                                            properly understand, evaluate, and respond to it.
                                        </li>
                                    </ul>
                                    <p>
                                        We will not respond to any request if we are unable to verify your
                                        identity and therefore confirm the personal information in our
                                        possession actually relates to you.
                                    </p>

                                    <p>
                                        Making a verifiable consumer request does not require you to create an
                                        account with us. We will use any personal information collected from
                                        you in connection with the verification of your request solely for the
                                        purposes of verification and shall not further disclose the personal
                                        information, retain it longer than necessary for purposes of
                                        verification, or use it for unrelated purposes.
                                    </p>
                                    <p>
                                        If you cannot personally submit a verifiable request, you can
                                        authorize a person registered with the California Secretary of State
                                        to act on your behalf.
                                    </p>
                                    <p>
                                        If you are an adult, you can make a verifiable request on behalf of a
                                        child under your parental authority.
                                    </p>
                                    <p>
                                        You can submit a maximum number of 2 requests over a period of 12
                                        months.
                                    </p>

                                    <h4>How and when we are expected to handle your request</h4>
                                    <p>
                                        We will confirm receipt of your verifiable request within 10 days and
                                        provide information about how we will process your request.
                                    </p>
                                    <p>
                                        We will respond to your request within 45 days of its receipt. Should
                                        we need more time, we will explain to you the reasons why, and how
                                        much more time we need. In this regard, please note that we may take
                                        up to 90 days to fulfill your request.
                                    </p>
                                    <p>
                                        Our disclosure(s) will cover the preceding 12-month period. Only with
                                        regard to personal information collected on or after January 1, 2022,
                                        you have the right to request that we disclose information beyond the
                                        12-month period, and we will provide them to you unless doing so
                                        proves impossible or would involve a disproportionate effort.
                                    </p>
                                    <p>
                                        Should we deny your request, we will explain you the reasons behind
                                        our denial.
                                    </p>
                                    <p>
                                        We do not charge a fee to process or respond to your verifiable
                                        request unless such request is manifestly unfounded or excessive. In
                                        such cases, we may charge a reasonable fee, or refuse to act on the
                                        request. In either case, we will communicate our choices and explain
                                        the reasons behind it.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="virginia_info">Further information for Virginia consumers</h2>
                                    <p>
                                        This section of the document integrates with and supplements the
                                        information contained in the rest of the privacy policy and is
                                        provided by the controller running this Application and, if the case
                                        may be, its parent, subsidiaries and affiliates (for the purposes of
                                        this section referred to collectively as “we”, “us”, “our”).
                                    </p>
                                    <p>
                                        This section applies to all Users (Users are referred to below, simply
                                        as “you”, “your”, “yours”), who are consumers residing in the
                                        Commonwealth of Virginia, according to the{" "}
                                        <a href="https://law.lis.virginia.gov/vacode/title59.1/chapter53/">
                                            {" "}
                                            “Virginia Consumer Data Protection Act"
                                        </a>{" "}
                                        (the "VCDPA"), and, for such consumers, it supersedes any other
                                        possibly divergent or conflicting information contained in the privacy
                                        policy.
                                    </p>

                                    <p>
                                        This part of the document uses the term “personal data” as defined in
                                        the VCDPA.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-vcdpa-information-collected">
                                        Categories of personal data processed
                                    </h3>
                                    <p>
                                        In this section, we summarize the categories of personal data that
                                        we've processed and the purposes thereof.{" "}
                                        <b>
                                            You can read about these activities in detail in the section
                                            titled “Detailed information on the processing of Persona Data”
                                            within this document
                                        </b>
                                        .
                                    </p>

                                    <h4>Categories of personal data we collect</h4>
                                    <p>
                                        We have collected the following categories of personal data:
                                        identifiers and internet information
                                    </p>

                                    <p>We do not collect sensitive data.</p>

                                    <p>
                                        We will not collect additional categories of personal data without
                                        notifying you.
                                    </p>

                                    <h4>Why we process your personal data</h4>
                                    <p>
                                        To find out why we process your personal data, you can read the
                                        sections titled “Detailed information on the processing of Personal
                                        Data” and “The purposes of processing” within this document.
                                    </p>
                                    <p>
                                        We won’t process your information for unexpected purposes, or for
                                        purposes incompatible with the purposes originally disclosed, without
                                        your consent.
                                        <br />
                                        You can freely give, deny, or withdraw such consent at any time using
                                        the contact details provided in this document.
                                    </p>

                                    <h4>
                                        How we use the data we collect: sharing of your personal data with
                                        third parties
                                    </h4>
                                    <p>
                                        {" "}
                                        We share your personal data with the third parties{" "}
                                        <b>
                                            listed in detail in the section titled “Detailed information on
                                            the processing of Personal Data” within this document
                                        </b>
                                        . These third parties are grouped and categorized in accordance with
                                        the different purposes of processing.
                                        <br />
                                        For our purposes, the word "third party" means "a natural or legal
                                        person, public authority, agency, or body other than the consumer,
                                        controller, processor, or an affiliate of the processor or the
                                        controller" as defined by the VCDPA.
                                    </p>

                                    <h4>Sale of your personal data</h4>

                                    <p>
                                        For our purposes, the word “sale” means any “exchange of personal data
                                        for monetary consideration by us to a third party“ as defined by the
                                        VCDPA.
                                        <br />
                                        Please note that according to the VCDPA, the disclosure of personal
                                        data to a processor that processes personal data on behalf of a
                                        controller does not constitute a sale. In addition, other specific
                                        exceptions set forth in the VCDPA may apply, such as, but not limited
                                        to, the disclosure of personal data to a third party for the provision
                                        of a product or service requested by you.
                                        <br />
                                        As specified in the “Detailed information on the processing of
                                        Personal Data” section of this document, our use of your personal
                                        information may be considered a sale under VCDPA.
                                    </p>

                                    <h5>
                                        Your right to opt out of the sale of your personal data and how you
                                        can exercise it
                                    </h5>
                                    <p>
                                        You have the right to opt out of the sale of your personal data. This
                                        means that whenever you request us to stop selling your data, we will
                                        abide by your request. To fully exercise your right to opt out you can
                                        contact us at any time using the contact details provided in this
                                        document.
                                    </p>
                                    <p>
                                        We use any personal data collected from you in connection with the
                                        submission of your opt-out request solely for the purpose of complying
                                        with the request.
                                    </p>

                                    <h4>Processing of your personal data for targeted advertising</h4>

                                    <p>
                                        We do not process your personal data for targeted advertising. If we
                                        decide to do so, we will inform you beforehand and will grant your
                                        right to opt out of the processing of your personal data for targeted
                                        advertising.
                                    </p>

                                    <h3>
                                        Your privacy rights under the Virginia Consumer Data Protection Act
                                        and how to exercise them
                                    </h3>

                                    <p>
                                        You may exercise certain rights regarding your data processed by us.
                                        In particular, you have the right to do the following:
                                    </p>
                                    <ul>
                                        <li>
                                            <b>access personal data: the right to know.</b> You have the right
                                            to request that we confirm whether or not we are processing your
                                            personal data. You also have the right to access such personal
                                            data.
                                        </li>
                                        <li>
                                            <b>correct inaccurate personal data.</b> You have the right to
                                            request that we correct any inaccurate personal data we maintain
                                            about you, taking into account the nature of the personal data and
                                            the purposes of the processing of the personal data.
                                        </li>
                                        <li>
                                            <b>request the deletion of your personal data.</b> You have the
                                            right to request that we delete any of your personal data.
                                        </li>
                                        <li>
                                            <b>obtain a copy of your personal data.</b> We will provide your
                                            personal data in a portable and usable format that allows you to
                                            transfer data easily to another entity — provided that this is
                                            technically feasible.
                                        </li>
                                        <li>
                                            <b>
                                                opt out of the processing of your personal data for the
                                                purposes of targeted advertising
                                            </b>
                                            , the <b>sale of personal data</b>, or <b>profiling</b> in
                                            furtherance of decisions that produce legal or similarly
                                            significant effects concerning you.
                                        </li>
                                        <li>
                                            <b>non-discrimination.</b> We will not discriminate against you
                                            for exercising your rights under the VCDPA. This means that we
                                            will not, among other things, deny goods or services, charge you a
                                            different price, or provide a different level or quality of goods
                                            or services just because you exercised your consumer privacy
                                            rights. However, if you refuse to provide your personal data to us
                                            or ask us to delete or stop selling your personal data, and that
                                            personal data or sale is necessary for us to provide you with
                                            goods or services, we may not be able to complete that
                                            transaction. To the extent permitted by the law, we may offer a
                                            different price, rate, level, quality, or selection of goods or
                                            services to you, including offering goods or services for no fee,
                                            if you have exercised your right to opt out, or our offer is
                                            related to your voluntary participation in a bona fide loyalty,
                                            rewards, premium features, discounts, or club card program.
                                        </li>
                                    </ul>

                                    <h4>How to exercise your rights</h4>
                                    <p>
                                        To exercise the rights described above, you need to submit your
                                        request to us by contacting us via the contact details provided in
                                        this document.
                                    </p>
                                    <p>For us to respond to your request, we need to know who you are.</p>
                                    <p>
                                        We will not respond to any request if we are unable to verify your
                                        identity using commercially reasonable efforts and therefore confirm
                                        that the personal data in our possession actually relate to you. In
                                        such cases, we may request that you provide additional information
                                        which is reasonably necessary to authenticate you and your request.
                                    </p>
                                    <p>
                                        Making a consumer request does not require you to create an account
                                        with us. However, we may require you to use your existing account. We
                                        will use any personal data collected from you in connection with your
                                        request solely for the purposes of authentication, without further
                                        disclosing the personal data, retaining it longer than necessary for
                                        purposes of authentication, or using it for unrelated purposes.
                                    </p>
                                    <p>
                                        If you are an adult, you can make a request on behalf of a child under
                                        your parental authority.
                                    </p>

                                    <h4>How and when we are expected to handle your request</h4>
                                    <p>
                                        We will respond to your request without undue delay, but in all cases
                                        and at the latest within 45 days of its receipt. Should we need more
                                        time, we will explain to you the reasons why, and how much more time
                                        we need. In this regard, please note that we may take up to 90 days to
                                        fulfill your request.
                                    </p>
                                    <p>
                                        Should we deny your request, we will explain to you the reasons behind
                                        our denial without undue delay, but in all cases and at the latest
                                        within 45 days of receipt of the request. It is your right to appeal
                                        such decision by submitting a request to us via the details provided
                                        in this document. Within 60 days of receipt of the appeal, we will
                                        inform you in writing of any action taken or not taken in response to
                                        the appeal, including a written explanation of the reasons for the
                                        decisions. If the appeal is denied you may{" "}
                                        <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">
                                            {" "}
                                            contact the Attorney General to submit a complaint
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        We do not charge a fee to respond to your request, for up to two
                                        requests per year. If your request is manifestly unfounded, excessive
                                        or repetitive, we may charge a reasonable fee or refuse to act on the
                                        request. In either case, we will communicate our choices and explain
                                        the reasons behind them.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="colorado_info">Further information for Colorado consumers</h2>
                                    <p>
                                        This section of the document integrates with and supplements the
                                        information contained in the rest of the privacy policy and is
                                        provided by the controller running this Application and, if the case
                                        may be, its parent, subsidiaries and affiliates (for the purposes of
                                        this section referred to collectively as “we”, “us”, “our”).
                                    </p>
                                    <p>
                                        This section applies to all Users (Users are referred to below, simply
                                        as “you”, “your”, “yours”), who are consumers residing in the State of
                                        Colorado, according to the “Colorado Privacy Act" (the "CPA"), and,
                                        for such consumers, it supersedes any other possibly divergent or
                                        conflicting information contained in the privacy policy.
                                    </p>

                                    <p>
                                        This part of the document uses the term “personal data” as defined in
                                        the CPA.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-cpa-information-collected">
                                        Categories of personal data processed
                                    </h3>
                                    <p>
                                        In this section, we summarize the categories of personal data that
                                        we've processed and the purposes thereof.{" "}
                                        <b>
                                            You can read about these activities in detail in the section
                                            titled “Detailed information on the processing of Persona Data”
                                            within this document
                                        </b>
                                        .
                                    </p>

                                    <h4>Categories of personal data we collect</h4>
                                    <p>
                                        We have collected the following categories of personal data:
                                        identifiers and internet information
                                    </p>

                                    <p>We do not collect sensitive data.</p>

                                    <p>
                                        We will not collect additional categories of personal data without
                                        notifying you.
                                    </p>

                                    <h4>Why we process your personal data</h4>
                                    <p>
                                        To find out why we process your personal data, you can read the
                                        sections titled “Detailed information on the processing of Personal
                                        Data” and “The purposes of processing” within this document.
                                    </p>
                                    <p>
                                        We won’t process your information for unexpected purposes, or for
                                        purposes incompatible with the purposes originally disclosed, without
                                        your consent.
                                        <br />
                                        You can freely give, deny, or withdraw such consent at any time using
                                        the contact details provided in this document.
                                    </p>

                                    <h4>
                                        How we use the data we collect: sharing of your personal data with
                                        third parties
                                    </h4>
                                    <p>
                                        {" "}
                                        We share your personal data with the third parties{" "}
                                        <b>
                                            listed in detail in the section titled “Detailed information on
                                            the processing of Personal Data” within this document
                                        </b>
                                        . These third parties are grouped and categorized in accordance with
                                        the different purposes of processing.
                                    </p>

                                    <p>
                                        For our purposes, the word "third party" means "a person, public
                                        authority, agency, or body other than a consumer, controller,
                                        processor, or affiliate of the processor or the controller." as
                                        defined by the CPA.
                                    </p>

                                    <h4>Sale of your personal data</h4>

                                    <p>
                                        As specified in the “Detailed information on the processing of
                                        Personal Data” section of this document, our use of your personal data
                                        may be considered a sale under the CPA.
                                    </p>

                                    <p>
                                        For our purposes, the word "sale", "sell", or "sold" means "the
                                        exchange of personal data for monetary or other valuable consideration
                                        by a controller to a third party" as defined by the CPA.
                                    </p>
                                    <p>
                                        Please note that according to the CPA, the disclosure of personal data
                                        to a processor that processes personal data on behalf of a controller
                                        does not constitute a sale. In addition, other specific exceptions set
                                        forth in the CPA may apply, such as, but not limited to, the
                                        disclosure of personal data to a third party for the provision of a
                                        product or service requested by you.
                                    </p>

                                    <h5>
                                        Your right to opt out of the sale of your personal data and how you
                                        can exercise it
                                    </h5>
                                    <p>
                                        You have the right to opt out of the sale of your personal data. This
                                        means that whenever you request us to stop selling your data, we will
                                        abide by your request.
                                    </p>
                                    <p>
                                        To fully exercise your right to opt out you can contact us at any
                                        time, using the contact details provided in this document.
                                    </p>
                                    <p>
                                        For a simplified opt-out method you can also use the privacy choices
                                        link provided on this Application.
                                    </p>
                                    <p>
                                        We use any personal data collected from you in connection with the
                                        submission of your opt-out request solely for the purpose of complying
                                        with the request.
                                    </p>

                                    <h4>Processing of your personal data for targeted advertising</h4>

                                    <p>
                                        We do not process your personal data for targeted advertising. If we
                                        decide to do so, we will inform you beforehand and will grant your
                                        right to opt out of the processing of your personal data for targeted
                                        advertising.
                                    </p>

                                    <p>
                                        For our purposes, the word "targeted advertising" means "displaying to
                                        a consumer an advertisement that is selected based on personal data
                                        obtained or inferred over time from the consumer's activities across
                                        nonaffiliated websites, applications, or online services to predict
                                        consumer preferences or interests" as defined by CPA. <br />
                                        <br />
                                        Please note that according to the CPA, targeted advertising does not
                                        include: “advertisements directed to a consumer in response to the
                                        consumer's request for information or feedback; advertisements based
                                        on activities within a controller's own websites or online
                                        applications or any affiliated website or online application;
                                        advertisements based on the context of a consumer's current search
                                        query, visit to an internet web site or online application; or
                                        processing personal data solely to measure or report advertising
                                        frequency, performance or reach”.
                                    </p>

                                    <h5>Universal opt-out mechanism: Global privacy control</h5>

                                    <p>
                                        If you want to submit requests to opt-out of the targeted advertising
                                        via a user-enabled global privacy control, like the Global Privacy
                                        Control (“<a href="https://globalprivacycontrol.org/">GPC</a>”), you
                                        are free to do so and we will abide by such request. The GPC consists
                                        of a setting or extension in the browser or mobile device and acts as
                                        a mechanism that websites can use to indicate they support the GPC
                                        signal. If you want to use GPC, you can download and enable it via a
                                        participating browser or browser extension. More information about
                                        downloading GPC is available{" "}
                                        <a href="https://globalprivacycontrol.org/">here</a>.
                                    </p>

                                    <h3>
                                        Your privacy rights under the Colorado Privacy Act and how to exercise
                                        them
                                    </h3>

                                    <p>
                                        You may exercise certain rights regarding your data processed by us.
                                        In particular, you have the right to do the following:
                                    </p>
                                    <ul>
                                        <li>
                                            opt out of the processing of your personal data for the purposes
                                            of targeted advertising, the sale of personal data, or profiling
                                            in furtherance of decisions that produce legal or similarly
                                            significant effects concerning you.
                                        </li>
                                        <li>
                                            access personal data. You have the right to request that we
                                            confirm whether or not we are processing your personal data. You
                                            also have the right to access such personal data.
                                        </li>
                                        <li>
                                            correct inaccurate personal data. You have the right to request
                                            that we correct any inaccurate personal data we maintain about
                                            you, taking into account the nature of the personal data and the
                                            purposes of the processing of the personal data.
                                        </li>
                                        <li>
                                            request the deletion of your personal data. You have the right to
                                            request that we delete any of your personal data.
                                        </li>
                                        <li>
                                            obtain a copy of your personal data. We will provide your personal
                                            data in a portable and usable format that allows you to transfer
                                            data easily to another entity – provided that this is technically
                                            feasible.
                                        </li>
                                    </ul>
                                    <p>
                                        In any case, we will not increase the cost of, or decrease the
                                        availability of, a product or service, based solely on the exercise of
                                        any of your rights and unrelated to the feasibility or the value of a
                                        service. However, to the extent permitted by the law, we may offer a
                                        different price, rate, level, quality, or selection of goods or
                                        services to you, including offering goods or services for no fee, if
                                        our offer is related to your voluntary participation in a bona fide
                                        loyalty, rewards, premium features, discounts, or club card program.
                                    </p>

                                    <h4>How to exercise your rights</h4>
                                    <p>
                                        To exercise the rights described above, you need to submit your
                                        request to us by contacting us via the contact details provided in
                                        this document.
                                    </p>
                                    <p>
                                        For us to respond to your request, we need to know who you are and
                                        which right you wish to exercise.
                                    </p>
                                    <p>
                                        We will not respond to any request if we are unable to verify your
                                        identity using commercially reasonable efforts and therefore confirm
                                        that the personal data in our possession actually relate to you. In
                                        such cases, we may request that you provide additional information
                                        which is reasonably necessary to authenticate you and your request.
                                    </p>
                                    <p>
                                        Making a consumer request does not require you to create an account
                                        with us. However, we may require you to use your existing account. We
                                        will use any personal data collected from you in connection with your
                                        request solely for the purposes of authentication, without further
                                        disclosing the personal data, retaining it longer than necessary for
                                        purposes of authentication, or using it for unrelated purposes.
                                    </p>
                                    <p>
                                        If you are an adult, you can make a request on behalf of a child under
                                        your parental authority.
                                    </p>

                                    <h4>How and when we are expected to handle your request</h4>
                                    <p>
                                        We will respond to your request without undue delay, but in all cases
                                        and at the latest within 45 days of its receipt. Should we need more
                                        time, we will explain to you the reasons why, and how much more time
                                        we need. In this regard, please note that we may take up to 90 days to
                                        fulfill your request.
                                    </p>
                                    <p>
                                        Should we deny your request, we will explain to you the reasons behind
                                        our denial without undue delay, but in all cases and at the latest
                                        within 45 days of receipt of the request. It is your right to appeal
                                        such decision by submitting a request to us via the details provided
                                        in this document. Within 45 days of receipt of the appeal, we will
                                        inform you in writing of any action taken or not taken in response to
                                        the appeal, including a written explanation of the reasons for the
                                        decisions. If the appeal is denied you may contact the Attorney
                                        General to submit a complaint.
                                    </p>
                                    <p>
                                        We do not charge a fee to respond to your request, for up to two
                                        requests per year.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="connecticut_info">
                                        Further information for Connecticut consumers
                                    </h2>
                                    <p>
                                        This section of the document integrates with and supplements the
                                        information contained in the rest of the privacy policy and is
                                        provided by the controller running this Application and, if the case
                                        may be, its parent, subsidiaries and affiliates (for the purposes of
                                        this section referred to collectively as “we”, “us”, “our”).
                                    </p>
                                    <p>
                                        This section applies o all Users (Users are referred to below, simply
                                        as “you”, “your”, “yours”), who are consumers residing in the State of
                                        Connecticut, according to “An Act Concerning Personal Data Privacy and
                                        Online Monitoring " (also known as "The Connecticut Data Privacy Act"
                                        or the “CTDPA"), and, for such consumers, it supersedes any other
                                        possibly divergent or conflicting information contained in the privacy
                                        policy.
                                    </p>

                                    <p>
                                        This part of the document uses the term “personal data” as defined in
                                        the CTDPA.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-ctdpa-information-collected">
                                        Categories of personal data processed
                                    </h3>
                                    <p>
                                        In this section, we summarize the categories of personal data that
                                        we've processed and the purposes thereof.{" "}
                                        <b>
                                            You can read about these activities in detail in the section
                                            titled “Detailed information on the processing of Persona Data”
                                            within this document
                                        </b>
                                        .
                                    </p>

                                    <h4>Categories of personal data we collect</h4>
                                    <p>
                                        We have collected the following categories of personal data:
                                        identifiers and internet information
                                    </p>

                                    <p>We do not collect sensitive data.</p>

                                    <p>
                                        We will not collect additional categories of personal data without
                                        notifying you.
                                    </p>

                                    <h4>Why we process your personal data</h4>
                                    <p>
                                        To find out why we process your personal data, you can read the
                                        sections titled “Detailed information on the processing of Personal
                                        Data” and “The purposes of processing” within this document.
                                    </p>
                                    <p>
                                        We won’t process your information for unexpected purposes, or for
                                        purposes incompatible with the purposes originally disclosed, without
                                        your consent.
                                        <br />
                                        You can freely give, deny, or withdraw such consent at any time using
                                        the contact details provided in this document.
                                    </p>

                                    <h4>
                                        How we use the data we collect: sharing of your personal data with
                                        third parties
                                    </h4>
                                    <p>
                                        {" "}
                                        We share your personal data with the third parties{" "}
                                        <b>
                                            listed in detail in the section titled “Detailed information on
                                            the processing of Personal Data” within this document
                                        </b>
                                        . These third parties are grouped and categorized in accordance with
                                        the different purposes of processing.
                                    </p>

                                    <p>
                                        For our purposes, the word "third party" means "a person, public
                                        authority, agency, or body other than a consumer, controller,
                                        processor, or affiliate of the processor or the controller." as
                                        defined by the CTDPA.
                                    </p>

                                    <h4>Sale of your personal data</h4>

                                    <p>
                                        As specified in the “Detailed information on the processing of
                                        Personal Data” section of this document, our use of your personal data
                                        may be considered a sale under the CTDPA.
                                    </p>

                                    <p>
                                        For our purposes, the word "sale", "sell", or "sold" means "the
                                        exchange of personal data for monetary or other valuable consideration
                                        by a controller to a third party" as defined by the CTDPA.
                                    </p>
                                    <p>
                                        Please note that according to the CTDPA, the disclosure of personal
                                        data to a processor that processes personal data on behalf of a
                                        controller does not constitute a sale. In addition, other specific
                                        exceptions set forth in the CTDPA may apply, such as, but not limited
                                        to, the disclosure of personal data to a third party for the provision
                                        of a product or service requested by you.
                                    </p>

                                    <h5>
                                        Your right to opt out of the sale of your personal data and how you
                                        can exercise it
                                    </h5>
                                    <p>
                                        You have the right to opt out of the sale of your personal data. This
                                        means that whenever you request us to stop selling your data, we will
                                        abide by your request.
                                    </p>
                                    <p>
                                        To fully exercise your right to opt out you can contact us at any
                                        time, using the contact details provided in this document.
                                    </p>
                                    <p>
                                        For a simplified opt-out method you can also use the privacy choices
                                        link provided on this Application.
                                    </p>
                                    <p>
                                        We use any personal data collected from you in connection with the
                                        submission of your opt-out request solely for the purpose of complying
                                        with the request.
                                    </p>

                                    <h4>Processing of your personal data for targeted advertising</h4>

                                    <p>
                                        We do not process your personal data for targeted advertising. If we
                                        decide to do so, we will inform you beforehand and will grant your
                                        right to opt out of the processing of your personal data for targeted
                                        advertising.
                                    </p>

                                    <p>
                                        For our purposes, the word "targeted advertising" means "displaying to
                                        a consumer an advertisement that is selected based on personal data
                                        obtained or inferred over time from the consumer's activities across
                                        non affiliated websites, applications, or online services to predict
                                        consumer preferences or interests" as defined by CTDPA. <br />
                                        <br />
                                        Please note that according to the CTDPA, targeted advertising does not
                                        include: “advertisements based on activities within a controller's own
                                        web sites or online applications; advertisements based on the context
                                        of a consumer's current search query, visit to an internet web site or
                                        online application; advertisements directed to a consumer in response
                                        to the consumer's request for information or feedback; or processing
                                        personal data solely to measure or report advertising frequency,
                                        performance or reach”.
                                    </p>

                                    <h5>Universal opt-out mechanism: Global privacy control</h5>

                                    <p>
                                        If you want to submit requests to opt-out of the targeted advertising
                                        via a user-enabled global privacy control, like the Global Privacy
                                        Control (“<a href="https://globalprivacycontrol.org/">GPC</a>”), you
                                        are free to do so and we will abide by such request. The GPC consists
                                        of a setting or extension in the browser or mobile device and acts as
                                        a mechanism that websites can use to indicate they support the GPC
                                        signal. If you want to use GPC, you can download and enable it via a
                                        participating browser or browser extension. More information about
                                        downloading GPC is available{" "}
                                        <a href="https://globalprivacycontrol.org/">here</a>.
                                    </p>

                                    <h3>
                                        Your privacy rights under the Connecticut Data Privacy Act and how to
                                        exercise them
                                    </h3>

                                    <p>
                                        You may exercise certain rights regarding your data processed by us.
                                        In particular, you have the right to do the following:
                                    </p>
                                    <ul>
                                        <li>
                                            access personal data. You have the right to request that we
                                            confirm whether or not we are processing your personal data. You
                                            also have the right to access such personal data.
                                        </li>
                                        <li>
                                            correct inaccurate personal data. You have the right to request
                                            that we correct any inaccurate personal data we maintain about
                                            you, taking into account the nature of the personal data and the
                                            purposes of the processing of the personal data.
                                        </li>
                                        <li>
                                            request the deletion of your personal data. You have the right to
                                            request that we delete any of your personal data.
                                        </li>
                                        <li>
                                            obtain a copy of your personal data. We will provide your personal
                                            data in a portable and usable format that allows you to transfer
                                            data easily to another entity – provided that this is technically
                                            feasible.
                                        </li>
                                        <li>
                                            opt out of the processing of your personal data for the purposes
                                            of targeted advertising, the sale of personal data, or profiling
                                            in furtherance of decisions that produce legal or similarly
                                            significant effects concerning you.
                                        </li>
                                    </ul>
                                    <p>
                                        In any case, we will not increase the cost of, or decrease the
                                        availability of, a product or service, based solely on the exercise of
                                        any of your rights and unrelated to the feasibility or the value of a
                                        service. However, to the extent permitted by the law, we may offer a
                                        different price, rate, level, quality, or selection of goods or
                                        services to you, including offering goods or services for no fee, if
                                        our offer is related to your voluntary participation in a bona fide
                                        loyalty, rewards, premium features, discounts, or club card program.
                                    </p>

                                    <h4>How to exercise your rights</h4>
                                    <p>
                                        To exercise the rights described above, you need to submit your
                                        request to us by contacting us via the contact details provided in
                                        this document.
                                    </p>
                                    <p>
                                        For us to respond to your request, we need to know who you are and
                                        which right you wish to exercise.
                                    </p>
                                    <p>
                                        We will not respond to any request if we are unable to verify your
                                        identity using commercially reasonable efforts and therefore confirm
                                        that the personal data in our possession actually relate to you. In
                                        such cases, we may request that you provide additional information
                                        which is reasonably necessary to authenticate you and your request.
                                    </p>
                                    <p>
                                        Making a consumer request does not require you to create an account
                                        with us. However, we may require you to use your existing account. We
                                        will use any personal data collected from you in connection with your
                                        request solely for the purposes of authentication, without further
                                        disclosing the personal data, retaining it longer than necessary for
                                        purposes of authentication, or using it for unrelated purposes.
                                    </p>
                                    <p>
                                        If you are an adult, you can make a request on behalf of a child under
                                        your parental authority.
                                    </p>

                                    <h4>How and when we are expected to handle your request</h4>
                                    <p>
                                        We will respond to your request without undue delay, but in all cases
                                        and at the latest within 45 days of its receipt. Should we need more
                                        time, we will explain to you the reasons why, and how much more time
                                        we need. In this regard, please note that we may take up to 90 days to
                                        fulfill your request.
                                    </p>
                                    <p>
                                        Should we deny your request, we will explain to you the reasons behind
                                        our denial without undue delay, but in all cases and at the latest
                                        within 45 days of receipt of the request. It is your right to appeal
                                        such decision by submitting a request to us via the details provided
                                        in this document. Within 45 days of receipt of the appeal, we will
                                        inform you in writing of any action taken or not taken in response to
                                        the appeal, including a written explanation of the reasons for the
                                        decisions. If the appeal is denied, you may{" "}
                                        <a href="https://portal.ct.gov/AG/Common/Complaint-Form-Landing-page">
                                            {" "}
                                            contact the Attorney General to submit a complaint
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        We do not charge a fee to respond to your request, for up to one
                                        request per year.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="utah_info">Further information for Utah consumers</h2>
                                    <p>
                                        This section of the document integrates with and supplements the
                                        information contained in the rest of the privacy policy and is
                                        provided by the controller running this Application and, if the case
                                        may be, its parent, subsidiaries and affiliates (for the purposes of
                                        this section referred to collectively as “we”, “us”, “our”).
                                    </p>
                                    <p>
                                        This section applies to all Users (Users are referred to below, simply
                                        as “you”, “your”, “yours”), who are consumers residing in the State of
                                        Utah, according to the “Consumer Privacy Act" (the “UCPA"), and, for
                                        such consumers, it supersedes any other possibly divergent or
                                        conflicting information contained in the privacy policy.
                                    </p>

                                    <p>
                                        This part of the document uses the term “personal data” as defined in
                                        the UCPA.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-ucpa-information-collected">
                                        Categories of personal data processed
                                    </h3>
                                    <p>
                                        In this section, we summarize the categories of personal data that
                                        we've processed and the purposes thereof.{" "}
                                        <b>
                                            You can read about these activities in detail in the section
                                            titled “Detailed information on the processing of Persona Data”
                                            within this document
                                        </b>
                                        .
                                    </p>

                                    <h4>Categories of personal data we collect</h4>
                                    <p>
                                        We have collected the following categories of personal data:
                                        identifiers and internet information
                                    </p>

                                    <p>We do not collect sensitive data.</p>

                                    <p>
                                        We will not collect additional categories of personal data without
                                        notifying you.
                                    </p>

                                    <h4>Why we process your personal data</h4>
                                    <p>
                                        To find out why we process your personal data, you can read the
                                        sections titled “Detailed information on the processing of Personal
                                        Data” and “The purposes of processing” within this document.
                                    </p>

                                    <h4>
                                        How we use the data we collect: sharing of your personal data with
                                        third parties
                                    </h4>
                                    <p>
                                        {" "}
                                        We share your personal data with the third parties{" "}
                                        <b>
                                            listed in detail in the section titled “Detailed information on
                                            the processing of Personal Data” within this document
                                        </b>
                                        . These third parties are grouped and categorized in accordance with
                                        the different purposes of processing.
                                    </p>

                                    <p>
                                        For our purposes, the word "third party" means "a person other than:
                                        the consumer, controller, or processor; or an affiliate or contractor
                                        of the controller or the processor" as defined by the UCPA.
                                    </p>

                                    <h4>Sale of your personal data</h4>

                                    <p>
                                        As specified in the “Detailed information on the processing of
                                        Personal Data” section of this document, our use of your personal data
                                        may be considered a sale under the UCPA.
                                    </p>

                                    <p>
                                        For our purposes, the word "sale", "sell", or "sold" means "the
                                        exchange of personal data for monetary or other valuable consideration
                                        by a controller to a third party" as defined by the UCPA.
                                    </p>
                                    <p>
                                        Please note that according to the UCPA, the disclosure of personal
                                        data to a processor that processes personal data on behalf of a
                                        controller does not constitute a sale. In addition, other specific
                                        exceptions set forth in the UCPA may apply, such as, but not limited
                                        to, the disclosure of personal data to a third party for the provision
                                        of a product or service requested by you.
                                    </p>

                                    <h5>
                                        Your right to opt out of the sale of your personal data and how you
                                        can exercise it
                                    </h5>
                                    <p>
                                        You have the right to opt out of the sale of your personal data. This
                                        means that whenever you request us to stop selling your data, we will
                                        abide by your request.
                                    </p>
                                    <p>
                                        To fully exercise your right to opt out you can contact us at any
                                        time, using the contact details provided in this document.
                                    </p>
                                    <p>
                                        For a simplified opt-out method you can also use the privacy choices
                                        link provided on this Application.
                                    </p>
                                    <p>
                                        We use any personal data collected from you in connection with the
                                        submission of your opt-out request solely for the purpose of complying
                                        with the request.
                                    </p>

                                    <h4>Processing of your personal data for targeted advertising</h4>

                                    <p>
                                        We do not process your personal data for targeted advertising. If we
                                        decide to do so, we will inform you beforehand and will grant your
                                        right to opt out of the processing of your personal data for targeted
                                        advertising.
                                    </p>

                                    <p>
                                        For our purposes, the word "targeted advertising" means "displaying to
                                        a consumer an advertisement that is selected based on personal data
                                        obtained or inferred over time from the consumer's activities across
                                        nonaffiliated websites, applications, or online services to predict
                                        consumer preferences or interests" as defined by UCPA. <br />
                                        <br />
                                        Please note that according to the UCPA, targeted advertising does not
                                        include: “advertisements based on activities within a controller's own
                                        websites or online applications or any affiliated website or online
                                        application; advertisements based on the context of a consumer's
                                        current search query, visit to an web site or online application;
                                        advertisements directed to a consumer in response to the consumer's
                                        request for information, product, a service or feedback; or processing
                                        personal data solely to measure or report advertising performance,
                                        reach or frequency.”
                                    </p>

                                    <h3>
                                        Your privacy rights under the Utah Consumer Privacy Act and how to
                                        exercise them
                                    </h3>

                                    <p>
                                        You may exercise certain rights regarding your data processed by us.
                                        In particular, you have the right to do the following:
                                    </p>
                                    <ul>
                                        <li>
                                            access personal data. You have the right to request that we
                                            confirm whether or not we are processing your personal data. You
                                            also have the right to access such personal data.
                                        </li>
                                        <li>
                                            request the deletion of your personal data. You have the right to
                                            request that we delete any of your personal data.
                                        </li>
                                        <li>
                                            obtain a copy of your personal data. We will provide your personal
                                            data in a portable and usable format that allows you to transfer
                                            data easily to another entity – provided that this is technically
                                            feasible.
                                        </li>
                                        <li>
                                            opt out of the processing of your personal data for the purposes
                                            of targeted advertising or the sale of personal data.
                                        </li>
                                    </ul>
                                    <p>
                                        In any case, we will not increase the cost of, or decrease the
                                        availability of, a product or service, based solely on the exercise of
                                        any of your rights and unrelated to the feasibility or the value of a
                                        service. However, to the extent permitted by the law, we may offer a
                                        different price, rate, level, quality, or selection of goods or
                                        services to you, including offering goods or services for no fee, if
                                        our offer is related to your voluntary participation in a bona fide
                                        loyalty, rewards, premium features, discounts, or club card program.
                                    </p>

                                    <h4>How to exercise your rights</h4>
                                    <p>
                                        To exercise the rights described above, you need to submit your
                                        request to us by contacting us via the contact details provided in
                                        this document.
                                    </p>
                                    <p>
                                        For us to respond to your request, we need to know who you are and
                                        which right you wish to exercise.
                                    </p>
                                    <p>
                                        We will not respond to any request if we are unable to verify your
                                        identity using commercially reasonable efforts and therefore confirm
                                        that the personal data in our possession actually relate to you. In
                                        such cases, we may request that you provide additional information
                                        which is reasonably necessary to authenticate you and your request. We
                                        may retain your email address to respond to your request.
                                    </p>
                                    <p>
                                        If you are an adult, you can make a request on behalf of a child under
                                        your parental authority.
                                    </p>

                                    <h4>How and when we are expected to handle your request</h4>
                                    <p>
                                        We will respond to your request without undue delay, but in all cases
                                        and at the latest within 45 days of its receipt. Should we need more
                                        time, we will explain to you the reasons why, and how much more time
                                        we need. In this regard, please note that we may take up to 90 days to
                                        fulfill your request.
                                    </p>
                                    <p>
                                        Should we deny your request, we will explain to you the reasons behind
                                        our denial without undue delay, but in all cases and at the latest
                                        within 45 days of receipt of the request.
                                    </p>
                                    <p>
                                        We do not charge a fee to respond to your request, for up to one
                                        request per year.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <h2 id="further_data_processing_info">
                                        Additional information about Data collection and processing
                                    </h2>
                                    <h3 className="iub-subheading iub-subheading-legal-protection">
                                        Legal action
                                    </h3>
                                    <p>
                                        The User's Personal Data may be used for legal purposes by the Owner
                                        in Court or in the stages leading to possible legal action arising
                                        from improper use of this Application or the related Services.
                                        <br />
                                        The User declares to be aware that the Owner may be required to reveal
                                        personal data upon request of public authorities.
                                    </p>
                                    <h3 className="iub-subheading iub-subheading-legal-specific-info">
                                        Additional information about User's Personal Data
                                    </h3>
                                    <p>
                                        In addition to the information contained in this privacy policy, this
                                        Application may provide the User with additional and contextual
                                        information concerning particular Services or the collection and
                                        processing of Personal Data upon request.
                                    </p>
                                    <h3 className="iub-subheading iub-subheading-legal-maintenance">
                                        System logs and maintenance
                                    </h3>
                                    <p>
                                        For operation and maintenance purposes, this Application and any
                                        third-party services may collect files that record interaction with
                                        this Application (System logs) or use other Personal Data (such as the
                                        IP Address) for this purpose.
                                    </p>
                                    <h3 className="iub-subheading iub-subheading-legal-information-not-contained">
                                        Information not contained in this policy
                                    </h3>
                                    <p>
                                        More details concerning the collection or processing of Personal Data
                                        may be requested from the Owner at any time. Please see the contact
                                        information at the beginning of this document.
                                    </p>

                                    <h3 className="iub-subheading iub-subheading-legal-changes-to-this-privacy-policy">
                                        Changes to this privacy policy
                                    </h3>
                                    <p>
                                        The Owner reserves the right to make changes to this privacy policy at
                                        any time by notifying its Users on this page and possibly within this
                                        Application and/or - as far as technically and legally feasible -
                                        sending a notice to Users via any contact information available to the
                                        Owner. It is strongly recommended to check this page often, referring
                                        to the date of the last modification listed at the bottom. <br />
                                        <br />
                                        Should the changes affect processing activities performed on the basis
                                        of the User’s consent, the Owner shall collect new consent from the
                                        User, where required.
                                    </p>
                                </div>

                                <div className="one_line_col">
                                    <div className="box_primary box_10 definitions expand">
                                        <h3
                                            id="definitions_and_legal_references"
                                            className="expand-click w_icon_24 icon_ribbon"
                                        >
                                            Definitions and legal references
                                        </h3>
                                        <div className="expand-content">
                                            <h4>Personal Data (or Data)</h4>
                                            <p>
                                                Any information that directly, indirectly, or in connection
                                                with other information — including a personal identification
                                                number — allows for the identification or identifiability of a
                                                natural person.
                                            </p>

                                            <h4>Usage Data</h4>
                                            <p>
                                                Information collected automatically through this Application
                                                (or third-party services employed in this Application), which
                                                can include: the IP addresses or domain names of the computers
                                                utilized by the Users who use this Application, the URI
                                                addresses (Uniform Resource Identifier), the time of the
                                                request, the method utilized to submit the request to the
                                                server, the size of the file received in response, the
                                                numerical code indicating the status of the server's answer
                                                (successful outcome, error, etc.), the country of origin, the
                                                features of the browser and the operating system utilized by
                                                the User, the various time details per visit (e.g., the time
                                                spent on each page within the Application) and the details
                                                about the path followed within the Application with special
                                                reference to the sequence of pages visited, and other
                                                parameters about the device operating system and/or the User's
                                                IT environment.
                                            </p>

                                            <h4>User</h4>
                                            <p>
                                                The individual using this Application who, unless otherwise
                                                specified, coincides with the Data Subject.
                                            </p>

                                            <h4>Data Subject</h4>
                                            <p>The natural person to whom the Personal Data refers.</p>

                                            <h4>Data Processor (or Processor)</h4>
                                            <p>
                                                The natural or legal person, public authority, agency or other
                                                body which processes Personal Data on behalf of the
                                                Controller, as described in this privacy policy.
                                            </p>

                                            <h4>Data Controller (or Owner)</h4>
                                            <p>
                                                The natural or legal person, public authority, agency or other
                                                body which, alone or jointly with others, determines the
                                                purposes and means of the processing of Personal Data,
                                                including the security measures concerning the operation and
                                                use of this Application. The Data Controller, unless otherwise
                                                specified, is the Owner of this Application.
                                            </p>

                                            <h4>This Application</h4>
                                            <p>
                                                The means by which the Personal Data of the User is collected
                                                and processed.
                                            </p>

                                            <h4>Service</h4>
                                            <p>
                                                The service provided by this Application as described in the
                                                relative terms (if available) and on this site/application.
                                            </p>

                                            <h4>European Union (or EU)</h4>
                                            <p>
                                                Unless otherwise specified, all references made within this
                                                document to the European Union include all current member
                                                states to the European Union and the European Economic Area.
                                            </p>

                                            <h4>Cookie</h4>
                                            <p>
                                                Cookies are Trackers consisting of small sets of data stored
                                                in the User's browser.
                                            </p>

                                            <h4>Tracker</h4>
                                            <p>
                                                Tracker indicates any technology - e.g Cookies, unique
                                                identifiers, web beacons, embedded scripts, e-tags and
                                                fingerprinting - that enables the tracking of Users, for
                                                example by accessing or storing information on the User’s
                                                device.
                                            </p>
                                            <hr />
                                            <h4>Legal information</h4>
                                            <p>
                                                This privacy statement has been prepared based on provisions
                                                of multiple legislations.
                                            </p>
                                            <p>
                                                This privacy policy relates solely to this Application, if not
                                                stated otherwise within this document.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="iub_footer">
                                    <p>Latest update: February 03, 2024</p>

                                    <p>
                                        <a
                                            target="_top"
                                            href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
                                            title="iubenda - Privacy Policy generator"
                                        >
                                            iubenda
                                        </a>{" "}
                                        hosts this content and only collects{" "}
                                        <a target="_top" href="//www.iubenda.com/privacy-policy/65675001">
                                            the Personal Data strictly necessary
                                        </a>{" "}
                                        for it to be provided.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <SmallFooter /> */}
        </motion.main>
    );
}
