import React from "react";

export default function ChartSkeleton() {
    return (
        <div className="chart-skeleton">
            <div className="line line-left"></div>
            <div className="line line-right"></div>
        </div>
    );
}
