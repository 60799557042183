import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../context";
import { useStateContext } from "../../stateContext";
import { Modal } from "../../components";

import {
    Navbar,
    Button,
    CampaignsTable,
    ActivityTable,
    Skeleton,
    Select,
    Drawer,
    CampaignsWizard,
    Tabs,
} from "../";
import localData from "../../localData";
import CampaignInformation from "./tabs/CampaignInformation";
import Targeting from "./tabs/Targeting";
import UploadAds from "./tabs/UploadAds";
import Summary from "./tabs/Summary";
import { auth } from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import ReactCrop, {
    centerCrop,
    convertToPercentCrop,
    convertToPixelCrop,
    makeAspectCrop,
} from "react-image-crop";
import useCrop from "../../hooks/useCrop";
import useUtil from "../../hooks/useUtil";

export default function CampaingsWizard() {
    const {
        showCampaingWizardDrawer,
        setShowCampaignWizardDrawer,
        campaignWizardStatus,
        setCampaignWizardStatus,
        requestedData,
        isLoading,
        formatDate,
        campaignState,
        setCampaignState,
        defaultCampaign,
        setSelectedImages,
        setCreatives,
        campaignsState,
    } = useGlobalContext();

    const { campaignTabs, setCampaignTabs, displayCreativesModal, setDisplayCreativesModal } = campaignsState;

    const { balance, balancePredicted } = requestedData.userProfileData.currentOrganization;

    const { plus, arrowRightExtended, checkbox, alert } = localData.svgs;

    const tabs = [
        {
            title: "Campaign Information",
            startIcon: checkbox,
            content: <CampaignInformation />,
            id: "campain-information-btn",
            tabClickable: campaignTabs.clickable.includes(1),
            tabMarked: campaignTabs.marked.includes(1),
            tabDisabled: campaignTabs.disabled.includes(1),
        },
        {
            title: "Targeting",
            startIcon: checkbox,
            content: <Targeting />,
            id: "targeting-btn",
            tabClickable: campaignTabs.clickable.includes(2),
            tabMarked: campaignTabs.marked.includes(2),
            tabDisabled: campaignTabs.disabled.includes(2),
        },
        {
            title: "Upload Ads",
            startIcon: checkbox,
            content: <UploadAds />,
            id: "upload-ads-btn",
            tabClickable: campaignTabs.clickable.includes(3),
            tabMarked: campaignTabs.marked.includes(3),
            tabDisabled: campaignTabs.disabled.includes(3),
        },
        {
            title: "Summary",
            startIcon: checkbox,
            content: <Summary />,
            id: "summary-btn",
            tabClickable: campaignTabs.clickable.includes(4),
            tabMarked: campaignTabs.marked.includes(4),
            tabDisabled: campaignTabs.disabled.includes(4),
        },
    ];

    return (
        <div className={`campaigns-wizard campaigns-wizard-${campaignWizardStatus}`}>
            <Drawer
                display={showCampaingWizardDrawer}
                setDisplay={setShowCampaignWizardDrawer}
                // tooltipContent={`${
                //     balance.toFixed(0) > 0
                //         ? "Launch a new ad campaign."
                //         : "“Top up your account balance before launching a campaign”."
                // }`}
                // disabled={balance.toFixed(0) > 0 ? false : true}
                toggler={
                    <Button
                        name="Create Campaign"
                        variant="contained"
                        startIcon={plus}
                        color="success"
                        // disabled={ balance.toFixed(0) > 0 ? false : true}
                        id="login-tutorial-3"
                        // onClick={()=>{
                        //     setTimeout(()=>{
                        //         setSteps(prev=>({...prev, run: true}))
                        //     },1000)
                        // }}
                    />
                }
                className="drawer-right drawer-fullscreen campaign-create-drawer"
                callback={() => {
                    setCampaignWizardStatus("create");
                    setCampaignState(defaultCampaign);
                    setSelectedImages([]);
                    setCreatives([]);
                    setCampaignTabs({
                        clickable: [],
                        marked: [],
                        disabled: [],
                    });
                }}
            >
                {campaignWizardStatus === "create" && balancePredicted.toFixed(0) <= 0 && (
                    <marquee direction="left">
                        <div className="alert alert-warning">
                            {alert} “Top up your account balance before launching a campaign”.
                        </div>
                    </marquee>
                )}
                <h2 className="campaigns-wizard-title display-2">{campaignWizardStatus} Campaign</h2>
                <Tabs
                    tabsProps={{
                        campaignWizardStatus,
                        setCampaignWizardStatus,
                    }}
                    tabs={tabs}
                    title="Create Camaign"
                    indicatorIcon={arrowRightExtended}
                    className="campaign-wizard-tabs"
                />
            </Drawer>
        </div>
    );
}
