import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "./context";
import useFormatter from "./hooks/useFormatter";

export const CampaignsContext = createContext();

// const endDate = new Date(); // today
// const startDate = new Date(endDate);
// startDate.setDate(endDate.getDate() - 6); // 7 days ago

// const selectionRange = JSON.parse(localStorage.getItem("selectionRange")) || {};

// const DEFAULTDATE = {
//     // startDate: startOfWeek,
//     // endDate: endOfWeek,
//     startDate:
//         (selectionRange.startDate && new Date(selectionRange.startDate)) || startDate,
//     endDate: (selectionRange.endDate && new Date(selectionRange.endDate)) || endDate,
//     // startDate: new Date(),
//     // endDate: new Date(),
//     key: "selection",
// };

export default function CampaignsProvider({ children }) {
    // const {
    //     requestedData: {
    //         userProfileData,
    //         campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
    //     },
    //     campaigns,
    //     setCampaigns,
    // } = useGlobalContext();
    // const { filteredCampaignsDateRange } = useFormatter();

    // const [isCampaignsCalendarOpen, setIsCampaignsCalendarOpen] = useState(false);
    // const [selectionRange, setSelectionRange] = useState(DEFAULTDATE);
    // const [defaultSelectionRange, setDefaultSelectionRange] = useState(DEFAULTDATE);
    // const [dateRange, setDateRange] = useState("");

    // useEffect(() => {
    //     if (!isCampaignsCalendarOpen) return;
    //     setSelectionRange(defaultSelectionRange);
    // }, [isCampaignsCalendarOpen]);

    return (
        <CampaignsContext.Provider
            value={{
                // isCampaignsCalendarOpen,
                // setIsCampaignsCalendarOpen,
                // selectionRange,
                // setSelectionRange,
                // dateRange,
                // setDateRange,
                // setDefaultSelectionRange,
            }}
        >
            {children}
        </CampaignsContext.Provider>
    );
}

export const useCampaignsContext = () => useContext(CampaignsContext);
