import React, { useState } from "react";
import useUtil from "../../hooks/useUtil";
import { Button } from "..";
import localData from "../../localData";

export default function CopyTextButton({ text, size='sm',disabled=false }) {
    const { copyText2 } = useUtil();

    const [isCopied, setIsCopied] = useState();

    const { copy, check } = localData.svgs;

    const handleCopyText = async () => {
        if (isCopied) return;
        const success = await copyText2(text);

        if (!success) return;
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    return (
        <Button
            className="copy-text-btn"
            tabIndex={-1}
            onClick={handleCopyText}
            // className="field-endIcon"
            icon={isCopied ? check : copy}
            variant="circle"
            size={size}
            color="secondary"
            type="button"
            tooltipContent={isCopied ? "Copied!" : "Copy"}
            disabled={disabled}
        />
    );
}
