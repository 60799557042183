import React from "react";
import localData from "../../localData";

export default function Loading({className = ''}) {
    const { preloader } = localData.images;
    return (
        <div className={`loading ${className}`}>
                <img src={preloader} alt="" />
            {/* <span className="sr-only">Loading...</span> */}
        </div>
    );
}
