import React, { useState, useRef, useEffect } from "react";
import localData from "../../localData";
import useUtil from "../../hooks/useUtil";
import { Button } from "../../components";

export default function ReferralBanner({ type = "column", name = "", value = "" }) {
    const { placeholder } = localData.images;
    const [_value, _setValue] = useState(value);
    const [isCopied, setIsCopied] = useState();

    useEffect(()=>{
        _setValue(value)
    },[value])

    const { copy, check } = localData.svgs;

    const fieldRef = useRef(null);
    const { copyText } = useUtil();

    const handleCopyText = async () => {
        if (isCopied) return;
        const success = await copyText(fieldRef.current);

        if (!success) return;
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    return (
        <div className={`referral-banner referral-banner-${type}`}>
            <h2 className="referral-banner-name">{name}</h2>
            <div className="referral-banner-inner">
                <div className="referral-banner-image" dangerouslySetInnerHTML={{ __html: _value }}></div>
                <div className="referral-banner-textarea">
                    <textarea
                        name=""
                        id=""
                        value={_value}
                        onChange={(e) => _setValue(e.target.value)}
                        ref={fieldRef}
                    >
                    
                    </textarea>
                        <Button
                            tabIndex={-1}
                            onClick={handleCopyText}
                            className="field-endIcon"
                            icon={isCopied ? check : copy}
                            variant="circle"
                            size="sm"
                            color="secondary"
                            type="button"
                            tooltipContent={isCopied ? "Copied!" : "Copy Link"}
                        />
                </div>
            </div>
        </div>
    );
}
