import React, { useState, useEffect } from "react";
import localData from "../../localData";

export default function useAccountsSort({ requestedData, setRequestedData }) {
    const { sortDown, sortUp, sortIcon } = localData.svgs;

    const [sort, setSort] = useState({
        type: "",
        orderDest: "desc",
    });

    const handleOnClick = (type = "", orderDest = null, callback = () => {}) => {
        callback();
        const a = sort.type !== type || sort.orderDest == "esc" ? "desc" : "esc";
        setSort({
            type,
            orderDest: orderDest || a,
        });
    };

    const getSortIcon = (type) => {
        if (sort.type !== type) return <span className="sort-btn">{sortIcon}</span>;
        return <span className="sort-btn active">{sort.orderDest == "desc" ? sortUp : sortDown}</span>;
    };

    const handleSorting = () => {
        const tempRequestedData = { ...requestedData };
        const tempUserProfileData = { ...tempRequestedData.userProfileData };
        const tempItems = [...tempUserProfileData.allOrganization];

        let sortedTempItems = [];

        if (sort.orderDest == "desc") {
            sortedTempItems = [...tempItems].sort((a, b) => b[sort.type] - a[sort.type]);
        } else {
            sortedTempItems = [...tempItems].sort((a, b) => a[sort.type] - b[sort.type]);
        }

        setRequestedData({
            ...requestedData,
            userProfileData: { ...requestedData.userProfileData, allOrganization: sortedTempItems },
        });
    };

    useEffect(() => {
        if (!requestedData.userProfileData.allOrganization.length) return;
        handleSorting();
    }, [sort]);

    return { handleOnClick, getSortIcon };
}
