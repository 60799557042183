import React, { useEffect,useState } from "react";

export default function Switch(
    { checked = false, color = "primary", label = "", callback = () => {},disabled = false },
    
) {
    const [isChecked, setIsChecked]=useState(checked)
    useEffect(()=>{
        setIsChecked(checked)
    },[checked])
    return (
        <label className={`form-check ${disabled ? 'disabled': ''}`}>
            <input
                type="checkbox"
                className="form-check-input"
                checked={isChecked}
                onChange={(e) => callback(e.target.checked)}
            />
            {label && <span className="form-check-label">{label}</span>}
            <div className="wrapper">
                <div className={`form-check-switch text-${color}`}></div>
            </div>
        </label>
    );
}
