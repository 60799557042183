import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useGlobalContext } from "../../context";
import { Button } from "../../components";
import { v4 as uuidv4 } from "uuid";
import useFormatter from "../../hooks/useFormatter";

// const countries = [
//     {
//         id: 403915,
//         value: "ad",
//         label: "Andorra",
//     },
//     {
//         id: 403916,
//         value: "ae",
//         label: "United Arab Emirates",
//     },
//     {
//         id: 403918,
//         value: "af",
//         label: "Afghanistan",
//     },
//     {
//         id: 403920,
//         value: "ag",
//         label: "Antigua And Barbuda",
//     },
//     {
//         id: 403921,
//         value: "ai",
//         label: "Anguilla",
//     },
//     {
//         id: 403923,
//         value: "al",
//         label: "Albania",
//     },
//     {
//         id: 403924,
//         value: "am",
//         label: "Armenia",
//     },
//     {
//         id: 403926,
//         value: "an",
//         label: "Netherlands Antilles",
//     },
//     {
//         id: 403928,
//         value: "ao",
//         label: "Angola",
//     },
//     {
//         id: 403931,
//         value: "aq",
//         label: "Antarctica",
//     },
//     {
//         id: 403932,
//         value: "ar",
//         label: "Argentina",
//     },
//     {
//         id: 403934,
//         value: "as",
//         label: "American Samoa",
//     },
//     {
//         id: 403936,
//         value: "at",
//         label: "Austria",
//     },
//     {
//         id: 403937,
//         value: "au",
//         label: "Australia",
//     },
//     {
//         id: 403939,
//         value: "aw",
//         label: "Aruba",
//     },
//     {
//         id: 403940,
//         value: "ax",
//         label: "Aland Islands",
//     },
//     {
//         id: 403942,
//         value: "az",
//         label: "Azerbaijan",
//     },
//     {
//         id: 403944,
//         value: "ba",
//         label: "Bosnia And Herzegowina",
//     },
//     {
//         id: 403946,
//         value: "bb",
//         label: "Barbados",
//     },
//     {
//         id: 403947,
//         value: "bd",
//         label: "Bangladesh",
//     },
//     {
//         id: 403948,
//         value: "be",
//         label: "Belgium",
//     },
//     {
//         id: 403950,
//         value: "bf",
//         label: "Burkina Faso",
//     },
//     {
//         id: 403951,
//         value: "bg",
//         label: "Bulgaria",
//     },
//     {
//         id: 403953,
//         value: "bh",
//         label: "Bahrain",
//     },
//     {
//         id: 403955,
//         value: "bi",
//         label: "Burundi",
//     },
//     {
//         id: 403957,
//         value: "bj",
//         label: "Benin",
//     },
//     {
//         id: 403958,
//         value: "bl",
//         label: "Saint Barthelemy",
//     },
//     {
//         id: 403959,
//         value: "bm",
//         label: "Bermuda",
//     },
//     {
//         id: 403961,
//         value: "bn",
//         label: "Brunei Darussalam",
//     },
//     {
//         id: 403963,
//         value: "bo",
//         label: "Bolivia",
//     },
//     {
//         id: 403965,
//         value: "bq",
//         label: "Bonaire/sint Eustatius/saba",
//     },
//     {
//         id: 403966,
//         value: "br",
//         label: "Brazil",
//     },
//     {
//         id: 403969,
//         value: "bs",
//         label: "Bahamas",
//     },
//     {
//         id: 403971,
//         value: "bt",
//         label: "Bhutan",
//     },
//     {
//         id: 403972,
//         value: "bv",
//         label: "Bouvet Island",
//     },
//     {
//         id: 403973,
//         value: "bw",
//         label: "Botswana",
//     },
//     {
//         id: 403974,
//         value: "by",
//         label: "Belarus",
//     },
//     {
//         id: 403975,
//         value: "bz",
//         label: "Belize",
//     },
//     {
//         id: 403976,
//         value: "ca",
//         label: "Canada",
//     },
//     {
//         id: 403979,
//         value: "cc",
//         label: "Cocos (keeling) Islands",
//     },
//     {
//         id: 403980,
//         value: "cd",
//         label: "Democratic Republic Of The Congo",
//     },
//     {
//         id: 403982,
//         value: "cf",
//         label: "Central African Republic",
//     },
//     {
//         id: 403983,
//         value: "cg",
//         label: "Congo",
//     },
//     {
//         id: 403984,
//         value: "ch",
//         label: "Switzerland",
//     },
//     {
//         id: 403987,
//         value: "ci",
//         label: "Cote D Ivoire",
//     },
//     {
//         id: 403988,
//         value: "ck",
//         label: "Cook Islands",
//     },
//     {
//         id: 403989,
//         value: "cl",
//         label: "Chile",
//     },
//     {
//         id: 403990,
//         value: "cm",
//         label: "Cameroon",
//     },
//     {
//         id: 403991,
//         value: "cn",
//         label: "China",
//     },
//     {
//         id: 403992,
//         value: "co",
//         label: "Colombia",
//     },
//     {
//         id: 403994,
//         value: "cr",
//         label: "Costa Rica",
//     },
//     {
//         id: 403997,
//         value: "cu",
//         label: "Cuba",
//     },
//     {
//         id: 403999,
//         value: "cv",
//         label: "Cape Verde",
//     },
//     {
//         id: 404001,
//         value: "cw",
//         label: "Curacao",
//     },
//     {
//         id: 404002,
//         value: "cx",
//         label: "Christmas Island",
//     },
//     {
//         id: 404003,
//         value: "cy",
//         label: "Cyprus",
//     },
//     {
//         id: 404005,
//         value: "cz",
//         label: "Czech Republic",
//     },
//     {
//         id: 404007,
//         value: "de",
//         label: "Germany",
//     },
//     {
//         id: 404011,
//         value: "dj",
//         label: "Djibouti",
//     },
//     {
//         id: 404012,
//         value: "dk",
//         label: "Denmark",
//     },
//     {
//         id: 404013,
//         value: "dm",
//         label: "Dominica",
//     },
//     {
//         id: 404014,
//         value: "do",
//         label: "Dominican Republic",
//     },
//     {
//         id: 404016,
//         value: "dz",
//         label: "Algeria",
//     },
//     {
//         id: 404018,
//         value: "ec",
//         label: "Ecuador",
//     },
//     {
//         id: 404019,
//         value: "ee",
//         label: "Estonia",
//     },
//     {
//         id: 404021,
//         value: "eg",
//         label: "Egypt",
//     },
//     {
//         id: 404022,
//         value: "eh",
//         label: "Western Sahara",
//     },
//     {
//         id: 404026,
//         value: "er",
//         label: "Eritrea",
//     },
//     {
//         id: 404027,
//         value: "es",
//         label: "Spain",
//     },
//     {
//         id: 404029,
//         value: "et",
//         label: "Ethiopia",
//     },
//     {
//         id: 404035,
//         value: "fi",
//         label: "Finland",
//     },
//     {
//         id: 404038,
//         value: "fj",
//         label: "Fiji",
//     },
//     {
//         id: 404040,
//         value: "fk",
//         label: "Falkland Islands (malvinas)",
//     },
//     {
//         id: 404041,
//         value: "fm",
//         label: "Federated States Of Micronesia",
//     },
//     {
//         id: 404042,
//         value: "fo",
//         label: "Faroe Islands",
//     },
//     {
//         id: 404044,
//         value: "fr",
//         label: "France",
//     },
//     {
//         id: 404047,
//         value: "ga",
//         label: "Gabon",
//     },
//     {
//         id: 404049,
//         value: "gb",
//         label: "United Kingdom",
//     },
//     {
//         id: 404050,
//         value: "gd",
//         label: "Grenada",
//     },
//     {
//         id: 404052,
//         value: "ge",
//         label: "Georgia",
//     },
//     {
//         id: 404053,
//         value: "gf",
//         label: "French Guiana",
//     },
//     {
//         id: 404054,
//         value: "gg",
//         label: "Guernsey",
//     },
//     {
//         id: 404055,
//         value: "gh",
//         label: "Ghana",
//     },
//     {
//         id: 404056,
//         value: "gi",
//         label: "Gibraltar",
//     },
//     {
//         id: 404057,
//         value: "gl",
//         label: "Greenland",
//     },
//     {
//         id: 404059,
//         value: "gm",
//         label: "Gambia",
//     },
//     {
//         id: 404060,
//         value: "gn",
//         label: "Guinea",
//     },
//     {
//         id: 404062,
//         value: "gp",
//         label: "Guadeloupe",
//     },
//     {
//         id: 404063,
//         value: "gq",
//         label: "Equatorial Guinea",
//     },
//     {
//         id: 404064,
//         value: "gr",
//         label: "Greece",
//     },
//     {
//         id: 404065,
//         value: "gs",
//         label: "South Georgia / South Sandwich Isl",
//     },
//     {
//         id: 404066,
//         value: "gt",
//         label: "Guatemala",
//     },
//     {
//         id: 404067,
//         value: "gu",
//         label: "Guam",
//     },
//     {
//         id: 404070,
//         value: "gw",
//         label: "Guinea-bissau",
//     },
//     {
//         id: 404071,
//         value: "gy",
//         label: "Guyana",
//     },
//     {
//         id: 404075,
//         value: "hk",
//         label: "Hong Kong",
//     },
//     {
//         id: 404076,
//         value: "hm",
//         label: "Heard And Mc Donald Islands",
//     },
//     {
//         id: 404077,
//         value: "hn",
//         label: "Honduras",
//     },
//     {
//         id: 404079,
//         value: "hr",
//         label: "Croatia",
//     },
//     {
//         id: 404081,
//         value: "ht",
//         label: "Haiti",
//     },
//     {
//         id: 404083,
//         value: "hu",
//         label: "Hungary",
//     },
//     {
//         id: 404088,
//         value: "id",
//         label: "Indonesia",
//     },
//     {
//         id: 404090,
//         value: "ie",
//         label: "Ireland",
//     },
//     {
//         id: 404096,
//         value: "il",
//         label: "Israel",
//     },
//     {
//         id: 404097,
//         value: "im",
//         label: "Isle Of Man",
//     },
//     {
//         id: 404098,
//         value: "in",
//         label: "India",
//     },
//     {
//         id: 404099,
//         value: "io",
//         label: "British Indian Ocean Territory",
//     },
//     {
//         id: 404104,
//         value: "iq",
//         label: "Iraq",
//     },
//     {
//         id: 404105,
//         value: "ir",
//         label: "Iran (islamic Republic Of)",
//     },
//     {
//         id: 404106,
//         value: "is",
//         label: "Iceland",
//     },
//     {
//         id: 404108,
//         value: "it",
//         label: "Italy",
//     },
//     {
//         id: 404112,
//         value: "je",
//         label: "Jersey",
//     },
//     {
//         id: 404113,
//         value: "jm",
//         label: "Jamaica",
//     },
//     {
//         id: 404114,
//         value: "jo",
//         label: "Jordan",
//     },
//     {
//         id: 404115,
//         value: "jp",
//         label: "Japan",
//     },
//     {
//         id: 404118,
//         value: "ke",
//         label: "Kenya",
//     },
//     {
//         id: 404119,
//         value: "kg",
//         label: "Kyrgyzstan",
//     },
//     {
//         id: 404121,
//         value: "kh",
//         label: "Cambodia",
//     },
//     {
//         id: 404122,
//         value: "ki",
//         label: "Kiribati",
//     },
//     {
//         id: 404128,
//         value: "km",
//         label: "Comoros",
//     },
//     {
//         id: 404130,
//         value: "kn",
//         label: "Saint Kitts And Nevis",
//     },
//     {
//         id: 404133,
//         value: "kp",
//         label: "North Korea",
//     },
//     {
//         id: 404134,
//         value: "kr",
//         label: "South Korea",
//     },
//     {
//         id: 404139,
//         value: "kw",
//         label: "Kuwait",
//     },
//     {
//         id: 404141,
//         value: "ky",
//         label: "Cayman Islands",
//     },
//     {
//         id: 404143,
//         value: "kz",
//         label: "Kazakhstan",
//     },
//     {
//         id: 404144,
//         value: "la",
//         label: "Lao Peoples Democratic Republic",
//     },
//     {
//         id: 404146,
//         value: "lb",
//         label: "Lebanon",
//     },
//     {
//         id: 404148,
//         value: "lc",
//         label: "Saint Lucia",
//     },
//     {
//         id: 404150,
//         value: "li",
//         label: "Liechtenstein",
//     },
//     {
//         id: 404152,
//         value: "lk",
//         label: "Sri Lanka",
//     },
//     {
//         id: 404155,
//         value: "lr",
//         label: "Liberia",
//     },
//     {
//         id: 404156,
//         value: "ls",
//         label: "Lesotho",
//     },
//     {
//         id: 404157,
//         value: "lt",
//         label: "Lithuania",
//     },
//     {
//         id: 404159,
//         value: "lu",
//         label: "Luxembourg",
//     },
//     {
//         id: 404161,
//         value: "lv",
//         label: "Latvia",
//     },
//     {
//         id: 404163,
//         value: "ly",
//         label: "Libyan Arab Jamahiriya",
//     },
//     {
//         id: 404164,
//         value: "ma",
//         label: "Morocco",
//     },
//     {
//         id: 404165,
//         value: "mc",
//         label: "Monaco",
//     },
//     {
//         id: 404166,
//         value: "md",
//         label: "Moldova",
//     },
//     {
//         id: 404167,
//         value: "me",
//         label: "Montenegro",
//     },
//     {
//         id: 404168,
//         value: "mf",
//         label: "Saint Martin",
//     },
//     {
//         id: 404169,
//         value: "mg",
//         label: "Madagascar",
//     },
//     {
//         id: 404171,
//         value: "mh",
//         label: "Marshall Islands",
//     },
//     {
//         id: 404174,
//         value: "mk",
//         label: "Macedonia",
//     },
//     {
//         id: 404176,
//         value: "ml",
//         label: "Mali",
//     },
//     {
//         id: 404178,
//         value: "mm",
//         label: "Myanmar",
//     },
//     {
//         id: 404179,
//         value: "mn",
//         label: "Mongolia",
//     },
//     {
//         id: 404181,
//         value: "mo",
//         label: "Macau",
//     },
//     {
//         id: 404183,
//         value: "mp",
//         label: "Northern Mariana Islands",
//     },
//     {
//         id: 404184,
//         value: "mq",
//         label: "Martinique",
//     },
//     {
//         id: 404185,
//         value: "mr",
//         label: "Mauritania",
//     },
//     {
//         id: 404187,
//         value: "ms",
//         label: "Montserrat",
//     },
//     {
//         id: 404189,
//         value: "mt",
//         label: "Malta",
//     },
//     {
//         id: 404191,
//         value: "mu",
//         label: "Mauritius",
//     },
//     {
//         id: 404192,
//         value: "mv",
//         label: "Maldives",
//     },
//     {
//         id: 404193,
//         value: "mw",
//         label: "Malawi",
//     },
//     {
//         id: 404194,
//         value: "mx",
//         label: "Mexico",
//     },
//     {
//         id: 404195,
//         value: "my",
//         label: "Malaysia",
//     },
//     {
//         id: 404197,
//         value: "mz",
//         label: "Mozambique",
//     },
//     {
//         id: 404198,
//         value: "na",
//         label: "Namibia",
//     },
//     {
//         id: 404202,
//         value: "nc",
//         label: "New Caledonia",
//     },
//     {
//         id: 404204,
//         value: "ne",
//         label: "Niger",
//     },
//     {
//         id: 404206,
//         value: "nf",
//         label: "Norfolk Island",
//     },
//     {
//         id: 404207,
//         value: "ng",
//         label: "Nigeria",
//     },
//     {
//         id: 404209,
//         value: "ni",
//         label: "Nicaragua",
//     },
//     {
//         id: 404210,
//         value: "nl",
//         label: "Netherlands",
//     },
//     {
//         id: 404213,
//         value: "no",
//         label: "Norway",
//     },
//     {
//         id: 404215,
//         value: "np",
//         label: "Nepal",
//     },
//     {
//         id: 404216,
//         value: "nr",
//         label: "Nauru",
//     },
//     {
//         id: 404218,
//         value: "nu",
//         label: "Niue",
//     },
//     {
//         id: 404221,
//         value: "nz",
//         label: "New Zealand",
//     },
//     {
//         id: 404226,
//         value: "om",
//         label: "Oman",
//     },
//     {
//         id: 404233,
//         value: "pa",
//         label: "Panama",
//     },
//     {
//         id: 404235,
//         value: "pe",
//         label: "Peru",
//     },
//     {
//         id: 404236,
//         value: "pf",
//         label: "French Polynesia",
//     },
//     {
//         id: 404237,
//         value: "pg",
//         label: "Papua New Guinea",
//     },
//     {
//         id: 404238,
//         value: "ph",
//         label: "Philippines",
//     },
//     {
//         id: 404240,
//         value: "pk",
//         label: "Pakistan",
//     },
//     {
//         id: 404241,
//         value: "pl",
//         label: "Poland",
//     },
//     {
//         id: 404244,
//         value: "pm",
//         label: "St. Pierre And Miquelon",
//     },
//     {
//         id: 404245,
//         value: "pn",
//         label: "Pitcairn",
//     },
//     {
//         id: 404246,
//         value: "pr",
//         label: "Puerto Rico",
//     },
//     {
//         id: 404247,
//         value: "ps",
//         label: "Palestinian Territories",
//     },
//     {
//         id: 404249,
//         value: "pt",
//         label: "Portugal",
//     },
//     {
//         id: 404252,
//         value: "pw",
//         label: "Palau",
//     },
//     {
//         id: 404253,
//         value: "py",
//         label: "Paraguay",
//     },
//     {
//         id: 404254,
//         value: "qa",
//         label: "Qatar",
//     },
//     {
//         id: 404256,
//         value: "re",
//         label: "Reunion",
//     },
//     {
//         id: 404259,
//         value: "ro",
//         label: "Romania",
//     },
//     {
//         id: 404261,
//         value: "rs",
//         label: "Serbia",
//     },
//     {
//         id: 404262,
//         value: "ru",
//         label: "Russian Federation",
//     },
//     {
//         id: 404264,
//         value: "rw",
//         label: "Rwanda",
//     },
//     {
//         id: 404266,
//         value: "sa",
//         label: "Saudi Arabia",
//     },
//     {
//         id: 404270,
//         value: "sb",
//         label: "Solomon Islands",
//     },
//     {
//         id: 404271,
//         value: "sc",
//         label: "Seychelles",
//     },
//     {
//         id: 404273,
//         value: "sd",
//         label: "Sudan",
//     },
//     {
//         id: 404275,
//         value: "se",
//         label: "Sweden",
//     },
//     {
//         id: 404277,
//         value: "sg",
//         label: "Singapore",
//     },
//     {
//         id: 404279,
//         value: "sh",
//         label: "St. Helena",
//     },
//     {
//         id: 404280,
//         value: "si",
//         label: "Slovenia",
//     },
//     {
//         id: 404282,
//         value: "sj",
//         label: "Svalbard And Jan Mayen Islands",
//     },
//     {
//         id: 404283,
//         value: "sk",
//         label: "Slovakia (slovak Republic)",
//     },
//     {
//         id: 404285,
//         value: "sl",
//         label: "Sierra Leone",
//     },
//     {
//         id: 404287,
//         value: "sm",
//         label: "San Marino",
//     },
//     {
//         id: 404289,
//         value: "sn",
//         label: "Senegal",
//     },
//     {
//         id: 404291,
//         value: "so",
//         label: "Somalia",
//     },
//     {
//         id: 404294,
//         value: "sr",
//         label: "Suriname",
//     },
//     {
//         id: 404296,
//         value: "ss",
//         label: "South Sudan",
//     },
//     {
//         id: 404298,
//         value: "st",
//         label: "Sao Tome And Principe",
//     },
//     {
//         id: 404301,
//         value: "sv",
//         label: "El Salvador",
//     },
//     {
//         id: 404304,
//         value: "sx",
//         label: "Sint Maarten",
//     },
//     {
//         id: 404305,
//         value: "sy",
//         label: "Syrian Arab Republic",
//     },
//     {
//         id: 404306,
//         value: "sz",
//         label: "Swaziland",
//     },
//     {
//         id: 404310,
//         value: "tc",
//         label: "Turks And Caicos Islands",
//     },
//     {
//         id: 404311,
//         value: "td",
//         label: "Chad",
//     },
//     {
//         id: 404313,
//         value: "tf",
//         label: "French Southern Territories",
//     },
//     {
//         id: 404314,
//         value: "tg",
//         label: "Togo",
//     },
//     {
//         id: 404316,
//         value: "th",
//         label: "Thailand",
//     },
//     {
//         id: 404319,
//         value: "tj",
//         label: "Tajikistan",
//     },
//     {
//         id: 404320,
//         value: "tk",
//         label: "Tokelau",
//     },
//     {
//         id: 404322,
//         value: "tl",
//         label: "Timor-leste",
//     },
//     {
//         id: 404324,
//         value: "tm",
//         label: "Turkmenistan",
//     },
//     {
//         id: 404325,
//         value: "tn",
//         label: "Tunisia",
//     },
//     {
//         id: 404327,
//         value: "to",
//         label: "Tonga",
//     },
//     {
//         id: 404329,
//         value: "tr",
//         label: "Turkey",
//     },
//     {
//         id: 404332,
//         value: "tt",
//         label: "Trinidad And Tobago",
//     },
//     {
//         id: 404334,
//         value: "tv",
//         label: "Tuvalu",
//     },
//     {
//         id: 404335,
//         value: "tw",
//         label: "Taiwan Province Of China",
//     },
//     {
//         id: 404338,
//         value: "tz",
//         label: "Tanzania",
//     },
//     {
//         id: 404339,
//         value: "ua",
//         label: "Ukraine",
//     },
//     {
//         id: 404341,
//         value: "ug",
//         label: "Uganda",
//     },
//     {
//         id: 404344,
//         value: "um",
//         label: "Us Minor Outlying Islands",
//     },
//     {
//         id: 405138,
//         value: "us",
//         label: "United States",
//     },
//     {
//         id: 405236,
//         value: "uy",
//         label: "Uruguay",
//     },
//     {
//         id: 405237,
//         value: "uz",
//         label: "Uzbekistan",
//     },
//     {
//         id: 405249,
//         value: "va",
//         label: "Holy See (vatican City State)",
//     },
//     {
//         id: 405397,
//         value: "vc",
//         label: "Saint Vincent And The Grenadines",
//     },
//     {
//         id: 405414,
//         value: "ve",
//         label: "Venezuela",
//     },
//     {
//         id: 405603,
//         value: "vg",
//         label: "British Virgin Islands",
//     },
//     {
//         id: 405608,
//         value: "vi",
//         label: "Us Virgin Islands",
//     },
//     {
//         id: 406023,
//         value: "vn",
//         label: "Viet Nam",
//     },
//     {
//         id: 406254,
//         value: "vu",
//         label: "Vanuatu",
//     },
//     {
//         id: 406744,
//         value: "wf",
//         label: "Wallis And Futuna Islands",
//     },
//     {
//         id: 407209,
//         value: "ws",
//         label: "Samoa",
//     },
//     {
//         id: 407472,
//         value: "ye",
//         label: "Yemen",
//     },
//     {
//         id: 407551,
//         value: "yt",
//         label: "Mayotte",
//     },
//     {
//         id: 407575,
//         value: "za",
//         label: "South Africa",
//     },
//     {
//         id: 407843,
//         value: "zm",
//         label: "Zambia",
//     },
//     {
//         id: 407904,
//         value: "zw",
//         label: "Zimbabwe",
//     },
// ];

// const europe = [
//     { id: 403915, value: "ad", label: "Andorra" },
//     { id: 403923, value: "al", label: "Albania" },
//     { id: 403924, value: "am", label: "Armenia" },
//     { id: 403936, value: "at", label: "Austria" },
//     { id: 403942, value: "az", label: "Azerbaijan" },
//     { id: 403974, value: "by", label: "Belarus" },
//     { id: 403948, value: "be", label: "Belgium" },
//     { id: 403944, value: "ba", label: "Bosnia And Herzegowina" },
//     { id: 403951, value: "bg", label: "Bulgaria" },
//     { id: 404079, value: "hr", label: "Croatia" },
//     { id: 404003, value: "cy", label: "Cyprus" },
//     { id: 404005, value: "cz", label: "Czech Republic" },
//     { id: 404012, value: "dk", label: "Denmark" },
//     { id: 404019, value: "ee", label: "Estonia" },
//     { id: 404035, value: "fi", label: "Finland" },
//     { id: 404044, value: "fr", label: "France" },
//     { id: 404052, value: "ge", label: "Georgia" },
//     { id: 404007, value: "de", label: "Germany" },
//     { id: 404064, value: "gr", label: "Greece" },
//     { id: 405249, value: "va", label: "Holy See (vatican City State)" },
//     { id: 404083, value: "hu", label: "Hungary" },
//     { id: 404106, value: "is", label: "Iceland" },
//     { id: 404090, value: "ie", label: "Ireland" },
//     { id: 404108, value: "it", label: "Italy" },
//     { id: 404161, value: "lv", label: "Latvia" },
//     { id: 404150, value: "li", label: "Liechtenstein" },
//     { id: 404157, value: "lt", label: "Lithuania" },
//     { id: 404159, value: "lu", label: "Luxembourg" },
//     { id: 404189, value: "mt", label: "Malta" },
//     { id: 404166, value: "md", label: "Moldova" },
//     { id: 404165, value: "mc", label: "Monaco" },
//     { id: 404210, value: "nl", label: "Netherlands" },
//     { id: 404174, value: "mk", label: "Macedonia" },
//     { id: 404213, value: "no", label: "Norway" },
//     { id: 404241, value: "pl", label: "Poland" },
//     { id: 404249, value: "pt", label: "Portugal" },
//     { id: 404259, value: "ro", label: "Romania" },
//     { id: 404287, value: "sm", label: "San Marino" },
//     { id: 404283, value: "sk", label: "Slovakia (slovak Republic)" },
//     { id: 404280, value: "si", label: "Slovenia" },
//     { id: 404027, value: "es", label: "Spain" },
//     { id: 404275, value: "se", label: "Sweden" },
//     { id: 403984, value: "ch", label: "Switzerland" },
//     { id: 404339, value: "ua", label: "Ukraine" },
// ];

// const asia = [
//     { id: 403918, value: "af", label: "Afghanistan" },
//     { id: 403953, value: "bh", label: "Bahrain" },
//     { id: 403947, value: "bd", label: "Bangladesh" },
//     { id: 403971, value: "bt", label: "Bhutan" },
//     { id: 403961, value: "bn", label: "Brunei Darussalam" },
//     { id: 404121, value: "kh", label: "Cambodia" },
//     { id: 403991, value: "cn", label: "China" },
//     { id: 404098, value: "in", label: "India" },
//     { id: 404088, value: "id", label: "Indonesia" },
//     { id: 404105, value: "ir", label: "Iran (Islamic Republic Of)" },
//     { id: 404104, value: "iq", label: "Iraq" },
//     { id: 404096, value: "il", label: "Israel" },
//     { id: 404115, value: "jp", label: "Japan" },
//     { id: 404114, value: "jo", label: "Jordan" },
//     { id: 404143, value: "kz", label: "Kazakhstan" },
//     { id: 404139, value: "kw", label: "Kuwait" },
//     { id: 404119, value: "kg", label: "Kyrgyzstan" },
//     { id: 404144, value: "la", label: "Lao People's Democratic Republic" },
//     { id: 404146, value: "lb", label: "Lebanon" },
//     { id: 404195, value: "my", label: "Malaysia" },
//     { id: 404192, value: "mv", label: "Maldives" },
//     { id: 404179, value: "mn", label: "Mongolia" },
//     { id: 404178, value: "mm", label: "Myanmar" },
//     { id: 404215, value: "np", label: "Nepal" },
//     { id: 404226, value: "om", label: "Oman" },
//     { id: 404240, value: "pk", label: "Pakistan" },
//     { id: 404247, value: "ps", label: "Palestine, State of" },
//     { id: 404238, value: "ph", label: "Philippines" },
//     { id: 404254, value: "qa", label: "Qatar" },
//     { id: 404266, value: "sa", label: "Saudi Arabia" },
//     { id: 404277, value: "sg", label: "Singapore" },
//     { id: 404152, value: "lk", label: "Sri Lanka" },
//     { id: 404305, value: "sy", label: "Syrian Arab Republic" },
//     { id: 404319, value: "tj", label: "Tajikistan" },
//     { id: 404316, value: "th", label: "Thailand" },
//     { id: 404322, value: "tl", label: "Timor-Leste" },
//     { id: 404329, value: "tr", label: "Turkey" },
//     { id: 404324, value: "tm", label: "Turkmenistan" },
//     { id: 403916, value: "ae", label: "United Arab Emirates" },
//     { id: 405237, value: "uz", label: "Uzbekistan" },
//     { id: 406023, value: "vn", label: "Viet Nam" },
//     { id: 407472, value: "ye", label: "Yemen" },
// ];

// const africa = [
//     { id: 404016, value: "dz", label: "Algeria" },
//     { id: 403928, value: "ao", label: "Angola" },
//     { id: 403957, value: "bj", label: "Benin" },
//     { id: 403973, value: "bw", label: "Botswana" },
//     { id: 403950, value: "bf", label: "Burkina Faso" },
//     { id: 403955, value: "bi", label: "Burundi" },
//     { id: 403990, value: "cm", label: "Cameroon" },
//     { id: 403999, value: "cv", label: "Cape Verde" },
//     { id: 403982, value: "cf", label: "Central African Republic" },
//     { id: 404311, value: "td", label: "Chad" },
//     { id: 404128, value: "km", label: "Comoros" },
//     { id: 403983, value: "cg", label: "Congo" },
//     { id: 403987, value: "ci", label: "Cote D Ivoire" },
//     { id: 403980, value: "cd", label: "Democratic Republic Of The Congo" },
//     { id: 404011, value: "dj", label: "Djibouti" },
//     { id: 404021, value: "eg", label: "Egypt" },
//     { id: 404063, value: "gq", label: "Equatorial Guinea" },
//     { id: 404026, value: "er", label: "Eritrea" },
//     { id: 404029, value: "et", label: "Ethiopia" },
//     { id: 404047, value: "ga", label: "Gabon" },
//     { id: 404059, value: "gm", label: "Gambia" },
//     { id: 404055, value: "gh", label: "Ghana" },
//     { id: 404060, value: "gn", label: "Guinea" },
//     { id: 404070, value: "gw", label: "Guinea-bissau" },
//     { id: 404118, value: "ke", label: "Kenya" },
//     { id: 404156, value: "ls", label: "Lesotho" },
//     { id: 404155, value: "lr", label: "Liberia" },
//     { id: 404163, value: "ly", label: "Libyan Arab Jamahiriya" },
//     { id: 404169, value: "mg", label: "Madagascar" },
//     { id: 404193, value: "mw", label: "Malawi" },
//     { id: 404176, value: "ml", label: "Mali" },
//     { id: 404185, value: "mr", label: "Mauritania" },
//     { id: 404191, value: "mu", label: "Mauritius" },
//     { id: 404164, value: "ma", label: "Morocco" },
//     { id: 404197, value: "mz", label: "Mozambique" },
//     { id: 404198, value: "na", label: "Namibia" },
//     { id: 404204, value: "ne", label: "Niger" },
//     { id: 404207, value: "ng", label: "Nigeria" },
//     { id: 404264, value: "rw", label: "Rwanda" },
//     { id: 404298, value: "st", label: "Sao Tome And Principe" },
//     { id: 404289, value: "sn", label: "Senegal" },
//     { id: 404271, value: "sc", label: "Seychelles" },
//     { id: 404285, value: "sl", label: "Sierra Leone" },
//     { id: 404291, value: "so", label: "Somalia" },
//     { id: 407575, value: "za", label: "South Africa" },
//     { id: 404273, value: "sd", label: "Sudan" },
//     { id: 404306, value: "sz", label: "Swaziland" },
//     { id: 404338, value: "tz", label: "Tanzania" },
//     { id: 404314, value: "tg", label: "Togo" },
//     { id: 404325, value: "tn", label: "Tunisia" },
//     { id: 404341, value: "ug", label: "Uganda" },
//     { id: 407843, value: "zm", label: "Zambia" },
//     { id: 407904, value: "zw", label: "Zimbabwe" },
// ];

// const north_america = [
//     { id: 403909, value: "ag", label: "Antigua And Barbuda" },
//     { id: 403940, value: "bs", label: "Bahamas" },
//     { id: 403945, value: "bb", label: "Barbados" },
//     { id: 403962, value: "bz", label: "Belize" },
//     { id: 403946, value: "ca", label: "Canada" },
//     { id: 403994, value: "cr", label: "Costa Rica" },
//     { id: 403996, value: "cu", label: "Cuba" },
//     { id: 403981, value: "dm", label: "Dominica" },
//     { id: 403986, value: "do", label: "Dominican Republic" },
//     { id: 403991, value: "sv", label: "El Salvador" },
//     { id: 403989, value: "gd", label: "Grenada" },
//     { id: 403992, value: "gt", label: "Guatemala" },
//     { id: 403994, value: "ht", label: "Haiti" },
//     { id: 403998, value: "hn", label: "Honduras" },
//     { id: 404013, value: "jm", label: "Jamaica" },
//     { id: 404035, value: "mx", label: "Mexico" },
//     { id: 404057, value: "ni", label: "Nicaragua" },
//     { id: 404090, value: "pa", label: "Panama" },
//     { id: 404210, value: "kn", label: "Saint Kitts And Nevis" },
//     { id: 404211, value: "lc", label: "Saint Lucia" },
//     { id: 404213, value: "vc", label: "Saint Vincent And The Grenadines" },
//     { id: 404343, value: "tt", label: "Trinidad And Tobago" },
//     { id: 407548, value: "us", label: "United States" },
// ];

// const south_america = [
//     { id: 403930, value: "ar", label: "Argentina" },
//     { id: 403933, value: "bo", label: "Bolivia" },
//     { id: 403935, value: "br", label: "Brazil" },
//     { id: 403944, value: "cl", label: "Chile" },
//     { id: 403943, value: "co", label: "Colombia" },
//     { id: 403947, value: "ec", label: "Ecuador" },
//     { id: 403961, value: "gy", label: "Guyana" },
//     { id: 403965, value: "py", label: "Paraguay" },
//     { id: 403966, value: "pe", label: "Peru" },
//     { id: 403969, value: "sr", label: "Suriname" },
//     { id: 403971, value: "uy", label: "Uruguay" },
//     { id: 403974, value: "ve", label: "Venezuela" },
// ];

// const oceania = [
//     { id: 403979, value: "au", label: "Australia" },
//     { id: 403992, value: "fm", label: "Federated States Of Micronesia" },
//     { id: 403993, value: "fj", label: "Fiji" },
//     { id: 403996, value: "ki", label: "Kiribati" },
//     { id: 403999, value: "mh", label: "Marshall Islands" },
//     { id: 404000, value: "nr", label: "Nauru" },
//     { id: 404001, value: "nz", label: "New Zealand" },
//     { id: 404004, value: "pw", label: "Palau" },
//     { id: 404005, value: "pg", label: "Papua New Guinea" },
//     { id: 404007, value: "ws", label: "Samoa" },
//     { id: 404008, value: "sb", label: "Solomon Islands" },
//     { id: 404009, value: "to", label: "Tonga" },
//     { id: 404010, value: "tv", label: "Tuvalu" },
//     { id: 404011, value: "vu", label: "Vanuatu" },
// ];

// const gcc = [
//     { id: 404027, value: "bh", label: "Bahrain" },
//     { id: 404052, value: "kw", label: "Kuwait" },
//     { id: 404067, value: "om", label: "Oman" },
//     { id: 404078, value: "qa", label: "Qatar" },
//     { id: 404081, value: "sa", label: "Saudi Arabia" },
//     { id: 404093, value: "ae", label: "United Arab Emirates" },
// ];

const countries = [
    {
        id: 403915,
        value: "ad",
        label: "Andorra",
        size: 950,
    },
    {
        id: 403916,
        value: "ae",
        label: "United Arab Emirates",
        size: 120203,
    },
    {
        id: 403918,
        value: "af",
        label: "Afghanistan",
        size: 525560,
    },
    {
        id: 403920,
        value: "ag",
        label: "Antigua And Barbuda",
        size: 1212,
    },
    {
        id: 403921,
        value: "ai",
        label: "Anguilla",
        size: 0,
    },
    {
        id: 403923,
        value: "al",
        label: "Albania",
        size: 33142,
    },
    {
        id: 403924,
        value: "am",
        label: "Armenia",
        size: 33337,
    },
    {
        id: 403926,
        value: "an",
        label: "Netherlands Antilles",
        size: 0,
    },
    {
        id: 403928,
        value: "ao",
        label: "Angola",
        size: 427068,
    },
    {
        id: 403931,
        value: "aq",
        label: "Antarctica",
        size: 0,
    },
    {
        id: 403932,
        value: "ar",
        label: "Argentina",
        size: 554903,
    },
    {
        id: 403934,
        value: "as",
        label: "American Samoa",
        size: 0,
    },
    {
        id: 403936,
        value: "at",
        label: "Austria",
        size: 0,
    },
    {
        id: 403937,
        value: "au",
        label: "Australia",
        size: 306492,
    },
    {
        id: 403939,
        value: "aw",
        label: "Aruba",
        size: 0,
    },
    {
        id: 403940,
        value: "ax",
        label: "Aland Islands",
        size: 0,
    },
    {
        id: 403942,
        value: "az",
        label: "Azerbaijan",
        size: 121768,
    },
    {
        id: 403944,
        value: "ba",
        label: "Bosnia And Herzegowina",
        size: 0,
    },
    {
        id: 403946,
        value: "bb",
        label: "Barbados",
        size: 3168,
    },
    {
        id: 403947,
        value: "bd",
        label: "Bangladesh",
        size: 2098079,
    },
    {
        id: 403948,
        value: "be",
        label: "Belgium",
        size: 141096,
    },
    {
        id: 403950,
        value: "bf",
        label: "Burkina Faso",
        size: 279799,
    },
    {
        id: 403951,
        value: "bg",
        label: "Bulgaria",
        size: 78244,
    },
    {
        id: 403953,
        value: "bh",
        label: "Bahrain",
        size: 21656,
    },
    {
        id: 403955,
        value: "bi",
        label: "Burundi",
        size: 154068,
    },
    {
        id: 403957,
        value: "bj",
        label: "Benin",
        size: 163692,
    },
    {
        id: 403958,
        value: "bl",
        label: "Saint Barthelemy",
        size: 0,
    },
    {
        id: 403959,
        value: "bm",
        label: "Bermuda",
        size: 0,
    },
    {
        id: 403961,
        value: "bn",
        label: "Brunei Darussalam",
        size: 5298,
    },
    {
        id: 403963,
        value: "bo",
        label: "Bolivia",
        size: 144655,
    },
    {
        id: 403965,
        value: "bq",
        label: "Bonaire/sint Eustatius/saba",
        size: 0,
    },
    {
        id: 403966,
        value: "br",
        label: "Brazil",
        size: 2597069,
    },
    {
        id: 403969,
        value: "bs",
        label: "Bahamas",
        size: 0,
    },
    {
        id: 403971,
        value: "bt",
        label: "Bhutan",
        size: 9330,
    },
    {
        id: 403972,
        value: "bv",
        label: "Bouvet Island",
        size: 0,
    },
    {
        id: 403973,
        value: "bw",
        label: "Botswana",
        size: 30204,
    },
    {
        id: 403974,
        value: "by",
        label: "Belarus",
        size: 109512,
    },
    {
        id: 403975,
        value: "bz",
        label: "Belize",
        size: 5298,
    },
    {
        id: 403976,
        value: "ca",
        label: "Canada",
        size: 481160,
    },
    {
        id: 403979,
        value: "cc",
        label: "Cocos (keeling) Islands",
        size: 0,
    },
    {
        id: 403980,
        value: "cd",
        label: "Democratic Republic Of The Congo",
        size: 0,
    },
    {
        id: 403982,
        value: "cf",
        label: "Central African Republic",
        size: 64548,
    },
    {
        id: 403983,
        value: "cg",
        label: "Congo",
        size: 1227145,
    },
    {
        id: 403984,
        value: "ch",
        label: "Switzerland",
        size: 0,
    },
    {
        id: 403987,
        value: "ci",
        label: "Cote D Ivoire",
        size: 340675,
    },
    {
        id: 403988,
        value: "ck",
        label: "Cook Islands",
        size: 0,
    },
    {
        id: 403989,
        value: "cl",
        label: "Chile",
        size: 233907,
    },
    {
        id: 403990,
        value: "cm",
        label: "Cameroon",
        size: 342290,
    },
    {
        id: 403991,
        value: "cn",
        label: "China",
        size: 0,
    },
    {
        id: 403992,
        value: "co",
        label: "Colombia",
        size: 650973,
    },
    {
        id: 403994,
        value: "cr",
        label: "Costa Rica",
        size: 62491,
    },
    {
        id: 403997,
        value: "cu",
        label: "Cuba",
        size: 133359,
    },
    {
        id: 403999,
        value: "cv",
        label: "Cape Verde",
        size: 0,
    },
    {
        id: 404001,
        value: "cw",
        label: "Curacao",
        size: 0,
    },
    {
        id: 404002,
        value: "cx",
        label: "Christmas Island",
        size: 0,
    },
    {
        id: 404003,
        value: "cy",
        label: "Cyprus",
        size: 15378,
    },
    {
        id: 404005,
        value: "cz",
        label: "Czech Republic",
        size: 129118,
    },
    {
        id: 404007,
        value: "de",
        label: "Germany",
        size: 1013901,
    },
    {
        id: 404011,
        value: "dj",
        label: "Djibouti",
        size: 12912,
    },
    {
        id: 404012,
        value: "dk",
        label: "Denmark",
        size: 70587,
    },
    {
        id: 404013,
        value: "dm",
        label: "Dominica",
        size: 870,
    },
    {
        id: 404014,
        value: "do",
        label: "Dominican Republic",
        size: 130896,
    },
    {
        id: 404016,
        value: "dz",
        label: "Algeria",
        size: 553871,
    },
    {
        id: 404018,
        value: "ec",
        label: "Ecuador",
        size: 220752,
    },
    {
        id: 404019,
        value: "ee",
        label: "Estonia",
        size: 15898,
    },
    {
        id: 404021,
        value: "eg",
        label: "Egypt",
        size: 1352599,
    },
    {
        id: 404022,
        value: "eh",
        label: "Western Sahara",
        size: 0,
    },
    {
        id: 404026,
        value: "er",
        label: "Eritrea",
        size: 44688,
    },
    {
        id: 404027,
        value: "es",
        label: "Spain",
        size: 570492,
    },
    {
        id: 404029,
        value: "et",
        label: "Ethiopia",
        size: 1518325,
    },
    {
        id: 404035,
        value: "fi",
        label: "Finland",
        size: 66860,
    },
    {
        id: 404038,
        value: "fj",
        label: "Fiji",
        size: 11095,
    },
    {
        id: 404040,
        value: "fk",
        label: "Falkland Islands (malvinas)",
        size: 0,
    },
    {
        id: 404041,
        value: "fm",
        label: "Federated States Of Micronesia",
        size: 1368,
    },
    {
        id: 404042,
        value: "fo",
        label: "Faroe Islands",
        size: 0,
    },
    {
        id: 404044,
        value: "fr",
        label: "France",
        size: 789560,
    },
    {
        id: 404047,
        value: "ga",
        label: "Gabon",
        size: 28716,
    },
    {
        id: 404049,
        value: "gb",
        label: "United Kingdom",
        size: 847910,
    },
    {
        id: 404050,
        value: "gd",
        label: "Grenada",
        size: 1488,
    },
    {
        id: 404052,
        value: "ge",
        label: "Georgia",
        size: 0,
    },
    {
        id: 404053,
        value: "gf",
        label: "French Guiana",
        size: 0,
    },
    {
        id: 404054,
        value: "gg",
        label: "Guernsey",
        size: 0,
    },
    {
        id: 404055,
        value: "gh",
        label: "Ghana",
        size: 409462,
    },
    {
        id: 404056,
        value: "gi",
        label: "Gibraltar",
        size: 0,
    },
    {
        id: 404057,
        value: "gl",
        label: "Greenland",
        size: 0,
    },
    {
        id: 404059,
        value: "gm",
        label: "Gambia",
        size: 31644,
    },
    {
        id: 404060,
        value: "gn",
        label: "Guinea",
        size: 179172,
    },
    {
        id: 404062,
        value: "gp",
        label: "Guadeloupe",
        size: 0,
    },
    {
        id: 404063,
        value: "gq",
        label: "Equatorial Guinea",
        size: 18698,
    },
    {
        id: 404064,
        value: "gr",
        label: "Greece",
        size: 125790,
    },
    {
        id: 404065,
        value: "gs",
        label: "South Georgia / South Sandwich Isl",
        size: 0,
    },
    {
        id: 404066,
        value: "gt",
        label: "Guatemala",
        size: 217351,
    },
    {
        id: 404067,
        value: "gu",
        label: "Guam",
        size: 0,
    },
    {
        id: 404070,
        value: "gw",
        label: "Guinea-bissau",
        size: 24312,
    },
    {
        id: 404071,
        value: "gy",
        label: "Guyana",
        size: 9476,
    },
    {
        id: 404075,
        value: "hk",
        label: "Hong Kong",
        size: 88958,
    },
    {
        id: 404076,
        value: "hm",
        label: "Heard And Mc Donald Islands",
        size: 0,
    },
    {
        id: 404077,
        value: "hn",
        label: "Honduras",
        size: 128436,
    },
    {
        id: 404079,
        value: "hr",
        label: "Croatia",
        size: 46092,
    },
    {
        id: 404081,
        value: "ht",
        label: "Haiti",
        size: 135336,
    },
    {
        id: 404083,
        value: "hu",
        label: "Hungary",
        size: 115275,
    },
    {
        id: 404088,
        value: "id",
        label: "Indonesia",
        size: 3330409,
    },
    {
        id: 404090,
        value: "ie",
        label: "Ireland",
        size: 62516,
    },
    {
        id: 404096,
        value: "il",
        label: "Israel",
        size: 117564,
    },
    {
        id: 404097,
        value: "im",
        label: "Isle Of Man",
        size: 0,
    },
    {
        id: 404098,
        value: "in",
        label: "India",
        size: 17006078,
    },
    {
        id: 404099,
        value: "io",
        label: "British Indian Ocean Territory",
        size: 0,
    },
    {
        id: 404104,
        value: "iq",
        label: "Iraq",
        size: 550190,
    },
    {
        id: 404105,
        value: "ir",
        label: "Iran (islamic Republic Of)",
        size: 1070054,
    },
    {
        id: 404106,
        value: "is",
        label: "Iceland",
        size: 4504,
    },
    {
        id: 404108,
        value: "it",
        label: "Italy",
        size: 707647,
    },
    {
        id: 404112,
        value: "je",
        label: "Jersey",
        size: 0,
    },
    {
        id: 404113,
        value: "jm",
        label: "Jamaica",
        size: 32112,
    },
    {
        id: 404114,
        value: "jo",
        label: "Jordan",
        size: 133956,
    },
    {
        id: 404115,
        value: "jp",
        label: "Japan",
        size: 1476348,
    },
    {
        id: 404118,
        value: "ke",
        label: "Kenya",
        size: 673184,
    },
    {
        id: 404119,
        value: "kg",
        label: "Kyrgyzstan",
        size: 80735,
    },
    {
        id: 404121,
        value: "kh",
        label: "Cambodia",
        size: 200666,
    },
    {
        id: 404122,
        value: "ki",
        label: "Kiribati",
        size: 1440,
    },
    {
        id: 404128,
        value: "km",
        label: "Comoros",
        size: 10889,
    },
    {
        id: 404130,
        value: "kn",
        label: "Saint Kitts And Nevis",
        size: 571,
    },
    {
        id: 404133,
        value: "kp",
        label: "North Korea",
        size: 308631,
    },
    {
        id: 404134,
        value: "kr",
        label: "South Korea",
        size: 623696,
    },
    {
        id: 404139,
        value: "kw",
        label: "Kuwait",
        size: 57864,
    },
    {
        id: 404141,
        value: "ky",
        label: "Cayman Islands",
        size: 0,
    },
    {
        id: 404143,
        value: "kz",
        label: "Kazakhstan",
        size: 235541,
    },
    {
        id: 404144,
        value: "la",
        label: "Lao Peoples Democratic Republic",
        size: 92995,
    },
    {
        id: 404146,
        value: "lb",
        label: "Lebanon",
        size: 82260,
    },
    {
        id: 404148,
        value: "lc",
        label: "Saint Lucia",
        size: 1992,
    },
    {
        id: 404150,
        value: "li",
        label: "Liechtenstein",
        size: 0,
    },
    {
        id: 404152,
        value: "lk",
        label: "Sri Lanka",
        size: 266280,
    },
    {
        id: 404155,
        value: "lr",
        label: "Liberia",
        size: 67536,
    },
    {
        id: 404156,
        value: "ls",
        label: "Lesotho",
        size: 26352,
    },
    {
        id: 404157,
        value: "lt",
        label: "Lithuania",
        size: 31925,
    },
    {
        id: 404159,
        value: "lu",
        label: "Luxembourg",
        size: 7930,
    },
    {
        id: 404161,
        value: "lv",
        label: "Latvia",
        size: 21549,
    },
    {
        id: 404163,
        value: "ly",
        label: "Libyan Arab Jamahiriya",
        size: 0,
    },
    {
        id: 404164,
        value: "ma",
        label: "Morocco",
        size: 446643,
    },
    {
        id: 404165,
        value: "mc",
        label: "Monaco",
        size: 438,
    },
    {
        id: 404166,
        value: "md",
        label: "Moldova",
        size: 30563,
    },
    {
        id: 404167,
        value: "me",
        label: "Montenegro",
        size: 7260,
    },
    {
        id: 404168,
        value: "mf",
        label: "Saint Martin",
        size: 0,
    },
    {
        id: 404169,
        value: "mg",
        label: "Madagascar",
        size: 380421,
    },
    {
        id: 404171,
        value: "mh",
        label: "Marshall Islands",
        size: 720,
    },
    {
        id: 404174,
        value: "mk",
        label: "Macedonia",
        size: 22041,
    },
    {
        id: 404176,
        value: "ml",
        label: "Mali",
        size: 278840,
    },
    {
        id: 404178,
        value: "mm",
        label: "Myanmar",
        size: 662726,
    },
    {
        id: 404179,
        value: "mn",
        label: "Mongolia",
        size: 41249,
    },
    {
        id: 404181,
        value: "mo",
        label: "Macau",
        size: 0,
    },
    {
        id: 404183,
        value: "mp",
        label: "Northern Mariana Islands",
        size: 0,
    },
    {
        id: 404184,
        value: "mq",
        label: "Martinique",
        size: 0,
    },
    {
        id: 404185,
        value: "mr",
        label: "Mauritania",
        size: 61188,
    },
    {
        id: 404187,
        value: "ms",
        label: "Montserrat",
        size: 0,
    },
    {
        id: 404189,
        value: "mt",
        label: "Malta",
        size: 6456,
    },
    {
        id: 404191,
        value: "mu",
        label: "Mauritius",
        size: 15192,
    },
    {
        id: 404192,
        value: "mv",
        label: "Maldives",
        size: 6372,
    },
    {
        id: 404193,
        value: "mw",
        label: "Malawi",
        size: 275052,
    },
    {
        id: 404194,
        value: "mx",
        label: "Mexico",
        size: 1588494,
    },
    {
        id: 404195,
        value: "my",
        label: "Malaysia",
        size: 402886,
    },
    {
        id: 404197,
        value: "mz",
        label: "Mozambique",
        size: 401035,
    },
    {
        id: 404198,
        value: "na",
        label: "Namibia",
        size: 31741,
    },
    {
        id: 404202,
        value: "nc",
        label: "New Caledonia",
        size: 0,
    },
    {
        id: 404204,
        value: "ne",
        label: "Niger",
        size: 337396,
    },
    {
        id: 404206,
        value: "nf",
        label: "Norfolk Island",
        size: 0,
    },
    {
        id: 404207,
        value: "ng",
        label: "Nigeria",
        size: 2690929,
    },
    {
        id: 404209,
        value: "ni",
        label: "Nicaragua",
        size: 83580,
    },
    {
        id: 404210,
        value: "nl",
        label: "Netherlands",
        size: 0,
    },
    {
        id: 404213,
        value: "no",
        label: "Norway",
        size: 66033,
    },
    {
        id: 404215,
        value: "np",
        label: "Nepal",
        size: 383856,
    },
    {
        id: 404216,
        value: "nr",
        label: "Nauru",
        size: 0,
    },
    {
        id: 404218,
        value: "nu",
        label: "Niue",
        size: 0,
    },
    {
        id: 404221,
        value: "nz",
        label: "New Zealand",
        size: 61772,
    },
    {
        id: 404226,
        value: "om",
        label: "Oman",
        size: 55224,
    },
    {
        id: 404233,
        value: "pa",
        label: "Panama",
        size: 55068,
    },
    {
        id: 404235,
        value: "pe",
        label: "Peru",
        size: 413934,
    },
    {
        id: 404236,
        value: "pf",
        label: "French Polynesia",
        size: 0,
    },
    {
        id: 404237,
        value: "pg",
        label: "Papua New Guinea",
        size: 123984,
    },
    {
        id: 404238,
        value: "ph",
        label: "Philippines",
        size: 1401167,
    },
    {
        id: 404240,
        value: "pk",
        label: "Pakistan",
        size: 2885828,
    },
    {
        id: 404241,
        value: "pl",
        label: "Poland",
        size: 453449,
    },
    {
        id: 404244,
        value: "pm",
        label: "St. Pierre And Miquelon",
        size: 0,
    },
    {
        id: 404245,
        value: "pn",
        label: "Pitcairn",
        size: 0,
    },
    {
        id: 404246,
        value: "pr",
        label: "Puerto Rico",
        size: 0,
    },
    {
        id: 404247,
        value: "ps",
        label: "Palestinian Territories",
        size: 66084,
    },
    {
        id: 404249,
        value: "pt",
        label: "Portugal",
        size: 125608,
    },
    {
        id: 404252,
        value: "pw",
        label: "Palau",
        size: 0,
    },
    {
        id: 404253,
        value: "py",
        label: "Paraguay",
        size: 90736,
    },
    {
        id: 404254,
        value: "qa",
        label: "Qatar",
        size: 34795,
    },
    {
        id: 404256,
        value: "re",
        label: "Reunion",
        size: 0,
    },
    {
        id: 404259,
        value: "ro",
        label: "Romania",
        size: 0,
    },
    {
        id: 404261,
        value: "rs",
        label: "Serbia",
        size: 79764,
    },
    {
        id: 404262,
        value: "ru",
        label: "Russian Federation",
        size: 1733332,
    },
    {
        id: 404264,
        value: "rw",
        label: "Rwanda",
        size: 164592,
    },
    {
        id: 404266,
        value: "sa",
        label: "Saudi Arabia",
        size: 447516,
    },
    {
        id: 404270,
        value: "sb",
        label: "Solomon Islands",
        size: 8885,
    },
    {
        id: 404271,
        value: "sc",
        label: "Seychelles",
        size: 1284,
    },
    {
        id: 404273,
        value: "sd",
        label: "Sudan",
        size: 589251,
    },
    {
        id: 404275,
        value: "se",
        label: "Sweden",
        size: 125804,
    },
    {
        id: 404277,
        value: "sg",
        label: "Singapore",
        size: 68443,
    },
    {
        id: 404279,
        value: "sh",
        label: "St. Helena",
        size: 0,
    },
    {
        id: 404280,
        value: "si",
        label: "Slovenia",
        size: 25284,
    },
    {
        id: 404282,
        value: "sj",
        label: "Svalbard And Jan Mayen Islands",
        size: 0,
    },
    {
        id: 404283,
        value: "sk",
        label: "Slovakia (slovak Republic)",
        size: 64656,
    },
    {
        id: 404285,
        value: "sl",
        label: "Sierra Leone",
        size: 0,
    },
    {
        id: 404287,
        value: "sm",
        label: "San Marino",
        size: 0,
    },
    {
        id: 404289,
        value: "sn",
        label: "Senegal",
        size: 226899,
    },
    {
        id: 404291,
        value: "so",
        label: "Somalia",
        size: 204792,
    },
    {
        id: 404294,
        value: "sr",
        label: "Suriname",
        size: 7356,
    },
    {
        id: 404296,
        value: "ss",
        label: "South Sudan",
        size: 164182,
    },
    {
        id: 404298,
        value: "st",
        label: "Sao Tome And Principe",
        size: 2772,
    },
    {
        id: 404301,
        value: "sv",
        label: "El Salvador",
        size: 78248,
    },
    {
        id: 404304,
        value: "sx",
        label: "Sint Maarten",
        size: 0,
    },
    {
        id: 404305,
        value: "sy",
        label: "Syrian Arab Republic",
        size: 233428,
    },
    {
        id: 404306,
        value: "sz",
        label: "Swaziland",
        size: 0,
    },
    {
        id: 404310,
        value: "tc",
        label: "Turks And Caicos Islands",
        size: 0,
    },
    {
        id: 404311,
        value: "td",
        label: "Chad",
        size: 219343,
    },
    {
        id: 404313,
        value: "tf",
        label: "French Southern Territories",
        size: 0,
    },
    {
        id: 404314,
        value: "tg",
        label: "Togo",
        size: 0,
    },
    {
        id: 404316,
        value: "th",
        label: "Thailand",
        size: 860364,
    },
    {
        id: 404319,
        value: "tj",
        label: "Tajikistan",
        size: 125064,
    },
    {
        id: 404320,
        value: "tk",
        label: "Tokelau",
        size: 0,
    },
    {
        id: 404322,
        value: "tl",
        label: "Timor-leste",
        size: 15816,
    },
    {
        id: 404324,
        value: "tm",
        label: "Turkmenistan",
        size: 77057,
    },
    {
        id: 404325,
        value: "tn",
        label: "Tunisia",
        size: 145956,
    },
    {
        id: 404327,
        value: "to",
        label: "Tonga",
        size: 1272,
    },
    {
        id: 404329,
        value: "tr",
        label: "Turkey",
        size: 1061080,
    },
    {
        id: 404332,
        value: "tt",
        label: "Trinidad And Tobago",
        size: 16757,
    },
    {
        id: 404334,
        value: "tv",
        label: "Tuvalu",
        size: 0,
    },
    {
        id: 404335,
        value: "tw",
        label: "Taiwan Province Of China",
        size: 280391,
    },
    {
        id: 404338,
        value: "tz",
        label: "Tanzania",
        size: 809256,
    },
    {
        id: 404339,
        value: "ua",
        label: "Ukraine",
        size: 447477,
    },
    {
        id: 404341,
        value: "ug",
        label: "Uganda",
        size: 570779,
    },
    {
        id: 404344,
        value: "um",
        label: "Us Minor Outlying Islands",
        size: 0,
    },
    {
        id: 405138,
        value: "us",
        label: "United States",
        size: 4079959,
    },
    {
        id: 405236,
        value: "uy",
        label: "Uruguay",
        size: 42491,
    },
    {
        id: 405237,
        value: "uz",
        label: "Uzbekistan",
        size: 421955,
    },
    {
        id: 405249,
        value: "va",
        label: "Holy See (vatican City State)",
        size: 0,
    },
    {
        id: 405397,
        value: "vc",
        label: "Saint Vincent And The Grenadines",
        size: 1200,
    },
    {
        id: 405414,
        value: "ve",
        label: "Venezuela",
        size: 404855,
    },
    {
        id: 405603,
        value: "vg",
        label: "British Virgin Islands",
        size: 0,
    },
    {
        id: 405608,
        value: "vi",
        label: "Us Virgin Islands",
        size: 0,
    },
    {
        id: 406023,
        value: "vn",
        label: "Viet Nam",
        size: 1186307,
    },
    {
        id: 406254,
        value: "vu",
        label: "Vanuatu",
        size: 3708,
    },
    {
        id: 406744,
        value: "wf",
        label: "Wallis And Futuna Islands",
        size: 0,
    },
    {
        id: 407209,
        value: "ws",
        label: "Samoa",
        size: 2724,
    },
    {
        id: 407472,
        value: "ye",
        label: "Yemen",
        size: 406524,
    },
    {
        id: 407551,
        value: "yt",
        label: "Mayotte",
        size: 0,
    },
    {
        id: 407575,
        value: "za",
        label: "South Africa",
        size: 723116,
    },
    {
        id: 407843,
        value: "zm",
        label: "Zambia",
        size: 235329,
    },
    {
        id: 407904,
        value: "zw",
        label: "Zimbabwe",
        size: 198360,
    },
];

const europeArray = [
    "Andorra",
    "Albania",
    "Armenia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "Bosnia And Herzegowina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Greece",
    "Holy See (vatican City State)",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Moldova",
    "Monaco",
    "Netherlands",
    "Macedonia",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "San Marino",
    "Slovakia (slovak Republic)",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Ukraine",
];

const asiaArray = [
    "Afghanistan",
    "Bahrain",
    "Bangladesh",
    "Bhutan",
    "Brunei Darussalam",
    "Cambodia",
    "China",
    "India",
    "Indonesia",
    "Iran (Islamic Republic Of)",
    "Iraq",
    "Israel",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Lebanon",
    "Malaysia",
    "Maldives",
    "Mongolia",
    "Myanmar",
    "Nepal",
    "Oman",
    "Pakistan",
    "Palestine, State of",
    "Philippines",
    "Qatar",
    "Saudi Arabia",
    "Singapore",
    "Sri Lanka",
    "Syrian Arab Republic",
    "Tajikistan",
    "Thailand",
    "Timor-Leste",
    "Turkey",
    "Turkmenistan",
    "United Arab Emirates",
    "Uzbekistan",
    "Viet Nam",
    "Yemen",
];

const africaArray = [
    "Algeria",
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cameroon",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Congo",
    "Cote D Ivoire",
    "Democratic Republic Of The Congo",
    "Djibouti",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Guinea-bissau",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Rwanda",
    "Sao Tome And Principe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "Sudan",
    "Swaziland",
    "Tanzania",
    "Togo",
    "Tunisia",
    "Uganda",
    "Zambia",
    "Zimbabwe",
];

const northAmericaArray = [
    "Antigua And Barbuda",
    "Bahamas",
    "Barbados",
    "Belize",
    "Canada",
    "Costa Rica",
    "Cuba",
    "Dominica",
    "Dominican Republic",
    "El Salvador",
    "Grenada",
    "Guatemala",
    "Haiti",
    "Honduras",
    "Jamaica",
    "Mexico",
    "Nicaragua",
    "Panama",
    "Saint Kitts And Nevis",
    "Saint Lucia",
    "Saint Vincent And The Grenadines",
    "Trinidad And Tobago",
    "United States",
];

const southAmericaArray = [
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Guyana",
    "Paraguay",
    "Peru",
    "Suriname",
    "Uruguay",
    "Venezuela",
];

const oceaniaArray = [
    "Australia",
    "Federated States Of Micronesia",
    "Fiji",
    "Kiribati",
    "Marshall Islands",
    "Nauru",
    "New Zealand",
    "Palau",
    "Papua New Guinea",
    "Samoa",
    "Solomon Islands",
    "Tonga",
    "Tuvalu",
    "Vanuatu",
];

const gccArray = ["Bahrain", "Kuwait", "Oman", "Qatar", "Saudi Arabia", "United Arab Emirates"];

const getContinent = (array) => {
    return countries.filter((item) => {
        const existingCountry = array.find((subItem) => subItem === item.label);
        if (existingCountry) return existingCountry;
    });
};

const europe = getContinent(europeArray);
const asia = getContinent(asiaArray);
const africa = getContinent(africaArray);
const north_america = getContinent(northAmericaArray);
const south_america = getContinent(southAmericaArray);
const oceania = getContinent(oceaniaArray);
const gcc = getContinent(gccArray);

const getFilteredCountries = (_countries) => {
    return _countries.map((item) => ({
        label: countries.find((country) => country.value == item).label || "undefined",
        value: item,
        size: countries.find((country) => country.value == item).size || 0
    }));
};

// const countryOptions = getFilteredCountries(countries);

export default function CountriesAndBid({ state, setState, items, setItems }) {
    const { requestedData, campaignState } = useGlobalContext();


    const [selectedCountries, setSelectedCountries] = useState([]);

    // useEffect(() => {
    //     console.log(selectedCountries);
    // }, [selectedCountries]);

    const {addCommas} = useFormatter()

    const handleCountryChange = (selectedOption) => {
        setSelectedCountries(selectedOption);

        let tempItems = [...selectedOption];
        tempItems = tempItems.map((item) => ({
            title: item.label,
            value: item.value,
            size: item.size,
            isSelected: true,
        }));
        setItems(tempItems);

        setState((state) => ({
            ...state,
            countriesTargeting: selectedOption.map((item) => item.value),
        }));
    };

    useEffect(() => {
        setSelectedCountries(getFilteredCountries(state.countriesTargeting));
    }, []);

    return (
        <div className="countries-and-bid">
            <h2 className="countries-and-bid-title display-2">Countries & Bid</h2>
            <div className="countries-and-bid-btn-group">
                <Button name="WW" size="xs" onClick={() => handleCountryChange(countries)} />
                <Button name="Europe" size="xs" onClick={() => handleCountryChange(europe)} />
                <Button name="Asia" size="xs" onClick={() => handleCountryChange(asia)} />
                <Button name="Africa" size="xs" onClick={() => handleCountryChange(africa)} />
                <Button name="North America" size="xs" onClick={() => handleCountryChange(north_america)} />
                <Button name="South America" size="xs" onClick={() => handleCountryChange(south_america)} />
                <Button name="Oceania" size="xs" onClick={() => handleCountryChange(oceania)} />
                <Button name="GCC" size="xs" onClick={() => handleCountryChange(gcc)} />
            </div>
            <Select
                value={selectedCountries}
                closeMenuOnSelect={false}
                options={countries}
                className="react-select"
                isMulti={true}
                onChange={handleCountryChange}
                placeholder="Select Country"
            />

        </div>
    );
}
