import React, { useState, useEffect, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Field } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import { auth, storage } from "../../../config/firebase";
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import ReactCrop, {
    centerCrop,
    convertToPercentCrop,
    convertToPixelCrop,
    makeAspectCrop,
} from "react-image-crop";
import useCrop from "../../../hooks/useCrop";
import "react-image-crop/src/ReactCrop.scss";

const ASPECT_RATIO = 1;
const MIN_DIMENSTION = 150;

const EditAvatarToggler = ({ onClick }) => {
    const { requestedData, placeholders } = useGlobalContext();
    const { pen } = localData.svgs;
    const { user } = localData.images;

    return (
        <div className="edit-avatar" onClick={onClick}>
            <img src={placeholders.editProfile.image || requestedData.userProfileData.profile_image} alt="" />

            <Button variant="circle" icon={pen} size="sm" iconColor="stroke" />
        </div>
    );
};

const FileInput = ({ handleImageCrop, crop, setCrop, imageRef,setIsCropDisabled }) => {
    const { placeholder } = localData.images;
    const { pen } = localData.svgs;

    const { profileImagePlaceholder, placeholders, setPlaceholders, errorAlert } = useGlobalContext();
   
    const inputRef = useRef();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        handleFile(file);
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFile = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const image = new Image();
                image.src = reader.result;
                image.onload = (e) => {
                    const { naturalWidth, naturalHeight } = e.currentTarget;
                    if (naturalWidth < MIN_DIMENSTION || naturalHeight < MIN_DIMENSTION) {
                        errorAlert(`Image must be at least 150 × 150 pixels`);
                        imageRef.current.src = placeholder;
                    } else {
                        imageRef.current.src = reader.result;
                        setPlaceholders((prev) => ({
                            ...prev,
                            editProfile: {
                                ...prev.editProfile,
                                modalImage: reader.result,
                                file,
                            },
                        }));
                    }
                };
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
    };

    return (
        <div
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDrop={handleFileDrop}
            className={`upload-image drag-drop-container ${
                placeholders.editProfile.modalImage ? "image-selected" : ""
            }`}
            onClick={() => !placeholders.editProfile.modalImage && inputRef.current.click()}
        >
            <input
                ref={inputRef}
                type="file"
                className="file-input"
                onChange={handleFileChange}
                style={{ display: "none" }}
            />
            <div
                className="wrapper"
                style={{ pointerEvents: placeholders.editProfile.modalImage ? "auto" : "none" }}
            >
                <div className="image-wrapper">
                    <img
                        // ref={imageRef}
                        // className={`file-image ${placeholders.editProfile.image ? "selected" : ""}`}
                        className={`file-image`}
                        src={placeholder}
                        alt="Selected Image"
                        style={{ display: placeholders.editProfile.modalImage ? "none" : "block" }}
                    />
                    <ReactCrop
                        crop={crop}
                        // onChange={(pixelCrop, percentCrop) =>
                        //     placeholders.editProfile.modalImage && setCrop(percentCrop)
                        // }
                        onChange={(pixelCrop, percentCrop) => {
                            setIsCropDisabled(false)
                            setCrop(percentCrop);
                        }}
                        circularCrop
                        keepSelection
                        aspect={ASPECT_RATIO}
                        minWidth={MIN_DIMENSTION}
                        // style={{ width: "100%", height: "100%" }}
                        // style={{ width: "100%" }}
                        style={{ display: placeholders.editProfile.modalImage ? "block" : "none" }}
                    >
                        <img
                            ref={imageRef}
                            // className={`file-image ${placeholders.editProfile.image ? "selected" : ""}`}
                            className={`file-image`}
                            src={placeholder}
                            alt="Selected Image"
                            // style={{ maxHeight: "70vh" }}
                            // onLoad={(e) => placeholders.editProfile.modalImage && handleImageCrop(e)}
                            onLoad={(e) => handleImageCrop(e)}
                        />
                    </ReactCrop>
                </div>
                <strong>
                    Drag your files here, or <span className="text-primary">browse</span>
                </strong>
                <p className="info">We support JPG and PNG file format</p>
            </div>
        </div>
    );
};

const ModalChild = ({ setIsOpen }) => {
    const { placeholders, setPlaceholders } = useGlobalContext();
    const { close } = localData.svgs;

    const [crop, setCrop] = useState();
    const [isCropDisabled,setIsCropDisabled] = useState(true)
    // useEffect(()=>{
    //     console.log(crop)
    // },[crop])
    const { setCanvasPreview } = useCrop();
    const imageRef = useRef();

    const handleImageCrop = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSTION / width) * 100;
        // const cropHeightInPercent = (MIN_DIMENSTION / height) * 100;
        const crop = makeAspectCrop(
            {
                unit: "%", // Can be 'px' or '%'
                width: width,
            },
            ASPECT_RATIO,
            width,
            height
        );

        const centeredCrop = centerCrop(crop, width, height);

        setCrop(centeredCrop);
    };

    // const handleImageCrop = (image) => {
    //     const { width, height } = image;

    //     // Calculate the initial crop dimensions
    //     const cropSize = Math.min(width, height, MIN_DIMENSTION);
    //     const x = (width - cropSize) / 2;
    //     const y = (height - cropSize) / 2;

    //     // Create the initial crop object
    //     const crop = {
    //         unit: "px", // Crop unit
    //         aspect: ASPECT_RATIO,
    //         width: cropSize,
    //         height: cropSize,
    //         x,
    //         y,
    //     };

    //     setCrop(crop);
    // };

    const getFileFromCanvas = async () => {
        return new Promise((resolve, reject) => {
            canvasRef.current.toBlob(function (blob) {
                if (blob) {
                    var file = new File([blob], "image.jpg", {
                        type: "image/jpeg",
                        lastModified: Date.now(), // Set last modified time to current time
                    });
                    resolve(file);
                } else {
                    reject(new Error("Failed to generate blob from canvas."));
                }
            }, "image/jpeg");
        });
    };
    const updateProfileImage = (image, file) => {
        // setProfleImage(profileImagePlaceholder);
        setPlaceholders((prev) => ({
            ...prev,
            editProfile: {
                ...prev.editProfile,
                // image: placeholders.editProfile.modalImage,
                image,
                file: file ? file : prev.editProfile.file,
            },
        }));
        closeModal();
    };

    const closeModal = () => {
        setIsOpen(false);
        // setProfileImagePlaceholder(null);
        setPlaceholders((prev) => ({
            ...prev,
            editProfile: {
                ...prev.editProfile,
                modalImage: null,
            },
        }));
    };

    const canvasRef = useRef(null);

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Upload Profile Image
                </h5>
                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>

            <div className="modal-body">
                <FileInput {...{ handleImageCrop, crop, setCrop, imageRef ,setIsCropDisabled}} />
            </div>

            <div className="modal-footer">
                <Button
                    variant="contained"
                    color="light"
                    style={{ marginRight: "10px" }}
                    onClick={closeModal}
                    name="cancel"
                />
                <Button
                    // disabled={!placeholders.editProfile.modalImage}
                    disabled={isCropDisabled}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    name="crop"
                    onClick={async () => {
                        setCanvasPreview(
                            imageRef.current,
                            canvasRef.current,
                            convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
                        );

                        try {
                            const file = await getFileFromCanvas();
                            const dataURL = canvasRef.current.toDataURL();
                            updateProfileImage(dataURL, file);
                        } catch (error) {
                            console.error("Error generating file from canvas:", error);
                        }
                    }}
                />
                <Button
                    disabled={!placeholders.editProfile.modalImage}
                    variant="contained"
                    name="save"
                    onClick={() => updateProfileImage(placeholders.editProfile.modalImage)}
                />
            </div>
            {crop && (
                <canvas
                    ref={canvasRef}
                    style={{
                        display: "none",
                        border: "1px solid",
                        width: "150px",
                        height: "150px",
                        objectFit: "contain",
                    }}
                />
            )}
        </>
    );
};

export default function EditProfile({}) {
    const {
        placeholders,
        setPlaceholders,
        getUpdateUserInfo,
        getLoadUserProfile,
        isLoading,
        requestedData,
        setIsLoading,
    } = useGlobalContext();
    const { preloaderBig } = localData.images;

    const [display, setDisplay] = useState("none");

    const [state, setState] = useState({
        name: requestedData.userProfileData.userName,
        organizationName: requestedData.userProfileData.currentOrganization.name || "",
    });

    useEffect(() => {
        setState({
            ...state,
            name: requestedData.userProfileData.userName,
            organizationName: requestedData.userProfileData.currentOrganization.name || "",
        });
    }, [requestedData]);

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isImageChanged = !!placeholders.editProfile.image;
        const isNameChanged = state.name !== requestedData.userProfileData.userName;
        const isOrganizationNameChanged =
            state.organizationName !== requestedData.userProfileData.currentOrganization.name;

        const obj = {};
        if (isNameChanged) obj.name = state.name;
        if (isOrganizationNameChanged) {
            obj.organizationName = state.organizationName;
            obj.organizationID = requestedData.userProfileData.currentOrganization.id;
        }

        if (isImageChanged) {
            uploadImage((url) =>
                getUpdateUserInfo({ ...obj, profile_image: url }, () => {
                    getLoadUserProfile();
                })
            );
        } else {
            getUpdateUserInfo(obj, () => {
                getLoadUserProfile();
            });
        }
    };

    const closeModal = () => {
        setPlaceholders((prev) => ({
            ...prev,
            editProfile: {
                ...placeholders.editProfile,
                modalImage: null,
            },
        }));
    };

    const [imageList, setImageList] = useState([]);
    const imageListRef = ref(storage, "/images");

    useEffect(() => {
        listAll(imageListRef).then((res) => {
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                });
            });
        });
    }, []);

    const uploadImage = (callback = () => {}) => {
        setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
        const imageRef = ref(storage, `images/${auth.currentUser.uid + uuidv4()}`);

        uploadBytes(imageRef, placeholders.editProfile.file)
            .then((snapshot) => getDownloadURL(snapshot.ref))
            .then((url) => {
                setImageList((prev) => [...prev, url]);
                // Update user information with the new image URL
                // getUpdateUserInfo({ ...raw, profile_image: url });
                callback(url);
            })
            .catch((error) => {
                // Handle errors during the upload
                console.error("Error uploading image:", error.message);
                setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
            });
    };

    return (
        <div className="edit-profile">
            <Modal
                Toggler={EditAvatarToggler}
                Child={ModalChild}
                modalCloseCallback={closeModal}
                display={display}
                setDisplay={setDisplay}
                className="modal-dialog-centered"
            />

            <form className="form" onSubmit={handleSubmit}>
                <div className="form-header">
                    <h2 className="form-title">Personal Information</h2>
                </div>
                <Field
                    name="name"
                    type="text"
                    label="Full Name"
                    color="secondary"
                    placeholder="Enter your full name"
                    required={true}
                    size="sm"
                    value={state.name}
                    callback={handleOnChange}
                />
                <Field
                    name="organizationName"
                    type="text"
                    label="Organization Name"
                    color="secondary"
                    placeholder="Enter your organization name"
                    required={true}
                    size="sm"
                    value={state.organizationName}
                    callback={handleOnChange}
                />

                <Button
                    className="submit-btn"
                    name="Update"
                    color="primary"
                    variant="contained"
                    size="sm"
                    disabled={
                        state.name == "" ||
                        isLoading.userInfoIsUpdating ||
                        (!placeholders.editProfile.image &&
                            state.name === requestedData.userProfileData.userName &&
                            state.organizationName === requestedData.userProfileData.currentOrganization.name)
                    }
                    icon={
                        isLoading.userInfoIsUpdating ? (
                            <span className="endIcon">
                                <img src={preloaderBig} />
                            </span>
                        ) : null
                    }
                />
            </form>
        </div>
    );
}
