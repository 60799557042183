import React, { useEffect, useState, useRef } from "react";
import {
    Navbar,
    Button,
    Tabs,
    Modal,
    Drawer,
    CopyTextButton,
    Field,
    Select,
    TableSkeleton,
    Calendar,
} from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// import TrackAnEventContent from "./tabs/track-an-event/TrackAnEventContent";
// import TrackAndThankYouContent from "./tabs/track-and-thank-you/TrackAndThankYouContent";
import useFormatter from "../../hooks/useFormatter";
import TrackingDrawer from "./tracking-drawer/TrackingDrawer";
import useFetch from "../../hooks/useFetch";
import Joyride, { STATUS } from "react-joyride";

const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal,
    arrowRight,
    penV2,
    textPreloader,
    BloomingImage,
    CodeType,
    trashV2,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function Tracking() {
    useEffect(() => {
        document.title = "Tracking";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content =
            "Monitor your progress and stay updated with real-time data on your activities.";
    }, []);
    const { fade } = useGlobalContext().animations;

    const {
        requestedData,
        successAlert,
        errorAlert,
        getAdvertiserPixelStatsRequest,
        conversionOptions,
        setConversionOptions,
        successAlertExpanded,
        warningAlertExpanded,
        requestedData: {
            userProfileData: { currentOrganization },
            campaignData: { currentOrganizationCampaigns },
        },
        isLoading,
        trackingState,
        campaignsState,
    } = useGlobalContext();

    const { campaignSelectionRange, setCampaignSelectionRange, handleCalendar } = campaignsState;

    const sendRequest = () => {
        getAdvertiserPixelStatsRequest();
    };

    useEffect(() => {
        if (!currentOrganization.id) return;
        getAdvertiserPixelStatsRequest();
    }, [currentOrganization.id]);

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            return;
        }

        if (!conversionOptions.advertiserPixelStats.value.length) {
            warningAlertExpanded(
                `You haven't created any conversions yet. Navigate to "Tracking" to setup Goal Tracking on your website.`,
                { toastId: "toast-tracking-alert" }
            );
        }
    }, [conversionOptions.advertiserPixelStats.value]);

    return (
        <motion.main {...fade} className="main tracking-page">
            <TrackingJoyride />
            <Navbar title="Tracking" />
            <div className="row">
                <Calendar
                    className="tracking-calendar"
                    _selectionRange={campaignSelectionRange}
                    callback={(range) => {
                        handleCalendar(range);
                        sendRequest();
                    }}
                    disabled={isLoading.campaignStatisticsLoading}
                />
                <TrackingDrawer />
            </div>
            <br />

            {conversionOptions.advertiserPixelStats.isLoading ? (
                <TableSkeleton message="Loading..." icon={textPreloader} />
            ) : (
                <>
                    <div className="scroll shadow" id="tour-step-3">
                        <table className="conversion-table">
                            <thead>
                                <tr>
                                    <th className="text-start">Conversion Name</th>
                                    <th>Conversions</th>
                                    <th>Date created</th>
                                    <th style={{ width: 0 }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!conversionOptions.advertiserPixelStats.value.length ? (
                                    <tr style={{ pointerEvents: "none" }}>
                                        <td colSpan="10">
                                            <div className="no-data">No Data To Show!</div>
                                        </td>
                                    </tr>
                                ) : (
                                    conversionOptions.advertiserPixelStats.value.map((item, index) => {
                                        return <ConversionTableRow key={index} {...{ item }} />;
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <br />
                    <br />
                </>
            )}
            <CodeType className="tracking-cover" />
        </motion.main>
    );
}

const TrackingJoyride = () => {
    const defaultSteps = [
        {
            content: (
                <h2 className="joyride-subtitle">
                    Welcome to the tracking guide. Follow these steps to track conversions.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Welcome to Tracking Guide",
        },

        {
            content: (
                <h2 className="joyride-subtitle">Click here to view the Blockchain-Ads tracking script.</h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#tracking-tutorial-2",
            title: "Viewing Tracking Script",
        },
    ];

    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });

    
    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "trackingTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("trackingTutorial")) return;
        setTimeout(() => {
            setSteps((prev) => ({ ...prev, run: true }));
        }, 500);
    }, []);


    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon 
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};

const ConversionTableRow = ({ item }) => {
    const {
        requestedData,
        successAlert,
        errorAlert,
        getAdvertiserPixelStatsRequest,
        conversionOptions,
        setConversionOptions,
        requestedData: {
            userProfileData: { currentOrganization },
            campaignData: { currentOrganizationCampaigns },
        },
        isLoading: { campaignDataIsLoading },
        trackingState,
        DeleteConversionPixelRequest,
    } = useGlobalContext();

    const { setDisplayInstallPixel, setDefaultTab, setIsEditingMode, setEditingTagId, setState } =
        trackingState;

    const { formatDateToYYYYMMDD } = useFormatter();
    const { DeleteConversionPixel } = useFetch();

    const [isStatLoading, setIsStatLoading] = useState(false);

    return (
        <tr>
            <td className="text-start">{item.tagName || "unknown"}</td>

            <td className="text-center">{item.conversionCount || 0}</td>
            <td className="text-center">
                {formatDateToYYYYMMDD(new Date(item.created_at._seconds * 1000)) || 0}
            </td>
            <td style={{ width: 0 }} className="text-center table-td-actions">
                <div className="btn-group">
                    <Button
                        variant="circle"
                        icon={penV2}
                        color="dark"
                        size="sm"
                        onClick={() => {
                            setDisplayInstallPixel("block");
                            setDefaultTab(1);
                            setIsEditingMode(true);
                            setEditingTagId(item.tagId);
                            setState({ tagName: item.tagName, url: "", contains: "" });
                        }}
                    />
                    <Button
                        variant="circle"
                        icon={trashV2}
                        color="danger"
                        size="sm"
                        onClick={() =>
                            DeleteConversionPixelRequest({
                                tagId: item.tagId,
                                setIsLoading: setIsStatLoading,
                            })
                        }
                        disabled={isStatLoading}
                    />
                </div>
            </td>
        </tr>
    );
};
