import React, { PureComponent, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Loading } from "../../../";

const isSmallScreen = window.innerWidth < 768;
const fontSize = isSmallScreen ? 8 : 12;

const defaultData = [
    {
        amt: 0,
        name: "Emtpy",
        uv: 0,
    },
  
];

const lineColors = [
    'purple',
    'rgba(57, 249, 147)',
    'rgba(21, 161, 197,1)',
    'rgba(6, 23, 38,1)',
    'rgba(249, 57, 57,1)',
    'rgba(245, 167, 49,1)',
]
export default class Example extends PureComponent {
    static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";

    render() {
        const {
            data = [],
            isLoading = false,
            lines = [],
        } = this.props;
        return (
            <div className="position">
                <div className="position-inner">
                                      
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={data.length && data.length > 1 && !isLoading ? data : defaultData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >                                
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="name"
                                    // axisLine={false}
                                    // tickLine={false}
                                    // dy={15}
                                    // tick={{ fontSize: fontSize }}
                                />
                                <YAxis
                                    // axisLine={false}
                                    // tick={{ fontSize: fontSize }}
                                    // tickLine={false}
                                    // dx={-5}
                                />
                                <Tooltip />
                                <Legend 
                                    iconType="circle"
                                    iconSize={10}
                                    align="right"
                                    verticalAlign="top"
                                />
                                {lines.map((item, i) => {
                                    return (
                                        <Line
                                        type="monotone"
                                        dataKey={item}
                                        stroke={lineColors[i]}
                                        // dot={false}
                                        key={item}
                                    />
                                    )
                                })}

                            </LineChart>
                        </ResponsiveContainer>
                </div>
            </div>
        );
    }
}
