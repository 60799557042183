import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import { Tabs, Search, Button, Carousel } from "../../../components";

let filteredItemstimeout = "";
let loadingTimeout = "";

export default function Blockchains() {
    const {
        requestedData,
        setRequestedData,
        isLoading,
        getLoadBlockchainData,
        blockchainsState,
        setIsLoading,
        errorAlert,activeBlockchain, setActiveBlockchain
    } = useGlobalContext();
    const { blockchainTabs, setActiveNetwork } = blockchainsState;

    const { plus, arrowRightExtended, checkbox, close } = localData.svgs;

    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const [selectedQuery, setSelectedQuery] = useState("");
    const [storedBlockchains, setStoredBlockchains] = useState([]);

    const handleFind = (query) => {
        const tempRequestedData = { ...requestedData };
        const tempItems = [...tempRequestedData.blockchainData];

        let filteredItems = [];
        if (!storedBlockchains.length) {
            setStoredBlockchains(tempItems);
            filteredItems = tempItems.filter((item) => item.hash == query);
        } else {
            filteredItems = storedBlockchains.filter((item) => item.hash == query);
        }

        if (!filteredItems.length) {
            errorAlert("Txn Hash does not exist.");
            return;
        }

        setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));

        setRequestedData({
            ...requestedData,
            blockchainData: [...filteredItems],
        });

        setSelectedQuery(filteredItems[0].hash);
        loading();
    };

    const handleBlockchainsReset = () => {
        setRequestedData({
            ...requestedData,
            blockchainData: [...storedBlockchains],
        });
        setSelectedQuery("");
        loading();
    };

    const loading = () => {
        setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
        clearTimeout(loadingTimeout);
        loadingTimeout = setTimeout(() => {
            setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: false }));
        }, 1000);
    };

    return (
        <div className="blockchains-wrapper">
            <div className="row">
                <div className="wrapper">
                    <h2 className="display-2">Activity</h2>

                    <Search
                        {...{
                            variant: "contained",
                            className: `blockchains-search`,
                            isOpen: isSearchOpen,
                            setIsOpen: setIsSearchOpen,
                            size: "sm",
                            color: "dark",
                            disabled: isLoading.blockchainDataIsLoading,
                            callback: handleFind,
                        }}
                    />
                    {selectedQuery && !isLoading.blockchainDataIsLoading && (
                        <div className="hash-query">
                            <div className="hash-query-text">{selectedQuery}</div>
                            <Button
                                variant="circle"
                                icon={close}
                                size="sm"
                                color="secondary"
                                onClick={handleBlockchainsReset}
                            />
                        </div>
                    )}
                </div>

                <div className="scroll-items">
                    <Carousel
                        Card={(item, index) => {
                            return (
                                <Button
                                    size="sm"
                                    color="dark"
                                    className={`${activeBlockchain?.id == item.id ? "active" : ""}`}
                                >
                                    <div
                                        className={`scroll-item-link `}
                                        onClick={() => setActiveBlockchain(item)}
                                        title={item.name}
                                    >
                                        {item.name}
                                    </div>
                                </Button>
                            );
                        }}
                        options={{
                            slidesPerView: "auto",
                            breakpoints: null,
                            loop: false,
                            autoplay: false,
                            speed: 500,
                            spaceBetween: 3,
                        }}
                        items={requestedData.campaignData.currentOrganizationCampaigns.filter(
                            (filteredItem) => filteredItem.extraData.smartContractAddress
                        )}
                    />
                </div>
            </div>
            <Tabs
                tabs={blockchainTabs}
                className="blockchain-tabs"
                swiper={blockchainTabs.length > 4 ? true : false}
                callback={(activeNetwork) => {
                    // setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
                    setActiveNetwork(activeNetwork);
                }}
                isLoading={isLoading.blockchainDataIsLoading}
                hideTabs={blockchainTabs.length == 1 && blockchainTabs[0].title == "BSC"}
            />
        </div>
    );
}
