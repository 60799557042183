import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import { CSVLink } from "react-csv";
import { Button } from "../../../components";
import { useGlobalContext } from "../../../context";

export default function CampaignsStatiscticsCSV() {
    const { requestedData, isLoading } = useGlobalContext();
    const { exportIcon } = localData.svgs;

    const [csvReport, setCsvReport] = useState({
        filename: "campaign_progression_data.csv",
        headers: [],
        data: [],
    });

    useEffect(() => {
        handleoDwnload();
    }, [requestedData.campaignData.currentOrganizationCampaigns]);

    const formatDataToCSV = () => {
        const statisticsData = requestedData.campaignData.currentOrganizationCampaigns;
        let csvData = [];

        statisticsData.forEach((campaign) => {
            const { name, progressionData } = campaign;

            Object.keys(progressionData).forEach((date) => {
                const { clicks, impressions, amount_spent, ctr, cpc, cpm } = progressionData[date];

                const csvRow = {
                    Name: name,
                    Date: date,
                    Clicks: clicks,
                    Impressions: impressions,
                    AmountSpent: amount_spent,
                    CTR: ctr,
                    CPC: cpc,
                    CPM: cpm,
                };

                csvData.push(csvRow);
            });
        });


        const headers = [
            { label: "Name", key: "Name" },
            { label: "Date", key: "Date" },
            { label: "Clicks", key: "Clicks" },
            { label: "Impressions", key: "Impressions" },
            { label: "Amount Spent", key: "AmountSpent" },
            { label: "CTR", key: "CTR" },
            { label: "CPC", key: "CPC" },
            { label: "CPM", key: "CPM" },
        ];

        return { data: csvData, headers: headers };
    };

    const handleoDwnload = () => {
        const { data, headers } = formatDataToCSV();
        const csvReport = {
            filename: "campaign_progression_data.csv",
            headers: headers,
            data: data,
        };
        setCsvReport(csvReport);
    };

    return (
        <CSVLink
            {...csvReport}
            className={`csv-btn-link ${
                !requestedData.campaignData.currentOrganizationCampaigns.length ? "disabled" : ""
            }`}
        >
            <Button size="sm" className="csv-btn" color="secondary" name="Export" startIcon={exportIcon} />
        </CSVLink>
    );
}
