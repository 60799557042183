import React, { useEffect } from "react";
import { Button } from "..";
import localData from "../../localData";
import { useGlobalContext } from "../../context";

export default function Pagination({ currentPage = 1, totalPages = 5, callback, isLoading }) {
    const { arrowLeft, arrowRight } = localData.svgs;

    const {isDarkModeEnabled} = useGlobalContext()

    const handlePagination = (index) => {
        if (currentPage === 1 && index === -1) return;
        if (currentPage === totalPages && index === 1) return;
        const page = currentPage + index;
        callback(page);
    };

    return (
        <nav className={`pagination`} >
           
            <Button
                variant="text"
                disabled={currentPage === 1}
                color={`${currentPage === 1 ? "secondary" : "primary"}`}
                size="sm"
                name="First"
                onClick={() => callback(1)}
            />
            <Button
                variant="text"
                disabled={currentPage === 1}
                color={`${currentPage === 1 ? "secondary" : "primary"}`}
                size="sm"
                icon={arrowLeft}
                onClick={() => handlePagination(-1)}
            />
            <Button
                variant="text"
                disabled={true}
                color="secondary"
                size="sm"
                name={`Page ${currentPage} of ${totalPages}`}
            />
            <Button
                variant="text"
                disabled={currentPage === totalPages}
                color={`${currentPage === totalPages ? "secondary" : "primary"}`}
                size="sm"
                icon={arrowRight}
                onClick={() => handlePagination(1)}
            />
            <Button
                variant="text"
                disabled={currentPage === totalPages}
                color={`${currentPage === totalPages ? "secondary" : "primary"}`}
                size="sm"
                name="Last"
                onClick={() => callback(totalPages)}
            />
        </nav>
    );
}
