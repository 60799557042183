import React from "react";
import { CarouselRef } from "../index";

export default function BannerSlide({ data }) {
    return (
        <div className="banner-slide">
            <CarouselRef
                items={data}
                Card={(item, index) => {
                    // console.log(item.item)

                    return (
                        <div className="banner-slide-block">
                            <img src={item.item} alt="" />
                        </div>
                    );
                }}
                options={{
                    slidesPerView: "auto",
                    breakpoints: null,
                    loop: true,
                    autoplay: true,
                    speed: 500,
                    lazy:{ loadPrevNext: true }, 
                    spaceBetween: 3,
                    pagination: { clickable: true, dynamicBullets: true },
                }}
            />
        </div>
    );
}
