import React, { useState, useEffect, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Field } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";

export default function Invite({}) {
    const { getInviteUser, isLoading } = useGlobalContext();
    const { preloaderBig } = localData.images;

    const [state, setState] = useState({
        inviteeEmail: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        getInviteUser(state.inviteeEmail, () => setState({ inviteeEmail: "" }));
        // getInviteUser({...state,profile_image: profileImage})
    };

    return (
        <div className="invite">
            <form className="form" onSubmit={handleSubmit}>
                <div className="form-header">
                    <h2 className="form-title">Invite by email</h2>
                    <p className="form-description">Add mail to invite user to this organization</p>
                </div>

                <Field
                    name="inviteeEmail"
                    value={state.inviteeEmail}
                    type="email"
                    label="Email"
                    color="secondary"
                    placeholder="eg: mail@blockchain-ads.com"
                    required={true}
                    size="sm"
                    callback={handleOnChange}
                />

                <Button
                    className="submit-btn"
                    name="Invite"
                    color="primary"
                    variant="contained"
                    size="sm"
                    disabled={state.inviteeEmail == "" || isLoading.inviteUserIsLoading}
                    icon={
                        isLoading.inviteUserIsLoading ? (
                            <span className="endIcon">
                                <img src={preloaderBig} />
                            </span>
                        ) : null
                    }
                />
            </form>
        </div>
    );
}
