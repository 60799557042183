import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../../components";
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";

export default function ShoppingPanel() {
    const { marketplaceState } = useGlobalContext();
    const {publications}  = marketplaceState
    const { heart, shoppingCart } = localData.svgs;

    const [shoppingCartItems,setShoppingCartItems] = useState([])
    const [wishlistItems,setWishlistItems] = useState([])


    useEffect(()=>{
        let tempWishlistItems = [...publications]
        tempWishlistItems = tempWishlistItems.filter(item=> item.isInWishlist && {...item})
        setWishlistItems(tempWishlistItems)

        let tempShoppingCartItems = [...publications]
        tempShoppingCartItems = tempShoppingCartItems.filter(item=> item.isInShoppingCart && {...item})
        setShoppingCartItems(tempShoppingCartItems)
    },[publications])

    return (
        <div className={`panel ${shoppingCartItems.length ? 'active': ''}`}>
            <div className="btn-group">
                <Link to="/additional-products/pr/wishlist">
                    <Button className="wishlist-btn" variant="circle" size="" color="dark">
                        {heart}
                        {wishlistItems.length ? <div className="badge">{wishlistItems.length}</div> : ''}
                    </Button>
                </Link>
                <Link to="/additional-products/pr/shopping-cart">
                    <Button className="shopping-cart-btn" variant="circle" size="" color="dark">
                        {shoppingCart}
                        {shoppingCartItems.length ? <div className="badge badge-success">{shoppingCartItems.length}</div> : ''}
                    </Button>
                </Link>
            </div>
        </div>
    );
}
