import React, { useEffect } from "react";
import { Chart } from "react-google-charts";
import { ProgressBar } from "../../../components";
import { Loading,ChartSkeleton } from "../../../components";
import { useResizeDetector } from "react-resize-detector";

export default function App({ data = [], isLoading = false }) {
    const options = {
        // colorAxis: { colors: ["#e7711c", "#4374e0"] }, // Specify your desired colors
        colorAxis: { colors: ["#17A2C5"] }, // Specify your desired colors
        // apiKey: ''
    };

    const progressBarColor = options.colorAxis.colors[0];

    // Calculate total population
    const totalPopulation = data.slice(1).reduce((sum, [, population]) => sum + population, 0);

    const { width, ref } = useResizeDetector();

    try {
        return (
            <div className="geochart-wrapper">
                <h2 className="display-2">Users by Country</h2>
                <br />

                <div className="country-metrics shadow" >
                    {isLoading ? (
                        <ChartSkeleton />
                    ) : !data.length ? (
                        <div className="no-data"> No Data To Show!</div>
                    ) : (
                        <div className="country-metrics-inner">
                            <div className="wrapper" ref={ref} style={{ width: '100%' }}>
                                <Chart
                                    chartEvents={[
                                        {
                                            eventName: "select",
                                            callback: ({ chartWrapper }) => {
                                                const chart = chartWrapper.getChart();
                                                const selection = chart.getSelection();
                                                if (selection.length === 0) return;
                                                const region = data[selection[0].row + 1];
                                                console.log("Selected : " + region);
                                            },
                                        },
                                    ]}
                                    chartType="GeoChart"
                                    width={width || '100%'}
                                    // height="400px"
                                    data={data}
                                    options={options}
                                    // chartError={(err) => console.log('Google Charts Error:', err)}
                                />
                            </div>
                            <div className="details">
                                <div className="details-header">
                                    <div className="details-th">Country</div>
                                    <div className="details-th">Users</div>
                                </div>
                                <div className="details-body">
                                    {data.map((item, index) => {
                                        if (index === 0) return;
                                        const country = item[0];
                                        const population = item[1];
                                        const percentage = (population / totalPopulation) * 100;
                                        // const opacity = percentage / 100 + 0.3;
                                        return (
                                            <ProgressBar
                                                key={index}
                                                progress={percentage}
                                                title={country.slice(0, 2)}
                                                value={population.toFixed(0)}
                                                // color="#14A0C4"
                                                color={progressBarColor}
                                                // color={`rgba(23, 162, 197, ${opacity})`}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    } catch (err) {
        return null;
    }
}

// import React, { useEffect, useState } from "react";
// import { Chart } from "react-google-charts";
// import { ProgressBar } from "../../../components";
// import { Loading } from "../../../components";
// import { useResizeDetector } from "react-resize-detector";

// export default function App({ data = [], isLoading = false }) {
//     const options = {
//         colorAxis: { colors: ["#17A2C5"] },
//     };

//     const progressBarColor = options.colorAxis.colors[0];

//     // Calculate total population
//     const totalPopulation = data.slice(1).reduce((sum, [, population]) => sum + population, 0);

//     const { width, ref } = useResizeDetector();
//     const [chartKey, setChartKey] = useState(0);

//     useEffect(() => {
//         // Clear and redraw the chart when the window is resized
//         setChartKey((prevKey) => prevKey + 1);
//     }, [width]);

//     return (
//         <div className="geochart-wrapper">
//             <h2 className="display-2">Users by Country</h2>
//             <br />

//             <div className="country-metrics shadow scroll" >
//                 {isLoading ? (
//                     <Loading />
//                 ) : !data.length ? (
//                     <div className="no-data"> No Data To Show!</div>
//                 ) : (
//                     <>
//                         <div className="wrapper" ref={ref}>
//                             <Chart
//                                 key={chartKey} // Use a unique key to force re-render and clear/redraw the chart
//                                 chartEvents={[
//                                     {
//                                         eventName: "select",
//                                         callback: ({ chartWrapper }) => {
//                                             const chart = chartWrapper.getChart();
//                                             const selection = chart.getSelection();
//                                             if (selection.length === 0) return;
//                                             const region = data[selection[0].row + 1];
//                                             console.log("Selected: " + region);
//                                         },
//                                     },
//                                 ]}
//                                 chartType="GeoChart"
//                                 width={width || "100%"}
//                                 data={data}
//                                 options={options}
//                             />
//                         </div>
//                         <div className="details">
//                             <div className="details-header">
//                                 <div className="details-th">Country</div>
//                                 <div className="details-th">Users</div>
//                             </div>
//                             <div className="details-body">
//                                 {data.map((item, index) => {
//                                     if (index === 0) return;
//                                     const country = item[0];
//                                     const population = item[1];
//                                     const percentage = (population / totalPopulation) * 100;
//                                     return (
//                                         <ProgressBar
//                                             key={index}
//                                             progress={percentage}
//                                             title={country.slice(0, 2)}
//                                             value={population.toFixed(0)}
//                                             color={progressBarColor}
//                                         />
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                     </>
//                 )}
//             </div>
//         </div>
//     );
// }
