// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import {getAuth} from 'firebase/auth'
// import {getFirestore} from 'firebase/firestore'

// const firebaseConfig = {
//   apiKey: "AIzaSyCpiA9TD6vGpNaK6wbYJ0ZrVDZYvpOteLU",
//   authDomain: "testingproject-88286.firebaseapp.com",
//   projectId: "testingproject-88286",
//   storageBucket: "testingproject-88286.appspot.com",
//   messagingSenderId: "720621648239",
//   appId: "1:720621648239:web:5bda6578bc9e3f9239509a",
//   measurementId: "G-0T7ZXBVMGN"
// };

// const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);

// export const auth = getAuth(app)
// export const db = getFirestore(app)

import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// production
const firebaseConfig = {
  apiKey: "AIzaSyBug-lb-FZJy90PCRSA_974NJsZjT8bq0s",
  authDomain: "hub.blockchain-ads.com",
  projectId: "web3-marketing-hub",
  storageBucket: "web3-marketing-hub.appspot.com",
  messagingSenderId: "181319171049",
  appId: "1:181319171049:web:12da31d95d71495340e978",
  measurementId: "G-YEYWWNDQ4R",
};

// testing
// const firebaseConfig = {
//   apiKey: "AIzaSyCfhbIdnpCXjrK4G_fm8nNW38XCQg-qJiQ",
//   authDomain: "bca-hosting.firebaseapp.com",
//   projectId: "bca-hosting",
//   storageBucket: "bca-hosting.appspot.com",
//   messagingSenderId: "557101955747",
//   appId: "1:557101955747:web:91c322d5f80ccf674cdd16",
//   measurementId: "G-CPJX41ZS3E"
// };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
